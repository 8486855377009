import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MCConstants } from 'src/app/mc-helpers/mc-constants/mc-constants';
import { MCLoadingService } from 'src/app/mc-service/mc-loading.service';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/mc-helpers/mc-format-datapiker';
import { MCTeacherAPI } from 'src/app/mc-repository/mc-api/mc-teacher-api';
import { MCTeacherEntity } from 'src/app/mc-entity/mc-entities/mc-teacher-entitys';
import { MCUtils } from 'src/app/mc-helpers/mc-class/mc-utils';
import { MCAlert } from 'src/app/mc-helpers/mc-alert';
import { Router } from '@angular/router';
import { MCRouter } from 'src/app/mc-helpers/mc-class/mc-router';
import { MCSenceEntity, MCSenceEntityRequests } from 'src/app/mc-entity/mc-entities/mc-sence-entitys';
import { MCMessagesEnum } from 'src/app/mc-helpers/mc-enums/mc-messages-enum';
import { MCSenceAPI } from 'src/app/mc-repository/mc-api/mc-sence-api';
import { NgxSpinnerService } from 'ngx-spinner';

export interface attributes {
  hidden: boolean;
  selectedValueTeacher: string;
  action: string;
  codeChecked: boolean;
}

@Component({
  selector: 'app-mc-view-sence',
  templateUrl: './mc-view-sence.component.html',
  styleUrls: ['./mc-view-sence.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ],
})
export class MCViewSenceComponent implements OnInit {

  @Output() senceRespond = new EventEmitter<string>();
  private _mcRouter: MCRouter;
  listTeacher: MCTeacherEntity[] = [];
  sence: MCSenceEntity = new MCSenceEntity(String());

  attributes = {
    hidden: false,
    selectedValueTeacher: MCConstants.notSelected,
    action: MCConstants.register,
    codeChecked: false
  };

  constructor(
    private _loading: MCLoadingService,
    public _route: Router,
    private _mcAlert: MCAlert,
    private _mcTeacherAPI: MCTeacherAPI,
    private _mcSenceAPI: MCSenceAPI,
    private _mcUtils: MCUtils,
    private spinner: NgxSpinnerService
  ) {
    this._mcRouter = new MCRouter(_route);
    this.getTeachers();
  }

  ngOnInit(): void {
  }

  getTeachers() {
    this.listTeacher = [];
    this._mcTeacherAPI.getTeachers(1, 1000, String(), String(), data => {
      this.listTeacher = data.entities;
    }, error => {
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }

  actionButton(action: string) {
    if (action == MCConstants.select) {
      this.senceRespond.emit(MCConstants.sences);
    } else {
      if (this.validateForm()) {
        if (this.attributes.action == MCConstants.register) {
          this.post();
        } else if (this.attributes.action == MCConstants.modify) {
          this.put()
        }
      }
    }
  }

  validateForm(): boolean {
    if (!this._mcUtils.validateStringUndefined(this.sence.code)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.senceCodeFieldEmpty)
      return false;
    } if (!this._mcUtils.validateStringUndefined(this.sence.title)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.senceNameFieldEmpty)
      return false;
    } else if (this.sence.duration.toString() == '0' || !this._mcUtils.validateStringUndefined(this.sence.duration.toString())) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.senceDurationFieldEmpty)
      return false;
    } else if (!this._mcUtils.validateDate(this.sence.expired_date)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.senceExpirationDateMinorDate)
      return false;
    } else if (!this._mcUtils.validateFormSelect([this.attributes.selectedValueTeacher])) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.senceTeacherSeleted)
      return false;
    } else {
      return true;
    }
  }

  post() {
    this._loading.callLoading();
    let senceInsert: MCSenceEntityRequests = new MCSenceEntityRequests(this.sence);
    this._mcSenceAPI.postSence(senceInsert, data => {
      this.senceRespond.emit(MCConstants.register);
    }, error => {
      this._loading.closeLoading();
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }

  put() {
    this.spinner.show();
    let senceInsert: MCSenceEntityRequests = new MCSenceEntityRequests(this.sence);
    this._mcSenceAPI.putSence(this.sence.id!.toString(), senceInsert, () => {
      this.senceRespond.emit(MCConstants.modify);
    }, error => {
      this.spinner.hide();
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }

  codeValidate() {
    if (this._mcUtils.validateFormEmpty([this.sence.code])) {
      this.validateExist();
    } else {
      this._mcAlert.showAlertWarning(MCMessagesEnum.codeSenceFind);
    }
  }

  validateExist() {
    this._loading.callLoading();
    this._mcSenceAPI.getSence(this.sence.code, () => {
      this._loading.closeLoading();
      this._mcAlert.showAlertError(MCMessagesEnum.codeSenceExist);
    }, error => {
      this._loading.closeLoading();
      if (error.objectMessage == undefined) {
        this.attributes.codeChecked = true;
      } else {
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      }
    })
  }

  changeSelect(event: any, numero: number) {
    if (numero == 12) {
      for (let rep of this.listTeacher) {
        if (rep.id == Number(this.attributes.selectedValueTeacher)) {
          this.sence.teacher = rep;
          break;
        }
      }
    }
  }

  resetSence() {
    this.sence = new MCSenceEntity(String());
    this.attributes.codeChecked = false;
    this.attributes.action = MCConstants.register;
    this.attributes.selectedValueTeacher = MCConstants.notSelected;
  }

  passSence(passSence: MCSenceEntity) {
    this.attributes.codeChecked = true;
    this.sence = new MCSenceEntity(passSence);
    this.attributes.selectedValueTeacher = this.sence.teacher!.id.toString();
    this.attributes.action = MCConstants.modify;
  }

  //valida duracion
  validateDuration(event: any) {
    if (!this._mcUtils.onlyNumber(event)) {
      this.sence.duration = this.sence.duration.replace(event.key, String());
    }
  }

  capitalLetter(text: any){
    if(text == 'code'){
      this.sence.code = this.sence.code.toUpperCase();
    } else if(text == 'title'){
      this.sence.title = this.sence.title.toUpperCase();
    }
  }

}
