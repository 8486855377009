<div (window:resize)="onResize($event)" class="m-0 row justify-content-center form-content-main"
    style="background-color: var(--mcBlueSure); width: 100%; height: 100% !important;">
    <div class="col-auto text-center" style="padding: 0.5rem;">
        <div class="box-form mat-elevation-z22" style="margin-top: 20%; padding-top:0px !important;">

            <div class="d-flex bd-highlight mb-3 p-3 box-form-title">
                <div class="me-auto my-auto bd-highlight">Iniciar sesión</div>
                <div class="bd-highlight">
                    <img src="./assets/imgmahuida.jpeg" alt="" width="50px">
                </div>
            </div>

            <form>
                <div class="row form-box-style">

                    <div class="col-12">
                        <mat-form-field>
                            <mat-label>Correo Electrónico</mat-label>
                            <input matInput type="email" maxlength="40" placeholder="Ej. tucorreo@example.com"
                                name="email" [(ngModel)]="attributes.email" (keyup)="emailKeydownEvent()" required>
                            <mat-hint>{{attributes.messageEmail}}</mat-hint>
                        </mat-form-field>
                    </div>

                    <div class="col-12">
                        <mat-form-field appearance="legacy">
                            <mat-label>Clave</mat-label>
                            <input matInput [type]="attributes.hide ? 'password' : 'text'" maxlength="20" name="pass"
                                [(ngModel)]="attributes.password" required>
                            <!-- <span matPrefix><mat-icon>vpn_key</mat-icon>&nbsp;</span> -->
                            <span matSuffix>
                                <button mat-icon-button (click)="attributes.hide = !attributes.hide"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="attributes.hide">
                                    <mat-icon>{{attributes.hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            </span>
                        </mat-form-field>
                    </div>

                </div>
            </form>
            <mat-divider></mat-divider>
            <div class="d-flex justify-content-between box-form-footer-old">
                <div>
                    <!-- <button mat-raised-button color="accent">
                        <mat-icon>close</mat-icon> Cerrar
                    </button> -->
                </div>
                <div class="separater"></div>
                <div>
                    <button *ngIf="true" mat-raised-button color="primary" (click)="post()">
                        <mat-icon>lock_open</mat-icon> Entrar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="position-absolute bottom-0 start-0"
    style="padding-bottom: 10px; padding-left: 10px; font-size: 16px; font-weight: 500;">
    Systema desarrollado por: <a href="https://senfys.com/" target="_blank"
        style="color: var(--backgroundColor)">SENFYS.COM </a>
    <span style="font-weight: 400;">v1.0.0</span>
</div> -->

<div *ngIf="attributes.isMobile" class="back-to-topw" style="line-height: normal;">
    Systema desarrollado por: <a href="https://senfys.com/" target="_blank"
        style="color: var(--backgroundColor)">SENFYS.COM </a>
    <span style="font-weight: 400;">v1.0.0</span>
</div>
