import { Injectable } from "@angular/core";
import { MCError } from "src/app/mc-helpers/mc-error";
import { MCDataSourceAPICoreBuilder } from "src/app/mc-entity/mc-builders/mc-data-source-api-core-builder";
import { MCDataSourceLocal, MCDataSourceLocalInterface } from "../mc-data-source-local";
import { MCDataSourceAPI } from "./mc-data-source-api";
import { MCEndpointsConstants } from "src/app/mc-helpers/mc-constants/mc-endpoints-constants";
import { HttpParams } from "@angular/common/http";
import { MCConstants } from "src/app/mc-helpers/mc-constants/mc-constants";
import { MCTeacherEntityRequests, MCTeachersEntity } from "src/app/mc-entity/mc-entities/mc-teacher-entitys";
import { MCHttpMethodsEnum } from "src/app/mc-helpers/mc-enums/mc-http-methods-enum";
import { MCAcademicTitlesEntity } from "src/app/mc-entity/mc-entities/mc-academic-title-entitys";
import { MCUtils } from "src/app/mc-helpers/mc-class/mc-utils";


export interface MCTeacherAPIInterface {

  getTeacher(
    document:string,
    success: () => void,
    failure: (error: MCError) => void
  ): any;

  getTeachers(
    page: number,
    limit: number,
    name: string,
    document:string,
    success: (entity: MCTeachersEntity) => void,
    failure: (error: MCError) => void
  ): any;

  putTeacher(
    idTeacher: string,
    teacher: MCTeacherEntityRequests,
    success: () => void,
    failure: (error: MCError) => void
  ): any;

  postTeacher(
    teacher: MCTeacherEntityRequests,
    success: (entity: any) => void,
    failure: (error: MCError) => void
  ): any;

  deleteTeacher(
    idTeacher: string,
    success: () => void,
    failure: (error: MCError) => void
  ): any;

  getAcademicTitles(
    success: (entity: MCAcademicTitlesEntity) => void,
    failure: (error: MCError) => void
  ): any;

}

@Injectable({
  providedIn: 'root'
})
export class MCTeacherAPI implements MCTeacherAPIInterface {
  // Private properties.

  private _omDataSourceLocal: MCDataSourceLocalInterface = new MCDataSourceLocal();

  // Contructors.

  constructor(private _apiDataSource: MCDataSourceAPI, private _mcUtils: MCUtils) { }


  postTeacher(
    teacher: MCTeacherEntityRequests,
    success: (entity: any) => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    builder.endpoint = MCEndpointsConstants.teacherAPI.teachers;
    builder.httpMethod = MCHttpMethodsEnum.post;
    builder.body = JSON.stringify(teacher);
    this._apiDataSource.core(builder).subscribe((data: any) => {
      success(data);
    }, error => {
      console.log(error);
      failure(new MCError(error));
    })
  }

  putTeacher(
    idTeacher: string,
    teacher: MCTeacherEntityRequests,
    success: () => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    builder.httpMethod = MCHttpMethodsEnum.put;
    builder.body = JSON.stringify(teacher);
    builder.endpoint = MCEndpointsConstants.teacherAPI.teacher.replace("[ID]", idTeacher);
    this._apiDataSource.core(builder).subscribe(() => {
      success()
    }, error => {
      failure(new MCError(error));
    })
  }

  deleteTeacher(
    idTeacher: string,
    success: () => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    builder.httpMethod = MCHttpMethodsEnum.delete;
    builder.endpoint = MCEndpointsConstants.teacherAPI.teacher.replace("[ID]", idTeacher);
    this._apiDataSource.core(builder).subscribe(() => {
      success()
    }, error => {
      console.log(error);
      failure(new MCError(error));
    })
  }

  getTeacher (
    document: string,
    success: () => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    builder.endpoint = MCEndpointsConstants.teacherAPI.teacher.replace("[ID]", document);
    this._apiDataSource.core(builder).subscribe((data: any) => {
      success()
    }, error => {
      failure(new MCError(error));
    })
  }

  getTeachers (
    page: number,
    limit: number,
    name: string,
    academic_title: string,
    success: (entity: MCTeachersEntity) => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    let params: HttpParams = new HttpParams();
    params = params.append(MCConstants.paramLimit, limit.toString());
    params = params.append(MCConstants.paramPage, page.toString());
    if(this._mcUtils.validateStringUndefined(name)) {
      params = params.append(MCConstants.paramName, name)
    }
    if(this._mcUtils.validateStringUndefined(academic_title)) {
      params = params.append(MCConstants.paramAcademicTitle, academic_title)
    }
    builder.httpParams = params;
    builder.endpoint = MCEndpointsConstants.teacherAPI.teachers;
    this._apiDataSource.core(builder).subscribe((data: any) => {
      success(new MCTeachersEntity(data.meta.total,data.data))
    }, error => {
      failure(new MCError(error));
    })
  }

  getAcademicTitles (
    success: (entity: MCAcademicTitlesEntity) => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    builder.endpoint = MCEndpointsConstants.teacherAPI.academicTitles;
    this._apiDataSource.core(builder).subscribe((data: any) => {
      success(new MCAcademicTitlesEntity(data.data))
    }, error => {
      failure(new MCError(error));
    })
  }

}
