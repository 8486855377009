import { MCTemplateEntity } from "./mc-template-entitys";

export class MCBasicCoursesEntity {
    // Properties.

    total: number;
    entities: MCBasicCourseEntity[] = [];

    // Constructors.

    constructor(total: number, object: any) {
        this.total = total ?? 0;
        if (object) {
            for (let entity of object) {
                this.entities.push(new MCBasicCourseEntity(entity));
            }
        }
    }
} 

export class MCBasicCourseEntity {
    id: string; 
    name: string;
    certificate_title: string;      
    template: MCTemplateEntity;

    constructor(object: any) {
        this.id = object.id ?? String();        
        this.name = object.name ?? String();
        this.certificate_title = object.certificate_title ?? String();       
        this.template = object.template ?? new MCTemplateEntity(String()); 
    }
}

export class MCBasicCourseEntityRequests { 
    id: string; 
    name: string;
    certificate_title: string;      
    template_id: number; 

    constructor(object: any) { 
        this.id = object.id;        
        this.name = object.name;
        this.certificate_title = this.lineBreak(object.certificate_title);   
        this.template_id = 1; 
    } 

    lineBreak(value: string) : string {
        return value.replace(/(\r\n|\n|\r)/gm, " ");
    } 
} 