<div *ngIf="attributes.hidden" class="m-0 row justify-content-center form-content-main">
  <div class="col-auto text-center" style="padding: 0.5rem;">
    <div class="box-form mat-elevation-z22">

      <form>
        <div class="row form-box-style">

          <div class="col-12">
            <mat-form-field>
              <mat-label>RUT</mat-label>
              <input matInput type="text" maxlength="12" name="rut" [disabled]="attributes.rutChecked"
                [(ngModel)]="teacher.document" required>
              <button mat-button matSuffix mat-icon-button aria-label="Find" class="btnBusquedas"
                [disabled]="attributes.rutChecked" [class.disabled-class]="attributes.rutChecked"
                (click)="rutValidate()">
                <mat-icon>search</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field appearance="legacy">
              <mat-label>Nombres y Apellidos</mat-label>
              <input matInput placeholder="Ej. Erwis José Álvarez Álvarez" maxlength="100" name="namecom"
                [disabled]="!attributes.rutChecked" [(ngModel)]="teacher.name" (keyup)="capitalLetter('name')" required>
            </mat-form-field>
          </div>

          <div class="col-12 aling-radio-button">
            <mat-radio-group [(ngModel)]="teacher.sex">
              <mat-radio-button value="Hombre" [disabled]="!attributes.rutChecked">Hombre</mat-radio-button>
              <mat-radio-button value="Mujer" [disabled]="!attributes.rutChecked">Mujer</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-12" style="padding-bottom: 15px;">
            <div class="fieldOld">
              <fieldset class="scheduler-border fieldOld" [disabled]="!attributes.rutChecked">
                <legend
                  [ngClass]="attributes.rutChecked == true ? 'scheduler-border-dos':'scheduler-border-dos-disabled'">
                  Títulos Profesionales *</legend>
                <div>
                  <!-- <p style="line-height: normal; text-align: left; padding-left: 5px;">Seleccione un título profesional y de clic al botón [+]
                    Repita la operación para agregar más de un título profesional</p> -->
                  <div class="row">
                    <div class="col-10 sin extra">
                      <mat-form-field appearance="legacy">
                        <mat-select disableRipple [(ngModel)]="attributes.selectedValueAcademicTitle"
                          [disabled]="!attributes.rutChecked" name="tip">
                          <mat-option value="notselected">-- Seleccione --</mat-option>
                          <mat-option *ngFor="let tp of listAcademicTitle; let iv = index" [value]="tp.id.toString()">
                            {{tp?.title}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div class="col-2 sin"><button mat-raised-button color="primary" class="mat-raised-button-add"
                        (click)="addAcademicTitle()" [disabled]="!attributes.rutChecked">+</button></div>
                  </div>

                  <mat-divider></mat-divider>
                  <mat-selection-list [multiple]="false" style="line-height: normal; padding-bottom: 10px;"
                    [disabled]="!attributes.rutChecked">
                    <mat-list-option *ngFor="let rept of listAcademicTitleTeacher;" [value]="rept.id">
                      {{rept.title}} <mat-icon mat-list-icon (click)="deletelistAcademicTitleTeacher(rept)">delete
                      </mat-icon>
                    </mat-list-option>
                  </mat-selection-list>
                </div>
              </fieldset>
            </div>
          </div>

          <!--<div class="col-12">
            <mat-form-field appearance="legacy">
              <mat-label>Título profesional</mat-label>
              <input matInput maxlength="100" name="title" [disabled]="!attributes.rutChecked"
                [(ngModel)]="teacher.occupation" required>
            </mat-form-field>
          </div> -->

          <div class="col-12">
            <mat-form-field appearance="legacy">
              <mat-label>Curriculum para Diploma</mat-label>
              <input matInput maxlength="240" name="name" [disabled]="!attributes.rutChecked"
                [(ngModel)]="teacher.curriculum" (keyup)="capitalLetter('curriculum')" required>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field>
              <mat-label>Celular</mat-label>
              <span matPrefix [class.disabled-class]="!attributes.rutChecked">+56&nbsp;</span>
              <input matInput type="tel" (paste)="false" placeholder="923456781" maxlength="9" name="celular"
                [disabled]="!attributes.rutChecked" (keyup)="phoneKeydownEvent($event)" [(ngModel)]="teacher.mobile"
                required>
              <mat-hint>{{attributes.messageMobile}}</mat-hint>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field>
              <mat-label>Correo Electrónico</mat-label>
              <input matInput type="email" maxlength="60" placeholder="Ej. tucorreo@example.com" name="email"
                [disabled]="!attributes.rutChecked" [(ngModel)]="teacher.email" (keyup)="emailKeydownEvent()" required>
              <mat-hint>{{attributes.messageEmail}}</mat-hint>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field appearance="legacy">
              <mat-label>Región</mat-label>
              <mat-select disableRipple [disabled]="!attributes.rutChecked" [(ngModel)]="attributes.selectedValueRegion"
                (selectionChange)="changeSelect($event,12)" name="reg" required>
                <mat-option value="notselected">-- Región --</mat-option>
                <mat-option *ngFor="let reg of listRegion; let iv = index" [value]="reg.id">{{reg?.id}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field appearance="legacy">
              <mat-label>Comuna</mat-label>
              <mat-select disableRipple [disabled]="!attributes.rutChecked"
                [(ngModel)]="attributes.selectedValueCommune" (selectionChange)="changeSelect($event,13)" name="com"
                required>
                <mat-option value="notselected">-- Comuna --</mat-option>
                <mat-option *ngFor="let com of listCommune" [value]="com.id.toString()">{{com?.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field appearance="legacy">
              <mat-label>Dirección</mat-label>
              <textarea matInput [rows]="1" maxlength="497" name="dir" [disabled]="!attributes.rutChecked"
                [(ngModel)]="teacher.address" (keyup)="capitalLetter('address')"></textarea>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field appearance="legacy">
              <mat-label>Observación</mat-label>
              <textarea matInput [rows]="1" maxlength="497" name="obs" [disabled]="!attributes.rutChecked"
                [(ngModel)]="teacher.observation" (keyup)="capitalLetter('observation')"></textarea>
            </mat-form-field>
          </div>

          <div class="col-12" *ngIf="attributes.action != 'Registrar'">
            <div class="fieldOld">
              <fieldset class="scheduler-border fieldOld" [disabled]="!attributes.rutChecked">
                <legend [ngClass]="attributes.rutChecked == true ? 'scheduler-border':'scheduler-border-disabled'">
                  Códigos Sence Asociados</legend>
                <div>
                  <mat-selection-list [multiple]="false" style="line-height: normal;">
                    <mat-list-option *ngFor="let item of teacher.sences;" [value]="item.id" style="padding-left: 5px;">
                      {{item.title}}
                    </mat-list-option>
                  </mat-selection-list>
                </div>
              </fieldset>
            </div>
          </div>

        </div>
      </form>
      <mat-divider></mat-divider>
      <div class="d-flex justify-content-between box-form-footer">
        <div>
          <button mat-raised-button color="primary" [disabled]="!attributes.rutChecked" (click)="actionButton('')">
            <mat-icon>save</mat-icon> Guardar
          </button>
        </div>
        <div class="separater"></div>
        <div>
          <button mat-raised-button color="accent" (click)="actionButton('Select')">
            <mat-icon>close</mat-icon> Cerrar
          </button>
        </div>
      </div>

    </div>
  </div>
</div>


<!-- <div class="col-12" style="padding-top: 15px;">
            <div class="d-flex bd-highlight">
              <div class="w-100 bd-highlight" style="padding-right: 10px;">
                <mat-form-field appearance="legacy">
                <mat-label>Agregar título profesional </mat-label>
                <mat-select disableRipple [(ngModel)]="attributes.selectedValueRegion" name="tip">
                  <mat-option value="notselected">-- Seleccione --</mat-option>
                  <mat-option *ngFor="let tp of listAcademicTitle; let iv = index" [value]="tp.id">{{tp?.title}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
              <div class="flex-shrink-1 bd-highlight cincopx">
                <button mat-raised-button color="primary" style="font-size: 20px; margin-top: 10px;">+</button>
              </div>
            </div>
          </div> -->



<!-- <div class="col-12" style="padding-top: 20px; padding-bottom: 10px;">
            <div class="fieldOld">
              <fieldset class="scheduler-border fieldOld">
                <legend class="scheduler-border-dos">Títulos profesionales asignados *</legend>

                <div>
                  <p style="line-height: normal; text-align: left; padding-left: 5px;">Seleccione un título profesional y de clic al botón [+]
                     Repita la operación para agregar más de un título profesional</p>
                  <div class="col-12 especial">
                    <div class="d-flex bd-highlight">
                      <div class="w-100 bd-highlight" style="padding-right: 10px;">
                        <mat-form-field appearance="legacy">
                        <mat-select disableRipple [(ngModel)]="attributes.selectedValueRegion" name="tip">
                          <mat-option value="notselected">-- Seleccione --</mat-option>
                          <mat-option *ngFor="let tp of listAcademicTitle; let iv = index" [value]="tp.id">{{tp?.title}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                      <div class="flex-shrink-1 bd-highlight cincopx">
                        <button mat-raised-button color="primary" style="font-size: 20px; margin-top: 10px;">+</button>
                      </div>
                    </div>
                  </div>

                  <mat-selection-list [multiple]="false" style="line-height: normal; padding-bottom: 5px;">
                    <mat-list-option *ngFor="let rept of teacher.academic_titles;" [value]="rept.id" style="padding-left: 5px;">
                      {{rept.title}}
                    </mat-list-option>
                  </mat-selection-list>
                </div>
              </fieldset>
            </div>
          </div>  -->


<!-- <div class="col-12">
            <div class="d-flex bd-highlight">
              <div class="w-100 bd-highlight" style="padding-right: 10px;">
                <mat-form-field appearance="legacy">
                <mat-select disableRipple name="com" required>
                  <mat-option value="ana">emilia</mat-option>
                  <mat-option value="jose">alvarez</mat-option>
                  <mat-option value="otro">lalo</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
              <div class="flex-shrink-1 bd-highlight cincopx">
                <button mat-raised-button color="primary" style="font-size: 20px; margin-top: 10px;">+</button>
              </div>
            </div>
          </div> -->


<!-- <div class="d-flex bd-highlight mb-3 p-3 box-form-title">
        <div class="me-auto my-auto bd-highlight">{{title}}</div>
        <div class="bd-highlight">
          <button mat-icon-button (click)="requestTeacher('list')">
            <mat-icon class="white-icon">close</mat-icon>
          </button>
        </div>
      </div> -->
