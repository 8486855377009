import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MCRouter } from 'src/app/mc-helpers/mc-class/mc-router';
import { MCDataSourceLocal, MCDataSourceLocalInterface } from 'src/app/mc-repository/mc-data-source-local';
import { MatPaginator } from '@angular/material/paginator';
import { MCConstants } from 'src/app/mc-helpers/mc-constants/mc-constants';
import { MCOpenCourseInscriptionsCustomerEntity } from 'src/app/mc-entity/mc-entities/mc-open-courses-customer-entitys';
import { MCRegionEntity } from 'src/app/mc-entity/mc-entities/mc-region-entitys';
import { MCLoadingService } from 'src/app/mc-service/mc-loading.service';
import { Router } from '@angular/router';
import { MCAlert } from 'src/app/mc-helpers/mc-alert';
import { MCCustomerAPI } from 'src/app/mc-repository/mc-api/mc-customer-api';
import { MCOpenCoursesAPI } from 'src/app/mc-repository/mc-api/mc-open-courses-api';
import { MCUtils } from 'src/app/mc-helpers/mc-class/mc-utils';
import { MCDependencyEntity } from 'src/app/mc-entity/mc-entities/mc-dependency-entitys';
import { MCCommuneEntity } from 'src/app/mc-entity/mc-entities/mc-commune-entitys';
import { MCMessagesEnum } from 'src/app/mc-helpers/mc-enums/mc-messages-enum';
import { MCStudentCustomerEntity } from 'src/app/mc-entity/mc-entities/mc-students-customer-entitys';
import { MCCourseStudentComponent } from '../mc-course-student/mc-course-student.component';


export interface attributesView {
  idDeleteStudent: string;
  idInscription: string;
  filterCollapses: boolean;
  view_crud: boolean;
  isMobile: boolean;
  lengthPaginador: number;
  pageIndex: number;
  pageSize: number;
  pageSizeOptions: number[];
  hideFiltersText: string;
  showFilteraText: string;
  tableArrow: any;
  isMobileTwo: boolean;
  idOpenCourse: number;
  btnTitle: string;
  search: string;
}


@Component({
  selector: 'app-mc-course-students',
  templateUrl: './mc-course-students.component.html',
  styleUrls: ['./mc-course-students.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MCCourseStudentsComponent implements OnInit {

  private _mcRouter: MCRouter;
  @ViewChild(MCCourseStudentComponent) child: any;
  @Output() modalRespond = new EventEmitter<string>();

  columnsToDisplay = ['scu_first', 'scu_last_name', 'scu_mothers_last_name', 'scu_name', 'scu_document', 'scu_cust_document', 'scu_arrow'];
  private _mcDataSourceLocal: MCDataSourceLocalInterface = new MCDataSourceLocal();

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  attributesView = {
    idDeleteStudent: String(),
    idInscription: String(),
    filterCollapses: false,
    view_crud: false,
    isMobile: false,
    pageIndex: 0,
    pageSize: 10,
    pageSizeOptions: [10, 20, 30, 50],
    lengthPaginador: 10,
    hideFiltersText: MCConstants.hideFiltersText,
    showFilteraText: MCConstants.showFilteraText,
    tableArrow: null,
    isMobileTwo: false,
    idOpenCourse: 0,
    btnTitle: "Agregar Participante",
    search: String()
  }

  listDataService: MCOpenCourseInscriptionsCustomerEntity[] = [];

  listDataOriginal: MCStudentCustomerEntity[] = [];
  listData: MCStudentCustomerEntity[] = [];
  listDataTwo: MCStudentCustomerEntity[] = [];
  listDataCopy: MCStudentCustomerEntity[] = [];

  listRegion: MCRegionEntity[] = [];
  listCommune: MCCommuneEntity[] = [];
  listDependency: MCDependencyEntity[] = [];

  constructor(
    private _loading: MCLoadingService,
    public _route: Router,
    private _mcAlert: MCAlert,
    private _mcCustomerAPI: MCCustomerAPI,
    private _mcOpenCoursesAPI: MCOpenCoursesAPI,
    private _mcUtils: MCUtils
  ) {
    this._mcRouter = new MCRouter(_route);
  }

  ngOnInit(): void {


    this.ChangeMobile(window.innerWidth);
  }

  onResize(event: any) {
    this.ChangeMobile(event.target.innerWidth);
  }

  ChangeMobile(size: number) {
    this.attributesView.isMobile = size < 1150;
    this.attributesView.isMobileTwo = size < 950
    if (this.attributesView.isMobile == false) {
      this.attributesView.btnTitle = "Agregar Participante";
      this.columnsToDisplay = ['scu_first', 'scu_last_name', 'scu_mothers_last_name', 'scu_name', 'scu_document', 'scu_cust_document', 'scu_arrow'];
    } else {//movil
      if (this.attributesView.isMobileTwo == false) {
        this.attributesView.btnTitle = "Agregar Participante";
        this.columnsToDisplay = ['scu_first', 'scu_last_name', 'scu_mothers_last_name', 'scu_name', 'scu_document', 'scu_cust_document', 'scu_arrow'];
      } else {
        this.attributesView.btnTitle = String();
        this.columnsToDisplay = ['scu_first', 'scu_last_name', 'scu_mothers_last_name', 'scu_name', 'scu_arrow'];
      }
    }
  }

  getSencesBeforePaging(idOpenCourse: number) {
    this.listDataService = [];
    this._loading.callLoading();
    this._mcOpenCoursesAPI.getOpenCoursesCustomers(idOpenCourse, data => {
      this.listDataService = data.entities;
      this.child.listData = data.entities;
      this.cleanData();
    }, error => {
      console.log(error);
      this._loading.closeLoading();
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }


  cleanData() {
    let listClean: MCStudentCustomerEntity[] = [];
    this.listDataOriginal = [];
    this.listData = [];
    if(this.listDataService.length > 0) {
      for(let data of this.listDataService) {
        if(data.students.length > 0) {
            for(let inscrStudent of data.students) {
              listClean.push(new MCStudentCustomerEntity(
                inscrStudent.id,
                data.id,
                inscrStudent.student.id,
                inscrStudent.student.document_type,
                inscrStudent.student.document,
                inscrStudent.student.name,
                inscrStudent.student.last_name,
                inscrStudent.student.mothers_last_name,
                inscrStudent.student.email ,
                inscrStudent.student.profession,
                inscrStudent.student.institution,
                inscrStudent.student.observation,
                data.customer.id,
                data.customer.type,
                data.customer.businessName,
                data.customer.document,
                data.customer.mobile,
                data.customer.commune.region.id,
                data.customer.commune.name
              )
            );
          }
        }
      }
    }


      let list = listClean.sort((a,b)=> a.last_name.toLowerCase().localeCompare(b.last_name.toLowerCase()) ||
                  a.mothers_last_name.toLowerCase().localeCompare(b.mothers_last_name.toLowerCase())  ||
                  a.name.toLowerCase().localeCompare(b.name.toLowerCase()))


      this.attributesView.lengthPaginador = list.length;
      this.listDataOriginal = list;
      this.attributesView.pageIndex = 0;
      this.listData = this.listDataOriginal;
      this.localTableData();
      this._loading.closeLoading();
  }

  hola(value: number) {
    this.attributesView.idOpenCourse = value;
    this.getSencesBeforePaging(value);
    this.ChangeMobile(window.innerWidth);
  }

  changeSelect(event: any, numero: number) {
    if (numero == 12) {
      //this.fillCommunes(this.filter.selectedValueRegion);
    }
  }

  //llena el combo de comunas dependiendo del la region seleccionada
  fillCommunes(valor: any) {
    if (valor != MCConstants.notSelected) {
      for (let rep of this.listRegion) {
        if (valor == rep.id) {
          this.listCommune = rep.communes!;
          break;
        }
      }
    } else {
      this.listCommune = [];
    }
  }


  deleteStudentInscription() {
    this._loading.callLoading();
    this._mcOpenCoursesAPI.deleteStudentInscription(
      this.attributesView.idOpenCourse,
      this.attributesView.idInscription,
      this.attributesView.idDeleteStudent, () => {
        this._loading.closeLoading();
        this.getSencesBeforePaging(this.attributesView.idOpenCourse);
        this._mcAlert.showAlertInformation(MCMessagesEnum.globalRemoveInscriptionStudent);
    }, error => {
      console.log(error);
      this._loading.closeLoading();
      if (error.objectMessage == undefined) {
        this._mcAlert.showAlertInformation(MCMessagesEnum.globalRemoveInscriptionStudent);
      } else {
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      }
     })
   }

  registerStudent() {
    this.attributesView.view_crud = !this.attributesView.view_crud;
    this.child.attributes.hidden = true;
    this.child.attributes.idOpenCourse = this.attributesView.idOpenCourse;
    this.child.resetStudent();
  }

  openModal(value: any) {
    this.attributesView.idDeleteStudent = value.id_inscription_student;
    this.attributesView.idInscription = value.id_inscriptions;
    this.modalRespond.emit("student");
  }

  actionCollapses() {
    this.attributesView.filterCollapses = !this.attributesView.filterCollapses;
  }

  indicatorArrow(value: any) {
    this.attributesView.tableArrow = value;
  }

  inscriptionRespond($event: any) {
    this.child.attributes.hidden = false;
    this.attributesView.view_crud = !this.attributesView.view_crud;
    if ($event == MCConstants.register) {
      this.getSencesBeforePaging(this.attributesView.idOpenCourse);
      this._mcAlert.showAlertInformation(MCMessagesEnum.enrolledStident);
    } else if ($event == MCConstants.modify) {
      this.getSencesBeforePaging(this.attributesView.idOpenCourse);
      this._mcAlert.showAlertInformation(MCMessagesEnum.globalUpdateData);
    }
    this.onActivate();
  }

  onActivate() {
    let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, pos - 60); // how far to scroll on each step
        } else {
            window.clearInterval(scrollToTop);
        }
    }, 4);
  }

  search(value: string) {
    if(value == "1") { this.attributesView.pageIndex = 0; }
    this.listData = [];
    if(this.attributesView.search.trim() == String()) {
      this.listData = this.listDataOriginal;
    } else {
      for(let ite of this.listDataOriginal) {
          let concat = ite.last_name +" "+ite.mothers_last_name+" "+ite.name;
        if(concat.toLowerCase().includes(this.attributesView.search.toLowerCase())) {
          this.listData.push(ite);
        }
      }
    }
    this.attributesView.lengthPaginador = this.listData.length;
    this.localTableData();
  }

  paginador(event: any) {
    if (this.attributesView.pageSize != event.pageSize) {
      this.attributesView.pageIndex = 0;
      this.attributesView.pageSize = event.pageSize;
    } else {
      this.attributesView.pageIndex = event.pageIndex;
    }
    this.search(String());
  }

  //recargar datos de la tabla
  localTableData() {
    this.listDataTwo = [];
    this.listDataCopy = [];
    for (let i: number = 0; i < this.listData.length; i++) {
      let mult: number = this.attributesView.pageIndex * this.attributesView.pageSize;
      if ((i >= mult) && (i < (mult + this.attributesView.pageSize))) {
        this.listDataTwo.push(this.listData[i]);
        this.listDataCopy.push(this.listData[i]);
      }
    }
  }

}
