export enum MCRoutesEnum {
    login = "login",
    admin = "admin",
    customer = "cliente",
    student = "participante",
    sence = "sence",
    teacher = "relator",
    course = "curso",
    courseOpen = "curso-abierto",
    courseClose = "curso-cerrado",
    email = "correos",
    doubleAsterisk = "**",
    full = "full",
    main = "main",
    error = "error",
    empty = ""
}
