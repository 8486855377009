import { Injectable } from "@angular/core";
import { MCError } from "src/app/mc-helpers/mc-error";
import { MCDataSourceAPICoreBuilder } from "src/app/mc-entity/mc-builders/mc-data-source-api-core-builder";
import { MCDataSourceLocal, MCDataSourceLocalInterface } from "../mc-data-source-local";
import { MCDataSourceAPI } from "./mc-data-source-api";
import { MCEndpointsConstants } from "src/app/mc-helpers/mc-constants/mc-endpoints-constants";
import { HttpHeaders } from "@angular/common/http";
import { MCConstants } from "src/app/mc-helpers/mc-constants/mc-constants";

export interface MCConfigurationAPIInterface {

  getRegionsPublic(
    success: (data: any) => void,
    failure: (error: MCError) => void
  ): any;

}

@Injectable({
  providedIn: 'root'
})
export class MCConfigurationAPI implements MCConfigurationAPIInterface {
  // Private properties.

  private _omDataSourceLocal: MCDataSourceLocalInterface = new MCDataSourceLocal();

  // Contructors.

  constructor(private _apiDataSource: MCDataSourceAPI) { }

  getRegionsPublic(
    success: (data: any) => void,
    failure: (error: MCError) => void
  ) {//token
    // let t = JSON.parse(this._omDataSourceLocal.getToken());
    // let ma = t.token;
    // let httpHeaders = new HttpHeaders();
    // httpHeaders = httpHeaders.append(MCConstants.authorization,`Bearer ${ma}`);

    const builder = new MCDataSourceAPICoreBuilder();
    builder.endpoint = MCEndpointsConstants.configurationAPI.regions;
    //builder.httpHeaders = httpHeaders;
    this._apiDataSource.core(builder).subscribe((data: any) => {
      success(data)
    }, error => {
      failure(new MCError(error));
    })
  }

}
