import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MCOpenCourseInscriptionsCustomerEntity } from 'src/app/mc-entity/mc-entities/mc-open-courses-customer-entitys';
import { MCStudentEntity, MCStudentEntityRequests } from 'src/app/mc-entity/mc-entities/mc-students-entitys';
import { MCAlert } from 'src/app/mc-helpers/mc-alert';
import { MCRouter } from 'src/app/mc-helpers/mc-class/mc-router';
import { MCUtils } from 'src/app/mc-helpers/mc-class/mc-utils';
import { MCConstants } from 'src/app/mc-helpers/mc-constants/mc-constants';
import { MCMessagesEnum } from 'src/app/mc-helpers/mc-enums/mc-messages-enum';
import { MCOpenCoursesAPI } from 'src/app/mc-repository/mc-api/mc-open-courses-api';
import { MCDataSourceLocal, MCDataSourceLocalInterface } from 'src/app/mc-repository/mc-data-source-local';
import { MCLoadingService } from 'src/app/mc-service/mc-loading.service';
declare var require: any;
const { validate, format } = require('rut.js');

export interface attributes {
  hidden: boolean;
  action: string;
  selectedValueInstitution: string;
  rutChecked: boolean;
  rutCheckedExist: boolean;
  messageEmail: string;
  idOpenCourse: number;
}

@Component({
  selector: 'app-mc-course-student',
  templateUrl: './mc-course-student.component.html',
  styleUrls: ['./mc-course-student.component.css']
})
export class MCCourseStudentComponent implements OnInit {

  @Output() inscriptionRespond = new EventEmitter<string>();
  private _mcRouter: MCRouter;
  private _mcDataSourceLocal: MCDataSourceLocalInterface = new MCDataSourceLocal();

  student: MCStudentEntity = new MCStudentEntity(String());
  listData: MCOpenCourseInscriptionsCustomerEntity[] = [];

  attributes = {
    hidden: false,
    action: MCConstants.register,
    selectedValueInstitution: MCConstants.notSelected,
    rutChecked: false,
    rutCheckedExist: false,
    messageEmail: String(),
    idOpenCourse: 0
  };

  constructor(
    private _loading: MCLoadingService,
    public _route: Router,
    private _mcUtils: MCUtils,
    private _mcAlert: MCAlert,
    private _mcOpenCoursesAPI: MCOpenCoursesAPI,
  ) {
    this._mcRouter = new MCRouter(_route);
   }

  ngOnInit(): void {
  }

  rutValidate() {
    this.student.document = this._mcUtils.removeBlanks(this.student.document);
    this.student.document = this.student.document.toUpperCase();
    if (this.student.document != String()) {
      this.student.document = format(this.student.document);
      validate(this.student.document) ? this.validateExist() : this._mcAlert.showAlertWarning(MCMessagesEnum.globalInvalidRut);
    } else {
      this._mcAlert.showAlertWarning(MCMessagesEnum.globalEmptyRut);
    }
  }

  validateExist() {
    this._loading.callLoading();
    this._mcOpenCoursesAPI.getOpenCoursesStudentExist(this.attributes.idOpenCourse, this.student.document, data => {
      this._loading.closeLoading();
      if(data.inscription){
        this._mcAlert.showAlertWarning(MCMessagesEnum.studentRegisterExist);
      } else {
        //deberia broquear todos los campos menos el boton de inscribir
        this._mcAlert.showAlertInformation(MCMessagesEnum.studentRegisterExistNotInscription);
        this.student = new MCStudentEntity(data.student);
        this.attributes.rutChecked = true;
      }
    }, error => {
      this._loading.closeLoading();
      if (error.objectMessage == undefined) {
        this.attributes.rutChecked = true;
        this.attributes.rutCheckedExist = true;
        //EL ESTUDIANTE NO EXISTE REGISTRE E INSCRIBA
        //this.attributes.hiddenFor = "creareinscribir";
      } else {
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      }
    })
  }

  resetStudent() {
    this.student = new MCStudentEntity(String());
    //this.course = new MCBasicCourseEntity(String());
    this.attributes.rutChecked = false;
    this.attributes.rutCheckedExist = false;
    this.attributes.action = MCConstants.register;
    this.attributes.selectedValueInstitution = MCConstants.notSelected;
  }

  passCourseBasic() {
   // this.course = new MCBasicCourseEntity(passBasicCourse);
    this.attributes.rutChecked = true;
    this.attributes.action = MCConstants.modify;
  }

  onEdit(event: any, item: any) {
    event.stopPropagation();
  }

  actionButton(action: string) {
    if (action == MCConstants.select) {
      this.resetStudent();
      this.inscriptionRespond.emit(MCConstants.coursesBasic);
    } else {
      if (this.validateForm()) {
        if (this.attributes.action == MCConstants.register) {
          this.post();
        } else if (this.attributes.action == MCConstants.modify) {
          this.put()
        }
      }
    }
  }

  post() {
     this._loading.callLoading();
     let array: MCStudentEntityRequests[] = [];
     let studentInsert: MCStudentEntityRequests = new MCStudentEntityRequests(this.student);
     array.push(studentInsert);
     this._mcOpenCoursesAPI.postStudentOpenCourses(this.attributes.idOpenCourse,this.attributes.selectedValueInstitution,array, () => {
       this.inscriptionRespond.emit(MCConstants.register);
     }, error => {
       this.errorPostPut(error);
     })
  }

  put() {
    // this._loading.callLoading();
    // let basicCourseInsert: MCBasicCourseEntityRequests = new MCBasicCourseEntityRequests(this.course);
    // this._mcBasicCourseAPI.putBasicCourse(this.course.id.toString(), basicCourseInsert, () => {
    //   this.courseRespond.emit(MCConstants.modify);
    // }, error => {
    //   this.errorPostPut(error);
    // })
  }

  errorPostPut(error : any){
    this._loading.closeLoading();
    if (error.objectMessage == undefined) {
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    } else {
      if(this._mcUtils.validateUndefinedNull(error.objectMessage.name)){
        this._mcAlert.showAlertError(error.objectMessage.name[0]);
      } else {
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      }
    }
  }

  //valida el correo
  emailKeydownEvent() {
    this.attributes.messageEmail = this._mcUtils.validateEmailKeyMessage(this.student.email);
  }

  capitalLetter(text: any){
    if(text == 'name'){
      this.student.name = this.student.name.toUpperCase();
    } else if(text == 'last_name'){
      this.student.last_name = this.student.last_name.toUpperCase();
    } else if(text == 'mothers_last_name'){
      this.student.mothers_last_name = this.student.mothers_last_name.toUpperCase();
    } else if(text == 'profession'){
      this.student.profession = this.student.profession.toUpperCase();
    }  else if(text == 'observation'){
      this.student.observation = this.student.observation.toUpperCase();
    }
  }

  //valida formulario antes de realzar una accion
  validateForm(): boolean {
    if (!this._mcUtils.validateStringUndefined(this.student.document)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.studentDocumentFieldEmpty)
      return false;
    } else if (!this._mcUtils.validateStringUndefined(this.student.name)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.studentNameFieldEmpty)
      return false;
    } else if (!this._mcUtils.validateStringUndefined(this.student.last_name)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.studentLastNameFieldEmpty)
      return false;
    } else if (!this._mcUtils.validateStringUndefined(this.student.mothers_last_name)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.studentMothersLastNameFieldEmpty)
      return false;
    } else if (!this._mcUtils.validateStringUndefined(this.student.profession)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.studentProfessionFieldEmpty)
      return false;
    } else if (!this._mcUtils.validateEmail(this.student.email)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.studentEmailFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateStringUndefinedNotselected(this.attributes.selectedValueInstitution)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.studentIntitutionNotSeleted);
      return false;
    } else {
      return true;
    }

  }

}
