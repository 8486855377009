import { MCConstants } from "src/app/mc-helpers/mc-constants/mc-constants";
import { MCTeacherEntity } from "./mc-teacher-entitys";

export class MCSencesEntity {
    total: number;
    entities: MCSenceEntity[] = [];

    constructor(total: number, object: any) {
        this.total = total ?? 0;
        if (object) {
            for (let entity of object) {
                this.entities.push(new MCSenceEntity(entity));
            }
        }
    }
}

export class MCSenceEntity {
    id?: number;
    teacher_id?: number;
    code: string;
    title: string;
    mode: string;
    duration: any;
    expired_date: string;
    teacher?: MCTeacherEntity;

    constructor(object: any) {
        this.id = object.id ?? 0;
        this.teacher_id = object.teacher_id ?? 0;
        this.code = object.code ?? String();
        this.title = object.title ?? String();
        this.mode = object.mode ?? MCConstants.faceToFace;
        this.duration = object.duration ?? String();
        this.expired_date = object.expired_date ?? this.transformDate();
        this.teacher = object.teacher ?? new MCTeacherEntity(String);
    }

    transformDate() : string{
        let date = new Date();
        return date.toLocaleDateString('sv-SE');
    }
}


export class MCSenceEntityRequests {
    teacher_id: number;
    code: string;
    title: string;
    mode: string;
    duration: number;
    expired_date: string;

    constructor(object: any) {
        this.teacher_id = object.teacher.id;
        this.code = object.code;
        this.title = object.title;
        this.mode = object.mode;
        this.duration = Number(object.duration);
        this.expired_date = this.transformDate(object.expired_date);
    }

    transformDate(valor: string) : string {
        return new Date(valor).toISOString().split('T')[0];
    }

}
