import { Component, OnInit, ViewChild } from '@angular/core';
import { MCToolbarClass } from 'src/app/mc-helpers/mc-class/mc-toolbar-class';
import { MCConstants } from 'src/app/mc-helpers/mc-constants/mc-constants';
import { MCViewCoursesComponent } from 'src/app/mc-component/mc-view-courses/mc-view-courses.component';
import { MCLoadingService } from 'src/app/mc-service/mc-loading.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
declare var window: any;
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MCRouter } from 'src/app/mc-helpers/mc-class/mc-router';
import { MCUtils } from 'src/app/mc-helpers/mc-class/mc-utils';
import { MCAlert } from 'src/app/mc-helpers/mc-alert';
import { MCOpenCoursesAPI } from 'src/app/mc-repository/mc-api/mc-open-courses-api';
import { MCOpenCourseEntity } from 'src/app/mc-entity/mc-entities/mc-open-course-entitys';
import { MCMessagesEnum } from 'src/app/mc-helpers/mc-enums/mc-messages-enum';
import localeIt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeIt, 'it');
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/mc-helpers/mc-format-datapiker';
import { FormControl, FormGroup } from '@angular/forms';
import { MCRegionEntity } from 'src/app/mc-entity/mc-entities/mc-region-entitys';
import { MCDataSourceLocal, MCDataSourceLocalInterface } from 'src/app/mc-repository/mc-data-source-local';

export interface filter {
  scope: string;
  modalidad: string;
  region_name: string;
  teacher_name: string;
  start_date: string;
  start_date_final: string;
  sence_title: string;
}

export interface attributesView {
  idDeleteOpenCourse: string;
  filterCollapses: boolean;
  view_crud: boolean;
  isMobile: boolean;
  lengthPaginador: number;
  pageIndex: number;
  pageSize: number;
  pageSizeOptions: number[];
  hideFiltersText: string;
  showFilteraText: string;
  tableArrow: any;
}

@Component({
  selector: 'app-mc-courses',
  templateUrl: './mc-courses.component.html',
  styleUrls: ['./mc-courses.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ],
})
export class MCCoursesComponent implements OnInit {

  @ViewChild(MCViewCoursesComponent) child: any;
  private _mcDataSourceLocal: MCDataSourceLocalInterface = new MCDataSourceLocal();
  range: any;
  statu: boolean = false;
  actionRequestToolbar: MCToolbarClass = new MCToolbarClass(MCConstants.open_courses, MCConstants.select);
  view_crud: boolean = false;
  private _mcRouter: MCRouter;

  attributesView = {
    idDeleteOpenCourse: String(),
    filterCollapses: false,
    view_crud: false,
    isMobile: false,
    lengthPaginador: 10,
    pageIndex: 0,
    pageSize: 10,
    pageSizeOptions: [10, 20, 30, 50],
    hideFiltersText: MCConstants.hideFiltersText,
    showFilteraText: MCConstants.showFilteraText,
    tableArrow: null
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  formModal: any;

  listRegion: MCRegionEntity[] = [];
  listData: MCOpenCourseEntity[] = [];
  listDataTwo: MCOpenCourseEntity[] = [];
  listDataCopy: MCOpenCourseEntity[] = [];
  filter = {
    scope: String(),
    modalidad: MCConstants.notSelected,
    region_name: MCConstants.notSelected,
    teacher_name: String(),
    start_date: String(),
    start_date_final: String(),
    sence_title: String()
  };
  columnsToDisplay = ['op_first', 'op_id', 'op_name_course', 'op_arrow'];

  constructor(
    private _loading: MCLoadingService,
    public _route: Router,
    private _mcAlert: MCAlert,
    private _mcOpenCoursesAPI: MCOpenCoursesAPI,
    private _mcUtils: MCUtils,
    private route_active: ActivatedRoute
  ) {
    this._mcRouter = new MCRouter(_route);
    if (this.route_active.snapshot.url[0].path == "curso-cerrado") {
      this.filter.scope = 'Cerrado';
    } else {
      this.filter.scope = 'Abierto';
    }
    this.titleTest(MCConstants.select);
  }

  ngOnInit(): void {
    this.range = new FormGroup({ start: new FormControl(), end: new FormControl() });
    this.ChangeMobile(window.innerWidth);
    this.getOpenCoursesBeforePaging();
    this.listRegion = this._mcDataSourceLocal.getRegions();
  }

  titleTest(action: string) {
    if (action == MCConstants.select) {
      this.actionRequestToolbar = new MCToolbarClass(this.filter.scope == 'Cerrado' ? MCConstants.close_courses : MCConstants.open_courses, action);
    } else if (action == MCConstants.modify) {
      this.actionRequestToolbar = new MCToolbarClass(this.filter.scope == 'Cerrado' ? MCConstants.close_course : MCConstants.open_course, action);
    } else if (action == MCConstants.register) {
      this.actionRequestToolbar = new MCToolbarClass(this.filter.scope == 'Cerrado' ? MCConstants.close_course : MCConstants.open_course, action);
    } else {
      this.actionRequestToolbar = new MCToolbarClass(this.filter.scope == 'Cerrado' ? MCConstants.modify +" "+ MCConstants.close_course : MCConstants.modify +" "+ MCConstants.open_course, action);
    }
  }

  onResize(event: any) {
    this.ChangeMobile(event.target.innerWidth);
  }

  ChangeMobile(size: number) {
    this.attributesView.isMobile = size < 1000;
    if (this.attributesView.isMobile == false) {
      this.columnsToDisplay = ['op_first', 'op_id', 'op_name_course', 'op_teacher', 'op_start_date', 'op_hours', 'op_mode', 'op_price', 'op_arrow'];
    } else {
      this.columnsToDisplay = ['op_first', 'op_id', 'op_name_course', 'op_arrow'];
    }
  }

  getOpenCoursesBeforePaging() {
    this.listData = [];
    this.listDataTwo = [];
    this.listDataCopy = [];
    this._loading.callLoading();
    this._mcOpenCoursesAPI.getOpenCourses(this.attributesView.pageIndex + 1, this.attributesView.pageSize, this.filter.scope,
      this.range.controls.start.value, this.range.controls.end.value, this.filter.region_name,
      this.filter.modalidad, this.filter.teacher_name, this.filter.sence_title, data => {
        this.attributesView.lengthPaginador = data.total;
        this.listData = data.entities;
        this.listDataTwo = data.entities;
        this.listDataCopy = data.entities;
        this._loading.closeLoading();
      }, error => {
        this._loading.closeLoading();
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      })
  }

  getOpenCoursesAfterPaging() {
    this._loading.callLoading();
    this.listDataTwo = [];
    this.listDataCopy = [];
    this._mcOpenCoursesAPI.getOpenCourses(this.attributesView.pageIndex + 1, this.attributesView.pageSize, this.filter.scope,
      this.range.controls.start.value, this.range.controls.end.value, this.filter.region_name,
      this.filter.modalidad, this.filter.teacher_name, this.filter.sence_title, data => {
        this.attributesView.lengthPaginador = data.total;
        this.listDataTwo = data.entities;
        this.listDataCopy = data.entities;
        for (let i: number = 0; i < data.entities.length; i++) {
          this.listData.push(data.entities[i]);
        }
        this._loading.closeLoading();
      }, error => {
        this._loading.closeLoading();
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      })
  }

  validDateFilter() {
    if(this.range.controls.start.value != null && this.range.controls.end.value != null) {
      this.findFilter();
    }
  }

  paginador(event: any) {
    if (this.attributesView.pageSize != event.pageSize) {
      this.attributesView.pageIndex = 0;
      this.attributesView.pageSize = event.pageSize;
      this.getOpenCoursesBeforePaging();
    } else {
      this.attributesView.pageIndex = event.pageIndex;
      let numero: number = (this.attributesView.pageIndex + 1) * this.attributesView.pageSize;
      if ((numero > this.listData.length) && (this.listData.length < event.length)) {
        this.getOpenCoursesAfterPaging();
      } else {
        this.localTableData();
      }
    }
  }

  //recargar datos de la tabla
  localTableData() {
    this.listDataTwo = [];
    this.listDataCopy = [];
    for (let i: number = 0; i < this.listData.length; i++) {
      let mult: number = this.attributesView.pageIndex * this.attributesView.pageSize;
      if ((i >= mult) && (i < (mult + this.attributesView.pageSize))) {
        this.listDataTwo.push(this.listData[i]);
        this.listDataCopy.push(this.listData[i]);
      }
    }
  }

  deleteOpenCourse() {
    this.closeModal();
    this._loading.callLoading();
    this._mcOpenCoursesAPI.deleteOpenCourses(this.attributesView.idDeleteOpenCourse, () => {
      this.resfleshTable();
      this._mcAlert.showAlertInformation(MCMessagesEnum.globalDeleteData);
    }, error => {
      this._loading.closeLoading();
      if (error.objectMessage == undefined) {
        this.resfleshTable();
        this._mcAlert.showAlertInformation(MCMessagesEnum.globalDeleteData);
      } else {
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      }
    })
  }

  modifyOpenCourse(element: any, basico: string) {
   // this.titleTest((basico == 'basic') ? MCConstants.modify : element.course.id+"-"+element.id);
    this.titleTest(element.course.name.substring(0,30)+" | "+element.start_date);
    this.child.attributes.hidden = true;
    this.child.passCourseOpen(element, basico);
    this.attributesView.view_crud = !this.attributesView.view_crud;
  }

  toolRespond($event: string) {
    if ($event == MCConstants.select) {
      this.titleTest(MCConstants.select);
      this.attributesView.view_crud = !this.attributesView.view_crud;
      this.child.attributes.hidden = false;
    } else {
      this.titleTest(MCConstants.register);
      this.attributesView.view_crud = !this.attributesView.view_crud;
      this.child.attributes.hidden = true;
      this.child.resetOpenCourse();
    }
  }

  coursesRespond($event: any) {
    //let respondOpenCourse: MCOpenCourseEntity = this.child.course;
    this.child.attributes.hidden = false;
    this.child._loading.closeLoading();
    this.attributesView.view_crud = !this.attributesView.view_crud;
    this.titleTest(MCConstants.select);
    if ($event == MCConstants.register) {
      this.resfleshTable();
      this._mcAlert.showAlertInformation(MCMessagesEnum.openCourseCreated);
    } else if ($event == MCConstants.modify) {
      this.resfleshTable();
      this._mcAlert.showAlertInformation(MCMessagesEnum.globalUpdateData);
      //this.refleshTable(respondOpenCourse);
    }
  }

  resfleshTable() {
    this.attributesView.pageIndex = 0;
    this.getOpenCoursesBeforePaging();
  }

  findFilter() {
    this.filter.sence_title = this.filter.sence_title.trim();
    this.resfleshTable();
  }

  cleanFilter() {
    this.range = new FormGroup({ start: new FormControl(), end: new FormControl() });
    this.filter.modalidad = MCConstants.notSelected;
    this.filter.region_name = MCConstants.notSelected;
    this.filter.teacher_name = String();
    this.filter.sence_title = String();
    this.resfleshTable();
  }

  actionCollapses() {
    this.attributesView.filterCollapses = !this.attributesView.filterCollapses;
  }

  indicatorArrow(value: any) {
    this.attributesView.tableArrow = value;
  }

  openModal(id: string) {
    this.formModal = new window.bootstrap.Modal(
      document.getElementById('staticBasicCourse')
    );
    this.attributesView.idDeleteOpenCourse = id;
    this.formModal.show();
  }

  closeModal() {
    this.formModal.hide();
  }

}
