import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MCConstants } from 'src/app/mc-helpers/mc-constants/mc-constants';
import { MCOpenCoursesAPI } from 'src/app/mc-repository/mc-api/mc-open-courses-api';
import { MCMessagesEnum } from 'src/app/mc-helpers/mc-enums/mc-messages-enum';
import { MCLoadingService } from 'src/app/mc-service/mc-loading.service';
import { MCCourseCustomerComponent } from '../mc-course-customer/mc-course-customer.component';
import { MCRouter } from 'src/app/mc-helpers/mc-class/mc-router';
import { MCDataSourceLocal, MCDataSourceLocalInterface } from 'src/app/mc-repository/mc-data-source-local';
import { MatPaginator } from '@angular/material/paginator';
import { MCOpenCourseInscriptionsCustomerEntity } from 'src/app/mc-entity/mc-entities/mc-open-courses-customer-entitys';
import { MCRegionEntity } from 'src/app/mc-entity/mc-entities/mc-region-entitys';
import { MCCommuneEntity } from 'src/app/mc-entity/mc-entities/mc-commune-entitys';
import { MCDependencyEntity } from 'src/app/mc-entity/mc-entities/mc-dependency-entitys';
import { ActivatedRoute, Router } from '@angular/router';
import { MCUtils } from 'src/app/mc-helpers/mc-class/mc-utils';
import { MCAlert } from 'src/app/mc-helpers/mc-alert';
import { MCCustomerAPI } from 'src/app/mc-repository/mc-api/mc-customer-api';
import { MCRoutesEnum } from 'src/app/mc-helpers/mc-enums/mc-routes-enum';

export interface attributesView {
  idDeleteCustomer: string;
  filterCollapses: boolean;
  view_crud: boolean;
  isMobile: boolean;
  lengthPaginador: number;
  pageIndex: number;
  pageSize: number;
  pageSizeOptions: number[];
  hideFiltersText: string;
  showFilteraText: string;
  tableArrow: any;
  isMobileTwo: boolean;
  idOpenCourse: number;
  btnTitle: string;
  isOpenCourse: boolean;
  search: string;
}

@Component({
  selector: 'app-mc-course-customers',
  templateUrl: './mc-course-customers.component.html',
  styleUrls: ['./mc-course-customers.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MCCourseCustomersComponent implements OnInit {

  @ViewChild(MCCourseCustomerComponent) child: any;
  @Output() modalRespond = new EventEmitter<string>();

  private _mcRouter: MCRouter;
  private _mcDataSourceLocal: MCDataSourceLocalInterface = new MCDataSourceLocal();
  columnsToDisplay = ['cu_first', 'cu_type', 'cu_document', 'cu_businessName', 'cu_mobile', 'cu_contact', 'cu_dependency', 'cu_delete', 'cu_arrow'];

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  attributesView = {
    idDeleteCustomer: String(),
    filterCollapses: false,
    view_crud: false,
    isMobile: false,
    pageIndex: 0,
    pageSize: 10,
    pageSizeOptions: [10, 20, 30, 50],
    lengthPaginador: 10,
    hideFiltersText: MCConstants.hideFiltersText,
    showFilteraText: MCConstants.showFilteraText,
    tableArrow: null,
    isMobileTwo: false,
    idOpenCourse: 0,
    btnTitle: "Agregar Cliente",
    isOpenCourse: true,
    search: String()
  }

  listDataOriginal: MCOpenCourseInscriptionsCustomerEntity[] = [];
  listData: MCOpenCourseInscriptionsCustomerEntity[] = [];
  listDataTwo: MCOpenCourseInscriptionsCustomerEntity[] = [];
  listDataCopy: MCOpenCourseInscriptionsCustomerEntity[] = [];

  listRegion: MCRegionEntity[] = [];
  listCommune: MCCommuneEntity[] = [];
  listDependency: MCDependencyEntity[] = [];

  constructor(
    private route_active: ActivatedRoute,
    private _loading: MCLoadingService,
    public _route: Router,
    private _mcAlert: MCAlert,
    private _mcCustomerAPI: MCCustomerAPI,
    private _mcOpenCoursesAPI: MCOpenCoursesAPI,
    private _mcUtils: MCUtils
  ) {

    this._mcRouter = new MCRouter(_route);
  }

  ngOnInit(): void {
    this.ChangeMobile(window.innerWidth);
    this.getDependency();
    this.listRegion = this._mcDataSourceLocal.getRegions();
    this.attributesView.isOpenCourse = this.route_active.snapshot.url[0].path == MCRoutesEnum.courseOpen;
  }

  getDependency() {
    this.listDependency = [];
    this._mcCustomerAPI.getDependency(data => {
      this.listDependency = data.entities;
    }, error => {
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }

  onResize(event: any) {
    this.ChangeMobile(event.target.innerWidth);
  }

  ChangeMobile(size: number) {
    this.attributesView.isMobile = size < 1150;
    this.attributesView.isMobileTwo = size < 950
    if (this.attributesView.isMobile == false) {
      this.attributesView.btnTitle = "Agregar Cliente";
      this.columnsToDisplay = ['cu_first', 'cu_type', 'cu_document', 'cu_businessName', 'cu_mobile', 'cu_contact', 'cu_dependency', 'cu_delete', 'cu_arrow'];
    } else {//movil
      if (this.attributesView.isMobileTwo == false) {
        this.attributesView.btnTitle = "Agregar Cliente";
        this.columnsToDisplay = ['cu_first', 'cu_type', 'cu_document', 'cu_businessName', 'cu_mobile', 'cu_dependency', 'cu_delete', 'cu_arrow'];
      } else {
        this.attributesView.btnTitle = String();
        this.columnsToDisplay = ['cu_first', 'cu_document', 'cu_businessName', 'cu_delete', 'cu_arrow'];
      }
    }
  }

  getSencesBeforePaging(idOpenCourse: number) {
    this.listDataOriginal = [];
    this.listData = [];
    this._loading.callLoading();
    this._mcOpenCoursesAPI.getOpenCoursesCustomers(idOpenCourse, data => {
      this.attributesView.lengthPaginador = data.entities.length;
      this.listDataOriginal = data.entities;
      this.attributesView.pageIndex = 0;
      this.listData = this.listDataOriginal;
      this.localTableData();
      this._loading.closeLoading();
    }, error => {
      console.log(error);
      this._loading.closeLoading();
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }

  onActivate() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 60); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 4);
  }

  hola(value: number) {
    this.attributesView.idOpenCourse = value;
    this.getSencesBeforePaging(value);
    this.ChangeMobile(window.innerWidth);
  }



  changeSelect(event: any, numero: number) {
    if (numero == 12) {
      //this.fillCommunes(this.filter.selectedValueRegion);
    }
  }

  //llena el combo de comunas dependiendo del la region seleccionada
  fillCommunes(valor: any) {
    if (valor != MCConstants.notSelected) {
      for (let rep of this.listRegion) {
        if (valor == rep.id) {
          this.listCommune = rep.communes!;
          break;
        }
      }
    } else {
      this.listCommune = [];
    }
  }

  modifyCustomer(element: any) {
    this.child.attributes.hiddentwo = element.type;
    this.child.attributes.hidden = true;
    // this.child.passCustomer(element);
    this.attributesView.view_crud = !this.attributesView.view_crud;

  }

  deleteCustomer() {
    this._loading.callLoading();
    this._mcOpenCoursesAPI.deleteCustomerOpenCourses(
      this.attributesView.idOpenCourse,
      this.attributesView.idDeleteCustomer, () => {
        this.getSencesBeforePaging(this.attributesView.idOpenCourse);
        this._mcAlert.showAlertInformation(MCMessagesEnum.globalRemoveInscriptionCustomer);
      }, error => {
        console.log(error);
        this._loading.closeLoading();
        if (error.objectMessage == undefined) {
          this._mcAlert.showAlertInformation(MCMessagesEnum.globalRemoveInscriptionCustomer);
        } else {
          this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
        }
      })
  }

  addCustomer() {
    if (this.attributesView.isOpenCourse == false) {
      if (this.listData.length < 1) {
        this.addCustomerAction();
      } else {
        this._mcAlert.showAlertWarning(MCMessagesEnum.courseCloseOneCliente);
      }
    } else {
      this.addCustomerAction();
    }
  }

  addCustomerAction() {
    this.attributesView.view_crud = !this.attributesView.view_crud;
    this.child.attributes.hidden = true;
    this.child.resetAdd();
    this.child.attributes.idOpenCourse = this.attributesView.idOpenCourse;
  }


  actionCollapses() {
    this.attributesView.filterCollapses = !this.attributesView.filterCollapses;
  }

  indicatorArrow(value: any) {
    this.attributesView.tableArrow = value;
  }

  inscriptionRespond($event: any) {
    this.child.attributes.hidden = false;
    this.child._loading.closeLoading();
    this.attributesView.view_crud = !this.attributesView.view_crud;
    if ($event == MCConstants.register) {
      this.getSencesBeforePaging(this.attributesView.idOpenCourse);
      this._mcAlert.showAlertInformation(MCMessagesEnum.enrolledCustomer);
    } else if ($event == MCConstants.modify) {
      this._mcAlert.showAlertInformation(MCMessagesEnum.globalUpdateData);
      this.getSencesBeforePaging(this.attributesView.idOpenCourse);
    }
    this.onActivate();
  }

  metodoStatus(event: any, t: any) {
    this._loading.callLoading();
    event.source.checked = t.paid;
    t.paid = !t.paid;
    this._mcOpenCoursesAPI.postCustomerPaidOpenCourses(
      this.attributesView.idOpenCourse, t.id, t.paid, data => {
        this._mcAlert.showAlertInformation(MCMessagesEnum.payUpdate);
        this._loading.closeLoading();
      }, error => {
        this.getSencesBeforePaging(this.attributesView.idOpenCourse);
        this._loading.closeLoading();
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      })
  }

  openModal(value: any) {
    this.attributesView.idDeleteCustomer = value.id;
    this.modalRespond.emit("customer");
  }

  search(value: string) {
    if(value == "1") { this.attributesView.pageIndex = 0; }
    this.listData = [];
    if(this.attributesView.search.trim() == String()) {
      this.listData = this.listDataOriginal;
    } else {
      for(let rep of this.listDataOriginal) {
        if(rep.customer.businessName.toLowerCase().includes(this.attributesView.search.toLowerCase())){
          this.listData.push(rep);
        }
      }
    }
    this.attributesView.lengthPaginador = this.listData.length;
    this.localTableData();
  }

  paginador(event: any) {
    if (this.attributesView.pageSize != event.pageSize) {
      this.attributesView.pageIndex = 0;
      this.attributesView.pageSize = event.pageSize;
    } else {
      this.attributesView.pageIndex = event.pageIndex;
    }
    this.search(String());
  }

  //recargar datos de la tabla
  localTableData() {
    this.listDataTwo = [];
    this.listDataCopy = [];
    for (let i: number = 0; i < this.listData.length; i++) {
      let mult: number = this.attributesView.pageIndex * this.attributesView.pageSize;
      if ((i >= mult) && (i < (mult + this.attributesView.pageSize))) {
        this.listDataTwo.push(this.listData[i]);
        this.listDataCopy.push(this.listData[i]);
      }
    }
  }

}
