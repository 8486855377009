import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MCBasicCourseEntity } from 'src/app/mc-entity/mc-entities/mc-basic-course-entitys';
import { MCCommuneEntity } from 'src/app/mc-entity/mc-entities/mc-commune-entitys';
import { MCOpenCourseEntity, MCOpenCourseEntityRequests } from 'src/app/mc-entity/mc-entities/mc-open-course-entitys';
import { MCRegionEntity } from 'src/app/mc-entity/mc-entities/mc-region-entitys';
import { MCScheduleEntity, MCScheduleEntityRequests } from 'src/app/mc-entity/mc-entities/mc-schedules-entitys';
import { MCSenceEntity } from 'src/app/mc-entity/mc-entities/mc-sence-entitys';
import { MCTeacherEntity } from 'src/app/mc-entity/mc-entities/mc-teacher-entitys';
import { MCAlert } from 'src/app/mc-helpers/mc-alert';
import { MCRouter } from 'src/app/mc-helpers/mc-class/mc-router';
import { MCUtils } from 'src/app/mc-helpers/mc-class/mc-utils';
import { MCConstants } from 'src/app/mc-helpers/mc-constants/mc-constants';
import { MCMessagesEnum } from 'src/app/mc-helpers/mc-enums/mc-messages-enum';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/mc-helpers/mc-format-datapiker';
import { MCRoutesEnum } from 'src/app/mc-helpers/mc-enums/mc-routes-enum';
import { MCBasicCourseAPI } from 'src/app/mc-repository/mc-api/mc-basic-course-api';
import { MCOpenCoursesAPI } from 'src/app/mc-repository/mc-api/mc-open-courses-api';
import { MCTeacherAPI } from 'src/app/mc-repository/mc-api/mc-teacher-api';
import { MCDataSourceLocal, MCDataSourceLocalInterface } from 'src/app/mc-repository/mc-data-source-local';
import { MCLoadingService } from 'src/app/mc-service/mc-loading.service';
import { MCCourseCustomersComponent } from '../mc-course/mc-course-customers/mc-course-customers.component';
import { MCCourseStudentsComponent } from '../mc-course/mc-course-students/mc-course-students.component';
declare var window: any;

export interface attributes {
  messageEmail: string;
  messageMobile: string;
  hidden: boolean;
  action: string;
  rutChecked: boolean;
  lengthPaginador: number,
  pageIndex: number;
  pageSize: number;
  pageSizeOptions: any;
  modifyBasic: string;
  deadline: string;
  endHours: string;
  baseCourseName: string;
  baseCourseTitle: string;
  hiddentwo: string;
  selectedValueRegion: string;
  selectedValueCommune: string;
  selectedValueCourseBasic: string;
  selectedValueTeacher: string;
  selectedValueSence: string;
  scopeLocal: string;
  tabCustomer: string;
  labelPrice: string;
  modalTitle: string;
  modalMessaje: string;
  modalCurrent: string;
  sumHours: string;
}

@Component({
  selector: 'app-mc-view-courses',
  templateUrl: './mc-view-courses.component.html',
  styleUrls: ['./mc-view-courses.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ],
})

export class MCViewCoursesComponent implements OnInit {
  @ViewChild(MCCourseCustomersComponent) childCustomers: any;
  @ViewChild(MCCourseStudentsComponent) childStudents: any;
  @Output() coursesRespond = new EventEmitter<string>();
  private _mcDataSourceLocal: MCDataSourceLocalInterface = new MCDataSourceLocal();
  private _mcRouter: MCRouter;
  courses = new MCOpenCourseEntity(String());
  hidden: boolean = false;
  selectedFile: any = null;
  url: string = "";
  radio: boolean = true;
  titleFirst: string = 'Info Del Curso';
  listBasicCourse: MCBasicCourseEntity[] = [];
  listRegion: MCRegionEntity[] = [];
  listCommune: MCCommuneEntity[] = [];
  listTeacher: MCTeacherEntity[] = [];
  listSence: MCSenceEntity[] = [];
  listSchedule: MCScheduleEntity[] = [];
  schedule: MCScheduleEntity = new MCScheduleEntity(String());
  formModal: any;
  modalRequest: any;
  permitidoCaracter : boolean = false;

  attributes = {
    messageEmail: String(),
    messageMobile: String(),
    hidden: false,
    action: MCConstants.register,
    rutChecked: false,
    lengthPaginador: 1,
    pageIndex: 0,
    pageSize: 1,
    pageSizeOptions: [10, 20, 30, 50],
    modifyBasic: 'basic',
    deadline: String(),
    endHours: '06:00',
    hiddentwo: MCConstants.notSelected,
    selectedValueRegion: MCConstants.notSelected,
    selectedValueCommune: MCConstants.notSelected,
    selectedValueCourseBasic: MCConstants.notSelected,
    selectedValueTeacher: MCConstants.notSelected,
    selectedValueSence: MCConstants.notSelected,
    scopeLocal: String(),
    tabCustomer: MCConstants.tabCustomerOpen,
    labelPrice: MCConstants.priceCourseOpen,
    modalTitle: String(),
    modalMessaje: String(),
    modalCurrent: String(),
    sumHours: '00:00'
  };

  constructor(
    private route_active: ActivatedRoute,
    private _loading: MCLoadingService,
    public _route: Router,
    private _mcOpenCoursesAPI: MCOpenCoursesAPI,
    private _mcAlert: MCAlert,
    private _mcBasicCourseAPI: MCBasicCourseAPI,
    private _mcTeacherAPI: MCTeacherAPI,
    private _mcUtils: MCUtils
  ) {
    this._mcRouter = new MCRouter(_route);
    this.getBasicCourses();
    this.getTeachers();
    this.listRegion = this._mcDataSourceLocal.getRegions();

  }

  ngOnInit(): void {
    this.fillCommunes(this.attributes.selectedValueRegion);
    if (this.route_active.snapshot.url[0].path == MCRoutesEnum.courseClose) {
      this.attributes.scopeLocal = MCConstants.closed;
      this.attributes.tabCustomer = MCConstants.tabCustomerClose;
      this.attributes.labelPrice = MCConstants.priceCourseClose;
    } else {
      this.attributes.scopeLocal = MCConstants.opened;
      this.attributes.tabCustomer = MCConstants.tabCustomerOpen;
      this.attributes.labelPrice = MCConstants.priceCourseOpen;
    }

  }

  clickTab($event: any) {
    if ($event.index == 1) {
      this.childCustomers.hola(this.courses.id);
    } else if ($event.index == 2) {
      this.childStudents.hola(this.courses.id);
    }
    this.onActivate();
  }

  onActivate() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 60); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 4);
  }

  listScheduleTransform() {
    this.listSchedule = [];
    for (let i: number = 0; i < this.courses.schedules.length; i++) {
      this.listSchedule.push(new MCScheduleEntity(
        {
          "id": this.courses.schedules[i].id,
          "day": this.courses.schedules[i].day,
          "start_time": this.courses.schedules[i].start_time,
          "final_time": this.courses.schedules[i].final_time
          //"start_time": this._mcUtils.timeTwelveHours(this.courses.schedules[i].start_time),
          //"final_time": this._mcUtils.timeTwelveHours(this.courses.schedules[i].final_time)
        }
      ));
    }
    this.sumHours();
  }

  passCourseOpen(passCourseOpen: MCOpenCourseEntity, basic: string) {
    this.listSchedule = [];
    this.courses = new MCOpenCourseEntity(passCourseOpen);
    this.courses.price = this.courses.price.slice(0,-3);
    this.transformPrice(this.courses.price);
    this.dateTimetoDate(this.courses.deadline);
    //this.attributes.codeChecked = true;
    this.attributes.selectedValueCourseBasic = this.courses.course.id;
    this.attributes.selectedValueTeacher = this.courses.teacher.id.toString();
    this.attributes.selectedValueRegion = this.courses.commune.region?.id.toString();
    this.attributes.modifyBasic = "";
    this.attributes.action = MCConstants.modify;
    this.titleFirst = 'Info Del Curso';
    this.listScheduleTransform();
    this.fillCommunes(this.attributes.selectedValueRegion);
    this.fillSence(this.attributes.selectedValueTeacher);
    this.onActivate();
  }

  //llena el combo de sence dependiendo del teacher seleccionado
  fillSence(valor: any) {
    for (let rep of this.listTeacher) {
      if (rep.id == Number(this.attributes.selectedValueTeacher)) {
        this.listSence = rep.sences;
        break;
      }
    }
    this.attributes.selectedValueSence = (this.courses.sence == undefined) ? MCConstants.notSelected : this.courses.sence.id!.toString();
  }

  dateTimetoDate(value: any) {
    if (value != undefined && value != String() && value != null) {
      let dateTime = value.split(" ");
      this.attributes.deadline = dateTime[0];
      let hhmm = dateTime[1].split(":");
      this.attributes.endHours = hhmm[0] + ":" + hhmm[1];
    }
  }

  changeSelect(event: any, numero: number) {
    if (numero == 1) {
      if (this.attributes.selectedValueCourseBasic == MCConstants.notSelected) {
        this.courses.name_course = String();
        this.courses.certificate_title_course = String();
      } else {
        for (let rep of this.listBasicCourse) {
          if (rep.id == this.attributes.selectedValueCourseBasic) {
            this.courses.name_course = rep.name;
            this.courses.certificate_title_course = rep.certificate_title;
            break;
          }
        }
      }
    } else if (numero == 12) {
      this.attributes.selectedValueCommune = MCConstants.notSelected;
      this.fillCommunes(this.attributes.selectedValueRegion);
    } else if (numero == 2) {
      if (this.attributes.selectedValueTeacher == MCConstants.notSelected) {
        this.listSence = [];
      } else {
        for (let rep of this.listTeacher) {
          if (rep.id == Number(this.attributes.selectedValueTeacher)) {
            this.listSence = rep.sences;
            break;
          }
        }
      }
      this.attributes.selectedValueSence = MCConstants.notSelected;
    }
  }

  radioChange() {
    this.attributes.selectedValueRegion = MCConstants.notSelected;
    this.attributes.selectedValueCommune = MCConstants.notSelected;
    this.courses.address = String();
    this.courses.place = String();
    this.courses.course_link = String();
  }

  //llena el combo de comunas dependiendo del la region seleccionada
  fillCommunes(valor: any) {
    if (valor != MCConstants.notSelected) {
      for (let rep of this.listRegion) {
        if (valor == rep.id) {
          this.listCommune = rep.communes!;
          break;
        }
      }
      if (this.courses.commune.id != 0) {
        this.attributes.selectedValueCommune = this.courses.commune.id.toString();
      }
    } else {
      this.attributes.selectedValueCommune = MCConstants.notSelected;
      this.listCommune = [];
    }
  }

  replaceData() {
    for (let rep of this.listRegion) {
      if (rep.id == this.attributes.selectedValueRegion) {
        this.courses.commune.region.id = rep.id;
        this.courses.commune.region.sequence = rep.sequence;
      }
    }

    for (let rep of this.listCommune) {
      if (rep.id == Number(this.attributes.selectedValueCommune)) {
        this.courses.commune.id = rep.id;
        this.courses.commune.name = rep.name;
      }
    }
    //this.customerRespond.emit(MCConstants.modify);
  }

  resetOpenCourse() {
    this.listSchedule = [];
    this.attributes.selectedValueCommune = MCConstants.notSelected;
    this.attributes.selectedValueRegion = MCConstants.notSelected;
    this.attributes.selectedValueTeacher = MCConstants.notSelected;
    this.attributes.selectedValueSence = MCConstants.notSelected;
    this.attributes.selectedValueCourseBasic = MCConstants.notSelected;
    this.courses = new MCOpenCourseEntity(String());
    this.attributes.action = MCConstants.register;
    this.attributes.modifyBasic = 'basic';
    this.titleFirst = 'Nuevo Curso';
    this.attributes.endHours = '06:00';
    this.attributes.deadline = String()
  }

  actionButton(action: string) {
    if (action == MCConstants.select) {
      this.coursesRespond.emit(MCConstants.coursesBasic);
    } else {
      if (this.validateForm()) {
        if (this.attributes.action == MCConstants.register) {
          this.post();
        } else if (this.attributes.action == MCConstants.modify) {
          this.put()
        }
      }
    }
  }

  transforListSchedule() :  any{
    let list_retun : any[] = [];
    for(let rep of this.listSchedule){
      list_retun.push(new MCScheduleEntityRequests(rep));
    }
    return list_retun;
  }

  post() {
    this._loading.callLoading();
  //  let listScheduleLocal: any = this._mcUtils.listTimeTwentyFourHours(this.listSchedule);
    let listScheduleLocal: any = this.transforListSchedule();
    let openCourseInsert: MCOpenCourseEntityRequests = new MCOpenCourseEntityRequests(
      this.courses,
      Number(this.attributes.selectedValueTeacher),
      Number(this.attributes.selectedValueSence),
      this.attributes.selectedValueCommune,
      this.attributes.selectedValueCourseBasic,
      listScheduleLocal,
      this.attributes.scopeLocal,
      this.attributes.deadline,
      this.attributes.endHours
    );
    this._mcOpenCoursesAPI.postOpenCourses(openCourseInsert, () => {
      this.coursesRespond.emit(MCConstants.register);
    }, error => {
      this.errorPostPut(error);
    })
  }

  put() {
    this._loading.callLoading();
   // let listScheduleLocal: any = this._mcUtils.listTimeTwentyFourHours(this.listSchedule);
    let listScheduleLocal: any = this.listSchedule;
    let openCourseInsert: MCOpenCourseEntityRequests = new MCOpenCourseEntityRequests(
      this.courses,
      Number(this.attributes.selectedValueTeacher),
      Number(this.attributes.selectedValueSence),
      this.attributes.selectedValueCommune,
      this.attributes.selectedValueCourseBasic,
      listScheduleLocal,
      this.attributes.scopeLocal,
      this.attributes.deadline,
      this.attributes.endHours
    );
    this._mcOpenCoursesAPI.putOpenCourses(this.courses.id, openCourseInsert, () => {
       this.coursesRespond.emit(MCConstants.modify);
    }, error => {
       this.errorPostPut(error);
    })
  }

  errorPostPut(error: any) {
    this._loading.closeLoading();
    if (error.objectMessage == undefined) {
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    } else {
      if (this._mcUtils.validateUndefinedNull(error.objectMessage.name)) {
        this._mcAlert.showAlertError(error.objectMessage.name[0]);
      } else {
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      }
    }
  }

  //valida formulario antes de realzar una accion
  validateForm(): boolean {
    if (this.attributes.scopeLocal == 'Cerrado') {
      this.attributes.deadline = '2050-12-31';
    }
    if (this.courses.mode == "Virtual") {
      if (!this._mcUtils.validateFormSelect([this.attributes.selectedValueCourseBasic])) {
        this._mcAlert.showAlertWarning(MCMessagesEnum.coursesCourseBasicNotSeleted);
        return false;
      } else if (!this._mcUtils.validateStringUndefined(this.courses.name_course)) {
        this._mcAlert.showAlertWarning(MCMessagesEnum.coursesNameFieldEmpty);
        return false;
      } else if (!this._mcUtils.validateStringUndefined(this.courses.certificate_title_course)) {
        this._mcAlert.showAlertWarning(MCMessagesEnum.coursesTitleFieldEmpty);
        return false;
      } else if (!this._mcUtils.validateFormSelect([this.attributes.selectedValueTeacher])) {
        this._mcAlert.showAlertWarning(MCMessagesEnum.coursesTeacherNotSeleted);
        return false;
      } else if (Number(this.courses.price) == 0) {
        let value = "El campo "+ this.attributes.labelPrice +" es obligatorio y no puede ser 0";
        this._mcAlert.showAlertWarning(value);
        return false;
      } else if (Number(this.courses.duration) == 0) {
        this._mcAlert.showAlertWarning(MCMessagesEnum.coursesDurationFieldEmpty);
        return false;
      } else if (!this._mcUtils.validateStringUndefinedNull(this.courses.start_date)) {
        this._mcAlert.showAlertWarning(MCMessagesEnum.coursesStartDateFieldEmpty);
        return false;
      } else if (!this._mcUtils.validateStringUndefinedNull(this.courses.final_date)) {
        this._mcAlert.showAlertWarning(MCMessagesEnum.coursesFinalDateFieldEmpty);
        return false;
      }  else if (!this._mcUtils.validateStringUndefinedNull(this.attributes.deadline)) {
        this._mcAlert.showAlertWarning(MCMessagesEnum.coursesDeadlineFieldEmpty);
        return false;
      // } else if (!this._mcUtils.validateStringUndefinedNull(this.courses.material_date)) {
      //   this._mcAlert.showAlertWarning(MCMessagesEnum.coursesMaterialDateFieldEmpty);
      //   return false;
      } else if (this.listSchedule.length == 0) {
        this._mcAlert.showAlertWarning(MCMessagesEnum.coursesListScheduleEmpty);
        return false;
      } else {
        return true;
      }
    } else {
      if (!this._mcUtils.validateFormSelect([this.attributes.selectedValueCourseBasic])) {
        this._mcAlert.showAlertWarning(MCMessagesEnum.coursesCourseBasicNotSeleted);
        return false;
      } else if (!this._mcUtils.validateStringUndefined(this.courses.name_course)) {
        this._mcAlert.showAlertWarning(MCMessagesEnum.coursesNameFieldEmpty);
        return false;
      } else if (!this._mcUtils.validateStringUndefined(this.courses.certificate_title_course)) {
        this._mcAlert.showAlertWarning(MCMessagesEnum.coursesTitleFieldEmpty);
        return false;
      } else if (!this._mcUtils.validateFormSelect([this.attributes.selectedValueTeacher])) {
        this._mcAlert.showAlertWarning(MCMessagesEnum.coursesTeacherNotSeleted);
        return false;
      }  else if (Number(this.courses.price) == 0) {
        let value = "El campo "+ this.attributes.labelPrice +" es obligatorio y no puede ser 0";
        this._mcAlert.showAlertWarning(value);
        return false;
      }  else if (Number(this.courses.duration) == 0) {
        this._mcAlert.showAlertWarning(MCMessagesEnum.coursesDurationFieldEmpty);
        return false;
      } else if (!this._mcUtils.validateFormSelect([this.attributes.selectedValueRegion])) {
        this._mcAlert.showAlertWarning(MCMessagesEnum.coursesRegionNotSeleted);
        return false;
      } else if (!this._mcUtils.validateFormSelect([this.attributes.selectedValueCommune])) {
        this._mcAlert.showAlertWarning(MCMessagesEnum.coursesComunaNotSeleted);
        return false;
      } else if (!this._mcUtils.validateStringUndefined(this.courses.place)) {
        this._mcAlert.showAlertWarning(MCMessagesEnum.coursesPlaceFieldEmpty);
        return false;
      } else if (!this._mcUtils.validateStringUndefined(this.courses.address)) {
        this._mcAlert.showAlertWarning(MCMessagesEnum.coursesAddressFieldEmpty);
        return false;
      } else if (!this._mcUtils.validateStringUndefinedNull(this.courses.start_date)) {
        this._mcAlert.showAlertWarning(MCMessagesEnum.coursesStartDateFieldEmpty);
        return false;
      } else if (!this._mcUtils.validateStringUndefinedNull(this.courses.final_date)) {
        this._mcAlert.showAlertWarning(MCMessagesEnum.coursesFinalDateFieldEmpty);
        return false;
      } else if (!this._mcUtils.validateStringUndefinedNull(this.attributes.deadline)) {
        this._mcAlert.showAlertWarning(MCMessagesEnum.coursesDeadlineFieldEmpty);
        return false;
      } else if (this.listSchedule.length == 0) {
        this._mcAlert.showAlertWarning(MCMessagesEnum.coursesListScheduleEmpty);
        return false;
      }  // } else if (!this._mcUtils.validateStringUndefinedNull(this.courses.material_date)) {
        //   this._mcAlert.showAlertWarning(MCMessagesEnum.coursesMaterialDateFieldEmpty);
        //   return false;
      else {
        return true;
      }
    }
  }

  // cerrar() {
  //   let hola: any = this._mcUtils.listTimeTwentyFourHours(this.listSchedule);
  //   //console.log(this.validateForm());
  //   // let openCourseInsert: MCOpenCourseEntityRequests = new MCOpenCourseEntityRequests(
  //   //   this.courses,
  //   //   Number(this.attributes.selectedValueTeacher),
  //   //   this.attributes.selectedValueSence,
  //   //   this.attributes.selectedValueCommune,
  //   //   this.attributes.selectedValueCourseBasic,
  //   //   this.listSchedule
  //   // );
  //   // console.log(this.attributes.selectedValueCourseBasic);
  //   // console.log(this.attributes.endHours);

  // }

  onFileSelected(e: any): void {
    if (e.target.files[0] != null && e.target.files[0] != undefined) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.url = event.target.result;
      }
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  addSchedule() {
    if (
      this._mcUtils.validateStringUndefinedNull(this.schedule.day) &&
      this._mcUtils.validateStringUndefinedNull(this.schedule.start_time) &&
      this._mcUtils.validateStringUndefinedNull(this.schedule.final_time)
    ) {
      //this.schedule.start_time = this._mcUtils.timeTwelveHours(this.schedule.start_time);
      //this.schedule.final_time = this._mcUtils.timeTwelveHours(this.schedule.final_time);
      // this.schedule.start_time = this.schedule.start_time;
      // this.schedule.final_time = this.schedule.final_time;

      let localSchedule: MCScheduleEntityRequests = new MCScheduleEntityRequests({
        "day": this.schedule.day,
        //"start_time": this._mcUtils.timeTwentyFourHours(this.schedule.start_time),
        //"final_time": this._mcUtils.timeTwentyFourHours(this.schedule.final_time)
        "start_time": this.schedule.start_time,
        "final_time": this.schedule.final_time
      });

      if (this.listSchedule.length == 0) {
        if (this.attributes.action == MCConstants.register) {
          this.listSchedule.push(this.schedule);
          this.schedule = new MCScheduleEntity(String());
          this.sumHours();
        } else if (this.attributes.action == MCConstants.modify) {
          this._loading.callLoading();
          this._mcOpenCoursesAPI.postOpenCoursesSchedules(this.courses.id.toString(), localSchedule, data => {
            this._loading.closeLoading();
            this.courses.schedules.push(data);
            this.listSchedule.push(this.schedule);
            this.schedule = new MCScheduleEntity(String());
            this.sumHours();
          }, (error) => {
            this._loading.closeLoading();
            this._mcAlert.showAlertError("ERROR NO SE PUDO AGREGAR ESTE HORARIO");
            this.schedule = new MCScheduleEntity(String());
          })
        }
      } else {
        let exist: boolean = false;
        for (let rep of this.listSchedule) {
          if (rep.day == this.schedule.day && rep.start_time == this.schedule.start_time && rep.final_time == this.schedule.final_time) {
            exist = true;
            break;
          }
        }
        if (exist == false) {
          if (this.attributes.action == MCConstants.register) {
            this.listSchedule.push(this.schedule);
            this.schedule = new MCScheduleEntity(String());
            this.sumHours();
          } else if (this.attributes.action == MCConstants.modify) {
            this._loading.callLoading();
            this._mcOpenCoursesAPI.postOpenCoursesSchedules(this.courses.id.toString(), localSchedule, data => {
              this._loading.closeLoading();
              this.courses.schedules.push(data);
              this.listSchedule.push(this.schedule);
              this.schedule = new MCScheduleEntity(String());
              this.sumHours();
            }, (error) => {
              this._loading.closeLoading();
              this.schedule = new MCScheduleEntity(String());
              this._mcAlert.showAlertError("ERROR NO SE PUDO AGREGAR ESTE HORARIO");
            })
          }
        } else {
          this.schedule = new MCScheduleEntity(String());
        }
      }
    }
  }

  deleteListSchedule(value: any) {
    if (this.attributes.action == MCConstants.register) {
      this.deleteListScheduleLocal(value);
    } else if (this.attributes.action == MCConstants.modify) {
      if (this.listSchedule.length > 1) {
        this._loading.callLoading();
        this._mcOpenCoursesAPI.deleteOpenCoursesSchedules(this.courses.id.toString(), value.id, () => {
          this.deleteListScheduleObjectLocal(value);
          this.deleteListScheduleLocal(value);
          this._loading.closeLoading();
        }, error => {
          this._loading.closeLoading();
          if (error.objectMessage == undefined) {
            this.deleteListScheduleObjectLocal(value);
            this.deleteListScheduleLocal(value);
          } else {
            this._mcAlert.showAlertError("Error al eliminar el horario, por favor intente de nuevo");
          }
        })
      } else {
        this._mcAlert.showAlertError("La lista de horario no puede ser vacia por lo tanto si desea eliminar este horario debe insertar uno perimero");
      }
    }
  }

  deleteListScheduleObjectLocal(value: any) {
    for (let i: number = 0; i < this.courses.schedules.length; i++) {
      if (this.courses.schedules[i].id == value.id) {
        this.courses.schedules.splice(i, 1)
        break;
      }
    }
  }

  deleteListScheduleLocal(value: any) {
    for (let i: number = 0; i < this.listSchedule.length; i++) {
      if (this.listSchedule[i].id == value.id) {
        this.listSchedule.splice(i, 1)
        break;
      }
    }
    this.sumHours();
  }

  getBasicCourses() {
    this.listBasicCourse = [];
    this._mcBasicCourseAPI.getBasicCourses(1, 10000, String(), String(), data => {
      this.listBasicCourse = data.entities;
    }, error => {
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }

  getTeachers() {
    this.listTeacher = [];
    this._mcTeacherAPI.getTeachers(1, 1000, String(), String(), data => {
      this.listTeacher = data.entities;
    }, error => {
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }

  //valida duracion
  validateDuration(event: any) {
    if (!this._mcUtils.onlyNumber(event)) {
      this.courses.duration = this.courses.duration.replace(event.key, String());
    }
  }

  validatePrice(event: any) {
    if (!this._mcUtils.onlyNumber(event)) {
      this.courses.price = this.courses.price.replace(event.key, String());
    }
  }

  respuestaModal(value: string) {
    this.closeModal();
    if (value == "si") {
      if (this.attributes.modalCurrent == "student") {
        this.childStudents.deleteStudentInscription();
      } else {
        this.childCustomers.deleteCustomer();
      }
    }
  }

  openModal() {
    this.formModal = new window.bootstrap.Modal(
      document.getElementById('modalExamen')
    );
    this.formModal.show();
  }


  modalRespond($event: any) {
    this.attributes.modalCurrent = $event;
    if ($event == "student") {
      this.attributes.modalTitle = "Desinscribir Estudiante";
      this.attributes.modalMessaje = "¿Desea Desinscribir El Estudiante Del Curso?";
      this.openModal();
    } else if ($event == "customer") {
      this.attributes.modalTitle = "Desinscribir Cliente";
      this.attributes.modalMessaje = "¿Desea Desinscribir El Cliente Del Curso?";
      this.openModal();
    }
  }

  closeModal() {
    this.formModal.hide();
  }

  keyNumber(event : any){
    let key = event.keyCode;
    if (key == 8){ return true; }
    if((key >= 48 && key <= 57) || (key >= 96 && key <= 105)){ return true; }
    return false;
  }

  formatAmount(event : any , numero: number , donde: number ){
    if(numero == 1) {
      this.permitidoCaracter = false;
      (this.keyNumber(event) == false) ? (this.permitidoCaracter = false , event.preventDefault()) :  this.permitidoCaracter = true;
    } if(numero == 2) {
      if(this.permitidoCaracter == true) {
        this.transformPrice(event.target.value);

        //  if(donde == 2) {
        //   for(let i:number = 0; i < this.communesAmountFullCopy.length; i++){
        //     if(this.communesAmountFullCopy[i].id == elemento.id){
        //       this.communesAmountFullCopy[i].amount =  (isNaN(sinpunto)) ? '$0' : ('$'+conpunto);
        //       break;
        //     }
        //   }
        //  } else {
        //     this.regionsAmountSelect.amount =  (isNaN(sinpunto)) ? '$0' : ('$'+conpunto);
        //  }

      }
    }
  }

  transformPrice(value: any) {
        let cadena1 = value.replace(/\$/g,'');
        let cadena = cadena1.replace(/\./g,'');
        let sinpunto : number = parseInt(cadena);
        let conpunto = sinpunto.toLocaleString('de-DE');
        this.courses.price = (isNaN(sinpunto)) ? '$0' : ('$'+conpunto);
  }

  capitalLetter(text: any){
    if(text == 'name_course'){
      this.courses.name_course = this.courses.name_course.toUpperCase();
    } else if(text == 'certificate_title_course'){
      this.courses.certificate_title_course = this.courses.certificate_title_course.toUpperCase();
    } else if(text == 'address'){
      this.courses.address = this.courses.address.toUpperCase();
    } else if(text == 'observation'){
      this.courses.observation = this.courses.observation.toUpperCase();
    } else if(text == 'place'){
      this.courses.place = this.courses.place.toUpperCase();
    } else if(text == 'message_to_user'){
      this.courses.message_to_user = this.courses.message_to_user.toUpperCase();
    }
  }

  sumHours() {
    let minutes = 0;
    let hrs = 0;
    for(let repList of this.listSchedule) {
      let hora1 = repList.start_time.split(":");
      let hora2 = repList.final_time.split(":");
      let start = '1987/10/08 '+hora1[0]+':'+hora1[1];
      let end = '1987/10/08 '+hora2[0]+':'+hora2[1];
      minutes = minutes + ((new Date(end).getTime() - new Date(start).getTime()) / 60000);
      hrs = hrs + ((new Date(end).getTime() - new Date(start).getTime()) / 3600000);
    }

    if(minutes > (this.courses.duration * 60)){
      this._mcAlert.showAlertWarning(MCMessagesEnum.coursesMaxHours);
    }

    if((minutes % 60) == 0){
      this.attributes.sumHours = parseInt(Number(hrs % 24).toString()).toString();
    } else {
      this.attributes.sumHours = parseInt(Number(hrs % 24).toString()) +" horas y "+  (minutes % 60)+" minutos";
    }

  }

}
