export class MCDependencysEntity {
    entities: MCDependencyEntity[] = [];

    constructor(object: any) {
        if (object) {
            for (let entity of object) {
                this.entities.push(new MCDependencyEntity(entity));
            }
        }
    }
}

export class MCDependencyEntity {
    id: string;
    sequence: number;

    constructor(object: any) {
        this.id = object.id ?? String();
        this.sequence = object.sequence ?? 0;
    }
}