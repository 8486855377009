import { Injectable } from "@angular/core";
import { MCError } from "src/app/mc-helpers/mc-error";
import { MCDataSourceAPICoreBuilder } from "src/app/mc-entity/mc-builders/mc-data-source-api-core-builder";
import { MCDataSourceLocal, MCDataSourceLocalInterface } from "../mc-data-source-local";
import { MCDataSourceAPI } from "./mc-data-source-api";
import { MCEndpointsConstants } from "src/app/mc-helpers/mc-constants/mc-endpoints-constants";
import { HttpParams } from "@angular/common/http";
import { MCConstants } from "src/app/mc-helpers/mc-constants/mc-constants";
import { MCUtils } from "src/app/mc-helpers/mc-class/mc-utils";
import { MCEmailsEntity } from "src/app/mc-entity/mc-entities/mc-emails-entitys";

export interface MCEmailAPIInterface {

  getEmails(
    page: number,
    limit: number,
    audience: string,
    region_name: string,
    commune_name: string,
    success: (entity: MCEmailsEntity) => void,
    failure: (error: MCError) => void
  ): any;

}

@Injectable({
  providedIn: 'root'
})
export class MCEmailAPI implements MCEmailAPIInterface {
  // Private properties.

  private _omDataSourceLocal: MCDataSourceLocalInterface = new MCDataSourceLocal();

  // Contructors.

  constructor(private _apiDataSource: MCDataSourceAPI, private _mcUtils: MCUtils) { }

  getEmails(
    page: number,
    limit: number,
    audience: string,
    region_name: string,
    commune_name: string,
    success: (entity: MCEmailsEntity) => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    let params: HttpParams = new HttpParams();
    params = params.append(MCConstants.paramLimit, limit.toString());
    params = params.append(MCConstants.paramPage, page.toString());
    if(audience != MCConstants.notSelected) {
      params = params.append(MCConstants.paramAudience, audience)
    }
    if(region_name != MCConstants.notSelected) {
      params = params.append(MCConstants.paramRegionName, region_name)
    }
    if(commune_name != MCConstants.notSelected) {
      params = params.append(MCConstants.paramCommuneName, commune_name)
    }

    builder.httpParams = params;
    builder.endpoint = MCEndpointsConstants.emailAPI.emails;
    this._apiDataSource.core(builder).subscribe((data: any) => {
      success(new MCEmailsEntity(data.meta.total,data.data))
    }, error => {
      failure(new MCError(error));
    })
  }

}
