import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MCCommuneEntity } from 'src/app/mc-entity/mc-entities/mc-commune-entitys';
import { MCCustomerEntity, MCCustomerEntityRequests, MCCustomerLegalEntityRequests } from 'src/app/mc-entity/mc-entities/mc-customer-entitys';
import { MCDependencyEntity } from 'src/app/mc-entity/mc-entities/mc-dependency-entitys';
import { MCOpenCourseInscriptionsCustomerEntity } from 'src/app/mc-entity/mc-entities/mc-open-courses-customer-entitys';
import { MCRegionEntity } from 'src/app/mc-entity/mc-entities/mc-region-entitys';
import { MCAlert } from 'src/app/mc-helpers/mc-alert';
import { MCRouter } from 'src/app/mc-helpers/mc-class/mc-router';
import { MCUtils } from 'src/app/mc-helpers/mc-class/mc-utils';
import { MCConstants } from 'src/app/mc-helpers/mc-constants/mc-constants';
import { MCMessagesEnum } from 'src/app/mc-helpers/mc-enums/mc-messages-enum';
import { MCRoutesEnum } from 'src/app/mc-helpers/mc-enums/mc-routes-enum';
import { MCCustomerAPI } from 'src/app/mc-repository/mc-api/mc-customer-api';
import { MCOpenCoursesAPI } from 'src/app/mc-repository/mc-api/mc-open-courses-api';
import { MCDataSourceLocal, MCDataSourceLocalInterface } from 'src/app/mc-repository/mc-data-source-local';
import { MCLoadingService } from 'src/app/mc-service/mc-loading.service';
declare var require: any;
const { validate, format } = require('rut.js');

export interface attributes {
  hidden: boolean;
  hiddentwo: string;
  hiddenTree: string;
  hiddenFor: string;
  rut: string;
  idOpenCourse: number;
  selectedValueRegion: string;
  selectedValueCommune: string;
  selectedValueDependencies: string;
  messageEmail: string;
  messageMobile: string;
  messageEmailTwo: string;
  messagePhone: string;
  action: string;
  isOpenCourse: boolean;
}

@Component({
  selector: 'app-mc-course-customer',
  templateUrl: './mc-course-customer.component.html',
  styleUrls: ['./mc-course-customer.component.css']
})
export class MCCourseCustomerComponent implements OnInit {

  private _mcRouter: MCRouter;

 // @Output() courseCustomerRespond = new EventEmitter<string>();
  @Output() inscriptionRespond = new EventEmitter<string>();
  inscriptionEntity: MCOpenCourseInscriptionsCustomerEntity = new MCOpenCourseInscriptionsCustomerEntity(String());
  private _mcDataSourceLocal: MCDataSourceLocalInterface = new MCDataSourceLocal();
  customer: MCCustomerEntity = new MCCustomerEntity(String());

  listRegion: MCRegionEntity[] = [];
  listCommune: MCCommuneEntity[] = [];
  listDependency: MCDependencyEntity[] = [];



  attributes = {
    hidden: false,
    hiddentwo: "none",
    hiddenTree: "cero",
    hiddenFor: String(),
    rut: String(),
    idOpenCourse: 0,
    selectedValueRegion: MCConstants.notSelected,
    selectedValueCommune: MCConstants.notSelected,
    selectedValueDependencies: MCConstants.notSelected,
    messageEmail: String(),
    messageMobile: String(),
    messageEmailTwo: String(),
    messagePhone: String(),
    action: MCConstants.register,
    isOpenCourse: true
  }

  constructor(
    private route_active: ActivatedRoute,
    private _loading: MCLoadingService,
    public _route: Router,
    private _mcAlert: MCAlert,
    private _mcCustomerAPI: MCCustomerAPI,
    private _mcOpenCoursesAPI: MCOpenCoursesAPI,
    private _mcUtils: MCUtils
  ) {
    this._mcRouter = new MCRouter(_route);
    this.attributes.hiddentwo = 'none';
    this.getDependency();
    this.listRegion = this._mcDataSourceLocal.getRegions();
  }

  ngOnInit(): void {
    this.fillCommunes(this.attributes.selectedValueRegion);
    this.fillCommunes(this.attributes.selectedValueRegion);
    this.attributes.isOpenCourse = this.route_active.snapshot.url[0].path == MCRoutesEnum.courseOpen;
    console.log(this.attributes.isOpenCourse);
  }

  //saber si es natural o juridico
  clickHidden(respond: string) {
    this.attributes.hiddentwo = respond;
    this.customer = new MCCustomerEntity(String());
    this.customer.document = this.attributes.rut;
  }

  guardar() {
    alert("datos guardados");
  }

  resetAdd() {
    this.attributes.hiddentwo = "none",
    this.attributes.hiddenTree = "cero",
    this.attributes.hiddenFor = String(),
    this.attributes.rut = String()
    this.attributes.action = MCConstants.register;
    this.attributes.selectedValueRegion = MCConstants.notSelected;
    this.attributes.selectedValueCommune = MCConstants.notSelected;
    this.attributes.selectedValueDependencies = MCConstants.notSelected;
  }

  onEdit(event: any, item: any) {
    event.stopPropagation();
    console.log(event);
    console.log(item);
  }

  actionButton(action: string) {
    this.attributes.hiddentwo = 'none';
    if (action == "list") {
      this.inscriptionRespond.emit(MCConstants.customers);
    } else {
      console.log("eliminar modificar o registrar");
    }
  }

  inscriptionCustomer() {
    console.log(this.customer.type);
    (this.customer.type == 'Natural') ? this.post() : this.postLegal();
  }

  post() {
    this._loading.callLoading();
    let arrayInsert: MCCustomerEntityRequests[] = [];
    let customerInsert: MCCustomerEntityRequests = new MCCustomerEntityRequests(
      this.customer,
      this.customer.commune.id
    );
    arrayInsert.push(customerInsert);
    console.log(arrayInsert)
    this._mcOpenCoursesAPI.postCustomersOpenCourses(this.attributes.idOpenCourse, arrayInsert, data => {
        this.inscriptionRespond.emit(MCConstants.register);
    }, error => {
      console.log(error);
      this._loading.closeLoading();
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }

  postLegal() {
    this._loading.callLoading();
    let arrayInsert: MCCustomerLegalEntityRequests[] = [];
    let customerInsert: MCCustomerLegalEntityRequests = new MCCustomerLegalEntityRequests(
      this.customer,
      this.customer.commune.id,
      this.customer.dependency.id
    );
    arrayInsert.push(customerInsert);
    this._mcOpenCoursesAPI.postCustomersOpenCourses(this.attributes.idOpenCourse, arrayInsert, data => {
      this.inscriptionRespond.emit(MCConstants.register);
    }, error => {
      console.log(error);
      this._loading.closeLoading();
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }

  inscriptionCustomerNotExist() {
    (this.attributes.hiddentwo == 'Natural') ? this.natural() : this.legal();
  }

  legal() {
    this.customer.type = "Institución";
    if (this.validateFormLegal()) {
      if (this.attributes.action == MCConstants.register) {
        this.postLegalNotExist();
      } else if (this.attributes.action == MCConstants.modify) {
       // this.putLegal();
      }
    }
  }

  natural() {
    this.customer.type = "Natural";
    if (this.validateForm()) {
      if (this.attributes.action == MCConstants.register) {
        this.postNotExist();
      } else if (this.attributes.action == MCConstants.modify) {
      //  this.put();
      }
    }
  }
  //valida formulario antes de realzar una accion
  validateFormLegal(): boolean {
    if (!this._mcUtils.validateStringUndefined(this.customer.document)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerDocumentFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateStringUndefined(this.customer.name)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerNameFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateStringUndefined(this.customer.institution_name)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerBusinessNameFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateStringUndefined(this.customer.contact)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerContactFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateFormSelect([this.attributes.selectedValueDependencies])) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerDependencieSeleted);
      return false;
    } else if (!this._mcUtils.validatePhone(this.customer.mobile)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerMobilFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateEmail(this.customer.email)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerEmailFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateFormSelect([this.attributes.selectedValueRegion])) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerRegionSeleted);
      return false;
    } else if (!this._mcUtils.validateFormSelect([this.attributes.selectedValueCommune])) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerComunaSeleted);
      return false;
    } else {
      return true;
    }
  }

  //valida formulario antes de realzar una accion
  validateForm(): boolean {
    if (!this._mcUtils.validateStringUndefined(this.customer.document)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerDocumentFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateStringUndefined(this.customer.name)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerNameNaturalFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateStringUndefined(this.customer.last_name)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerLastNameFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateStringUndefined(this.customer.mothers_last_name)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerMothersLastNameFieldEmpty);
      return false;
    }  else if (!this._mcUtils.validateStringUndefined(this.customer.institution_name)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerBusinessNameFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateStringUndefined(this.customer.profession)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerProfessionFieldEmpty);
      return false;
    }  else if (!this._mcUtils.validatePhone(this.customer.mobile)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerMobilFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateEmail(this.customer.email)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerEmailFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateFormSelect([this.attributes.selectedValueRegion])) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerRegionSeleted);
      return false;
    } else if (!this._mcUtils.validateFormSelect([this.attributes.selectedValueCommune])) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerComunaSeleted);
      return false;
    } else {
      return true;
    }
  }

  postNotExist() {
    this._loading.callLoading();
    let arrayInsert: MCCustomerEntityRequests[] = [];
    let customerInsert: MCCustomerEntityRequests = new MCCustomerEntityRequests(
      this.customer,
      Number(this.attributes.selectedValueCommune)
    );
    arrayInsert.push(customerInsert);
    console.log(arrayInsert)
    this._mcOpenCoursesAPI.postCustomersOpenCourses(this.attributes.idOpenCourse, arrayInsert, data => {
       console.log(data);
       this.inscriptionRespond.emit(MCConstants.register);
    }, error => {
      console.log(error);
      this._loading.closeLoading();
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }

  postLegalNotExist() {
    this._loading.callLoading();
    let arrayInsert: MCCustomerLegalEntityRequests[] = [];
    let customerInsert: MCCustomerLegalEntityRequests = new MCCustomerLegalEntityRequests(
      this.customer,
      Number(this.attributes.selectedValueCommune),
      this.attributes.selectedValueDependencies
    );
    arrayInsert.push(customerInsert);
    this._mcOpenCoursesAPI.postCustomersOpenCourses(this.attributes.idOpenCourse, arrayInsert, data => {
      console.log(data);
      this.inscriptionRespond.emit(MCConstants.register);
    }, error => {
      console.log(error);
      this._loading.closeLoading();
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }

  // legal() {
  //   if (this.validateFormLegal()) {
  //     if (this.attributes.action == MCConstants.register) {
  //       this.postLegal();
  //     } else if (this.attributes.action == MCConstants.modify) {
  //       this.putLegal();
  //     }
  //   } else {
  //     this._mcAlert.showAlertWarning(MCMessagesEnum.globalRequiredFieldsAlert);
  //   }
  // }

  // natural() {
  //   if (this.validateForm()) {
  //     if (this.attributes.action == MCConstants.register) {
  //       this.post();
  //     } else if (this.attributes.action == MCConstants.modify) {
  //       this.put();
  //     }
  //   } else {
  //     this._mcAlert.showAlertWarning(MCMessagesEnum.globalRequiredFieldsAlert);
  //   }
  // }

  close() {
    this.attributes.hiddentwo = 'none';
    this.resetAdd();
    this.inscriptionRespond.emit(MCConstants.customers);
  }

  rutValidate() {
    this.attributes.rut = this._mcUtils.removeBlanks(this.attributes.rut);
    this.attributes.rut = this.attributes.rut.toUpperCase();
    if (this.attributes.rut != String()) {
      this.attributes.rut = format(this.attributes.rut);
      validate(this.attributes.rut) ? this.validateExist() : this._mcAlert.showAlertWarning(MCMessagesEnum.globalInvalidRut);
    } else {
      this._mcAlert.showAlertWarning(MCMessagesEnum.globalEmptyRut);
    }
  }

  validateExist() {
    this._loading.callLoading();
    this._mcOpenCoursesAPI.getOpenCoursesCustomerExist(this.attributes.idOpenCourse, this.attributes.rut, data => {
      this.attributes.hiddenFor = (data.inscription) ? "inscripto" : "inscribir";
      this.customer = new MCCustomerEntity(data.customer);
      this._loading.closeLoading();
    }, error => {
      this._loading.closeLoading();
      if (error.objectMessage == undefined) {
        this.attributes.hiddenFor = "creareinscribir";
      } else {
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      }
    })
  }

  getDependency() {
    this.listDependency = [];
    this._mcCustomerAPI.getDependency(data => {
      this.listDependency = data.entities;
    }, error => {
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }

  //llena el combo de comunas dependiendo del la region seleccionada
  fillCommunes(valor: any) {
    if (valor != MCConstants.notSelected) {
      for (let rep of this.listRegion) {
        if (valor == rep.id) {
          this.listCommune = rep.communes!;
          break;
        }
      }
      if (this.customer.commune.id != 0) {
        this.attributes.selectedValueCommune = this.customer.commune.id.toString();
      }
    } else {
      this.attributes.selectedValueCommune = MCConstants.notSelected;
      this.listCommune = [];
    }
  }


  //valida el correo
  emailKeydownEvent() {
    this.attributes.messageEmail = this._mcUtils.validateEmailKeyMessage(this.customer.email);
  }

  emailKeydownEventTwo() {
    this.attributes.messageEmailTwo = this._mcUtils.validateEmailKeyMessage(this.customer.email_two);
  }


  //valida el numero en el campo telefono
  phoneKeydownEvent(event: any) {
    if (this._mcUtils.onlyNumber(event)) {
      this.attributes.messageMobile = this._mcUtils.validatePhoneKeyMessage(this.customer.mobile);
    } else {
      this.customer.mobile = this.customer.mobile.replace(event.key, String());
    }
  }

  phoneKeydownEventTwo(event: any) {
    if (this._mcUtils.onlyNumber(event)) {
      this.attributes.messagePhone = this._mcUtils.validatePhoneKeyMessage(this.customer.phone);
    } else {
      this.customer.phone = this.customer.phone.replace(event.key, String());
    }
  }

  changeSelect(event: any, numero: number) {
    if (numero == 12) {
      this.attributes.selectedValueCommune = MCConstants.notSelected;
      this.fillCommunes(this.attributes.selectedValueRegion);
    }
  }

  capitalLetter(text: any){
    if(text == 'name'){
      this.customer.name = this.customer.name.toUpperCase();
    } else if(text == 'last_name'){
      this.customer.last_name = this.customer.last_name.toUpperCase();
    } else if(text == 'mothers_last_name'){
      this.customer.mothers_last_name = this.customer.mothers_last_name.toUpperCase();
    } else if(text == 'institution_name'){
      this.customer.institution_name = this.customer.institution_name.toUpperCase();
    } else if(text == 'profession'){
      this.customer.profession = this.customer.profession.toUpperCase();
    } else if(text == 'address'){
      this.customer.address = this.customer.address.toUpperCase();
    } else if(text == 'observation'){
      this.customer.observation = this.customer.observation.toUpperCase();
    } else if(text == 'activity'){
      this.customer.activity = this.customer.activity.toUpperCase();
    } else if(text == 'contact'){
      this.customer.contact = this.customer.contact.toUpperCase();
    }
  }

  // //valida formulario antes de realzar una accion
  // validateForm(): boolean {
  //   return !(!this._mcUtils.validateFormEmpty([this.customer.document, this.customer.name, this.customer.institution_name]) ||
  //     !this._mcUtils.validateFormSelect([this.attributes.selectedValueRegion, this.attributes.selectedValueCommune]) ||
  //     !this._mcUtils.validateEmail(this.customer.email) ||
  //     !this._mcUtils.validatePhone(this.customer.mobile))
  // }

  // //valida formulario antes de realzar una accion
  // validateFormLegal(): boolean {
  //   return !(!this._mcUtils.validateFormEmpty([this.customer.document, this.customer.name]) ||
  //     !this._mcUtils.validateFormSelect([this.attributes.selectedValueRegion, this.attributes.selectedValueCommune]) ||
  //     !this._mcUtils.validateEmail(this.customer.email) ||
  //     !this._mcUtils.validatePhone(this.customer.mobile))
  // }

}
