
export class MCStudentsEntity {
    // Properties.

    total: number;
    entities: MCStudentEntity[] = [];

    // Constructors.

    constructor(total: number, object: any) {
        this.total = total ?? 0;
        if (object) {
            for (let entity of object) {
                this.entities.push(new MCStudentEntity(entity));
            }
        }
    }
}

export class MCStudentEntity {

  id: number;
  document_type: string;
  document: string;
  name: string;
  last_name: string;
  mothers_last_name: string;
  email: string;
  profession: string;
  institution: string;
  observation: string;

  constructor(object: any) {

    this.id = object.id ?? 0;
    this.document_type = object.document_type ?? "RUT";
    this.document = object.document ?? String();
    this.name = object.name ?? String();
    this.last_name = object.last_name ?? String();
    this.mothers_last_name = object.mothers_last_name ?? String();
    this.email = object.email ?? String();
    this.profession = object.profession ?? String();
    this.institution = object.institution ?? String();
    this.observation = object.observation ?? String();

  }
}

export class MCStudentEntityRequests {
  id: number;
  document_type: string;
  document: string;
  name: string;
  last_name: string;
  mothers_last_name: string;
  email: string;
  profession: string;
  institution: string;
  observation: string;

    constructor(object: any) {

      this.id = object.id;
      this.document_type = object.document_type;
      this.document = object.document;
      this.name = object.name;
      this.last_name = object.last_name;
      this.mothers_last_name = object.mothers_last_name;
      this.email = object.email;
      this.profession = object.profession;
      this.institution = object.institution;
      this.observation = object.observation;
    }

}
