import { Component, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MCEmailEntity } from 'src/app/mc-entity/mc-entities/mc-emails-entitys';
import { MatPaginator } from '@angular/material/paginator';
import { MCToolbarClass } from 'src/app/mc-helpers/mc-class/mc-toolbar-class';
import { MCConstants } from 'src/app/mc-helpers/mc-constants/mc-constants';
import { MCRouter } from 'src/app/mc-helpers/mc-class/mc-router';
import { MCLoadingService } from 'src/app/mc-service/mc-loading.service';
import { Router } from '@angular/router';
import { MCAlert } from 'src/app/mc-helpers/mc-alert';
import { MCUtils } from 'src/app/mc-helpers/mc-class/mc-utils';
import { MCEmailAPI } from 'src/app/mc-repository/mc-api/mc-emails-api';
import { McViewEmailComponent } from 'src/app/mc-component/mc-view-email/mc-view-email.component';
import { MCRegionEntity } from 'src/app/mc-entity/mc-entities/mc-region-entitys';
import { MCCommuneEntity } from 'src/app/mc-entity/mc-entities/mc-commune-entitys';
import { MCDataSourceLocal, MCDataSourceLocalInterface } from 'src/app/mc-repository/mc-data-source-local';
declare var window: any;
import * as XLSX from 'xlsx';
import { MCJsonToExcelEmailsEntity } from 'src/app/mc-entity/mc-entities/mc-json-to-excel-emails-entitys';

export interface filter {
  selectedValueAudience: string;
  selectedValueRegion: string;
  selectedValueCommune: string;
}

export interface attributesView {
  idDeleteCourse: string;
  filterCollapses: boolean;
  view_crud: boolean;
  isMobile: boolean;
  lengthPaginador: number;
  pageIndex: number;
  pageSize: number;
  pageSizeOptions: number[];
  hideFiltersText: string;
  showFilteraText: string;
  tableArrow: any;
}

@Component({
  selector: 'app-mc-email',
  templateUrl: './mc-email.component.html',
  styleUrls: ['./mc-email.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MCEmailComponent implements OnInit {

  @ViewChild(McViewEmailComponent) child: any;
  private _mcRouter: MCRouter;
  actionRequestToolbar: MCToolbarClass = new MCToolbarClass(MCConstants.emails, MCConstants.select);
  private _mcDataSourceLocal: MCDataSourceLocalInterface = new MCDataSourceLocal();
  listRegion: MCRegionEntity[] = [];
  listCommune: MCCommuneEntity[] = [];
  //listDependency: MCDependencyEntity[] = [];

  attributesView = {
    idDeleteCourse: String(),
    filterCollapses: false,
    view_crud: false,
    isMobile: false,
    lengthPaginador: 10,
    pageIndex: 0,
    pageSize: 10,
    pageSizeOptions: [10, 20, 30, 50],
    hideFiltersText: MCConstants.hideFiltersText,
    showFilteraText: MCConstants.showFilteraText,
    tableArrow: null
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  formModal: any;

  listData: MCEmailEntity[] = [];
  listDataTwo: MCEmailEntity[] = [];
  listDataCopy: MCEmailEntity[] = [];

  filter = {
    selectedValueRegion: MCConstants.notSelected,
    selectedValueCommune: MCConstants.notSelected,
    selectedValueAudience: MCConstants.notSelected
  };
  columnsToDisplay = ['ma_rut', 'ma_razonsocial', 'ma_audience', 'ma_arrow'];
  //columnsToDisplay = ['ma_first', 'ma_rut', 'ma_razonsocial', 'ma_audience', 'ma_arrow'];
  constructor(
    private _loading: MCLoadingService,
    public _route: Router,
    private _mcAlert: MCAlert,
    private _mcEmailAPI: MCEmailAPI,
    private _mcUtils: MCUtils

  ) {
    this._mcRouter = new MCRouter(_route);
    this.actionRequestToolbar = new MCToolbarClass(MCConstants.emails, MCConstants.select);
  }

  ngOnInit(): void {
    this.ChangeMobile(window.innerWidth);
    this.getBasicCoursesBeforePaging();
    this.listRegion = this._mcDataSourceLocal.getRegions();
  }

  onResize(event: any) {
    this.ChangeMobile(event.target.innerWidth);
  }

  ChangeMobile(size: number) {
    this.attributesView.isMobile = size < 900;
    if (this.attributesView.isMobile == false) {
      this.columnsToDisplay = ['ma_rut', 'ma_razonsocial', 'ma_audience', 'ma_region', 'ma_comune', 'ma_arrow'];
    } else {
      this.columnsToDisplay = ['ma_rut','ma_razonsocial', 'ma_arrow'];
    }
  }

  getBasicCoursesBeforePaging() {
    this.listData = [];
    this.listDataTwo = [];
    this.listDataCopy = [];
    this._loading.callLoading();
    this._mcEmailAPI.getEmails(this.attributesView.pageIndex + 1, this.attributesView.pageSize, this.filter.selectedValueAudience,
        this.filter.selectedValueRegion, this.filter.selectedValueCommune, data => {
        this.attributesView.lengthPaginador = data.total;
        this.listData = data.entities;
        this.listDataTwo = data.entities;
        this.listDataCopy = data.entities;
        this._loading.closeLoading();
      }, error => {
        this._loading.closeLoading();
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      })
  }

  getBasicCoursesAfterPaging() {
    this._loading.callLoading();
    this.listDataTwo = [];
    this.listDataCopy = [];
    this._mcEmailAPI.getEmails(this.attributesView.pageIndex + 1, this.attributesView.pageSize, this.filter.selectedValueAudience,
      this.filter.selectedValueRegion, this.filter.selectedValueCommune, data => {
        this.attributesView.lengthPaginador = data.total;
        this.listDataTwo = data.entities;
        this.listDataCopy = data.entities;
        for (let i: number = 0; i < data.entities.length; i++) {
          this.listData.push(data.entities[i]);
        }
        this._loading.closeLoading();
      }, error => {
        this._loading.closeLoading();
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      })
  }

  paginador(event: any) {
    if (this.attributesView.pageSize != event.pageSize) {
      this.attributesView.pageIndex = 0;
      this.attributesView.pageSize = event.pageSize;
      this.getBasicCoursesBeforePaging();
    } else {
      this.attributesView.pageIndex = event.pageIndex;
      let numero: number = (this.attributesView.pageIndex + 1) * this.attributesView.pageSize;
      if ((numero > this.listData.length) && (this.listData.length < event.length)) {
        this.getBasicCoursesAfterPaging();
      } else {
        this.localTableData();
      }
    }
  }

  //recargar datos de la tabla
  localTableData() {
    this.listDataTwo = [];
    this.listDataCopy = [];
    for (let i: number = 0; i < this.listData.length; i++) {
      let mult: number = this.attributesView.pageIndex * this.attributesView.pageSize;
      if ((i >= mult) && (i < (mult + this.attributesView.pageSize))) {
        this.listDataTwo.push(this.listData[i]);
        this.listDataCopy.push(this.listData[i]);
      }
    }
  }

  // deleteBasicCourse() {
  //   this.closeModal();
  //   this._loading.callLoading();
  //   this._mcBasicCourseAPI.deleteBasicCourse(this.attributesView.idDeleteCourse, () => {
  //     this.resfleshTable();
  //     this._mcAlert.showAlertInformation(MCMessagesEnum.globalDeleteData);
  //   }, error => {
  //     this._loading.closeLoading();
  //     if (error.objectMessage == undefined) {
  //       this.resfleshTable();
  //       this._mcAlert.showAlertInformation(MCMessagesEnum.globalDeleteData);
  //     } else {
  //       this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
  //     }
  //   })
  // }

  modifyBasicCourse(element: any) {
    this.actionRequestToolbar = new MCToolbarClass(MCConstants.email, MCConstants.modify);
    this.child.attributes.hidden = true;
    this.child.passCourseBasic(element);
    this.attributesView.view_crud = !this.attributesView.view_crud;
  }

  toolRespond($event: string) {
    if ($event == MCConstants.select) {
      this.actionRequestToolbar = new MCToolbarClass(MCConstants.emails, MCConstants.select);
      this.attributesView.view_crud = !this.attributesView.view_crud;
      this.child.attributes.hidden = false;
    } else {
      this.actionRequestToolbar = new MCToolbarClass(MCConstants.email, MCConstants.register);
      this.attributesView.view_crud = !this.attributesView.view_crud;
      this.child.attributes.hidden = true;
      this.child.resetBasicCourse();
    }
  }

  emailRespond($event: any) {
    this.child.attributes.hidden = false;
    this.child._loading.closeLoading();
    this.attributesView.view_crud = !this.attributesView.view_crud;
    this.actionRequestToolbar = new MCToolbarClass(MCConstants.emails, MCConstants.select);
    if ($event == MCConstants.modify) {
    //  this.refleshTable(respondBasicCourse);
    }
  }

  resfleshTable() {
    this.attributesView.pageIndex = 0;
    this.getBasicCoursesBeforePaging();
  }

  findFilter() {
    this.resfleshTable();
  }

  cleanFilter() {
    this.filter.selectedValueAudience = MCConstants.notSelected;
    this.filter.selectedValueRegion = MCConstants.notSelected;
    this.filter.selectedValueCommune = MCConstants.notSelected;
    this.resfleshTable();
  }

  actionCollapses() {
    this.attributesView.filterCollapses = !this.attributesView.filterCollapses;
  }

  indicatorArrow(value: any) {
    this.attributesView.tableArrow = value;
  }

  changeSelect(event: any, numero: number) {
    if (numero == 12) {
      this.filter.selectedValueCommune = MCConstants.notSelected;
      this.fillCommunes(this.filter.selectedValueRegion);
    }
  }

  //llena el combo de comunas dependiendo del la region seleccionada
  fillCommunes(valor: any) {
    if (valor != MCConstants.notSelected) {
      for (let rep of this.listRegion) {
        if (valor == rep.id) {
          this.listCommune = rep.communes!;
          break;
        }
      }
    } else {
      this.filter.selectedValueCommune = MCConstants.notSelected;
      this.listCommune = [];
    }
  }

  exportexcel(): void
  {
    /* pass here the table id */
    //let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(this.listDataTwo);
    //ws['!cols']![0] = { hidden: true };
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'ExcelSheet.xlsx');
  }

  public exportAsExcelFile(): void {
    let jsonToExcel = new MCJsonToExcelEmailsEntity;
    const workSheet = XLSX.utils.json_to_sheet(jsonToExcel.transforData(this.listDataTwo));
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, workSheet, 'data'); // add the worksheet to the book
    XLSX.writeFile(wb, 'temp.xlsx'); // initiate a file download in browser
  }


}
