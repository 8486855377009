import { MatPaginatorIntl } from '@angular/material/paginator';

const ramgoEtiqueta = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) { return `0 de ${length}`; }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

    return `Página ${Math.ceil(endIndex / pageSize)} de ${Math.ceil(length / pageSize)}    Total Registros: ${length}`;
  //return `${startIndex + 1} - ${endIndex} de ${length}`;
}

export function paginatorTranslator() {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = 'Items por página:';
  paginatorIntl.nextPageLabel = 'Siguiente';
  paginatorIntl.previousPageLabel = 'Anterior';
  paginatorIntl.getRangeLabel = ramgoEtiqueta;

  return paginatorIntl;
}
