<app-mc-toolbar [actionRequest]="actionRequestToolbar" (toolRespond)="toolRespond($event)" class="fixed-top up-div">
</app-mc-toolbar>
<div class="mobileClassTool">
  <app-mc-view-courses (coursesRespond)="coursesRespond($event)"></app-mc-view-courses>

  <div *ngIf="!attributesView.view_crud">
    <div class="mainDivContent">
      <div *ngIf="!attributesView.filterCollapses">
        <div class="d-flex flex-wrap pe-3">
          <div><button class="btn btn-outline-dark btnDarkCollapse"
              (click)="actionCollapses()">{{attributesView.showFilteraText}} <i
                class="bi bi-caret-down-fill"></i></button></div>
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li *ngIf="range.controls.start.value != null && range.controls.start.value != ''
                           && range.controls.end.value != null && range.controls.end.value != ''"
                  class="breadcrumb-item">Fecha:
                  <span>{{range.controls.start.value | date:'dd/MM/yyyy'}} - {{range.controls.end.value |
                    date:'dd/MM/yyyy'}}</span>
                </li>
                <li *ngIf="filter.modalidad != 'notselected'" class="breadcrumb-item">Modalidad:
                  <span>{{filter.modalidad}}</span>
                <li *ngIf="filter.region_name != 'notselected'" class="breadcrumb-item">Región:
                  <span>{{filter.region_name}}</span>
                </li>
                <li *ngIf="filter.teacher_name != ''" class="breadcrumb-item">Relator:
                  <span>{{filter.teacher_name}}</span>
                  <!-- <li *ngIf="filter.sence_title != ''" class="breadcrumb-item">Título sence:
                  <span>{{filter.sence_title}}</span>
                </li> -->
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <button *ngIf="attributesView.filterCollapses" class="btn btn-outline-dark btnDarkCollapse"
        (click)="actionCollapses()">
        {{attributesView.hideFiltersText}} <i class="bi bi-caret-up-fill"></i>
      </button>
    </div>
    <div *ngIf="attributesView.filterCollapses" class="mat-elevation-z8 mainFilterStyle">
      <div>
        <div class="row">

          <div class="col-12 col-sm-6 col-md-4">
            <mat-form-field appearance="legacy">
              <mat-label>Por fecha desde - hasta</mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker" (click)="picker.open()">
                <input matStartDate formControlName="start" placeholder="Desde">
                <input matEndDate formControlName="end" placeholder="Hasta" (dateChange)="validDateFilter()">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-4">
            <mat-form-field appearance="legacy">
              <mat-label>Modalidad</mat-label>
              <mat-select disableRipple [(ngModel)]="filter.modalidad" name="fmodalidad" (ngModelChange)="findFilter()">
                <mat-option value="notselected">-- Todas --</mat-option>
                <mat-option value="Presencial">Presencial</mat-option>
                <mat-option value="Virtual">Virtual</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-4">
            <mat-form-field appearance="legacy">
              <mat-label>Región</mat-label>
              <mat-select disableRipple [(ngModel)]="filter.region_name" name="reg" (ngModelChange)="findFilter()">
                <mat-option value="notselected">-- Región --</mat-option>
                <mat-option *ngFor="let reg of listRegion; let iv = index" [value]="reg.id">{{reg?.id}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>


          <div class="col-12 col-sm-6 col-md-4">
            <mat-form-field appearance="legacy">
              <mat-label>Relator</mat-label>
              <input matInput maxlength="100" name="tp" [(ngModel)]="filter.teacher_name" (keyup.enter)="findFilter()">
            </mat-form-field>
          </div>

          <!-- <div class="col-12 col-sm-6 col-md-4">
            <mat-form-field appearance="legacy">
              <mat-label>Título sence</mat-label>
              <input matInput maxlength="50" [(ngModel)]="filter.sence_title">
            </mat-form-field>
          </div> -->



          <div class="col-12" style="margin-top: 15px;">
            <button mat-raised-button color="primary" (click)="findFilter()">
              <mat-icon>search</mat-icon>Buscar
            </button>
            <button mat-raised-button class="btnFilter" (click)="cleanFilter()">
              <mat-icon>brush</mat-icon>Limpiar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="before-table mat-elevation-z8">
      <div class="before-table-div">

        <table mat-table (window:resize)="onResize($event)" [dataSource]="listDataTwo" multiTemplateDataRows>

          <ng-container matColumnDef="op_first">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <!-- <button mat-menu-item (click)="modifyOpenCourse(element, '')">
                  <mat-icon>playlist_add_check</mat-icon>
                  <span>Ver o editar detalles del curso</span>
                </button> -->
                <button mat-menu-item (click)="modifyOpenCourse(element, 'basic')">
                  <mat-icon>create</mat-icon>
                  <span>Editar</span>
                </button>
                <button mat-menu-item (click)="openModal(element.id)">
                  <mat-icon>delete</mat-icon>
                  <span>Eliminar</span>
                </button>
              </mat-menu>

            </td>
          </ng-container>

          <ng-container matColumnDef="op_id">
            <th mat-header-cell *matHeaderCellDef> Código </th>
            <td mat-cell *matCellDef="let element"> {{element?.course?.id}}-{{element?.id}}</td>
          </ng-container>

          <ng-container matColumnDef="op_name_course">
            <th mat-header-cell *matHeaderCellDef> Nombre del Curso </th>
            <td mat-cell *matCellDef="let element">{{element?.name_course}} </td>
          </ng-container>

          <ng-container *ngIf="!attributesView.isMobile" matColumnDef="op_teacher">
            <th mat-header-cell *matHeaderCellDef> Relator </th>
            <td mat-cell *matCellDef="let element">{{element?.teacher?.name}} </td>
          </ng-container>

          <ng-container *ngIf="!attributesView.isMobile" matColumnDef="op_start_date">
            <th mat-header-cell *matHeaderCellDef> Fecha de Inicio </th>
            <td mat-cell *matCellDef="let element">{{element?.start_date | date:'dd/MM/yyyy'}} </td>
          </ng-container>

          <ng-container *ngIf="!attributesView.isMobile" matColumnDef="op_hours">
            <th mat-header-cell *matHeaderCellDef> Horas </th>
            <td mat-cell *matCellDef="let element">{{element?.duration}} </td>
          </ng-container>

          <ng-container *ngIf="!attributesView.isMobile" matColumnDef="op_mode">
            <th mat-header-cell *matHeaderCellDef> Modalidad</th>
            <td mat-cell *matCellDef="let element">{{element?.mode}} </td>
          </ng-container>

          <ng-container *ngIf="!attributesView.isMobile" matColumnDef="op_price">
            <th mat-header-cell *matHeaderCellDef> Precio </th>
            <td mat-cell *matCellDef="let element">${{element?.price | currency: '':'':'.0-0':'it'}} </td>
          </ng-container>

          <ng-container matColumnDef="op_arrow">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <mat-icon *ngIf="attributesView.tableArrow != element">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="attributesView.tableArrow == element">keyboard_arrow_up</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
              <div class="example-element-detail" [@detailExpand]="element == listDataCopy ? 'expanded' : 'collapsed'">
                <div style="width: 100%; padding: 3px !important;">
                  <div class="row g-0 mat-elevation-z8">
                    <div class="col-12" style="padding: 10px !important; padding-top: 20px !important;">
                      <fieldset class="scheduler-border">
                        <legend class="scheduler-border" style="margin-top: -15px !important; width: 100px !important;">
                          Información
                        </legend>

                        <div class="row">
                          <div class="col-12 col-md-6 p-2">

                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Código: </span> {{element?.course?.id}}-{{element?.id}}
                              </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Nombre del curso: </span> {{element?.name_course}}
                              </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Título para el diploma:</span>
                                {{element?.certificate_title_course}}
                              </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Duración del curso: </span> {{element?.duration}} Horas
                              </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Modalidad: </span> {{element?.mode}}
                              </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Valor del curso: </span> ${{element?.price | currency:
                                '':'':'.0-0':'it'}}
                              </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Suspendido: </span> {{element?.discontinued == true ?
                                'Si':
                                'No'}}
                              </mat-list-item>
                            </div>
                            <div *ngIf="element?.mode == 'Presencial'" class="col-mat-list">
                              <mat-list-item><span class="spa">Región : </span> {{element?.commune?.region?.id}}
                              </mat-list-item>
                            </div>
                            <div *ngIf="element?.mode == 'Presencial'" class="col-mat-list">
                              <mat-list-item><span class="spa">Comuna: </span> {{element?.commune?.name}}
                              </mat-list-item>
                            </div>
                            <div *ngIf="element?.mode == 'Presencial'" class="col-mat-list">
                              <mat-list-item><span class="spa">Lugar </span> {{element?.place}}
                              </mat-list-item>
                            </div>
                            <div *ngIf="element?.mode == 'Presencial'" class="col-mat-list">
                              <mat-list-item><span class="spa">Dirección: </span> {{element?.address}}
                              </mat-list-item>
                            </div>
                            <div *ngIf="element?.mode != 'Presencial'" class="col-mat-list">
                              <mat-list-item><span class="spa">Link Zoom: </span> {{element?.link}}
                              </mat-list-item>
                            </div>
                          </div>


                          <div class="col-12 col-md-6 p-2">
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Fecha Inicio: </span> {{element?.start_date |
                                date:'dd/MM/yyyy'}}
                              </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Fecha Final: </span> {{element?.final_date |
                                date:'dd/MM/yyyy'}}
                              </mat-list-item>
                            </div>
                            <div class="col-mat-list" *ngIf="this.filter.scope != 'Cerrado'">
                              <mat-list-item><span class="spa">Fecha y hora de cierre de inscripción: </span>
                                {{element?.deadline | date:'dd/MM/yyyy h:mm a'}}
                              </mat-list-item>
                            </div>
                            <!-- <div class="col-mat-list">
                              <mat-list-item><span class="spa">Fecha material de asistencia: </span>
                                {{element?.material_date
                                | date:'dd/MM/yyyy'}}
                              </mat-list-item>
                            </div> -->
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Relator: </span> {{element?.teacher?.name}}
                              </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa"> Título sence: </span> {{element?.sence?.title}}
                              </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Observación </span> {{element?.observation}}
                              </mat-list-item>
                            </div>
                            <div class="col-mat-list" style="text-align: right;">
                              <mat-list-item>
                                <button mat-raised-button color="primary" (click)="modifyOpenCourse(element, 'basic')"
                                style="width: 150px; margin-right: 10%;" >Ingresar al Curso</button>
                              </mat-list-item>
                            </div>
                          </div>
                        </div>


                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
            [class.example-expanded-row]="listDataCopy === element"
            (click)="listDataCopy = listDataCopy === element ? null : element; indicatorArrow(listDataCopy != element ? null : element)">
          </tr>

          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

        </table>

        <mat-paginator #paginator [length]="attributesView.lengthPaginador" [pageIndex]="attributesView.pageIndex"
          [pageSize]="attributesView.pageSize" [pageSizeOptions]="attributesView.pageSizeOptions"
          (page)="paginador($event)">
        </mat-paginator>

      </div>
    </div>
  </div>

</div>

<div class="modal fade" id="staticBasicCourse" data-bs-backdrop="static" data-bs-keyboard="false"
  aria-labelledby="staticBackdropLabel" aria-hidden="false" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h2 id="staticBackdropLabel">Eliminar Courso</h2>
        <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        <div>
          <div>
            <form>
              <div class="row form-box-style">
                <h4>¿Si elimina este registro no podrá recuperarlo, desea eliminar el registro?</h4>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row" style="width: 100%;">
          <div class="col-6">
            <button mat-raised-button color="warn" (click)="deleteOpenCourse()" style="width: 100%;">Sí</button>
          </div>
          <div class="col-6">
            <button mat-raised-button (click)="closeModal()" style="width: 100%;">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
