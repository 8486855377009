import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MCConstants } from 'src/app/mc-helpers/mc-constants/mc-constants';

export interface attributes {
  hidden: boolean;
  action: string;
  codeChecked: boolean;
}

@Component({
  selector: 'app-mc-view-email',
  templateUrl: './mc-view-email.component.html',
  styleUrls: ['./mc-view-email.component.css']
})
export class McViewEmailComponent implements OnInit {
  @Output() emailRespond = new EventEmitter<string>();
  attributes = {
    hidden: false,
    action: MCConstants.register,
    codeChecked: false,
  };

  constructor() { }

  ngOnInit(): void {
  }

}
