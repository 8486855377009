
import { MCRoutesEnum } from './mc-enums/mc-routes-enum'
import { MCErrorTypeEnum } from './mc-enums/mc-error-type-enum'
import { MCUtils } from './mc-class/mc-utils'

export class MCError {
    // Private properties.

    private _mcUtils = new MCUtils()

    // Properties.
    
    title = "¡Ha ocurrido un error!"
    subtitle = "500"
    code = 500
    message = "Lo sentimos, se ha producido un error inesperado. Inténtalo nuevamente."
    objectMessage: any = undefined;
    route = MCRoutesEnum.error
    errorType = MCErrorTypeEnum.redirect

    // Constructors.

    constructor(error?: any) {
        if (
            error == undefined ||
            error == null ||
            error.status == undefined ||
            error.status == null
        ) {
            this.errorType = MCErrorTypeEnum.alert
            return
        }
        this.code = error.status
        switch (error.status) {
            case 400:
                this.message = this.getConstraintMessage(error.error) ?? this.message
                this.objectMessage = error.error.errors;
                this.errorType = MCErrorTypeEnum.alert
                break
            case 401:
                this.message = "Su sesión a expirado."
                this.errorType = MCErrorTypeEnum.all
                break
            case 404:
                 if (
                     error.error != undefined &&
                     error.error != null
                 ) {
                    this.title = "¡Página no encontrada!"
                    this.message = this.getConstraintMessage(error.error) ?? "La página a la cual esta intentando acceder no existe. Verifica e inténtalo nuevamente."
                    this.errorType = MCErrorTypeEnum.redirect
                    this.objectMessage = error.error.errors;
                 } else {
                    this.errorType = MCErrorTypeEnum.alert
                 }
                 break
            case 422:
                let newMessage = this.processErrors(error)
                this.message = this._mcUtils.validateStringUndefinedNull(newMessage) ? newMessage : this.message
                this.errorType = MCErrorTypeEnum.alert
                break
            case 429:
                this.message = this.getConstraintMessage(error.error);
                this.objectMessage = error.error.errors;
                this.errorType = MCErrorTypeEnum.alert
                break
            case 500:
                this.message = "Lo sentimos, al parecer tenemos problemas de conexión. Inténtalo nuevamente y si el problema persiste comunícate con  Soporte técnico"
                this.errorType = MCErrorTypeEnum.redirect
                break
            default:
                break
        }
        this.subtitle = error.status
    }

  private getConstraintMessage(value: any): any {
    if (
      value != undefined &&
      value != null &&
      value.errors != undefined &&
      value.errors != null &&
      value.errors.constraint != undefined &&
      value.errors.constraint != null &&
      value.errors.constraint[0] != undefined &&
      value.errors.constraint[0] != null
    ) {
      return value.errors.constraint[0];
    } 
  }

  private processErrors(value: any): any {
    let message = String()
    let objectMessage = value.error.errors
    if(objectMessage) {
        for (const field in objectMessage) { 
            let newMessage = objectMessage[field][0]
            if (newMessage) {
                message = newMessage 
                break
            }
        }
    }
    return message
  }
}