import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MCRouter } from 'src/app/mc-helpers/mc-class/mc-router';
import { MCError } from 'src/app/mc-helpers/mc-error';
import { MCRoutesEnum } from 'src/app/mc-helpers/mc-enums/mc-routes-enum';

@Component({
  selector: 'app-mc-error',
  templateUrl: './mc-error.component.html' 
})
export class MCErrorComponent {

  nombreApp : string = ""; 
  sessionStorag : boolean = false;  
  textoExtra: string = "";
   
  titulo : string;  urlText : string = "";   mesaje : string;   
  private _mcRouter: MCRouter

  constructor(
    private route: Router
  ) {
    this._mcRouter = new MCRouter(route)
    const error = this._mcRouter.routerData!.data as MCError
    this.titulo = error.title 
    this.urlText = error.subtitle
    this.mesaje = error.message
  }

  cancelar() {    
    if(this._mcRouter.routerData == undefined || this._mcRouter.routerData == null) {
      this._mcRouter.navigateToWithWindowLocation(MCRoutesEnum.admin)
    } else {
      this._mcRouter.navigateToURL(this._mcRouter.routerData)
    }
  }
 

}
