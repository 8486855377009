export const MCConstants = {
    //s
    rut: "RUT",
    pas: "PAS",
    telephonePrefix: "+56",
    aceptar: "Aceptar",
    close: "Cerrar",
    error: "Error",
    gobalCaution: "Advertencia",
    informationMessage: "Mensaje de Información",
    notSelected: "notselected",
    woman: "Mujer",
    men: "Hombre",
    virtual: "Virtual",
    faceToFace: "Presencial",
    natural: "Natural",
    institution: "Institución",
    hideFiltersText: "Filtrar Por",
    showFilteraText: "Filtrar Por",
    tabCustomerClose: "Cliente",
    tabCustomerOpen: "Listado de Clientes",
    priceCourseOpen: "Valor por Participante",
    priceCourseClose: "Valor del Curso",
    closed: "Cerrado",
    opened: "Abierto",

    //Acciones
    register: "Registrar",
    modify: "Modificar",
    delete: "Eliminar",
    select: "Select",

    //params
    paramPage :"page",
    paramLimit:"limit",
    paramId: "id",
    paramName:"name",
    paramDocument :"document",
    paramCode: "code",
    paramTitle: "title",
    paramRegionName: "region_name",
    paramCommuneName: "commune_name",
    paramDependencyName: "dependency_name",
    paramDocumentType: "document_type",
    paramInstitutionName: "institution_name",
    paramType: "type",
    paramLastName: "last_name",
    paramMotherLastName: "mothers_last_name",
    paramCourseBaseName: "course_base",
    paramAudience:"audience",

    //teacher
    paramAcademicTitle: "academic_title",
    //param sence
    paramCodeSence: "code_sence",
    paramTeacherName: "teacher_name",
    paramTeacherMode: "mode",
    //param Basic Courses
    paramCertificateTitle: "certificate_title",
    //open course
    paramScope: 'scope',
    paramStartDate: 'start_date',
    paramStartDateFinal: 'start_date_final',
    paramMode: 'mode',
    paramSenceTitle: 'sence_title',

    //entidades
    teacher: "Relator",
    teachers: "Listado de relatores",
    sence: "Sence",
    sences: "Listado de códigos sence",
    student: "Participante",
    students: "Listado de participante",
    customer: "Cliente",
    customers: "Listado de clientes",
    courseBasic: "Curso Base",
    coursesBasic: "Listado de cursos base",
    course: "Curso",
    courses: "Listado de cursos",
    open_course: "Curso abierto",
    open_courses: "Listado de curso abiertos",
    close_course: "Curso cerrado",
    close_courses: "Listado de curso cerrados",
    email: "Correo",
    emails: "Listado de correos",

    headerContentType: "Content-Type",
    xEmail: "X-MahuidaCourses-Email",
    xPassword: "X-MahuidaCourses-Password",
    authorization: "Authorization",
    bearer: "Bearer",
    xToken: "X-MahuidaCourses-Token"
}
