import { MCConstants } from "src/app/mc-helpers/mc-constants/mc-constants";
import { MCCommuneEntity } from "./mc-commune-entitys";

export class MCEmailsEntity {
    // Properties.

    total: number;
    entities: MCEmailEntity[] = [];

    // Constructors.

    constructor(total: number, object: any) {
        this.total = total ?? 0;
        if (object) {
            for (let entity of object) {
                this.entities.push(new MCEmailEntity(entity));
            }
        }
    }
}

export class MCEmailEntity {
    id: number;
    document_type: string;
    document: string;
    name: string;
    last_name: string;
    email: string;
    audience: string;
    allow_advertising: boolean;
    commune?: MCCommuneEntity;
    businessName: string;

    constructor(object: any) {
        this.id = object.id ?? 0;
        this.document_type = object.document_type ?? MCConstants.rut;
        this.document = object.document ?? String();
        this.name = object.name ?? String();
        this.last_name = object.last_name ?? String();
        this.email = object.email ?? String();
        this.audience = object.audience ?? String();
        this.allow_advertising = object.allow_advertising ?? false;
        this.commune = object.commune;
        this.businessName = this.businessNameMethod(object);
    }

    businessNameMethod(object: any): string {
      let l_type: string = object.type ?? MCConstants.natural
      let l_name: string = object.name ?? String();
      if (l_type == MCConstants.natural) {
          let l_last_name: string = object.last_name ?? String();
          let l_mothers_last_name: string = object.mothers_last_name ?? String();
          return l_name + " " + l_last_name + " " + l_mothers_last_name;
      } else {
          return l_name;
      }
  }
}

export class MCEmailEntityRequests {
    commune_id: number;
    document_type: string;
    document: string;
    name: string;
    sex: string;
    curriculum: string;
    mobile: string;
    email: string;
    address: string;
    observation: string;
    academic_titles:number[];

    constructor(object: any, idCommune: number, listAcademicTitles: any) {
        this.commune_id = idCommune;
        this.document_type = object.document_type;
        this.document = object.document;
        this.name = object.name;
        this.sex = object.sex;
        this.curriculum = object.curriculum;
        this.mobile = object.mobile;
        this.email = object.email;
        this.address = object.address;
        this.observation = object.observation;
        this.academic_titles = listAcademicTitles;
    }
}
