import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MCCommuneEntity } from 'src/app/mc-entity/mc-entities/mc-commune-entitys';
import { MCCustomerEntity, MCCustomerEntityRequests, MCCustomerLegalEntityRequests } from 'src/app/mc-entity/mc-entities/mc-customer-entitys';
import { MCDependencyEntity } from 'src/app/mc-entity/mc-entities/mc-dependency-entitys';
import { MCRegionEntity } from 'src/app/mc-entity/mc-entities/mc-region-entitys';
import { MCAlert } from 'src/app/mc-helpers/mc-alert';
import { MCRouter } from 'src/app/mc-helpers/mc-class/mc-router';
import { MCUtils } from 'src/app/mc-helpers/mc-class/mc-utils';
import { MCConstants } from 'src/app/mc-helpers/mc-constants/mc-constants';
import { MCMessagesEnum } from 'src/app/mc-helpers/mc-enums/mc-messages-enum';
import { MCCustomerAPI } from 'src/app/mc-repository/mc-api/mc-customer-api';
import { MCDataSourceLocal, MCDataSourceLocalInterface } from 'src/app/mc-repository/mc-data-source-local';
import { MCLoadingService } from 'src/app/mc-service/mc-loading.service';
declare var require: any;
const { validate, format } = require('rut.js');

export interface attributes {
  messageEmail: string;
  messageMobile: string;
  messageEmailTwo: string;
  messagePhone: string;
  hidden: boolean;
  selectedValueRegion: string;
  selectedValueCommune: string;
  action: string;
  rutChecked: boolean;
  selectedValueDependencies: string;
  hiddentwo: string;
}

@Component({
  selector: 'app-mc-view-customer',
  templateUrl: './mc-view-customer.component.html',
  styleUrls: ['./mc-view-customer.component.css']
})
export class MCViewCustomerComponent implements OnInit {

  private _mcRouter: MCRouter;
  @Output() customerRespond = new EventEmitter<string>();
  private _mcDataSourceLocal: MCDataSourceLocalInterface = new MCDataSourceLocal();
  customer: MCCustomerEntity = new MCCustomerEntity(String());

  listRegion: MCRegionEntity[] = [];
  listCommune: MCCommuneEntity[] = [];
  listDependency: MCDependencyEntity[] = [];

  attributes = {
    messageEmail: String(),
    messageMobile: String(),
    messageEmailTwo: String(),
    messagePhone: String(),
    hidden: false,
    selectedValueRegion: MCConstants.notSelected,
    selectedValueCommune: MCConstants.notSelected,
    action: MCConstants.register,
    rutChecked: false,
    selectedValueDependencies: MCConstants.notSelected,
    hiddentwo: MCConstants.notSelected
  };

  constructor(
    private _loading: MCLoadingService,
    public _route: Router,
    private _mcUtils: MCUtils,
    private _mcAlert: MCAlert,
    private _mcCustomerAPI: MCCustomerAPI,
  ) {
    this._mcRouter = new MCRouter(_route);
    this.getDependency();
    this.listRegion = this._mcDataSourceLocal.getRegions();
  }

  ngOnInit(): void {
    this.fillCommunes(this.attributes.selectedValueRegion);
  }

  getDependency() {
    this.listDependency = [];
    this._mcCustomerAPI.getDependency(data => {
      this.listDependency = data.entities;
    }, error => {
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }

  rutValidate() {
    this.customer.document = this._mcUtils.removeBlanks(this.customer.document);
    this.customer.document = this.customer.document.toUpperCase();
    if (this.customer.document != String()) {
      this.customer.document = format(this.customer.document);
      validate(this.customer.document) ? this.validateExist() : this._mcAlert.showAlertWarning(MCMessagesEnum.globalInvalidRut);
    } else {
      this._mcAlert.showAlertWarning(MCMessagesEnum.globalEmptyRut);
    }
  }

  validateExist() {
    this._loading.callLoading();
    this._mcCustomerAPI.getCustomer(this.customer.document, () => {
      this._loading.closeLoading();
      this._mcAlert.showAlertError(MCMessagesEnum.rutExist);
    }, error => {
      this._loading.closeLoading();
      if (error.objectMessage == undefined) {
        this.attributes.rutChecked = true;
      } else {
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      }
    })
  }

  changeSelect(event: any, numero: number) {
    if (numero == 12) {
      this.attributes.selectedValueCommune = MCConstants.notSelected;
      this.fillCommunes(this.attributes.selectedValueRegion);
    }
  }

  //llena el combo de comunas dependiendo del la region seleccionada
  fillCommunes(valor: any) {
    if (valor != MCConstants.notSelected) {
      for (let rep of this.listRegion) {
        if (valor == rep.id) {
          this.listCommune = rep.communes!;
          break;
        }
      }
     //this.attributes.selectedValueCommune != MCConstants.notSelected
      if (this.customer.commune.id != 0) {
        this.attributes.selectedValueCommune = this.customer.commune.id.toString();
      }
    } else {
      this.listCommune = [];
      this.attributes.selectedValueCommune = MCConstants.notSelected;
    }
  }


  resetCustomer() {
    this.customer = new MCCustomerEntity(String());
    this.attributes.hiddentwo = MCConstants.notSelected;
    this.attributes.rutChecked = false;
    this.attributes.action = MCConstants.register;
    this.attributes.selectedValueRegion = MCConstants.notSelected;
    this.attributes.selectedValueCommune = MCConstants.notSelected;
    this.attributes.selectedValueDependencies = MCConstants.notSelected;
  }

  passCustomer(passCustomer: MCCustomerEntity) {
    this.customer = new MCCustomerEntity(passCustomer);
    this.attributes.rutChecked = true;
    this.attributes.selectedValueRegion = this.customer.commune.region!.id.toString();
    this.attributes.selectedValueDependencies = this.customer.dependency.id;
    this.fillCommunes(this.attributes.selectedValueRegion);
    this.attributes.action = MCConstants.modify;
  }

  onEdit(event: any, item: any) {
    event.stopPropagation();
  }

  actionButton(action: string) {
    if (action == MCConstants.select) {
      this.customerRespond.emit(MCConstants.customers);
      this.attributes.hiddentwo = MCConstants.notSelected;
    } else {
      (this.attributes.hiddentwo == 'Natural') ? this.natural() : this.legal();
    }
  }

  legal() {
    if (this.validateFormLegal()) {
      if (this.attributes.action == MCConstants.register) {
        this.postLegal();
      } else if (this.attributes.action == MCConstants.modify) {
        this.putLegal();
      }
    }
  }

  natural() {
    if (this.validateForm()) {
      if (this.attributes.action == MCConstants.register) {
        this.post();
      } else if (this.attributes.action == MCConstants.modify) {
        this.put();
      }
    }
  }

   //valida formulario antes de realzar una accion
   validateFormLegal(): boolean {
    if (!this._mcUtils.validateStringUndefined(this.customer.document)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerDocumentFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateStringUndefined(this.customer.name)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerNameFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateStringUndefined(this.customer.institution_name)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerBusinessNameFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateStringUndefined(this.customer.contact)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerContactFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateFormSelect([this.attributes.selectedValueDependencies])) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerDependencieSeleted);
      return false;
    } else if (!this._mcUtils.validatePhone(this.customer.mobile)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerMobilFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateEmail(this.customer.email)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerEmailFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateFormSelect([this.attributes.selectedValueRegion])) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerRegionSeleted);
      return false;
    } else if (!this._mcUtils.validateFormSelect([this.attributes.selectedValueCommune])) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerComunaSeleted);
      return false;
    } else {
      return true;
    }
  }

  //valida formulario antes de realzar una accion
  validateForm(): boolean {
    if (!this._mcUtils.validateStringUndefined(this.customer.document)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerDocumentFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateStringUndefined(this.customer.name)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerNameNaturalFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateStringUndefined(this.customer.last_name)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerLastNameFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateStringUndefined(this.customer.mothers_last_name)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerMothersLastNameFieldEmpty);
      return false;
    }  else if (!this._mcUtils.validateStringUndefined(this.customer.institution_name)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerBusinessNameFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateStringUndefined(this.customer.profession)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerProfessionFieldEmpty);
      return false;
    }  else if (!this._mcUtils.validatePhone(this.customer.mobile)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerMobilFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateEmail(this.customer.email)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerEmailFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateFormSelect([this.attributes.selectedValueRegion])) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerRegionSeleted);
      return false;
    } else if (!this._mcUtils.validateFormSelect([this.attributes.selectedValueCommune])) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.customerComunaSeleted);
      return false;
    } else {
      return true;
    }
  }

  post() {
    this._loading.callLoading();
    let customerInsert: MCCustomerEntityRequests = new MCCustomerEntityRequests(
      this.customer,
      Number(this.attributes.selectedValueCommune)
    );

    this._mcCustomerAPI.postCustomer(customerInsert, () => {
      this.customerRespond.emit(MCConstants.register);
    }, error => {
      this._loading.closeLoading();
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }

  put() {
    this._loading.callLoading();
    let customerInsert: MCCustomerEntityRequests = new MCCustomerEntityRequests(
      this.customer,
      Number(this.attributes.selectedValueCommune)
    );
    this._mcCustomerAPI.putCustomer(this.customer.id.toString(), customerInsert, () => {
      this.replaceData();
    }, error => {
      this._loading.closeLoading();
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }

  postLegal() {
    this._loading.callLoading();
    let customerInsert: MCCustomerLegalEntityRequests = new MCCustomerLegalEntityRequests(
      this.customer,
      Number(this.attributes.selectedValueCommune),
      this.attributes.selectedValueDependencies
    );

    this._mcCustomerAPI.postCustomerLegal(customerInsert, () => {
      this.customerRespond.emit(MCConstants.register);
    }, error => {
      this._loading.closeLoading();
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }

  putLegal() {
    this._loading.callLoading();
    let customerInsert: MCCustomerLegalEntityRequests = new MCCustomerLegalEntityRequests(
      this.customer,
      Number(this.attributes.selectedValueCommune),
      this.attributes.selectedValueDependencies
    );

    this._mcCustomerAPI.putCustomerLegal(this.customer.id.toString(), customerInsert, () => {
      this.replaceData();
    }, error => {
      this._loading.closeLoading();
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }

  replaceData() {
    this.customer.businessName = this.customer.businessNameMethodView(
      this.customer.name,
      this.customer.last_name,
      this.customer.mothers_last_name
    );

    if (this.customer.type == MCConstants.institution) {
      for (let rep of this.listDependency) {
        if (rep.id == this.attributes.selectedValueDependencies) {
          this.customer.dependency.id = rep.id;
          this.customer.dependency.sequence = rep.sequence;
        }
      }
    }

    for (let rep of this.listRegion) {
      if (rep.id == this.attributes.selectedValueRegion) {
        this.customer.commune.region.id = rep.id;
        this.customer.commune.region.sequence = rep.sequence;
      }
    }

    for (let rep of this.listCommune) {
      if (rep.id == Number(this.attributes.selectedValueCommune)) {
        this.customer.commune.id = rep.id;
        this.customer.commune.name = rep.name;
      }
    }
    this.customerRespond.emit(MCConstants.modify);
  }

  //valida el correo
  emailKeydownEvent() {
    this.attributes.messageEmail = this._mcUtils.validateEmailKeyMessage(this.customer.email);
  }

  emailKeydownEventTwo() {
    this.attributes.messageEmailTwo = this._mcUtils.validateEmailKeyMessage(this.customer.email_two);
  }


  //valida el numero en el campo telefono
  phoneKeydownEvent(event: any) {
    if (this._mcUtils.onlyNumber(event)) {
      this.attributes.messageMobile = this._mcUtils.validatePhoneKeyMessage(this.customer.mobile);
    } else {
      this.customer.mobile = this.customer.mobile.replace(event.key, String());
    }
  }

  phoneKeydownEventTwo(event: any) {
    if (this._mcUtils.onlyNumber(event)) {
      this.attributes.messagePhone = this._mcUtils.validatePhoneKeyMessage(this.customer.phone);
    } else {
      this.customer.phone = this.customer.phone.replace(event.key, String());
    }
  }

  clickHidden(respond: string) {
    this.attributes.hiddentwo = respond;
    this.customer.type = respond;
  }

  capitalLetter(text: any){
    if(text == 'name'){
      this.customer.name = this.customer.name.toUpperCase();
    } else if(text == 'last_name'){
      this.customer.last_name = this.customer.last_name.toUpperCase();
    } else if(text == 'mothers_last_name'){
      this.customer.mothers_last_name = this.customer.mothers_last_name.toUpperCase();
    } else if(text == 'institution_name'){
      this.customer.institution_name = this.customer.institution_name.toUpperCase();
    } else if(text == 'profession'){
      this.customer.profession = this.customer.profession.toUpperCase();
    } else if(text == 'address'){
      this.customer.address = this.customer.address.toUpperCase();
    } else if(text == 'observation'){
      this.customer.observation = this.customer.observation.toUpperCase();
    } else if(text == 'activity'){
      this.customer.activity = this.customer.activity.toUpperCase();
    } else if(text == 'contact'){
      this.customer.contact = this.customer.contact.toUpperCase();
    }
  }

  //   close(){
  //     this.actionButton("list");
  //   }

  //   onEdit(event: any, item: any){
  //     event.stopPropagation();
  //     console.log(event);
  //     console.log(item);
  // }



  //   goForward(stepper: MatStepper){
  //     stepper.selectedIndex
  //     console.log(stepper);
  //     stepper.next();
  //   }

  //   goBack(stepper: MatStepper){
  //     stepper.previous();
  // }



}
