import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MCBasicCourseEntity, MCBasicCourseEntityRequests } from 'src/app/mc-entity/mc-entities/mc-basic-course-entitys';
import { MCAlert } from 'src/app/mc-helpers/mc-alert';
import { MCRouter } from 'src/app/mc-helpers/mc-class/mc-router';
import { MCUtils } from 'src/app/mc-helpers/mc-class/mc-utils';
import { MCConstants } from 'src/app/mc-helpers/mc-constants/mc-constants';
import { MCMessagesEnum } from 'src/app/mc-helpers/mc-enums/mc-messages-enum';
import { MCBasicCourseAPI } from 'src/app/mc-repository/mc-api/mc-basic-course-api';
import { MCDataSourceLocal, MCDataSourceLocalInterface } from 'src/app/mc-repository/mc-data-source-local';
import { MCLoadingService } from 'src/app/mc-service/mc-loading.service';

export interface attributes {
  hidden: boolean;
  action: string;
  codeChecked: boolean;
}

@Component({
  selector: 'app-mc-view-course',
  templateUrl: './mc-view-course.component.html',
  styleUrls: ['./mc-view-course.component.css']
})
export class MCViewCourseComponent implements OnInit {


  private _mcRouter: MCRouter;
  @Output() courseRespond = new EventEmitter<string>();
  private _mcDataSourceLocal: MCDataSourceLocalInterface = new MCDataSourceLocal();
  course: MCBasicCourseEntity = new MCBasicCourseEntity(String());

  attributes = {
    hidden: false,
    action: MCConstants.register,
    codeChecked: false,
  };

  constructor(
    private _loading: MCLoadingService,
    public _route: Router,
    private _mcUtils: MCUtils,
    private _mcAlert: MCAlert,
    private _mcBasicCourseAPI: MCBasicCourseAPI,
  ) {
    this._mcRouter = new MCRouter(_route);
  }

  ngOnInit(): void { }

  codeValidate() {
    if (this._mcUtils.validateFormEmpty([this.course.id])) {
      this.validateExist();
    } else {
      this._mcAlert.showAlertWarning(MCMessagesEnum.codeSenceFind);
    }
  }

  validateExist() {
    this._loading.callLoading();
    this._mcBasicCourseAPI.getBasicCourse(this.course.id, () => {
      this._loading.closeLoading();
      this._mcAlert.showAlertError(MCMessagesEnum.codeBasicCourseExist);
    }, error => {
      this._loading.closeLoading();
      if (error.objectMessage == undefined) {
        this.attributes.codeChecked = true;
      } else {
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      }
    })
  }

  resetBasicCourse() {
    this.course = new MCBasicCourseEntity(String());
    this.attributes.codeChecked = false;
    this.attributes.action = MCConstants.register;
  }

  passCourseBasic(passBasicCourse: MCBasicCourseEntity) {
    this.course = new MCBasicCourseEntity(passBasicCourse);
    this.attributes.codeChecked = true;
    this.attributes.action = MCConstants.modify;
  }

  onEdit(event: any, item: any) {
    event.stopPropagation();
  }

  actionButton(action: string) {
    if (action == MCConstants.select) {
      this.courseRespond.emit(MCConstants.coursesBasic);
    } else {
      if (this.validateForm()) {
        if (this.attributes.action == MCConstants.register) {
          this.post();
        } else if (this.attributes.action == MCConstants.modify) {
          this.put()
        }
      }
    }
  }

  //valida formulario antes de realzar una accion
  validateForm(): boolean {
    if (!this._mcUtils.validateStringUndefined(this.course.id)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.courseBaeCodeFieldEmpty)
      return false;
    } else if (!this._mcUtils.validateStringUndefined(this.course.name)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.courseBaseNameFieldEmpty)
      return false;
    } else if (!this._mcUtils.validateStringUndefined(this.course.certificate_title)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.courseBaseTitleFieldEmpty)
      return false;
    } else {
      return true;
    }
  }

  post() {
    this._loading.callLoading();
    let basicCourseInsert: MCBasicCourseEntityRequests = new MCBasicCourseEntityRequests(this.course);
    this._mcBasicCourseAPI.postBasicCourse(basicCourseInsert, () => {
      this.courseRespond.emit(MCConstants.register);
    }, error => {
      this.errorPostPut(error);
    })
  }

  put() {
    this._loading.callLoading();
    let basicCourseInsert: MCBasicCourseEntityRequests = new MCBasicCourseEntityRequests(this.course);
    this._mcBasicCourseAPI.putBasicCourse(this.course.id.toString(), basicCourseInsert, () => {
      this.courseRespond.emit(MCConstants.modify);
    }, error => {
      this.errorPostPut(error);
    })
  }

  errorPostPut(error: any) {
    this._loading.closeLoading();
    if (error.objectMessage == undefined) {
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    } else {
      if (this._mcUtils.validateUndefinedNull(error.objectMessage.name)) {
        this._mcAlert.showAlertError(error.objectMessage.name[0]);
      } else {
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      }
    }
  }

  capitalLetter(text: any){
    if(text == 'id'){
      this.course.id = this.course.id.toUpperCase();
    } else if(text == 'name'){
      this.course.name = this.course.name.toUpperCase();
    } else if(text == 'certificate_title'){
      this.course.certificate_title = this.course.certificate_title.toUpperCase();
    }
  }

}
