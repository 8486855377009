//IMPOR ANGULAR
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
 //IMPORT NGXSPINNER
 import { NgxSpinnerModule } from "ngx-spinner";
//IMPORT ANGULAR MATERIAL
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule }  from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatListModule } from '@angular/material/list';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
//IMPORT COMPONENTES
import { paginatorTranslator } from './mc-helpers/mc-paginator-translator';
import { MCAdminComponent } from './mc-admin/mc-admin.component';
import { MCTeacherComponent } from './mc-admin/mc-teacher/mc-teacher.component';
import { MCViewTeacherComponent } from './mc-component/mc-view-teacher/mc-view-teacher.component';
import { MCToolbarComponent } from './mc-component/mc-toolbar/mc-toolbar.component';
import { MCSenceComponent } from './mc-admin/mc-sence/mc-sence.component';
import { MCCustomerComponent } from './mc-admin/mc-customer/mc-customer.component';
import { MCStudentComponent } from './mc-admin/mc-student/mc-student.component';
import { MCViewSenceComponent } from './mc-component/mc-view-sence/mc-view-sence.component';
import { MCViewStudentComponent } from './mc-component/mc-view-student/mc-view-student.component';
import { MCViewCustomerComponent } from './mc-component/mc-view-customer/mc-view-customer.component';
import { MCViewCourseComponent } from './mc-component/mc-view-course/mc-view-course.component';
import { MCViewCoursesComponent } from './mc-component/mc-view-courses/mc-view-courses.component';
import { MCCoursesComponent } from './mc-admin/mc-courses/mc-courses.component';
import { MCCourseComponent } from './mc-admin/mc-course/mc-course.component';
import { MCCourseCustomerComponent } from './mc-component/mc-course/mc-course-customer/mc-course-customer.component';
import { MCCourseCustomersComponent } from './mc-component/mc-course/mc-course-customers/mc-course-customers.component';
import { MCCourseStudentComponent } from './mc-component/mc-course/mc-course-student/mc-course-student.component';
import { MCCourseStudentsComponent } from './mc-component/mc-course/mc-course-students/mc-course-students.component';
import { MCMainComponent } from './mc-admin/mc-main/mc-main.component';
import { MCErrorComponent } from './mc-component/mc-error/mc-error.component';
import { MCLoginComponent } from './mc-component/mc-login/mc-login.component';
import { MCEmailComponent } from './mc-admin/mc-email/mc-email.component';
import { McViewEmailComponent } from './mc-component/mc-view-email/mc-view-email.component';


@NgModule({
  declarations: [
    AppComponent,
    MCAdminComponent,
    MCTeacherComponent,
    MCViewTeacherComponent,
    MCToolbarComponent,
    MCSenceComponent,
    MCCustomerComponent,
    MCStudentComponent,
    MCViewSenceComponent,
    MCViewStudentComponent,
    MCViewCustomerComponent,
    MCViewCourseComponent,
    MCViewCoursesComponent,
    MCCoursesComponent,
    MCCourseComponent,
    MCCourseCustomerComponent,
    MCCourseCustomersComponent,
    MCCourseStudentComponent,
    MCCourseStudentsComponent,
    MCMainComponent,
    MCErrorComponent,
    MCLoginComponent,
    MCEmailComponent,
    McViewEmailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    MatDialogModule,
    MatSliderModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatTableModule,
    MatExpansionModule,
    MatDividerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    MatListModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatNativeDateModule
  ], exports: [
    MatNativeDateModule
 ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useValue: paginatorTranslator()
    }, {
      provide: MAT_DATE_LOCALE,
      useValue: 'es'
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
