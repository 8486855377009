import { Component, OnInit, ViewChild } from '@angular/core';
import { MCToolbarClass } from 'src/app/mc-helpers/mc-class/mc-toolbar-class';
import { MCConstants } from 'src/app/mc-helpers/mc-constants/mc-constants';
import { MCViewCustomerComponent } from 'src/app/mc-component/mc-view-customer/mc-view-customer.component';
import { MCLoadingService } from 'src/app/mc-service/mc-loading.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MCCustomerEntity } from 'src/app/mc-entity/mc-entities/mc-customer-entitys';
import { MCRouter } from 'src/app/mc-helpers/mc-class/mc-router';
import { MCCustomerAPI } from 'src/app/mc-repository/mc-api/mc-customer-api';
import { Router } from '@angular/router';
import { MCAlert } from 'src/app/mc-helpers/mc-alert';
import { MCUtils } from 'src/app/mc-helpers/mc-class/mc-utils';
import { MCMessagesEnum } from 'src/app/mc-helpers/mc-enums/mc-messages-enum';
import { MCRegionEntity } from 'src/app/mc-entity/mc-entities/mc-region-entitys';
import { MCDependencyEntity } from 'src/app/mc-entity/mc-entities/mc-dependency-entitys';
import { MCCommuneEntity } from 'src/app/mc-entity/mc-entities/mc-commune-entitys';
import { MCDataSourceLocal, MCDataSourceLocalInterface } from 'src/app/mc-repository/mc-data-source-local';
declare var require: any;
const { validate, format } = require('rut.js');
declare var window: any;

export interface filter {
  name: string;
  document: string;
  institution_name: string;
  selectedValueDependencies: string;
  selectedValueRegion: string;
  selectedValueCommune: string;
  selectedType: string;
  last_name: string;
  mother_last_name: string;
}


export interface attributesView {
  idDeleteCustomer: string;
  filterCollapses: boolean;
  view_crud: boolean;
  isMobile: boolean;
  lengthPaginador: number;
  pageIndex: number;
  pageSize: number;
  pageSizeOptions: number[];
  hideFiltersText: string;
  showFilteraText: string;
  tableArrow: any;
  isMobileTwo: boolean;
}


@Component({
  selector: 'app-mc-customer',
  templateUrl: './mc-customer.component.html',
  styleUrls: ['./mc-customer.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class MCCustomerComponent implements OnInit {

  @ViewChild(MCViewCustomerComponent) child: any;
  private _mcRouter: MCRouter;
  actionRequestToolbar: MCToolbarClass = new MCToolbarClass(MCConstants.customers, MCConstants.select);
  columnsToDisplay = ['cu_first', 'cu_type', 'cu_document', 'cu_businessName', 'cu_arrow'];
  private _mcDataSourceLocal: MCDataSourceLocalInterface = new MCDataSourceLocal();

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  attributesView = {
    idDeleteCustomer: String(),
    filterCollapses: false,
    view_crud: false,
    isMobile: false,
    pageIndex: 0,
    pageSize: 10,
    pageSizeOptions: [10, 20, 30, 50],
    lengthPaginador: 10,
    hideFiltersText: MCConstants.hideFiltersText,
    showFilteraText: MCConstants.showFilteraText,
    tableArrow: null,
    isMobileTwo: false
  }

  formModalSence: any = null;
  listData: MCCustomerEntity[] = [];
  listDataTwo: MCCustomerEntity[] = [];
  listDataCopy: MCCustomerEntity[] = [];
  filter = {
    name: String(),
    document: String(),
    institution_name: String(),
    selectedValueRegion: MCConstants.notSelected,
    selectedValueCommune: MCConstants.notSelected,
    selectedValueDependencies: MCConstants.notSelected,
    selectedType: MCConstants.notSelected,
    last_name: String(),
    mother_last_name: String(),
  };

  listRegion: MCRegionEntity[] = [];
  listCommune: MCCommuneEntity[] = [];
  listDependency: MCDependencyEntity[] = [];

  constructor(
    private _loading: MCLoadingService,
    public _route: Router,
    private _mcAlert: MCAlert,
    private _mcCustomerAPI: MCCustomerAPI,
    private _mcUtils: MCUtils
  ) {
    this.actionRequestToolbar = new MCToolbarClass(MCConstants.customers, MCConstants.select);
    this._mcRouter = new MCRouter(_route);
  }

  ngOnInit(): void {
    this.ChangeMobile(window.innerWidth);
    this.getSencesBeforePaging();
    this.getDependency();
    this.listRegion = this._mcDataSourceLocal.getRegions();
  }

  getDependency() {
    this.listDependency = [];
    this._mcCustomerAPI.getDependency(data => {
      this.listDependency = data.entities;
    }, error => {
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }

  onResize(event: any) {
    this.ChangeMobile(event.target.innerWidth);
  }

  ChangeMobile(size: number) {
    this.attributesView.isMobile = size < 1150;
    this.attributesView.isMobileTwo = size < 950
    if (this.attributesView.isMobile == false) {
      this.columnsToDisplay = ['cu_first', 'cu_type', 'cu_document', 'cu_businessName', 'cu_mobile', 'cu_contact', 'cu_dependency', 'cu_arrow'];
    } else {//movil
      if (this.attributesView.isMobileTwo == false) {
        this.columnsToDisplay = ['cu_first', 'cu_type', 'cu_document', 'cu_businessName', 'cu_mobile', 'cu_dependency', 'cu_arrow'];
      } else {
        this.columnsToDisplay = ['cu_first', 'cu_document', 'cu_businessName', 'cu_arrow'];
      }
    }
  }

  getSencesBeforePaging() {
    this.listData = [];
    this.listDataTwo = [];
    this.listDataCopy = [];
    this._loading.callLoading();
    this._mcCustomerAPI.getCustomers(
      this.attributesView.pageIndex + 1, this.attributesView.pageSize, this.filter.selectedType, this.filter.selectedValueRegion,
      this.filter.selectedValueCommune, this.filter.name, this.filter.last_name, this.filter.mother_last_name,
      this.filter.selectedValueDependencies, this.filter.document, this.filter.institution_name, data => {
        this.attributesView.lengthPaginador = data.total;
        this.listData = data.entities;
        this.listDataTwo = data.entities;
        this.listDataCopy = data.entities;
        this._loading.closeLoading();
      }, error => {
        this._loading.closeLoading();
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      })
  }

  getSencesAfterPaging() {
    this.listDataTwo = [];
    this.listDataCopy = [];
    this._loading.callLoading();
    this._mcCustomerAPI.getCustomers(
      this.attributesView.pageIndex + 1, this.attributesView.pageSize, this.filter.selectedType, this.filter.selectedValueRegion,
      this.filter.selectedValueCommune, this.filter.name, this.filter.last_name, this.filter.mother_last_name,
      this.filter.selectedValueDependencies, this.filter.document, this.filter.institution_name, data => {
        this.attributesView.lengthPaginador = data.total;
        this.listDataTwo = data.entities;
        this.listDataCopy = data.entities;
        for (let i: number = 0; i < data.entities.length; i++) {
          this.listData.push(data.entities[i]);
        }
        this._loading.closeLoading();
      }, error => {
        this._loading.closeLoading();
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      })
  }

  paginador(event: any) {
    if (this.attributesView.pageSize != event.pageSize) {
      this.attributesView.pageIndex = 0;
      this.attributesView.pageSize = event.pageSize;
      this.getSencesBeforePaging();
    } else {
      this.attributesView.pageIndex = event.pageIndex;
      let numero: number = (this.attributesView.pageIndex + 1) * this.attributesView.pageSize;
      if ((numero > this.listData.length) && (this.listData.length < event.length)) {
        this.getSencesAfterPaging();
      } else {
        this.localTableData();
      }
    }
  }

  //recargar datos de la tabla
  localTableData() {
    this.listDataTwo = [];
    this.listDataCopy = [];
    for (let i: number = 0; i < this.listData.length; i++) {
      let mult: number = this.attributesView.pageIndex * this.attributesView.pageSize;
     // console.log((i + ">=" + mult) + "&&" + (i + "<" + (mult + this.attributesView.pageSize)));
      if ((i >= mult) && (i < (mult + this.attributesView.pageSize))) {
        this.listDataTwo.push(this.listData[i]);
        this.listDataCopy.push(this.listData[i]);
      }
    }
  }

  changeSelect(event: any, numero: number) {
    if (numero == 12) {
      this.filter.selectedValueCommune = MCConstants.notSelected;
      this.fillCommunes(this.filter.selectedValueRegion);
    } else if (numero == 1) {
      this.filter.last_name = String();
      this.filter.mother_last_name = String();
      if (this.filter.selectedType != "Institución") {
        this.filter.selectedValueDependencies = MCConstants.notSelected;
      }
    } else if (numero == 2) {
      if (this.filter.selectedValueDependencies != MCConstants.notSelected) {
        this.filter.selectedType = "Institución";
        this.filter.last_name = String();
        this.filter.mother_last_name = String();
      }
    }
  }

  //llena el combo de comunas dependiendo del la region seleccionada
  fillCommunes(valor: any) {
    if (valor != MCConstants.notSelected) {
      for (let rep of this.listRegion) {
        if (valor == rep.id) {
          this.listCommune = rep.communes!;
          break;
        }
      }
    } else {
      this.filter.selectedValueCommune = MCConstants.notSelected;
      this.listCommune = [];
    }
  }

  findFilter() {
    if(this.filter.document == "-"){ this.filter.document = String(); }
    this.filter.selectedValueCommune = this.filter.selectedValueCommune.trim();
    this.filter.selectedValueRegion = this.filter.selectedValueRegion.trim();
    this.resfleshTable();
  }

  cleanFilter() {
    this.filter.document = String();
    this.filter.name = String();
    this.filter.last_name = String();
    this.filter.mother_last_name = String();
    this.filter.institution_name = String();
    this.filter.selectedType = String();
    this.filter.selectedValueCommune = String();
    this.filter.selectedValueRegion = String();
    this.filter.selectedValueDependencies = String();
    this.resfleshTable();
  }

  modifyCustomer(element: any) {
    this.child.attributes.hiddentwo = element.type;
    this.actionRequestToolbar = new MCToolbarClass(MCConstants.customer, MCConstants.modify);
    this.child.attributes.hidden = true;
    this.child.passCustomer(element);
    this.attributesView.view_crud = !this.attributesView.view_crud;
  }

  resfleshTable() {
    this.attributesView.pageIndex = 0;
    this.getSencesBeforePaging();
  }

  refleshTable(respondSence: MCCustomerEntity) {
    for (let i: number = 0; i < this.listData.length; i++) {
      if (respondSence.id == this.listData[i].id) {
        this.listData[i] = respondSence;
        break;
      }
    }

    for (let i: number = 0; i < this.listDataTwo.length; i++) {
      if (respondSence.id == this.listDataTwo[i].id) {
        this.listDataTwo[i] = respondSence;
        this.listDataCopy[i] = respondSence;
        break;
      }
    }
    this._mcAlert.showAlertInformation(MCMessagesEnum.globalUpdateData);
  }

  deleteCustomer() {
    this.closeModal();
    this._loading.callLoading();
    this._mcCustomerAPI.deleteCustomer(this.attributesView.idDeleteCustomer, () => {
      this.resfleshTable();
      this._mcAlert.showAlertInformation(MCMessagesEnum.globalDeleteData);
    }, error => {
      this._loading.closeLoading();
      if (error.objectMessage == undefined) {
        this.resfleshTable();
        this._mcAlert.showAlertInformation(MCMessagesEnum.globalDeleteData);
      } else {
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      }
    })
  }

  toolRespond($event: string) {
    if ($event == MCConstants.select) {
      this.actionRequestToolbar = new MCToolbarClass(MCConstants.customers, MCConstants.select);
      this.attributesView.view_crud = !this.attributesView.view_crud;
      this.child.attributes.hidden = false;
    } else {
      this.actionRequestToolbar = new MCToolbarClass(MCConstants.customer, MCConstants.register);
      this.attributesView.view_crud = !this.attributesView.view_crud;
      this.child.attributes.hidden = true;
      this.child.resetCustomer();
    }
  }

  customerRespond($event: any) {
    let respondCustomer: MCCustomerEntity = this.child.customer;
    this.child.attributes.hidden = false;
    this.child._loading.closeLoading();
    this.attributesView.view_crud = !this.attributesView.view_crud;
    this.actionRequestToolbar = new MCToolbarClass(MCConstants.customer, MCConstants.select);
    if ($event == MCConstants.register) {
      this.resfleshTable();
      this._mcAlert.showAlertInformation(MCMessagesEnum.customerCreated);
    } else if ($event == MCConstants.modify) {
      this.refleshTable(respondCustomer);
     // this.resfleshTable();
    }
  }

  openModal(id: string) {
    this.formModalSence = new window.bootstrap.Modal(
      document.getElementById('modalSence')
    );
    this.attributesView.idDeleteCustomer = id;
    this.formModalSence.show();
  }

  closeModal() {
    this.formModalSence.hide();
  }

  actionCollapses() {
    this.attributesView.filterCollapses = !this.attributesView.filterCollapses;
  }

  indicatorArrow(value: any) {
    this.attributesView.tableArrow = value;
  }

  formatRut(event: any) {
    let next : boolean = false;
    (this._mcUtils.validateRut(event) == false) ? (next = false , event.preventDefault()) :  next = true;
    if (next) {
      this.filter.document = format(event.target.value);
    }
  }


}
