export class MCAcademicTitlesEntity {
    entities: MCAcademicTitleEntity[] = [];

    constructor(object: any) {
        if (object) {
            for (let entity of object) {
                this.entities.push(new MCAcademicTitleEntity(entity));
            }
        }
    }
}

export class MCAcademicTitleEntity {
    id: number = 0;
    title: string = String();

    constructor(object: any) {
        this.id = object.id ?? 0;
        this.title = object.title ?? String();
    }
} 