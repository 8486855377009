<app-mc-toolbar [actionRequest]="actionRequestToolbar" (toolRespond)="toolRespond($event)" class="fixed-top up-div">
</app-mc-toolbar>
<div class="mobileClassTool">
  <app-mc-view-teacher (teacherRespond)="teacherRespond($event)"></app-mc-view-teacher>

  <div *ngIf="!attributesView.view_crud">
    <div class="mainDivContent">
      <div *ngIf="!attributesView.filterCollapses">
        <div class="d-flex flex-wrap pe-3">
          <div><button class="btn btn-outline-dark btnDarkCollapse"
              (click)="actionCollapses()">{{attributesView.showFilteraText}} <i
                class="bi bi-caret-down-fill"></i></button></div>
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li *ngIf="filter.name != ''" class="breadcrumb-item">Nombre: <span>{{filter.name}}</span></li>
                <li *ngIf="filter.title != ''" class="breadcrumb-item">Título profesional: <span>{{filter.title}}</span>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <button *ngIf="attributesView.filterCollapses" class="btn btn-outline-dark btnDarkCollapse"
        (click)="actionCollapses()">
        {{attributesView.hideFiltersText}} <i class="bi bi-caret-up-fill"></i>
      </button>
    </div>
    <div *ngIf="attributesView.filterCollapses" class="mat-elevation-z8 mainFilterStyle">
      <div>
        <div class="row">

          <div class="col-12 col-sm-6">
            <mat-form-field appearance="legacy">
              <mat-label>Nombre</mat-label>
              <input matInput [(ngModel)]="filter.name" name="fname" (keyup.enter)="findFilter()">
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6">
            <mat-form-field appearance="legacy">
              <mat-label>Título profesional</mat-label>
              <input matInput maxlength="12" (paste)="false" name="tp" [(ngModel)]="filter.title" (keyup.enter)="findFilter()">
            </mat-form-field>
          </div>

          <div class="col-12" style="margin-top: 15px;">
            <button mat-raised-button color="primary" (click)="findFilter()">
              <mat-icon>search</mat-icon>Buscar
            </button>
            <button mat-raised-button class="btnFilter" (click)="cleanFilter()">
              <mat-icon>brush</mat-icon>Limpiar
            </button>
          </div>
        </div>
      </div>
    </div>


    <div class="before-table mat-elevation-z8">
      <div class="before-table-div">

        <table mat-table (window:resize)="onResize($event)" [dataSource]="listDataTwo" multiTemplateDataRows>

          <ng-container matColumnDef="tc_first">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>

              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="modifyTeacher(element)">
                  <mat-icon>create</mat-icon>
                  <span>Modificar</span>
                </button>
                <button mat-menu-item (click)="openModal(element.id)">
                  <mat-icon>delete</mat-icon>
                  <span>Eliminar</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>

          <ng-container matColumnDef="tc_rut">
            <th mat-header-cell *matHeaderCellDef> RUT </th>
            <td mat-cell *matCellDef="let element"> {{element?.document}} </td>
          </ng-container>

          <ng-container matColumnDef="tc_name">
            <th mat-header-cell *matHeaderCellDef> Nombre </th>
            <td mat-cell *matCellDef="let element"> {{element?.name}} </td>
          </ng-container>

          <!-- <ng-container *ngIf="!attributesView.isMobile" matColumnDef="tctitle">
            <th mat-header-cell *matHeaderCellDef>Título Profesional 1</th>
            <td mat-cell *matCellDef="let element"> {{element?.academic_titles[0].title}} </td>
          </ng-container> -->

          <ng-container *ngIf="!this.attributesView.isMobilePhone" matColumnDef="tc_mobile">
            <th mat-header-cell *matHeaderCellDef> Celular </th>
            <td mat-cell *matCellDef="let element"> +56 {{element?.mobile}} </td>
          </ng-container>

          <ng-container *ngIf="!attributesView.isMobile" matColumnDef="tc_email">
            <th mat-header-cell *matHeaderCellDef> Correo </th>
            <td mat-cell *matCellDef="let element"> {{element?.email}} </td>
          </ng-container>

          <ng-container matColumnDef="tc_arrow">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <mat-icon *ngIf="attributesView.tableArrow != element">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="attributesView.tableArrow == element">keyboard_arrow_up</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
              <div class="example-element-detail" [@detailExpand]="element == listDataCopy ? 'expanded' : 'collapsed'">
                <div style="width: 100%;">
                  <!-- <div class="row g-0 borderExpandDetail mat-elevation-z8"> -->
                  <div class="row g-0 mat-elevation-z8">
                    <div class="col-12 col-md-6 col-lg-4 p-2">
                      <div class="row">
                        <div class="col-12" style="padding-top: 20px;">
                          <fieldset class="scheduler-border fieldOld">
                            <legend class="scheduler-border"
                              style="margin-top: -15px !important; width: 100px !important;"> Información
                            </legend>
                            <div>
                              <div class="row">
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa">Sexo: </span> {{element?.sex}} </mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa">Curriculum para diploma: </span>
                                    {{element?.curriculum}}
                                  </mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa">Celular: </span> {{element?.mobile}} </mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa">Correo: </span> {{element?.email}} </mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa">Región : </span> {{element?.commune?.region?.id}}
                                  </mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa">Comuna: </span> {{element?.commune?.name}}
                                  </mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa">Dirección: </span> {{element?.address}}
                                  </mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item>&nbsp;</mat-list-item>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>


                    <div class="col-12 col-md-6 col-lg-8 p-2">
                      <div class="row">
                        <div class="col-12 col-md-12 col-lg-6" style="padding-top: 20px;">
                          <fieldset class="scheduler-border fieldOld">
                            <legend class="scheduler-border"
                              style="margin-top: -15px !important; width: 170px !important;"> Títulos Profesionales
                            </legend>
                            <div>
                              <div class="row">
                                <div class="col-mat-list" *ngFor="let rep of element?.academic_titles">
                                  <mat-list-item class="spafancy">- {{rep.title}}</mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item>&nbsp;</mat-list-item>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>

                        <div class="col-12 col-md-12 col-lg-6" style="padding-top: 20px;">
                          <fieldset class="scheduler-border fieldOld">
                            <legend class="scheduler-border"
                              style="margin-top: -15px !important; width: 200px !important;">Códigos sence asociados
                            </legend>
                            <div>
                              <div class="row">
                                <div class="col-mat-list" *ngFor="let sens of element?.sences">
                                  <mat-list-item class="spafancy">- {{sens.title}}</mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item>&nbsp;</mat-list-item>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>

                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
            [class.example-expanded-row]="listDataCopy === element"
            (click)="listDataCopy = listDataCopy === element ? null : element; indicatorArrow(listDataCopy != element ? null : element)">
          </tr>

          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

        </table>

        <mat-paginator #paginator [length]="attributesView.lengthPaginador" [pageIndex]="attributesView.pageIndex"
          [pageSize]="attributesView.pageSize" [pageSizeOptions]="attributesView.pageSizeOptions"
          (page)="paginador($event)">
        </mat-paginator>

      </div>
    </div>

    <!--DE ACA-->
    <!-- Modal -->


    <ng-template #dialogTemplate>
      <div>
        <h1 mat-dialog-title>Eliminar Relator</h1>
        <div>
          <form>
            <div class="row form-box-style">

              <div class="col-12">
                <mat-form-field>
                  <h4>¿Si elimina este registro no podra recuperarlo, desea eliminar el registro?</h4>
                </mat-form-field>
              </div>

            </div>
          </form>
        </div>
        <mat-dialog-actions>
          <button mat-raised-button color="warn" (click)="deleteTeacher()">Si</button>
          <button mat-raised-button mat-dialog-close>No</button>
        </mat-dialog-actions>
      </div>
    </ng-template>

  </div>

</div>

<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
  aria-labelledby="staticBackdropLabel" aria-hidden="false" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h2 id="staticBackdropLabel">Eliminar Relator</h2>
        <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        <div>
          <div>
            <form>
              <div class="row form-box-style">
                <h4>¿Si elimina este registro no podrá recuperarlo, desea eliminar el registro?</h4>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row" style="width: 100%;">
          <div class="col-6">
            <button mat-raised-button color="warn" (click)="deleteTeacher()" style="width: 100%;">Sí</button>
          </div>
          <div class="col-6">
            <button mat-raised-button (click)="closeModal()" style="width: 100%;">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
