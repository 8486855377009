import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MCToolbarClass } from 'src/app/mc-helpers/mc-class/mc-toolbar-class';
import { MCConstants } from 'src/app/mc-helpers/mc-constants/mc-constants';
import { MCViewCourseComponent } from 'src/app/mc-component/mc-view-course/mc-view-course.component';
import { MCLoadingService } from 'src/app/mc-service/mc-loading.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MCBasicCourseEntity } from 'src/app/mc-entity/mc-entities/mc-basic-course-entitys';
import { MCAlert } from 'src/app/mc-helpers/mc-alert';
import { MCUtils } from 'src/app/mc-helpers/mc-class/mc-utils';
import { MCBasicCourseAPI } from 'src/app/mc-repository/mc-api/mc-basic-course-api';
import { MCRouter } from 'src/app/mc-helpers/mc-class/mc-router';
import { MCMessagesEnum } from 'src/app/mc-helpers/mc-enums/mc-messages-enum';
declare var window: any;

export interface filter {
  name: string;
  certificate_title: string;
}

export interface attributesView {
  idDeleteCourse: string;
  filterCollapses: boolean;
  view_crud: boolean;
  isMobile: boolean;
  lengthPaginador: number;
  pageIndex: number;
  pageSize: number;
  pageSizeOptions: number[];
  hideFiltersText: string;
  showFilteraText: string;
  tableArrow: any;
}

@Component({
  selector: 'app-mc-course',
  templateUrl: './mc-course.component.html',
  styleUrls: ['./mc-course.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MCCourseComponent implements OnInit {

  @ViewChild(MCViewCourseComponent) child: any;
  private _mcRouter: MCRouter;
  actionRequestToolbar: MCToolbarClass = new MCToolbarClass(MCConstants.coursesBasic, MCConstants.select);

  attributesView = {
    idDeleteCourse: String(),
    filterCollapses: false,
    view_crud: false,
    isMobile: false,
    lengthPaginador: 10,
    pageIndex: 0,
    pageSize: 10,
    pageSizeOptions: [10, 20, 30, 50],
    hideFiltersText: MCConstants.hideFiltersText,
    showFilteraText: MCConstants.showFilteraText,
    tableArrow: null
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  formModal: any;

  listData: MCBasicCourseEntity[] = [];
  listDataTwo: MCBasicCourseEntity[] = [];
  listDataCopy: MCBasicCourseEntity[] = [];
  filter = { name: String(), certificate_title: String() };
  columnsToDisplay = ['co_first', 'co_id', 'co_name', 'co_arrow'];

  constructor(
    private _loading: MCLoadingService,
    public _route: Router,
    private _mcAlert: MCAlert,
    private _mcBasicCourseAPI: MCBasicCourseAPI,
    private _mcUtils: MCUtils

  ) {
    this._mcRouter = new MCRouter(_route);
    this.actionRequestToolbar = new MCToolbarClass(MCConstants.coursesBasic, MCConstants.select);
  }

  ngOnInit(): void {
    this.ChangeMobile(window.innerWidth);
    this.getBasicCoursesBeforePaging();
  }

  onResize(event: any) {
    this.ChangeMobile(event.target.innerWidth);
  }

  ChangeMobile(size: number) {
    this.attributesView.isMobile = size < 900;
    if (this.attributesView.isMobile == false) {
      this.columnsToDisplay = ['co_first', 'co_id', 'co_name', 'co_certificate_title', 'co_arrow'];
    } else {
      this.columnsToDisplay = ['co_first', 'co_id', 'co_name', 'co_arrow'];
    }
  }

  getBasicCoursesBeforePaging() {
    this.listData = [];
    this.listDataTwo = [];
    this.listDataCopy = [];
    this._loading.callLoading();
    this._mcBasicCourseAPI.getBasicCourses(this.attributesView.pageIndex + 1, this.attributesView.pageSize,
      this.filter.name, this.filter.certificate_title, data => {
        this.attributesView.lengthPaginador = data.total;
        this.listData = data.entities;
        this.listDataTwo = data.entities;
        this.listDataCopy = data.entities;
        this._loading.closeLoading();
      }, error => {
        this._loading.closeLoading();
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      })
  }

  getBasicCoursesAfterPaging() {
    this._loading.callLoading();
    this.listDataTwo = [];
    this.listDataCopy = [];
    this._mcBasicCourseAPI.getBasicCourses(this.attributesView.pageIndex + 1, this.attributesView.pageSize,
      this.filter.name, this.filter.certificate_title, data => {
        this.attributesView.lengthPaginador = data.total;
        this.listDataTwo = data.entities;
        this.listDataCopy = data.entities;
        for (let i: number = 0; i < data.entities.length; i++) {
          this.listData.push(data.entities[i]);
        }
        this._loading.closeLoading();
      }, error => {
        this._loading.closeLoading();
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      })
  }

  paginador(event: any) {
    if (this.attributesView.pageSize != event.pageSize) {
      this.attributesView.pageIndex = 0;
      this.attributesView.pageSize = event.pageSize;
      this.getBasicCoursesBeforePaging();
    } else {
      this.attributesView.pageIndex = event.pageIndex;
      let numero: number = (this.attributesView.pageIndex + 1) * this.attributesView.pageSize;
      if ((numero > this.listData.length) && (this.listData.length < event.length)) {
        this.getBasicCoursesAfterPaging();
      } else {
        this.localTableData();
      }
    }
  }

  //recargar datos de la tabla
  localTableData() {
    this.listDataTwo = [];
    this.listDataCopy = [];
    for (let i: number = 0; i < this.listData.length; i++) {
      let mult: number = this.attributesView.pageIndex * this.attributesView.pageSize;
      if ((i >= mult) && (i < (mult + this.attributesView.pageSize))) {
        this.listDataTwo.push(this.listData[i]);
        this.listDataCopy.push(this.listData[i]);
      }
    }
  }

  deleteBasicCourse() {
    this.closeModal();
    this._loading.callLoading();
    this._mcBasicCourseAPI.deleteBasicCourse(this.attributesView.idDeleteCourse, () => {
      this.resfleshTable();
      this._mcAlert.showAlertInformation(MCMessagesEnum.globalDeleteData);
    }, error => {
      this._loading.closeLoading();
      if (error.objectMessage == undefined) {
        this.resfleshTable();
        this._mcAlert.showAlertInformation(MCMessagesEnum.globalDeleteData);
      } else {
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      }
    })
  }

  modifyBasicCourse(element: any) {
    this.actionRequestToolbar = new MCToolbarClass(MCConstants.courseBasic, MCConstants.modify);
    this.child.attributes.hidden = true;
    this.child.passCourseBasic(element);
    this.attributesView.view_crud = !this.attributesView.view_crud;
  }

  toolRespond($event: string) {
    if ($event == MCConstants.select) {
      this.actionRequestToolbar = new MCToolbarClass(MCConstants.coursesBasic, MCConstants.select);
      this.attributesView.view_crud = !this.attributesView.view_crud;
      this.child.attributes.hidden = false;
    } else {
      this.actionRequestToolbar = new MCToolbarClass(MCConstants.courseBasic, MCConstants.register);
      this.attributesView.view_crud = !this.attributesView.view_crud;
      this.child.attributes.hidden = true;
      this.child.resetBasicCourse();
    }
  }

  refleshTable(respondCourse: MCBasicCourseEntity) {
    for (let i: number = 0; i < this.listData.length; i++) {
      if (respondCourse.id == this.listData[i].id) {
        this.listData[i] = respondCourse;
        break;
      }
    }

    for (let i: number = 0; i < this.listDataTwo.length; i++) {
      if (respondCourse.id == this.listDataTwo[i].id) {
        this.listDataTwo[i] = respondCourse;
        this.listDataCopy[i] = respondCourse;
        break;
      }
    }

    this._mcAlert.showAlertInformation(MCMessagesEnum.globalUpdateData);
  }

  courseRespond($event: any) {
    let respondBasicCourse: MCBasicCourseEntity = this.child.course;
    this.child.attributes.hidden = false;
    this.child._loading.closeLoading();
    this.attributesView.view_crud = !this.attributesView.view_crud;
    this.actionRequestToolbar = new MCToolbarClass(MCConstants.coursesBasic, MCConstants.select);
    if ($event == MCConstants.register) {
      this.resfleshTable();
      this._mcAlert.showAlertInformation(MCMessagesEnum.basicCourseCreated);
    } else if ($event == MCConstants.modify) {
      this.refleshTable(respondBasicCourse);
    }
  }

  resfleshTable() {
    this.attributesView.pageIndex = 0;
    this.getBasicCoursesBeforePaging();
  }

  findFilter() {
    this.filter.certificate_title = this.filter.certificate_title.trim();
    this.filter.name = this.filter.name.trim();
    this.resfleshTable();
  }

  cleanFilter() {
    this.filter.certificate_title = String();
    this.filter.name = String();
    this.resfleshTable();
  }

  actionCollapses() {
    this.attributesView.filterCollapses = !this.attributesView.filterCollapses;
  }

  indicatorArrow(value: any) {
    this.attributesView.tableArrow = value;
  }

  openModal(id: string) {
    this.formModal = new window.bootstrap.Modal(
      document.getElementById('staticBasicCourse')
    );
    this.attributesView.idDeleteCourse = id;
    this.formModal.show();
  }

  closeModal() {
    this.formModal.hide();
  }

}
