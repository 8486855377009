import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'; 
import { MCRoutesEnum } from './mc-helpers/mc-enums/mc-routes-enum';
import { MCAdminComponent } from './mc-admin/mc-admin.component';
import { MCCourseComponent } from './mc-admin/mc-course/mc-course.component';
import { MCCoursesComponent } from './mc-admin/mc-courses/mc-courses.component';
import { MCCustomerComponent } from './mc-admin/mc-customer/mc-customer.component';
import { MCSenceComponent } from './mc-admin/mc-sence/mc-sence.component';
import { MCStudentComponent } from './mc-admin/mc-student/mc-student.component';
import { MCTeacherComponent } from './mc-admin/mc-teacher/mc-teacher.component';
import { MCMainComponent } from './mc-admin/mc-main/mc-main.component';
import { MCErrorComponent } from './mc-component/mc-error/mc-error.component';
import { MCLoginComponent } from './mc-component/mc-login/mc-login.component';
import { MCEmailComponent } from './mc-admin/mc-email/mc-email.component';

const routes: Routes = [
  { path: MCRoutesEnum.login, component: MCLoginComponent },
  {
    path: MCRoutesEnum.admin, component: MCAdminComponent,
    children:[
      {path: MCRoutesEnum.main, component:MCMainComponent},
      {path: MCRoutesEnum.customer, component:MCCustomerComponent},
      {path: MCRoutesEnum.student, component:MCStudentComponent},
      {path: MCRoutesEnum.sence, component:MCSenceComponent},
      {path: MCRoutesEnum.teacher, component:MCTeacherComponent},
      {path: MCRoutesEnum.course, component:MCCourseComponent},
      {path: MCRoutesEnum.courseOpen, component:MCCoursesComponent},
      {path: MCRoutesEnum.courseClose, component:MCCoursesComponent},
      {path: MCRoutesEnum.email, component:MCEmailComponent},
      {path: String(), redirectTo: MCRoutesEnum.main, pathMatch: MCRoutesEnum.full},
      {path: MCRoutesEnum.doubleAsterisk, redirectTo: MCRoutesEnum.main, pathMatch: MCRoutesEnum.full}
    ]
  },
  {path: MCRoutesEnum.error, component:MCErrorComponent},         
  {path: String(), redirectTo: MCRoutesEnum.login, pathMatch: MCRoutesEnum.full},
  {path: MCRoutesEnum.doubleAsterisk, redirectTo: MCRoutesEnum.login, pathMatch: MCRoutesEnum.full}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
