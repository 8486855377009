import { Component, OnInit, ViewChild } from '@angular/core';
import { MCToolbarClass } from 'src/app/mc-helpers/mc-class/mc-toolbar-class';
import { MCConstants } from 'src/app/mc-helpers/mc-constants/mc-constants';
import { MCViewSenceComponent } from 'src/app/mc-component/mc-view-sence/mc-view-sence.component';
import { MCLoadingService } from 'src/app/mc-service/mc-loading.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MCSenceEntity } from 'src/app/mc-entity/mc-entities/mc-sence-entitys';
import { Router } from '@angular/router';
import { MCAlert } from 'src/app/mc-helpers/mc-alert';
import { MCUtils } from 'src/app/mc-helpers/mc-class/mc-utils';
import { MCSenceAPI } from 'src/app/mc-repository/mc-api/mc-sence-api';
import { MCRouter } from 'src/app/mc-helpers/mc-class/mc-router';
import { MCMessagesEnum } from 'src/app/mc-helpers/mc-enums/mc-messages-enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { MCTeacherEntity } from 'src/app/mc-entity/mc-entities/mc-teacher-entitys';
import { MCTeacherAPI } from 'src/app/mc-repository/mc-api/mc-teacher-api';
declare var window: any;

export interface filter {
  code: string;
  title: string;
  selectedValueTeacher: string,
  modalidad: string;
}

export interface attributesView {
  idDeleteSence: string;
  filterCollapses: boolean;
  view_crud: boolean;
  isMobile: boolean;
  lengthPaginador: number;
  pageIndex: number;
  pageSize: number;
  pageSizeOptions: number[];
  hideFiltersText: string;
  showFilteraText: string;
  tableArrow: any;
}

@Component({
  selector: 'app-mc-sence',
  templateUrl: './mc-sence.component.html',
  styleUrls: ['./mc-sence.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class MCSenceComponent implements OnInit {

  @ViewChild(MCViewSenceComponent) child: any;
  private _mcRouter: MCRouter;
  actionRequestToolbar: MCToolbarClass = new MCToolbarClass(MCConstants.sences, MCConstants.select);
  listTeacher: MCTeacherEntity[] = [];
  columnsToDisplay = ['se_first', 'se_code', 'se_title', 'se_arrow'];

  attributesView = {
    idDeleteSence: String(),
    filterCollapses: false,
    view_crud: false,
    isMobile: false,
    lengthPaginador: 10,
    pageIndex: 0,
    pageSize: 10,
    pageSizeOptions: [10, 20, 30, 50],
    hideFiltersText: MCConstants.hideFiltersText,
    showFilteraText: MCConstants.showFilteraText,
    tableArrow: null
  }
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  formModalSence: any = null;
  listData: MCSenceEntity[] = [];
  listDataTwo: MCSenceEntity[] = [];
  listDataCopy: MCSenceEntity[] = [];
  filter = {
    code: String(),
    title: String(),
    selectedValueTeacher: MCConstants.notSelected,
    modalidad: MCConstants.notSelected
  };

  constructor(
    private _loading: MCLoadingService,
    public _route: Router,
    private _mcAlert: MCAlert,
    private _mcSenceAPI: MCSenceAPI,
    private _mcUtils: MCUtils,
    private _mcTeacherAPI: MCTeacherAPI,
    private spinner: NgxSpinnerService
  ) {
    this.actionRequestToolbar = new MCToolbarClass(MCConstants.sences, MCConstants.select);
    this._mcRouter = new MCRouter(_route);
  }

  ngOnInit(): void {
    this.getTeachers()
    this.ChangeMobile(window.innerWidth);
    this.getSencesBeforePaging();
  }

  onResize(event: any) {
    this.ChangeMobile(event.target.innerWidth);
  }

  ChangeMobile(size: number) {
    this.attributesView.isMobile = size < 900;
    if (this.attributesView.isMobile == false) {
      this.columnsToDisplay = ['se_first', 'se_code', 'se_title', 'se_mode', 'se_teacher', 'se_arrow'];
    } else {//movil
      this.columnsToDisplay = ['se_first', 'se_code', 'se_title', 'se_arrow'];
    }
  }

  getSencesBeforePaging() {
    //AGREGAR FILTRO POR MODADLIDAD (this.filter.modalidad == MCConstants.notSelected) ? String() : this.filter.modalidad;
    this.listData = [];
    this.listDataTwo = [];
    this.listDataCopy = [];
    this._loading.callLoading();
    this._mcSenceAPI.getSences(this.attributesView.pageIndex + 1, this.attributesView.pageSize,
      this.filter.code, this.filter.title, this.filter.selectedValueTeacher, this.filter.modalidad, data => {
        this.attributesView.lengthPaginador = data.total;
        this.listData = data.entities;
        this.listDataTwo = data.entities;
        this.listDataCopy = data.entities;
        this._loading.closeLoading();
      }, error => {
        this._loading.closeLoading();
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      })
  }

  getSencesAfterPaging() {
    //AGREGAR FILTRO POR MODADLIDAD (this.filter.modalidad == MCConstants.notSelected) ? String() : this.filter.modalidad;
    this.listDataTwo = [];
    this.listDataCopy = [];
    this._loading.callLoading();
    this._mcSenceAPI.getSences(this.attributesView.pageIndex + 1, this.attributesView.pageSize,
      this.filter.code, this.filter.title, this.filter.selectedValueTeacher, this.filter.modalidad, data => {
        this.attributesView.lengthPaginador = data.total;
        this.listDataTwo = data.entities;
        this.listDataCopy = data.entities;
        for (let i: number = 0; i < data.entities.length; i++) {
          this.listData.push(data.entities[i]);
        }
        this._loading.closeLoading();
      }, error => {
        this._loading.closeLoading();
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      })
  }

  paginador(event: any) {
    //this.pageSize = event.pageSize;
    if (this.attributesView.pageSize != event.pageSize) {
      this.attributesView.pageIndex = 0;
      this.attributesView.pageSize = event.pageSize;
      this.getSencesBeforePaging();
    } else {
      this.attributesView.pageIndex = event.pageIndex;
      let numero: number = (this.attributesView.pageIndex + 1) * this.attributesView.pageSize;
      if ((numero > this.listData.length) && (this.listData.length < event.length)) {
        this.getSencesAfterPaging();
      } else {
        this.localTableData();
      }
    }
  }

  //recargar datos de la tabla
  localTableData() {
    this.listDataTwo = [];
    this.listDataCopy = [];
    for (let i: number = 0; i < this.listData.length; i++) {
      let mult: number = this.attributesView.pageIndex * this.attributesView.pageSize;
     // console.log((i + ">=" + mult) + "&&" + (i + "<" + (mult + this.attributesView.pageSize)));
      if ((i >= mult) && (i < (mult + this.attributesView.pageSize))) {
        this.listDataTwo.push(this.listData[i]);
        this.listDataCopy.push(this.listData[i]);
      }
    }
  }

  findFilter() {
    this.filter.code = this.filter.code.trim();
    this.filter.title = this.filter.title.trim();
    this.resfleshTable();
  }

  cleanFilter() {
    this.filter.code = String();
    this.filter.title = String();
    this.filter.selectedValueTeacher = String();
    this.filter.modalidad = MCConstants.notSelected
    this.resfleshTable();
  }

  modifySence(element: any) {
    this.actionRequestToolbar = new MCToolbarClass(MCConstants.sence, MCConstants.modify);
    this.child.attributes.hidden = true;
    this.child.passSence(element);
    this.attributesView.view_crud = !this.attributesView.view_crud;
  }

  resfleshTable() {
    this.attributesView.pageIndex = 0;
    this.getSencesBeforePaging();
  }

  deleteSence() {
    this.closeModal();
    this._loading.callLoading();
    this._mcSenceAPI.deleteSence(this.attributesView.idDeleteSence, () => {
      this.resfleshTable();
      this._mcAlert.showAlertInformation(MCMessagesEnum.globalDeleteData);
    }, error => {
      this._loading.closeLoading();
      if (error.objectMessage == undefined) {
        this.resfleshTable();
        this._mcAlert.showAlertInformation(MCMessagesEnum.globalDeleteData);
      } else {
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      }
    })
  }


  toolRespond($event: string) {
    if ($event == MCConstants.select) {
      this.actionRequestToolbar = new MCToolbarClass(MCConstants.sences, MCConstants.select);
      this.attributesView.view_crud = !this.attributesView.view_crud;
      this.child.attributes.hidden = false;
    } else {
      this.actionRequestToolbar = new MCToolbarClass(MCConstants.sence, MCConstants.register);
      this.attributesView.view_crud = !this.attributesView.view_crud;
      this.child.attributes.hidden = true;
      this.child.resetSence();
    }
  }

  senceRespond($event: any) {
    let respondSence: MCSenceEntity = this.child.sence;
    this.child.attributes.hidden = false;
    //this.child._loading.closeLoading();
    this.spinner.hide();

    this.attributesView.view_crud = !this.attributesView.view_crud;
    this.actionRequestToolbar = new MCToolbarClass(MCConstants.sences, MCConstants.select);
    if ($event == MCConstants.register) {
      this.resfleshTable();
      this._mcAlert.showAlertInformation(MCMessagesEnum.senceCreated);
    } else if ($event == MCConstants.modify) {
      this.refleshTable(respondSence);
    }
    //this.loading.closeLoading();
    //this.scrollTop();
  }

  refleshTable(respondSence: MCSenceEntity) {
    for (let i: number = 0; i < this.listData.length; i++) {
      if (respondSence.id == this.listData[i].id) {
        this.listData[i] = respondSence;
        break;
      }
    }

    for (let i: number = 0; i < this.listDataTwo.length; i++) {
      if (respondSence.id == this.listDataTwo[i].id) {
        this.listDataTwo[i] = respondSence;
        this.listDataCopy[i] = respondSence;
        break;
      }
    }
    this._mcAlert.showAlertInformation(MCMessagesEnum.globalUpdateData);
  }

  openModal(id: string) {
    this.formModalSence = new window.bootstrap.Modal(
      document.getElementById('modalSence')
    );
    this.attributesView.idDeleteSence = id;
    this.formModalSence.show();
  }

  closeModal() {
    this.formModalSence.hide();
  }

  actionCollapses() {
    this.attributesView.filterCollapses = !this.attributesView.filterCollapses;
  }

  indicatorArrow(value: any) {
    this.attributesView.tableArrow = value;
  }

  getTeachers() {
    this.listTeacher = [];
    this._mcTeacherAPI.getTeachers(1, 1000, String(), String(), data => {
      this.listTeacher = data.entities;
    }, error => {
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }
}


  // scrollTop(){
  //   setTimeout(() => {
  //     window.scrollTo(0, 0);
  //   },1);
  // }
