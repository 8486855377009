import { Component, OnInit } from '@angular/core';
import { MCLoadingService } from '../mc-service/mc-loading.service';
import { Router, RouterLinkActive } from '@angular/router';
import { MCConfigurationAPI } from '../mc-repository/mc-api/mc-configuration-api';
import { MCUtils } from '../mc-helpers/mc-class/mc-utils';
import { MCAlert } from '../mc-helpers/mc-alert';
import { MCRouter } from '../mc-helpers/mc-class/mc-router';
import { MCDataSourceLocal, MCDataSourceLocalInterface } from '../mc-repository/mc-data-source-local';
import { MCAuthenticationAPI } from '../mc-repository/mc-api/mc-authentication-api';
import { MCMessagesEnum } from '../mc-helpers/mc-enums/mc-messages-enum';
import { MCRoutesEnum } from '../mc-helpers/mc-enums/mc-routes-enum';
import { DateAdapter } from '@angular/material/core';

export interface attributes {
  menuMobile: boolean;
  desktopCollapseFalse: boolean;
  timeAlert: boolean;
  relojToken: number;
}

@Component({
  selector: 'app-mc-admin',
  templateUrl: './mc-admin.component.html',
  styleUrls: ['./mc-admin.component.css']
})
export class MCAdminComponent implements OnInit {
  private _mcRouter: MCRouter;
  private _mcDataSourceLocal: MCDataSourceLocalInterface = new MCDataSourceLocal();

  attributes = {
    menuMobile: false,
    desktopCollapseFalse: false,
    timeAlert: false,
    relojToken: 0
  };

  interval: any;

  constructor(
    private _loading: MCLoadingService,
    public _route: Router,
    private _mcAlert: MCAlert,
    private _mcUtils: MCUtils,
    private _mcConfigurationAPI: MCConfigurationAPI,
    private _mcAuthenticationAPI: MCAuthenticationAPI,
  ) {
    this._mcRouter = new MCRouter(_route);
    this.callRegions();
  }

  callRegions() {
    this._mcConfigurationAPI.getRegionsPublic(data => {
      this._mcDataSourceLocal.setRegions(data.data);
      this._loading.closeLoading();
    }, error => {
      this._loading.closeLoading();
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }

  ngOnInit(): void {
    this.interval = setInterval(() => { this.methodRefresh(); }, 1000);
    this.squares_height(document.getElementById('container')!.offsetWidth);
  }

  onResize() { this.squares_height(document.getElementById('container')!.offsetWidth); }

  squares_height(width_one: any) {
    if (width_one <= 991) {
      this.attributes.menuMobile = true;
    } else {
      this.attributes.menuMobile = false;
      if (this.attributes.desktopCollapseFalse) {
        document.getElementById('refresh')!.click();
        this.attributes.desktopCollapseFalse = false;
      }
    }
  }

  logout() {
    this._mcAuthenticationAPI.postLogout(() => {
      this._mcRouter.navigateTo(MCRoutesEnum.login)
    }, error => {
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }

  refreshToken() {
    this._mcAuthenticationAPI.postRefresh(() => {
      this.attributes.timeAlert = false;
      this.methodRefresh();
    }, error => {
      this.attributes.timeAlert = false;
      this._mcAlert.showAlertError(MCMessagesEnum.errorRefleshToken);
    })
  }

  methodRefresh() {
    if (this._mcDataSourceLocal.getToken() == null) {
      this._mcRouter.navigateTo(MCRoutesEnum.login)
      this.closeRefreshToken();
    }
    let timeToken = JSON.parse(this._mcDataSourceLocal.getToken()).time;
    let timeStartCountDown = (timeToken - 65);
    let timeStart = new Date(this._mcDataSourceLocal.getTimeStart()).getTime();
    let diff = (new Date().getTime() - timeStart) / 1000;
    if (diff > timeStartCountDown) {
      this.attributes.timeAlert = true;
      let countDown = Math.round(timeToken - (diff + 5));
      this.attributes.relojToken = countDown;
      if (countDown < 1) {
        this.closeRefreshToken();
        this._mcRouter.navigateTo(MCRoutesEnum.login)
      }
    }
  }

  closeRefreshToken() {
    clearInterval(this.interval);
    this.attributes.timeAlert = false;
  }

  closeMenu() {
    document.getElementById('refresh')!.click();
    this.attributes.desktopCollapseFalse = false;
  }
}
