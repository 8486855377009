<app-mc-toolbar [actionRequest]="actionRequestToolbar" (toolRespond)="toolRespond($event)" class="fixed-top up-div">
</app-mc-toolbar>
<div class="mobileClassTool">
  <app-mc-view-customer (customerRespond)="customerRespond($event)"></app-mc-view-customer>

  <div *ngIf="!attributesView.view_crud">
    <div class="mainDivContent">
      <div *ngIf="!attributesView.filterCollapses">
        <div class="d-flex flex-wrap pe-3">
          <div><button class="btn btn-outline-dark btnDarkCollapse"
              (click)="actionCollapses()">{{attributesView.showFilteraText}} <i
                class="bi bi-caret-down-fill"></i></button></div>
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li *ngIf="filter.selectedType != 'notselected'" class="breadcrumb-item">Tipo de cliente:
                  <span>{{filter.selectedType}}</span>
                </li>
                <li *ngIf="filter.document != ''" class="breadcrumb-item">RUT: <span>{{filter.document}}</span>
                <li *ngIf="filter.name != ''" class="breadcrumb-item">Nombre o Razón social:
                  <span>{{filter.name}}</span>
                <li *ngIf="filter.last_name != ''" class="breadcrumb-item">Apellido Paterno:
                  <span>{{filter.last_name}}</span>
                <li *ngIf="filter.mother_last_name != ''" class="breadcrumb-item">Apellido Materno:
                  <span>{{filter.mother_last_name}}</span>
                <li *ngIf="filter.institution_name != ''" class="breadcrumb-item">Nombre de la institución:
                  <span>{{filter.institution_name}}</span>
                <li *ngIf="filter.selectedValueDependencies != 'notselected'" class="breadcrumb-item">Dependencia:
                  <span>{{filter.selectedValueDependencies}}</span>
                <li *ngIf="filter.selectedValueRegion != 'notselected'" class="breadcrumb-item">Región:
                  <span>{{filter.selectedValueRegion}}</span>
                <li *ngIf="filter.selectedValueCommune != 'notselected'" class="breadcrumb-item">Comuna:
                  <span>{{filter.selectedValueCommune}}</span>

                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <button *ngIf="attributesView.filterCollapses" class="btn btn-outline-dark btnDarkCollapse"
        (click)="actionCollapses()">
        {{attributesView.hideFiltersText}} <i class="bi bi-caret-up-fill"></i>
      </button>
    </div>
    <div *ngIf="attributesView.filterCollapses" class="mat-elevation-z8 mainFilterStyle">
      <div>
        <div class="row">

          <div class="col-12 col-sm-3 col-lg-2">
            <mat-form-field appearance="legacy">
              <mat-label>Tipo de cliente</mat-label>
              <mat-select disableRipple [(ngModel)]="filter.selectedType" name="tipe"
                (selectionChange)="changeSelect($event,1)" (ngModelChange)="findFilter()">
                <mat-option value="notselected">-- Todos --</mat-option>
                <mat-option value="Natural">Natural</mat-option>
                <mat-option value="Institución">Institución</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-3 col-lg-2">
            <mat-form-field appearance="legacy">
              <mat-label>RUT</mat-label>
              <input matInput maxlength="20" (paste)="false" name="rut" placeholder="Ejemplo: 19.324.511-k"
                (keydown)="formatRut($event)" (keyup)="formatRut($event)" (keyup.enter)="findFilter()" [ngModel]="filter.document">
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-lg-4">
            <mat-form-field appearance="legacy">
              <mat-label>Nombre o Razón social</mat-label>
              <input matInput maxlength="100" [(ngModel)]="filter.name" (keyup.enter)="findFilter()">
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-lg-2">
            <mat-form-field appearance="legacy" data-bs-toggle="tooltip" data-bs-placement="top"
              title="Filtro Solo disponible si esta selecionado el Tipo de cliente Natural">
              <mat-label>Apellido Paterno</mat-label>
              <input matInput maxlength="30" [disabled]="filter.selectedType != 'Natural'"
                [(ngModel)]="filter.last_name" (keyup.enter)="findFilter()">
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-lg-2">
            <mat-form-field appearance="legacy" data-bs-toggle="tooltip" data-bs-placement="top"
              title="Filtro Solo disponible si esta selecionado el Tipo de cliente Natural">
              <mat-label>Apellido Materno</mat-label>
              <input matInput maxlength="30" [disabled]="filter.selectedType != 'Natural'"
                [(ngModel)]="filter.mother_last_name" (keyup.enter)="findFilter()">
            </mat-form-field>
          </div>


          <div class="col-12 col-sm-6 col-lg-3">
            <mat-form-field appearance="legacy">
              <mat-label>Nombre de la institución</mat-label>
              <input matInput maxlength="100" name="ndli" [(ngModel)]="filter.institution_name" (keyup.enter)="findFilter()">
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-lg-3">
            <mat-form-field appearance="legacy">
              <mat-label>Dependencia</mat-label>
              <mat-select disableRipple [(ngModel)]="filter.selectedValueDependencies"
                (selectionChange)="changeSelect($event,2)" name="dependecy" (ngModelChange)="findFilter()">
                <mat-option value="notselected">-- Dependencia-</mat-option>
                <mat-option *ngFor="let dep of listDependency; let vx = index" [value]="dep.id">{{dep?.id}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-lg-3">
            <mat-form-field appearance="legacy">
              <mat-label>Región</mat-label>
              <mat-select disableRipple [(ngModel)]="filter.selectedValueRegion"
                (selectionChange)="changeSelect($event,12)" name="reg" (ngModelChange)="findFilter()">
                <mat-option value="notselected">-- Región --</mat-option>
                <mat-option *ngFor="let reg of listRegion; let iv = index" [value]="reg.id">{{reg?.id}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-lg-3">
            <mat-form-field appearance="legacy">
              <mat-label>Comuna</mat-label>
              <mat-select disableRipple [(ngModel)]="filter.selectedValueCommune"
                (selectionChange)="changeSelect($event,13)" name="com" (ngModelChange)="findFilter()">
                <mat-option value="notselected">-- Comuna --</mat-option>
                <mat-option *ngFor="let com of listCommune" [value]="com.name">{{com?.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12" style="margin-top: 15px;">
            <button mat-raised-button color="primary" (click)="findFilter()">
              <mat-icon>search</mat-icon>Buscar
            </button>
            <button mat-raised-button class="btnFilter" (click)="cleanFilter()">
              <mat-icon>brush</mat-icon>Limpiar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="before-table mat-elevation-z8">
      <div class="before-table-div">

        <table mat-table (window:resize)="onResize($event)" [dataSource]="listDataTwo" multiTemplateDataRows>

          <ng-container matColumnDef="cu_first">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="modifyCustomer(element)">
                  <mat-icon>create</mat-icon>
                  <span>Modificar</span>
                </button>
                <button mat-menu-item (click)="openModal(element.id)">
                  <mat-icon>delete</mat-icon>
                  <span>Eliminar</span>
                </button>
              </mat-menu>

            </td>
          </ng-container>

          <ng-container *ngIf="!attributesView.isMobileTwo" matColumnDef="cu_type">
            <th mat-header-cell *matHeaderCellDef> Tipo </th>
            <td mat-cell *matCellDef="let element"> {{element?.type}} </td>
          </ng-container>

          <ng-container matColumnDef="cu_document">
            <th mat-header-cell *matHeaderCellDef> RUT </th>
            <td mat-cell *matCellDef="let element"> {{element?.document}} </td>
          </ng-container>

          <ng-container matColumnDef="cu_businessName">
            <th mat-header-cell *matHeaderCellDef> Razón social </th>
            <td mat-cell *matCellDef="let element"> {{element?.businessName}} </td>
          </ng-container>


          <ng-container *ngIf="!attributesView.isMobileTwo" matColumnDef="cu_mobile">
            <th mat-header-cell *matHeaderCellDef> Celular </th>
            <td mat-cell *matCellDef="let element"> +56 {{element?.mobile}} </td>
          </ng-container>

          <ng-container *ngIf="!attributesView.isMobile" matColumnDef="cu_contact">
            <th mat-header-cell *matHeaderCellDef> Contacto </th>
            <td mat-cell *matCellDef="let element"> {{element?.contact}} </td>
          </ng-container>

          <ng-container *ngIf="!attributesView.isMobileTwo" matColumnDef="cu_dependency">
            <th mat-header-cell *matHeaderCellDef> Dependencia </th>
            <td mat-cell *matCellDef="let element"> {{element?.dependency?.id}} </td>
          </ng-container>

          <ng-container matColumnDef="cu_arrow">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <mat-icon *ngIf="attributesView.tableArrow != element">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="attributesView.tableArrow == element">keyboard_arrow_up</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
              <div class="example-element-detail" [@detailExpand]="element == listDataCopy ? 'expanded' : 'collapsed'">
                <div style="width: 100%; padding: 3px !important;">
                  <div class="row g-0 mat-elevation-z8">
                    <div class="col-12" style="padding: 10px !important; padding-top: 20px !important;">
                      <fieldset class="scheduler-border">
                        <legend class="scheduler-border" style="margin-top: -15px !important; width: 100px !important;">
                          Información
                        </legend>

                        <div class="row">

                          <div class="col-12 col-md-6 p-2">

                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Tipo: </span> {{element?.type}} </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Nombre de la institución: </span>
                                {{element?.institution_name}}</mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Giro: </span> {{element?.activity}}</mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Dependencia: </span> {{element?.dependency?.id}}
                              </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Región : </span> {{element?.commune?.region?.id}}
                              </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Comuna: </span> {{element?.commune?.name}}
                              </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Dirección: </span> {{element?.address}} </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Observación: </span> {{element?.observation}}
                              </mat-list-item>
                            </div>
                          </div>

                          <div class="col-12 col-md-6 p-2">
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Profesión/cargo: </span> {{element?.profession}}
                              </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Contacto: </span> {{element?.contact}}</mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Celular: </span> +56 {{element?.mobile}} </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Teléfono: </span> +56 {{element?.phone}}</mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Correo 1: </span> {{element?.email}} </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Correo 2: </span> {{element?.email_two}}</mat-list-item>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
            [class.example-expanded-row]="listDataCopy === element"
            (click)="listDataCopy = listDataCopy === element ? null : element; indicatorArrow(listDataCopy != element ? null : element)">
          </tr>

          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

        </table>

        <mat-paginator #paginator [length]="attributesView.lengthPaginador" [pageIndex]="attributesView.pageIndex"
          [pageSize]="attributesView.pageSize" [pageSizeOptions]="attributesView.pageSizeOptions"
          (page)="paginador($event)">
        </mat-paginator>

      </div>
    </div>

  </div>

</div>

<div class="modal fade" id="modalSence" data-bs-backdrop="static" data-bs-keyboard="false"
  aria-labelledby="staticBackdropLabel" aria-hidden="false" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h2 id="staticBackdropLabel">Eliminar Sence</h2>
        <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        <div>
          <div>
            <form>
              <div class="row form-box-style">
                <h4>¿Si elimina este registro no podrá recuperarlo, desea eliminar el registro?</h4>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row" style="width: 100%;">
          <div class="col-6">
            <button mat-raised-button color="warn" (click)="deleteCustomer()" style="width: 100%;">Sí</button>
          </div>
          <div class="col-6">
            <button mat-raised-button (click)="closeModal()" style="width: 100%;">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
