<div *ngIf="attributes.hidden" class="m-0 row justify-content-center form-content-main">
  <div *ngIf="attributes.hiddentwo == 'notselected'" class="col-auto text-center" style="padding: 0.5rem;">
    <div class="row mat-elevation-z22 p-2" style="padding-bottom: 50px !important;">
      <div class="col-12 p-2 select-customer-type">
        SELECCIONE EL TIPO DE CLIENTE</div>
      <div class="col-6">
        <button *ngIf="true" mat-raised-button color="primary" class="btn-natural" (click)="clickHidden('Natural')">
          <mat-icon style="font-size: 35px !important; margin-left:  -5px;">person</mat-icon><br>Natural
        </button>
      </div>
      <div class="col-6">
        <button *ngIf="true" mat-raised-button color="primary" class="btn-institucion"
          (click)="clickHidden('Institución')">
          <mat-icon style="font-size: 30px !important;">account_balance</mat-icon><br>Institución
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="attributes.hiddentwo == 'Natural'" class="col-auto text-center" style="padding: 0.5rem;">
    <div class="col-auto text-center" style="padding: 0.5rem;">
      <div class="box-form mat-elevation-z22">
        <form>
          <div class="row form-box-style">

            <div class="col-12">
              <mat-form-field>
                <mat-label>RUT</mat-label>
                <input matInput type="text" maxlength="12" name="rut" [disabled]="attributes.rutChecked"
                  [(ngModel)]="customer.document" required>
                <button mat-button matSuffix mat-icon-button aria-label="Find" class="btnBusquedas"
                  [disabled]="attributes.rutChecked" [class.disabled-class]="attributes.rutChecked" (click)="rutValidate()">
                  <mat-icon>search</mat-icon>
                </button>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Nombres</mat-label>
                <input matInput maxlength="240" name="namecom" [disabled]="!attributes.rutChecked"
                  [(ngModel)]="customer.name" (keyup)="capitalLetter('name')" required>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Apellido Paterno</mat-label>
                <input matInput maxlength="30" name="nameap" [disabled]="!attributes.rutChecked"
                  [(ngModel)]="customer.last_name" (keyup)="capitalLetter('last_name')"  required>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Apellido Materno</mat-label>
                <input matInput maxlength="30" name="nameam" [disabled]="!attributes.rutChecked"
                  [(ngModel)]="customer.mothers_last_name" (keyup)="capitalLetter('mothers_last_name')" required>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Institución</mat-label>
                <input matInput maxlength="240" name="institucion" [disabled]="!attributes.rutChecked"
                  [(ngModel)]="customer.institution_name" (keyup)="capitalLetter('institution_name')" required>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Profesión/Cargo</mat-label>
                <input matInput maxlength="140" name="cargo" [disabled]="!attributes.rutChecked"
                  [(ngModel)]="customer.profession" (keyup)="capitalLetter('profession')" required>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field>
                <mat-label>Celular</mat-label>
                <span matPrefix>+56 &nbsp;</span>
                <input matInput type="tel" (paste)="false" placeholder="923456781" maxlength="9" name="celular"
                  [disabled]="!attributes.rutChecked" (keyup)="phoneKeydownEvent($event)" [(ngModel)]="customer.mobile"
                  required>
                <mat-hint>{{attributes.messageMobile}}</mat-hint>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field>
                <mat-label>Correo Electrónico</mat-label>
                <input matInput type="email" maxlength="60" placeholder="Ej. tucorreo@example.com" name="email"
                  [disabled]="!attributes.rutChecked" [(ngModel)]="customer.email" (keyup)="emailKeydownEvent()"
                  required>
                <mat-hint>{{attributes.messageEmail}}</mat-hint>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Región</mat-label>
                <mat-select disableRipple [disabled]="!attributes.rutChecked"
                  [(ngModel)]="attributes.selectedValueRegion" (selectionChange)="changeSelect($event,12)" name="reg"
                  required>
                  <mat-option value="notselected">-- Región --</mat-option>
                  <mat-option *ngFor="let reg of listRegion; let iv = index" [value]="reg.id">{{reg?.id}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
<!--(selectionChange)="changeSelect($event,13)"-->
            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Comuna</mat-label>
                <mat-select disableRipple [disabled]="!attributes.rutChecked"   [(ngModel)]="attributes.selectedValueCommune"  name="csom" required>
                  <mat-option value="notselected">-- Comuna --</mat-option>
                  <mat-option *ngFor="let comu of listCommune" [value]="comu.id.toString()">{{comu?.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

          </div>
        </form>
        <mat-divider></mat-divider>
        <div class="d-flex justify-content-between box-form-footer">
          <div>
            <button *ngIf="true" mat-raised-button color="primary" (click)="actionButton('')">
              <mat-icon>save</mat-icon> Guardar
            </button>
          </div>
          <div class="separater"></div>
          <div>
            <button mat-raised-button color="accent" (click)="actionButton('Select')">
              <mat-icon>close</mat-icon> Cerrar
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div *ngIf="attributes.hiddentwo == 'Institución'" class="col-auto text-center" style="padding: 0.5rem;">
    <div class="col-auto text-center" style="padding: 0.5rem;">
      <div class="box-form mat-elevation-z22">

        <form>
          <div class="row form-box-style">

            <div class="col-12">
              <mat-form-field>
                <mat-label>RUT</mat-label>
                <input matInput type="text" maxlength="12" name="rut" [disabled]="attributes.rutChecked"
                  [(ngModel)]="customer.document" required>
                <button mat-button matSuffix mat-icon-button aria-label="Find" class="btnBusquedas"
                  [disabled]="attributes.rutChecked" [class.disabled-class]="attributes.rutChecked" (click)="rutValidate()">
                  <mat-icon>search</mat-icon>
                </button>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Razón Social</mat-label>
                <input matInput maxlength="240" name="namecomrz" [disabled]="!attributes.rutChecked"
                  [(ngModel)]="customer.name" (keyup)="capitalLetter('name')" required>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Nombre de la Institución</mat-label>
                <input matInput maxlength="240" name="nameinsti" [disabled]="!attributes.rutChecked"
                  [(ngModel)]="customer.institution_name" (keyup)="capitalLetter('institution_name')" required>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Giro</mat-label>
                <input matInput maxlength="99" name="giro" [disabled]="!attributes.rutChecked"
                  [(ngModel)]="customer.activity" (keyup)="capitalLetter('activity')">
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Persona de Contacto</mat-label>
                <input matInput maxlength="99" name="percon" [disabled]="!attributes.rutChecked"
                  [(ngModel)]="customer.contact" (keyup)="capitalLetter('contact')" required>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Dependencia</mat-label>
                <mat-select disableRipple [disabled]="!attributes.rutChecked"
                  [(ngModel)]="attributes.selectedValueDependencies" name="dependecy" required>
                  <mat-option value="notselected">-- Dependencia-</mat-option>
                  <mat-option *ngFor="let dep of listDependency; let vx = index" [value]="dep.id">{{dep?.id}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field>
                <mat-label>Celular</mat-label>
                <span matPrefix>+56 &nbsp;</span>
                <input matInput type="tel" (paste)="false" placeholder="923456781" maxlength="9" name="mobile"
                  [disabled]="!attributes.rutChecked" (keyup)="phoneKeydownEvent($event)" [(ngModel)]="customer.mobile"
                  required>
                <mat-hint>{{attributes.messageMobile}}</mat-hint>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field>
                <mat-label>Teléfono Fijo</mat-label>
                <span matPrefix>+56 &nbsp;</span>
                <input matInput type="tel" (paste)="false" placeholder="923456781" maxlength="9" name="phone"
                  [disabled]="!attributes.rutChecked" (keyup)="phoneKeydownEventTwo($event)"
                  [(ngModel)]="customer.phone">
                <mat-hint>{{attributes.messagePhone}}</mat-hint>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field>
                <mat-label>Correo Electrónico 1</mat-label>
                <input matInput type="email" maxlength="60" placeholder="Ej. tucorreo@example.com" name="email"
                  [disabled]="!attributes.rutChecked" [(ngModel)]="customer.email" (keyup)="emailKeydownEvent()"
                  required>
                <mat-hint>{{attributes.messageEmail}}</mat-hint>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field>
                <mat-label>Correo Electrónico 2</mat-label>
                <input matInput type="email" maxlength="60" placeholder="Ej. tucorreodos@example.com" name="emailtwo"
                  [disabled]="!attributes.rutChecked" [(ngModel)]="customer.email_two" (keyup)="emailKeydownEventTwo()">
                <mat-hint>{{attributes.messageEmailTwo}}</mat-hint>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Región</mat-label>
                <mat-select disableRipple [disabled]="!attributes.rutChecked"
                  [(ngModel)]="attributes.selectedValueRegion" (selectionChange)="changeSelect($event,12)" name="reg"
                  required>
                  <mat-option value="notselected">-- Región --</mat-option>
                  <mat-option *ngFor="let reg of listRegion; let iv = index" [value]="reg.id">{{reg?.id}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Comuna</mat-label>
                <mat-select disableRipple [disabled]="!attributes.rutChecked"
                  [(ngModel)]="attributes.selectedValueCommune" (selectionChange)="changeSelect($event,13)" name="com"
                  required>
                  <mat-option value="notselected">-- Comuna --</mat-option>
                  <mat-option *ngFor="let com of listCommune" [value]="com.id.toString()">{{com?.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Dirección</mat-label>
                <textarea matInput [rows]="2" maxlength="497" name="dire" [disabled]="!attributes.rutChecked"
                  [(ngModel)]="customer.address" (keyup)="capitalLetter('address')"></textarea>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Observación</mat-label>
                <textarea matInput [rows]="2" maxlength="497" name="obs" [disabled]="!attributes.rutChecked"
                  [(ngModel)]="customer.observation" (keyup)="capitalLetter('observation')"></textarea>
              </mat-form-field>
            </div>

          </div>
        </form>
        <mat-divider></mat-divider>
        <div class="d-flex justify-content-between box-form-footer">
          <div>
            <button *ngIf="true" mat-raised-button color="primary" (click)="actionButton('')">
              <mat-icon>save</mat-icon> Guardar
            </button>
          </div>
          <div class="separater"></div>
          <div>
            <button mat-raised-button color="accent" (click)="actionButton('Select')">
              <mat-icon>close</mat-icon> Cerrar
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
