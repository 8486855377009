import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from '../../../environments/environment';
import { MCHttpMethodsEnum } from '../../mc-helpers/mc-enums/mc-http-methods-enum';
import { MCDataSourceAPICoreBuilder } from '../../mc-entity/mc-builders/mc-data-source-api-core-builder';
import { MCDataSourceLocal, MCDataSourceLocalInterface } from "../mc-data-source-local"; 
import { MCConstants } from "src/app/mc-helpers/mc-constants/mc-constants";

export interface MCDatasourceAPIInterface {
    core<T>(builder: MCDataSourceAPICoreBuilder): Observable<T>
}

@Injectable({
    providedIn: 'root'
})
export class MCDataSourceAPI implements MCDatasourceAPIInterface {
    
    // Private properties.
    private _omDataSourceLocal: MCDataSourceLocalInterface = new MCDataSourceLocal()

    // Constructors.
    constructor(private http: HttpClient) { }

    // APIDataSourceInterface.
    core<T>(builder: MCDataSourceAPICoreBuilder): Observable<T>{
        const url = environment.apiUri + builder.endpoint
        let finalHttpHeaders = new HttpHeaders();
        if(builder.httpHeaders){
            finalHttpHeaders = builder.httpHeaders;
        }
        if(builder.formData == null) {
            finalHttpHeaders = finalHttpHeaders.append(MCConstants.headerContentType, 'application/json')
        }
      
          // let t = JSON.parse(this._omDataSourceLocal.getToken());
    // let ma = t.token; 
    // let httpHeaders = new HttpHeaders();     
    // httpHeaders = httpHeaders.append(MCConstants.authorization,`Bearer ${ma}`);

        const token = this._omDataSourceLocal.getToken()
        if(token) {
            finalHttpHeaders = finalHttpHeaders.append(MCConstants.authorization, `Bearer ${JSON.parse(token).token}`)        
        }
      
        const object: Object = {
             //withCredentials:false,
            headers: finalHttpHeaders,
            observe: builder.enableObserve ? "response" : String(),
            params: builder.httpParams
        };
        switch (builder.httpMethod) {
            case MCHttpMethodsEnum.get: 
                return this.http.get<T>(url, object)
            case MCHttpMethodsEnum.post:
                if(builder.formData) {
                    return this.http.post<T>(url, builder.formData, object)
                } else {
                    return this.http.post<T>(url, builder.body, object)
                }
            case MCHttpMethodsEnum.put:
                if(builder.formData) {
                    return this.http.put<T>(url, builder.formData, object)
                } else {
                    return this.http.put<T>(url, builder.body, object)
                }
            case MCHttpMethodsEnum.delete:
                return this.http.delete<T>(url, object)
            default:
             return this.http.get<T>(url, object);
        }
       
    }
}