<div *ngIf="attributes.hidden" class="m-0 row justify-content-center form-content-main">

  <div *ngIf="attributes.hiddentwo == 'none'" class="col-auto text-center" style="padding: 0.5rem;">
    <div class="row mat-elevation-z8 p-2" style="padding-bottom: 50px !important; max-width: 600px; min-width: 280px;">

      <div class="d-flex">
        <div class="p-2 ms-auto">
          <mat-icon style="cursor: pointer;" (click)="close()">close</mat-icon>
        </div>
      </div>
      <div class="col-12 p-2">Escriba el RUT del Cliente y de Clic en <mat-icon>search</mat-icon>
      </div>

      <div class="col-12">
        <mat-form-field>
          <mat-label>RUT</mat-label>
          <input matInput type="text" maxlength="12" name="rut" [(ngModel)]="attributes.rut">
          <button mat-button matSuffix mat-icon-button aria-label="Find" class="btnBusquedas" (click)="rutValidate()">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="col-12 p-2" *ngIf="attributes.hiddenFor == 'inscripto'">El cliente: {{customer.businessName}}, Ya se encuentra registrado en este curso.</div>

      <div class="col-12 p-2" *ngIf="attributes.hiddenFor == 'inscribir'">Los datos del cliente ya están registrados en el sistema. ¿Desea inscribir el cliente: {{customer.businessName}} al curso?</div>

      <div class="d-flex justify-content-between box-form-footer" *ngIf="attributes.hiddenFor == 'inscribir'">
        <div>
          <button *ngIf="true" mat-raised-button color="primary" (click)="inscriptionCustomer()">
            <mat-icon>playlist_add</mat-icon> Inscribir
          </button>
        </div>
        <div class="separater"></div>
        <div>
          <button mat-raised-button color="accent" (click)="close()">
            <mat-icon>close</mat-icon> Cancelar
          </button>
        </div>
      </div>

      <div class="col-12 p-2" *ngIf="attributes.hiddenFor == 'creareinscribir'">Los Datos del Cliente NO Están Registrados en el Sistema. Seleccione el Tipo de Cliente que Desea Inscribir</div>

      <div class="col-6" *ngIf="attributes.hiddenFor == 'creareinscribir' && attributes.isOpenCourse == true">
        <button mat-raised-button color="primary" class="btn-natural" (click)="clickHidden('Natural')">
          <mat-icon style="font-size: 35px !important; margin-left:  -5px;">person</mat-icon><br>Natural
        </button>
      </div>
      <div class="col-6" *ngIf="attributes.hiddenFor == 'creareinscribir'">
        <button mat-raised-button color="primary" class="btn-institucion" (click)="clickHidden('Institución')">
          <mat-icon style="font-size: 30px !important;">account_balance</mat-icon><br>Institución
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="attributes.hiddentwo == 'Natural'" class="col-auto text-center" style="padding: 0.5rem;">
    <div class="col-auto text-center" style="padding: 0.5rem;">
      <div class="box-form mat-elevation-z22">
        <form>
          <div class="row form-box-style">

            <div class="col-12">
              <mat-form-field>
                <mat-label>RUT</mat-label>
                <input matInput type="text" maxlength="12" name="rut" [disabled]="true" [(ngModel)]="customer.document" required>
                <button mat-button matSuffix mat-icon-button aria-label="Find" class="btnBusquedas"
                        (click)="rutValidate()" [class.disabled-class]="true">
                  <mat-icon>search</mat-icon>
                </button>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Nombres</mat-label>
                <input matInput maxlength="240" name="namecom" [(ngModel)]="customer.name" (keyup)="capitalLetter('name')" required>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Apellido Paterno</mat-label>
                <input matInput maxlength="30" name="nameap" [(ngModel)]="customer.last_name" (keyup)="capitalLetter('last_name')" required>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Apellido Materno</mat-label>
                <input matInput maxlength="30" name="nameam" [(ngModel)]="customer.mothers_last_name" (keyup)="capitalLetter('mothers_last_name')" required>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Institución</mat-label>
                <input matInput maxlength="240" name="institucion" [(ngModel)]="customer.institution_name" (keyup)="capitalLetter('institution_name')" required>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Profesión/Cargo</mat-label>
                <input matInput maxlength="140" name="cargo" [(ngModel)]="customer.profession" (keyup)="capitalLetter('profession')" required>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field>
                <mat-label>Celular</mat-label>
                <span matPrefix>+56 &nbsp;</span>
                <input matInput type="tel" (paste)="false" placeholder="923456781" maxlength="9" name="celular"
                  (keyup)="phoneKeydownEvent($event)" [(ngModel)]="customer.mobile" required>
                <mat-hint>{{attributes.messageMobile}}</mat-hint>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field>
                <mat-label>Correo Electrónico</mat-label>
                <input matInput type="email" maxlength="60" placeholder="Ej. tucorreo@example.com" name="email"
                  [(ngModel)]="customer.email" (keyup)="emailKeydownEvent()" required>
                <mat-hint>{{attributes.messageEmail}}</mat-hint>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Región</mat-label>
                <mat-select disableRipple [(ngModel)]="attributes.selectedValueRegion"
                  (selectionChange)="changeSelect($event,12)" name="reg" required>
                  <mat-option value="notselected">-- Región --</mat-option>
                  <mat-option *ngFor="let reg of listRegion; let iv = index" [value]="reg.id">{{reg?.id}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Comuna</mat-label>
                <mat-select disableRipple [(ngModel)]="attributes.selectedValueCommune"
                  (selectionChange)="changeSelect($event,13)" name="com" required>
                  <mat-option value="notselected">-- Comuna --</mat-option>
                  <mat-option *ngFor="let com of listCommune" [value]="com.id.toString()">{{com?.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

          </div>
        </form>
        <mat-divider></mat-divider>
        <div class="d-flex justify-content-between box-form-footer">
          <div>
            <button *ngIf="true" mat-raised-button color="primary" (click)="inscriptionCustomerNotExist()">
              <mat-icon>save</mat-icon> Guardar
            </button>
          </div>
          <div class="separater"></div>
          <div>
            <button mat-raised-button color="accent" (click)="close()">
              <mat-icon>close</mat-icon> Cerrar
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div *ngIf="attributes.hiddentwo == 'Institución'" class="col-auto text-center" style="padding: 0.5rem;">
    <div class="col-auto text-center" style="padding: 0.5rem;">
      <div class="box-form mat-elevation-z22">

        <form>
          <div class="row form-box-style">

            <div class="col-12">
              <mat-form-field>
                <mat-label>RUT</mat-label>
                <input matInput type="text" maxlength="12" name="rut" [disabled]="true" [(ngModel)]="customer.document" required>
                <button mat-button matSuffix mat-icon-button aria-label="Find" class="btnBusquedas" [class.disabled-class]="true" (click)="rutValidate()">
                  <mat-icon>search</mat-icon>
                </button>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Razón Social</mat-label>
                <input matInput maxlength="240" name="namecomrz" [(ngModel)]="customer.name" (keyup)="capitalLetter('name')" required>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Nombre de la Institución</mat-label>
                <input matInput maxlength="240" name="nameinsti" [(ngModel)]="customer.institution_name" (keyup)="capitalLetter('institution_name')" required>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Giro</mat-label>
                <input matInput maxlength="99" name="giro" [(ngModel)]="customer.activity" (keyup)="capitalLetter('activity')">
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Persona de Contacto</mat-label>
                <input matInput maxlength="99" name="percon" [(ngModel)]="customer.contact" (keyup)="capitalLetter('contact')" required>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Dependencia</mat-label>
                <mat-select disableRipple [(ngModel)]="attributes.selectedValueDependencies" name="dependecy" required>
                  <mat-option value="notselected">-- Dependencia-</mat-option>
                  <mat-option *ngFor="let dep of listDependency; let vx = index" [value]="dep.id">{{dep?.id}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field>
                <mat-label>Celular</mat-label>
                <span matPrefix>+56 &nbsp;</span>
                <input matInput type="tel" (paste)="false" placeholder="923456781" maxlength="9" name="mobile"
                       (keyup)="phoneKeydownEvent($event)" [(ngModel)]="customer.mobile" required>
                <mat-hint>{{attributes.messageMobile}}</mat-hint>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field>
                <mat-label>Teléfono Fijo</mat-label>
                <span matPrefix>+56 &nbsp;</span>
                <input matInput type="tel" (paste)="false" placeholder="923456781" maxlength="9" name="phone"
                        (keyup)="phoneKeydownEventTwo($event)" [(ngModel)]="customer.phone">
                <mat-hint>{{attributes.messagePhone}}</mat-hint>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field>
                <mat-label>Correo Electrónico 1</mat-label>
                <input matInput type="email" maxlength="60" placeholder="Ej. tucorreo@example.com" name="email"
                       [(ngModel)]="customer.email" (keyup)="emailKeydownEvent()"
                  required>
                <mat-hint>{{attributes.messageEmail}}</mat-hint>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field>
                <mat-label>Correo Electrónico 2</mat-label>
                <input matInput type="email" maxlength="60" placeholder="Ej. tucorreodos@example.com" name="emailtwo"
                       [(ngModel)]="customer.email_two" (keyup)="emailKeydownEventTwo()">
                <mat-hint>{{attributes.messageEmailTwo}}</mat-hint>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Región</mat-label>
                <mat-select disableRipple [(ngModel)]="attributes.selectedValueRegion" (selectionChange)="changeSelect($event,12)" name="reg"
                  required>
                  <mat-option value="notselected">-- Región --</mat-option>
                  <mat-option *ngFor="let reg of listRegion; let iv = index" [value]="reg.id">{{reg?.id}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Comuna</mat-label>
                <mat-select disableRipple [(ngModel)]="attributes.selectedValueCommune" (selectionChange)="changeSelect($event,13)" name="com"
                  required>
                  <mat-option value="notselected">-- Comuna --</mat-option>
                  <mat-option *ngFor="let com of listCommune" [value]="com.id.toString()">{{com?.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Dirección</mat-label>
                <textarea matInput [rows]="2" maxlength="497" name="dire" [(ngModel)]="customer.address" (keyup)="capitalLetter('address')"></textarea>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="legacy">
                <mat-label>Observación</mat-label>
                <textarea matInput [rows]="2" maxlength="497" name="obs" [(ngModel)]="customer.observation" (keyup)="capitalLetter('observation')"></textarea>
              </mat-form-field>
            </div>

          </div>
        </form>
        <mat-divider></mat-divider>
        <div class="d-flex justify-content-between box-form-footer">
          <div>
            <button *ngIf="true" mat-raised-button color="primary" (click)="inscriptionCustomerNotExist()">
              <mat-icon>save</mat-icon> Guardar
            </button>
          </div>
          <div class="separater"></div>
          <div>
            <button mat-raised-button color="accent" (click)="close()">
              <mat-icon>close</mat-icon> Cerrar
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
