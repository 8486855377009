<div *ngIf="attributes.hidden" class="m-0 row justify-content-center form-content-main">
  <div class="col-auto text-center" style="padding: 0.5rem;">
    <div class="box-form mat-elevation-z22">
      <form>
        <div class="row form-box-style">

          <div class="col-12">
            <mat-form-field>
              <mat-label>RUT</mat-label>
              <input matInput type="text" maxlength="12" name="rut" [(ngModel)]="student.document"
                     [disabled]="attributes.rutChecked" required>
              <button mat-button matSuffix mat-icon-button aria-label="Find" class="btnBusquedas"
                      [disabled]="attributes.rutChecked" [class.disabled-class]="attributes.rutChecked" (click)="rutValidate()" >
                <mat-icon>search</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field appearance="legacy">
              <mat-label>Nombres</mat-label>
              <input matInput maxlength="250" name="name" [(ngModel)]="student.name" (keyup)="capitalLetter('name')" [disabled]="!attributes.rutCheckedExist" required>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field appearance="legacy">
              <mat-label>Apellido Paterno</mat-label>
              <input matInput maxlength="30" name="nameap" [(ngModel)]="student.last_name" (keyup)="capitalLetter('last_name')" [disabled]="!attributes.rutCheckedExist" required>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field appearance="legacy">
              <mat-label>Apellido Materno</mat-label>
              <input matInput maxlength="30" name="nameam"
                [(ngModel)]="student.mothers_last_name" [disabled]="!attributes.rutCheckedExist" (keyup)="capitalLetter('mothers_last_name')" required>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field appearance="legacy">
              <mat-label>Profesión/Cargo</mat-label>
              <input matInput maxlength="140" name="cargo"
                [(ngModel)]="student.profession" [disabled]="!attributes.rutCheckedExist" (keyup)="capitalLetter('profession')" required>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field>
              <mat-label>Correo Electrónico</mat-label>
              <input matInput type="email" maxlength="60" placeholder="Ej. tucorreo@example.com" name="email"
                 [(ngModel)]="student.email" (keyup)="emailKeydownEvent()" [disabled]="!attributes.rutCheckedExist" required>
              <mat-hint>{{attributes.messageEmail}}</mat-hint>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field appearance="legacy">
              <mat-label>Institución</mat-label>
              <mat-select disableRipple [(ngModel)]="attributes.selectedValueInstitution" [disabled]="!attributes.rutChecked" name="instu" required>
                <mat-option value="notselected">-- Seleccione --</mat-option>
                <ng-container *ngFor="let rep of listData">
                  <mat-option *ngIf="rep?.customer?.type == 'Institución'" [value]="rep.id.toString()">
                    {{rep?.customer?.businessName}}</mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>

        </div>
      </form>
      <mat-divider></mat-divider>
      <div class="d-flex justify-content-between box-form-footer">
        <div>
          <button [disabled]="!attributes.rutChecked" mat-raised-button color="primary" (click)="actionButton('')">
            <mat-icon>save</mat-icon> Inscribir
          </button>
        </div>
        <div class="separater"></div>
        <div>
          <button mat-raised-button color="accent" (click)="actionButton('Select')">
            <mat-icon>close</mat-icon> Cerrar
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
