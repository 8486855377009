import { MCConstants } from "src/app/mc-helpers/mc-constants/mc-constants";

export class MCStudentCustomerEntity {

  id_inscription_student: number;
  id_inscriptions: number;
  id: number;
  document_type: string;
  document: string;
  name: string;
  last_name: string;
  mothers_last_name: string;
  email: string;
  profession: string;
  institution: string;
  observation: string;
  id_customer: number;
  type_customer: string;
  businessName_customer: string;
  document_customer: string;
  celular_customer: string;
  region_customer: string;
  comuna_customer: string;

  constructor(
    id_inscription_student: number,
    id_inscriptions: number,
    id: number,
    document_type: string,
    document: string,
    name: string,
    last_name: string,
    mothers_last_name: string,
    email: string,
    profession: string,
    institution: string,
    observation: string,
    id_customer: number,
    type_customer: string,
    businessName_customer: string,
    document_customer: string,
    celular_customer: string,
    region_customer: string,
    comuna_customer: string
  ) {
    this.id_inscription_student = id_inscription_student ?? 0;
    this.id_inscriptions = id_inscriptions ?? 0;
    this.id = id ?? 0;
    this.document_type = document_type ?? String();
    this.document = document ?? String();
    this.name = name ?? String();
    this.last_name = last_name ?? String();
    this.mothers_last_name = mothers_last_name ?? String();
    this.email = email ?? String();
    this.profession = profession ?? String();
    this.institution = institution ?? String();
    this.observation = observation ?? String();
    this.id_customer = id_customer ?? 0;
    this.type_customer = type_customer ?? String();
    this.businessName_customer = (type_customer == MCConstants.natural) ? String() : businessName_customer;
    this.document_customer = (type_customer == MCConstants.natural) ? String() : document_customer;
    this.celular_customer = celular_customer ?? String();
    this.region_customer = region_customer ?? String();
    this.comuna_customer = comuna_customer ?? String();

  }

}
