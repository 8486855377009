import { MCConstants } from "src/app/mc-helpers/mc-constants/mc-constants";
import { MCCommuneEntity } from "./mc-commune-entitys";
import { MCDependencyEntity } from "./mc-dependency-entitys";
import { MCStudentEntity } from "./mc-students-entitys";

export class MCOpenCourseCustomersEntity {
    // Properties.

    total: number;
    entities: MCOpenCourseInscriptionsCustomerEntity[] = [];

    // Constructors.

    constructor(total: number, object: any) {
        this.total = total ?? 0;
        if (object) {
            for (let entity of object) {
                this.entities.push(new MCOpenCourseInscriptionsCustomerEntity(entity));
            }
        }
    }
}

export class MCOpenCourseInscriptionsStidentEntity {
  // Properties.

  id: number;
  student: MCStudentEntity;

  // Constructors.

  constructor(id: number, object: any) {
      this.id = id ?? 0;
      this.student = object;
  }
}

export class MCOpenCourseInscriptionsCustomerEntity {
  id: number;
  amount: number;
  paid: boolean;
  customer: MCOpenCourseCustomerEntity;
  students: MCOpenCourseInscriptionsStidentEntity[];

  constructor(object: any) {
      this.id = object.id ?? 0;
      this.amount = object.amount ?? 0
      this.paid = (object.paid == 0) ? false : true;
      this.customer = (object.customer != undefined) ? new MCOpenCourseCustomerEntity(object.customer) : new MCOpenCourseCustomerEntity(String());
      this.students = object.students ?? [];
  }

}

export class MCOpenCourseCustomerEntity {
    id: number;
    type: string;
    document_type: string;
    document: string;
    name: string;
    last_name: string;
    mothers_last_name: string;
    institution_name: string;
    activity: string;
    profession: string;
    phone: string;
    mobile: string;
    email: string;
    email_two: string;
    address: string;
    test: string;
    observation: string;
    contact: string;
    commune: MCCommuneEntity;
    dependency: MCDependencyEntity;
    businessName: string;

    constructor(object: any) {
        this.id = object.id ?? 0;
        this.type = object.type ?? MCConstants.natural;
        this.document_type = object.document_type ?? MCConstants.rut;
        this.document = object.document ?? String();
        this.name = object.name ?? String();
        this.last_name = object.last_name ?? String();
        this.mothers_last_name = object.mothers_last_name ?? String();
        this.institution_name = object.institution_name ?? String();
        this.activity = object.activity ?? String();
        this.profession = object.profession ?? String();
        this.phone = object.phone ?? String();
        this.mobile = object.mobile ?? String();
        this.email = object.email ?? String();
        this.email_two = object.email_two ?? String();
        this.address = object.address ?? String();
        this.test = object.test ?? String();
        this.observation = object.observation ?? String();
        this.contact = object.contact ?? String();
        this.commune = object.commune ?? new MCCommuneEntity;
        this.dependency = object.dependency ?? new MCDependencyEntity(String());
        this.businessName = this.businessNameMethod(object);

    }

    businessNameMethod(object: any): string {
        let l_type: string = object.type ?? MCConstants.natural
        let l_name: string = object.name ?? String();
        if (l_type == MCConstants.natural) {
            let l_last_name: string = object.last_name ?? String();
            let l_mothers_last_name: string = object.mothers_last_name ?? String();
            return l_name + " " + l_last_name + " " + l_mothers_last_name;
        } else {
            return l_name;
        }
    }

    businessNameMethodView(name: string, last_name: string, mothers_last_name: string): string {
        return name + " " + last_name + " " + mothers_last_name;
    }
}

export class MCOpenCourseCustomerEntityRequests {

    document_type: string;
    document: string;
    name: string;
    last_name: string;
    mothers_last_name: string;
    institution_name: string;
    profession: string;
    mobile: string;
    email: string;
    address: string;
    commune_id: number;
    type: string;

    constructor(object: any, idCommune: number) {
        this.document_type = object.document_type;
        this.document = object.document;
        this.name = this.upperLowerCaseWord(object.name);
        this.last_name = object.last_name;
        this.mothers_last_name = object.mothers_last_name;
        this.institution_name = object.institution_name;
        this.profession = object.profession;
        this.mobile = object.mobile;
        this.email = object.email;
        this.address = object.address;
        this.commune_id = idCommune;
        this.type = object.type;
    }

    upperLowerCaseWord(value: string): string {
        let palabras = value.split(" ");
        for (let i = 0; i < palabras.length; i++) {
            palabras[i] = palabras[i].replace(/ /g, String());
            if (palabras[i] != String()) {
                palabras[i] = palabras[i][0].toUpperCase() + palabras[i].substring(1).toLowerCase();
            }
        }
        return palabras.join(" ");
    }
}

export class MCOpenCourseCustomerLegalEntityRequests {

    document_type: string;
    document: string;
    name: string;
    institution_name: string;
    activity: string;
    contact: string;
    phone: string;
    mobile: string;
    email: string;
    email_two: string;
    commune_id: number;
    dependency_id: string;
    type: string;
    address: string;
    observation: string;

    constructor(object: any, idCommune: number, idDependencies: string) {
        this.document_type = object.document_type;
        this.document = object.document;
        this.name = this.upperLowerCaseWord(object.name);
        this.institution_name = object.institution_name;
        this.activity = object.activity;
        this.contact = object.contact;
        this.phone = object.phone;
        this.mobile = object.mobile;
        this.email = object.email;
        this.email_two = object.email_two;
        this.commune_id = idCommune;
        this.dependency_id = idDependencies;
        this.type = object.type;
        this.address = object.address;
        this.observation = object.observation;
    }

    upperLowerCaseWord(value: string): string {
        let palabras = value.split(" ");
        for (let i = 0; i < palabras.length; i++) {
            if (palabras[i].trim() != String()) {
                palabras[i] = palabras[i][0].toUpperCase() + palabras[i].substring(1).toLowerCase();
            }
        }
        return palabras.join(" ");
    }
}
