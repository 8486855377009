import { MCError } from "src/app/mc-helpers/mc-error";
import { MCDataSourceAPICoreBuilder } from "src/app/mc-entity/mc-builders/mc-data-source-api-core-builder";
import { MCDataSourceLocal, MCDataSourceLocalInterface } from "../mc-data-source-local";    
import { MCDataSourceAPI } from "./mc-data-source-api";
import { MCEndpointsConstants } from "src/app/mc-helpers/mc-constants/mc-endpoints-constants"; 
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { MCConstants } from "src/app/mc-helpers/mc-constants/mc-constants";
 import { MCHttpMethodsEnum } from "src/app/mc-helpers/mc-enums/mc-http-methods-enum"; 
import { Injectable } from "@angular/core";
import { MCUtils } from "src/app/mc-helpers/mc-class/mc-utils";  
 

export interface MCAuthenticationAPIInterface {
 
  postLogin(  
    email: string,
    password: string,
    success: () => void, 
    failure: (error: MCError) => void
  ): any;

  postLogout(   
    success: () => void, 
    failure: (error: MCError) => void
  ): any;

  postRefresh(   
    success: () => void, 
    failure: (error: MCError) => void
  ): any;
 
}
@Injectable({
  providedIn: 'root'
}) 
export class MCAuthenticationAPI implements MCAuthenticationAPIInterface {
  // Private properties.

  private _mcDataSourceLocal: MCDataSourceLocalInterface = new MCDataSourceLocal(); 

  // Contructors.

  constructor(private _apiDataSource: MCDataSourceAPI, private _mcUtils: MCUtils) { }


  postLogin(
    email: string,
    password: string, 
    success: () => void, 
    failure: (error: MCError) => void
  ) {
    let httpHeaders = new HttpHeaders();     
    httpHeaders = httpHeaders.append(MCConstants.xEmail, email);
    httpHeaders = httpHeaders.append(MCConstants.xPassword, password);
    const builder = new MCDataSourceAPICoreBuilder();  
    builder.endpoint = MCEndpointsConstants.authenticationAPI.login;       
    builder.httpHeaders = httpHeaders;    
    builder.httpMethod = MCHttpMethodsEnum.post;   
    builder.enableObserve = true;
    this._apiDataSource.core(builder).subscribe((data: any) => {    
      this._mcDataSourceLocal.removeToken(); 
      this._mcDataSourceLocal.setToken(JSON.stringify({'token':data.headers.get(MCConstants.xToken) , 'time':data.body.data.expires_in}));  
        success();
    }, error => {   
      failure(new MCError(error));
    })
  }

  postLogout(
    success: () => void, 
    failure: (error: MCError) => void
  ) {       
    const builder = new MCDataSourceAPICoreBuilder();  
    builder.endpoint = MCEndpointsConstants.authenticationAPI.logout;    
    builder.httpMethod = MCHttpMethodsEnum.post;   
    this._apiDataSource.core(builder).subscribe((data: any) => {         
      this._mcDataSourceLocal.removeToken();       
      success();
    }, error => {    
      failure(new MCError(error));
    })
  }

  postRefresh( 
    success: () => void, 
    failure: (error: MCError) => void
  ) { 
    const builder = new MCDataSourceAPICoreBuilder();  
    builder.endpoint = MCEndpointsConstants.authenticationAPI.refresh;   
    builder.enableObserve = true;   
    builder.httpMethod = MCHttpMethodsEnum.post;   
    this._apiDataSource.core(builder).subscribe((data: any) => {    
      this._mcDataSourceLocal.removeToken(); 
      this._mcDataSourceLocal.setTimeStart();
      this._mcDataSourceLocal.setToken(JSON.stringify({'token':data.headers.get(MCConstants.xToken) , 'time':data.body.data.expires_in}));
      success();
    }, error => {   
      failure(new MCError(error));
    })
  } 
}