<div id="container" (window:resize)="onResize()">
  <!--MENU-->
  <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light justify-content-end mat-elevation-z2">

    <!--MENU FOR MOBILE-->
    <a *ngIf="attributes.menuMobile" id="menu-toggle" class="navbar-brand">
      <button id="refresh" class="navbar-toggler btnMenu" type="button" data-bs-toggle="collapse"
        (click)="attributes.desktopCollapseFalse = !attributes.desktopCollapseFalse" routerLinkActive="active"
        data-bs-target="#navbarsMC" aria-controls="navbarsMC" aria-haspopup="true" aria-expanded="false"
        aria-label="Toggle navigation">
        <i class="bi bi-list"></i>
      </button>
    </a>

    <div class="collapse navbar-collapse" id="navbarsMC">
      <ul class="navbar-nav mr-auto">

        <li *ngIf="attributes.menuMobile" #rla="routerLinkActive" class="nav-item dropdown lado-der"
          routerLinkActive="active">
          <a [class.menu-mobil]="attributes.menuMobile" class="nav-link dropdown-toggle" id="navbarDropdown"
            role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src="/../assets/teacher.png" alt=""> Maestros
          </a>
          <div class="dropdown-menu backgroupColor" aria-labelledby="navbarDropdown">
            <a [class.menu-mobil-list]="attributes.menuMobile" class="nav-link submenu" routerLink="/admin/relator"
              (click)="closeMenu()">Relatores</a>
            <div class="dropdown-divider"></div>
            <a [class.menu-mobil-list]="attributes.menuMobile" class="nav-link submenu" routerLink="/admin/sence"
              (click)="closeMenu()">Cursos SENCE</a>
            <div class="dropdown-divider"></div>
            <a [class.menu-mobil-list]="attributes.menuMobile" class="nav-link submenu" routerLink="/admin/curso"
              (click)="closeMenu()">Cursos Base</a>
            <div class="dropdown-divider"></div>
            <a [class.menu-mobil-list]="attributes.menuMobile" class="nav-link submenu" routerLink="/admin/correos"
            (click)="closeMenu()">Correos</a>
          <div class="dropdown-divider"></div>
          </div>
        </li>

        <li class="nav-item dropdown lado-der" *ngIf="attributes.menuMobile">
          <a [class.menu-mobil]="attributes.menuMobile" class="nav-link" routerLink="/admin/cliente"
            routerLinkActive="active" (click)="closeMenu()" id="navbarDropdown" role="button" aria-haspopup="true">
            <img src="/../assets/customer.png" alt=""> Cliente
          </a>
        </li>

        <li class="nav-item dropdown lado-der" *ngIf="attributes.menuMobile">
          <a [class.menu-mobil]="attributes.menuMobile" class="nav-link" routerLink="/admin/participante" routerLinkActive="active"
             (click)="closeMenu()" id="navbarDropdown" role="button" aria-haspopup="true">
            <img src="/../assets/student.png" alt=""> Participante
          </a>
        </li>

        <li class="nav-item dropdown lado-der" *ngIf="attributes.menuMobile">
          <a [class.menu-mobil]="attributes.menuMobile" class="nav-link " routerLink="/admin/curso-abierto"
            routerLinkActive="active" (click)="closeMenu()" id="navbarDropdown" role="button" aria-haspopup="true">
            <img src="/../assets/open.png" alt=""> Cursos Abiertos
          </a>
        </li>

        <li class="nav-item dropdown lado-der" *ngIf="attributes.menuMobile">
          <a [class.menu-mobil]="attributes.menuMobile" class="nav-link" routerLink="/admin/curso-cerrado"
            routerLinkActive="active" (click)="closeMenu()" id="navbarDropdown" role="button" aria-haspopup="true">
            <img src="/../assets/close.png" alt=""> Cursos Cerrados
          </a>
        </li>


        <!--END MENU FOR MOBILE-->

        <!--MENU FOR DESKTOP-->

        <!-- <li class="nav-item dropdown lado-der" *ngIf="!menuMobile">
            <a [class.menu-mobil]="menuMobile" class="nav-link dropdown-toggle"   id="navbarDropdown"
              role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img src="/../assets/curso-cerrado.png" alt="">
              Archivo
            </a>
            <div class="dropdown-menu backgroupColor" aria-labelledby="navbarDropdown">
              <a [class.menu-mobil-list]="menuMobile" class="nav-link submenu"><i class="bi bi-dot"></i>Clientes</a>
              <div class="dropdown-divider"></div>
              <a [class.menu-mobil-list]="menuMobile" class="nav-link submenu"><i class="bi bi-dot"></i>Métodos de
                Envíos</a>
              <div [class.menu-mobil-list]="menuMobile" class="dropdown-divider"></div>
              <a [class.menu-mobil-list]="menuMobile" class="nav-link submenu"><i class="bi bi-dot"></i>Usuarios</a>
            </div>
          </li> -->

        <li *ngIf="!attributes.menuMobile" class="nav-item dropdown lado-der" #rla="routerLinkActive"
          routerLinkActive="active">
          <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <div class="li-a-div">
              <img src="/../assets/teacher.png" alt="">
            </div>
            <br>&nbsp;&nbsp;&nbsp;&nbsp;Maestros
          </a>
          <div class="dropdown-menu lado-der" aria-labelledby="navbarDropdown">
            <a class="nav-link submenu" routerLink="/admin/relator">Relatores</a>
            <div class="dropdown-divider"></div>
            <a class="nav-link submenu" routerLink="/admin/sence">Cursos SENCE</a>
            <div class="dropdown-divider"></div>
            <a class="nav-link submenu" routerLink="/admin/curso">Cursos Base</a>
            <div class="dropdown-divider"></div>
            <a class="nav-link submenu" routerLink="/admin/correos">Correos</a>
            <div class="dropdown-divider"></div>
          </div>
        </li>

        <li class="nav-item dropdown lado-der" routerLinkActive="active" *ngIf="!attributes.menuMobile">
          <a class="nav-link sutituto" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false"
            routerLink="/admin/cliente">
            <div class="li-a-div">
              <img src="/../assets/customer.png" alt="">
            </div>
            <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cliente
          </a>
        </li>

        <li class="nav-item dropdown lado-der" routerLinkActive="active" *ngIf="!attributes.menuMobile">
          <a class="nav-link sutituto"  id="navbarDropdown" role="button" aria-haspopup="true"
             aria-expanded="false" routerLink="/admin/participante">
            <div class="li-a-div">
              <img src="/../assets/student.png" alt="">
            </div>
            <br>&nbsp;&nbsp;&nbsp;&nbsp;Participante
          </a>
        </li>

        <li class="nav-item dropdown lado-der" routerLinkActive="active" *ngIf="!attributes.menuMobile">
          <a class="nav-link sutituto" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false"
            routerLink="/admin/curso-abierto">
            <div class="li-a-div">
              <img src="/../assets/open.png" alt="">
            </div>
            <br>Cursos Abiertos
          </a>
        </li>

        <li class="nav-item dropdown lado-der" routerLinkActive="active" *ngIf="!attributes.menuMobile">
          <a class="nav-link sutituto" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false"
            routerLink="/admin/curso-cerrado">
            <div class="li-a-div">
              <img src="/../assets/close.png" alt="">
            </div>
            <br>Cursos Cerrados
          </a>
        </li>



      </ul>

      <div style="width: 100%;" *ngIf="!attributes.menuMobile">
        <form class="d-flex">
          <div class="form-control form-div"></div>
          <button class="btn btnSalir" type="submit" (click)="logout()">
            <mat-icon>power_settings_new</mat-icon>
          </button>
        </form>
      </div>

      <div *ngIf="attributes.menuMobile">
        <button class="btn btnSalir" type="submit" (click)="logout()">
          <mat-icon>power_settings_new</mat-icon>
        </button>
      </div>
    </div>

  </nav>
  <!--END MENU-->

  <div style="margin-top: -0.6rem;">
    <div id="page-content-wrapper">
      <div class="mobileClass fixed-top" style="background-color: #FFFFFF !important;">
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<div target="black" class="back-to-topw mat-elevation-z8">
  <div class="toast" [ngClass]="(attributes.timeAlert)?'show':''" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header" style="color: black !important; font-weight: 400 !important;">
      <strong class="me-auto">Tu sesión va a expirar en:</strong>
      <small class="reloj">{{attributes.relojToken}} Segundos</small>
      <button type="button" class="btn-close" (click)="closeRefreshToken()"></button>
    </div>
    <div class="toast-body">
      <button mat-raised-button color="primary" (click)="refreshToken()">Continuar sesión</button>
    </div>
  </div>
</div>

<ngx-spinner type="ball-clip-rotate-multiple" bdColor="rgba(65, 105, 225, 1)" color="#FFFFFF">
  <p style="font-size: 20px; color: #FFFFFF; margin-top: 50px;">Centro Mahuida</p>
</ngx-spinner>

<!-- <ngx-spinner
  bdColor="rgba(0, 0, 0, 1)"
  template="<img src='https://media.giphy.com/media/o8igknyuKs6aY/giphy.gif' />"
>
</ngx-spinner> -->
