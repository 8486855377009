<div>
  <app-mc-course-customer (inscriptionRespond)="inscriptionRespond($event)"></app-mc-course-customer>
  <div *ngIf="!attributesView.view_crud">
    <div class="mainFilterStyled">
      <div class="d-flex bd-highlight">
        <div class="w-100 bd-highlight">
          <mat-form-field>
            <mat-label> Buscar por Razón Social </mat-label>
            <input matInput type="text" maxlength="100" name="find" [(ngModel)]="attributesView.search" (keyup.enter)="search('1')">
            <button mat-button matSuffix mat-icon-button aria-label="Find" class="btnBusquedas" (click)="search('1')">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="flex-shrink-1 bd-highlight">
          <button mat-raised-button color="primary" style="margin-top: 8px; margin-left: 10px;" (click)="addCustomer()">
            <mat-icon>add</mat-icon> {{attributesView.btnTitle}}
          </button>
        </div>
      </div>
    </div>

    <div class="before-table mat-elevation-z8">
      <div class="before-table-div">

        <table mat-table (window:resize)="onResize($event)" [dataSource]="listDataTwo" multiTemplateDataRows>

          <ng-container matColumnDef="cu_first">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
              <!-- <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="openModal(element)">
                  <mat-icon>delete</mat-icon>
                  <span>Desinscribir</span>
                </button>
              </mat-menu> -->
              <button mat-icon-button (click)="openModal(element)"><i class="bi bi-clipboard2-x-fill"
                  style="font-size: 18px;"></i></button>
            </td>
          </ng-container>

          <ng-container *ngIf="!attributesView.isMobileTwo" matColumnDef="cu_type">
            <th mat-header-cell *matHeaderCellDef> Tipo </th>
            <td mat-cell *matCellDef="let element"> {{element?.customer?.type}} </td>
          </ng-container>

          <ng-container matColumnDef="cu_document">
            <th mat-header-cell *matHeaderCellDef> RUT </th>
            <td mat-cell *matCellDef="let element"> {{element?.customer?.document}} </td>
          </ng-container>

          <ng-container matColumnDef="cu_businessName">
            <th mat-header-cell *matHeaderCellDef> Razón Social </th>
            <td mat-cell *matCellDef="let element"> {{element?.customer?.businessName}} </td>
          </ng-container>


          <ng-container *ngIf="!attributesView.isMobileTwo" matColumnDef="cu_mobile">
            <th mat-header-cell *matHeaderCellDef> Celular </th>
            <td mat-cell *matCellDef="let element"> +56 {{element?.customer?.mobile}} </td>
          </ng-container>

          <ng-container *ngIf="!attributesView.isMobile" matColumnDef="cu_contact">
            <th mat-header-cell *matHeaderCellDef> Contacto </th>
            <td mat-cell *matCellDef="let element"> {{element?.customer?.contact}} </td>
          </ng-container>

          <ng-container *ngIf="!attributesView.isMobileTwo" matColumnDef="cu_dependency">
            <th mat-header-cell *matHeaderCellDef> Dependencia </th>
            <td mat-cell *matCellDef="let element"> {{element?.customer?.dependency?.id}} </td>
          </ng-container>

          <ng-container matColumnDef="cu_delete">
            <th mat-header-cell *matHeaderCellDef> Pagado </th>
            <td style="text-align: right" mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
              <mat-slide-toggle [checked]="element.paid" [matTooltip]="element.paid == false ? 'No pagado': 'Pagado'"
                matTooltipPosition="left" (change)="metodoStatus($event, element)">
              </mat-slide-toggle>
            </td>
          </ng-container>

          <ng-container matColumnDef="cu_arrow">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <mat-icon *ngIf="attributesView.tableArrow != element">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="attributesView.tableArrow == element">keyboard_arrow_up</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
              <div class="example-element-detail" [@detailExpand]="element == listDataCopy ? 'expanded' : 'collapsed'">
                <div style="width: 100%; padding: 3px !important;">
                  <div class="row g-0 mat-elevation-z8">
                    <div class="col-12" style="padding: 10px !important; padding-top: 20px !important;">
                      <fieldset class="scheduler-border">
                        <legend class="scheduler-border" style="margin-top: -15px !important; width: 100px !important;">
                          Información
                        </legend>

                        <div class="row">

                          <div class="col-12 col-md-6 p-2">

                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Tipo:s </span> {{element?.customer?.type}}
                              </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Nombre de la Institución: </span>
                                {{element?.customer?.institution_name}}</mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Giro: </span> {{element?.customer?.activity}}
                              </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Dependencia: </span>
                                {{element?.customer?.dependency?.id}}
                              </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Región : </span>
                                {{element?.customer?.commune?.region?.id}}
                              </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Comuna: </span> {{element?.customer?.commune?.name}}
                              </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Dirección: </span> {{element?.customer?.address}}
                              </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Observación: </span> {{element?.customer?.observation}}
                              </mat-list-item>
                            </div>
                          </div>

                          <div class="col-12 col-md-6 p-2">
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Profesión/Cargo: </span>
                                {{element?.customer?.profession}}
                              </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Contacto: </span> {{element?.customer?.contact}}
                              </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Celular: </span> +56 {{element?.customer?.mobile}}
                              </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Teléfono: </span> +56 {{element?.customer?.phone}}
                              </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Correo 1: </span> {{element?.customer?.email}}
                              </mat-list-item>
                            </div>
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Correo 2: </span> {{element?.customer?.email_two}}
                              </mat-list-item>
                            </div>

                          </div>


                        </div>

                      </fieldset>




                    </div>

                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
            [class.example-expanded-row]="listDataCopy === element"
            (click)="listDataCopy = listDataCopy === element ? null : element; indicatorArrow(listDataCopy != element ? null : element)">
          </tr>

          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

        </table>

        <mat-paginator #paginator [length]="attributesView.lengthPaginador" [pageIndex]="attributesView.pageIndex"
          [pageSize]="attributesView.pageSize" [pageSizeOptions]="attributesView.pageSizeOptions"
          (page)="paginador($event)">
        </mat-paginator>

      </div>
    </div>

  </div>

</div>
