<div class="margin-top">
    <div class="d-flex justify-content-start informative_header pe-3 mat-elevation-z1">
        <div>
            <button mat-raised-button color="primary" [disabled]="btnDisable" (click)="arrowEvent()">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
        <div>
            <button *ngIf="((title != 'Listado de clientes') && (title != 'Cliente')) &&
                           ((title != 'Listado de participante') && (title != 'Participante')) &&
                           ((title != 'Listado de correos') && (title != 'Correo'))"
                            mat-raised-button color="primary" [disabled]="!btnDisable" [matTooltip]="titleToolTip" (click)="addEvent()">
                <mat-icon>add_to_photos</mat-icon>
            </button>
        </div>
        <div>
            <div class="pipe">|</div>
        </div>
        <div class="pe-2" >
            <div class="title">{{titleLocal}}</div>
        </div>
    </div>
</div>
