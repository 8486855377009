import { MCOpenCourseInscriptionsCustomerEntity } from "./mc-open-courses-customer-entitys";
import { MCStudentEntity } from "./mc-students-entitys";

export class MCInscriptionStudentsEntity {
  // Properties.

  total: number;
  entities: MCInscriptionStudentEntity[] = [];

  // Constructors.

  constructor(total: number, object: any) {
      this.total = total ?? 0;
      if (object) {
          for (let entity of object) {
              this.entities.push(new MCInscriptionStudentEntity(entity));
          }
      }
  }
}

export class MCInscriptionStudentEntity {
  id: number;
  inscription: MCOpenCourseInscriptionsCustomerEntity;
  student: MCStudentEntity;

  constructor(object: any) {
      this.id = object.id ?? 0;
      this.inscription = object.inscription ?? new MCOpenCourseInscriptionsCustomerEntity(String());
      this.student = object.student ?? new MCStudentEntity(String());
  }
}
