import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MCStudentEntity, MCStudentEntityRequests } from 'src/app/mc-entity/mc-entities/mc-students-entitys';
import { MCAlert } from 'src/app/mc-helpers/mc-alert';
import { MCRouter } from 'src/app/mc-helpers/mc-class/mc-router';
import { MCUtils } from 'src/app/mc-helpers/mc-class/mc-utils';
import { MCConstants } from 'src/app/mc-helpers/mc-constants/mc-constants';
import { MCMessagesEnum } from 'src/app/mc-helpers/mc-enums/mc-messages-enum';
import { MCStudentAPI } from 'src/app/mc-repository/mc-api/mc-student-api';
import { MCDataSourceLocal, MCDataSourceLocalInterface } from 'src/app/mc-repository/mc-data-source-local';
import { MCLoadingService } from 'src/app/mc-service/mc-loading.service';
declare var require: any;
const { validate, format } = require('rut.js');

export interface attributes {
  hidden: boolean;
  action: string;
  codeChecked: boolean;
  rutCheckedExist: boolean;
  rutChecked: boolean;
  messageEmail: string;
  isNatural: boolean;
}

@Component({
  selector: 'app-mc-view-student',
  templateUrl: './mc-view-student.component.html',
  styleUrls: ['./mc-view-student.component.css']
})
export class MCViewStudentComponent implements OnInit {

  @Output() studentRespond = new EventEmitter<string>();

  private _mcRouter: MCRouter;
  private _mcDataSourceLocal: MCDataSourceLocalInterface = new MCDataSourceLocal();
  student: MCStudentEntity = new MCStudentEntity(String());

  attributes = {
    hidden: false,
    action: MCConstants.register,
    codeChecked: false,
    rutCheckedExist: false,
    rutChecked: false,
    messageEmail: String(),
    isNatural: true
  };

  constructor(
    private _loading: MCLoadingService,
    public _route: Router,
    private _mcUtils: MCUtils,
    private _mcAlert: MCAlert,
    private _mcStudentAPI: MCStudentAPI,
  ) {
    this._mcRouter = new MCRouter(_route);
  }

  ngOnInit(): void { }

  passStudent(passStudent: MCStudentEntity, intitution: string) {
    if (intitution == String() || intitution == undefined || intitution == null) {
      this.attributes.isNatural = true;
    } else {
      this.attributes.isNatural = false;
    }
    this.student = new MCStudentEntity(passStudent);
    this.attributes.rutCheckedExist = true;
    this.attributes.action = MCConstants.modify;
  }

  onEdit(event: any, item: any) {
    event.stopPropagation();
  }

  actionButton(action: string) {
    if (action == MCConstants.select) {
      this.studentRespond.emit(MCConstants.students);
    } else {
      if (this.validateForm()) {
        if (this.attributes.action == MCConstants.modify) {
          this.put()
        }
      }
    }
  }

  //valida formulario antes de realzar una accion
  validateForm(): boolean {
    if (!this._mcUtils.validateStringUndefined(this.student.document)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.studentDocumentFieldEmpty)
      return false;
    } else if (!this._mcUtils.validateStringUndefined(this.student.name)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.studentNameFieldEmpty)
      return false;
    } else if (!this._mcUtils.validateStringUndefined(this.student.last_name)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.studentLastNameFieldEmpty)
      return false;
    } else if (!this._mcUtils.validateStringUndefined(this.student.mothers_last_name)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.studentMothersLastNameFieldEmpty)
      return false;
    } else if (!this._mcUtils.validateStringUndefined(this.student.profession)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.studentProfessionFieldEmpty)
      return false;
    } else if (!this._mcUtils.validateEmail(this.student.email)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.studentEmailFieldEmpty);
      return false;
    } else {
      return true;
    }
  }

  put() {
    this._loading.callLoading();
    let studentInsert: MCStudentEntityRequests = new MCStudentEntityRequests(this.student);
    this._mcStudentAPI.putStudent(this.student.id.toString(), studentInsert, () => {
      this.studentRespond.emit(MCConstants.modify);
    }, error => {
      this.errorPostPut(error);
    })
  }

  errorPostPut(error: any) {
    this._loading.closeLoading();
    if (error.objectMessage == undefined) {
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    } else {
      if (this._mcUtils.validateUndefinedNull(error.objectMessage.name)) {
        this._mcAlert.showAlertError(error.objectMessage.name[0]);
      } else {
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      }
    }
  }

  validateExist() {
    this._loading.callLoading();
    this._mcStudentAPI.getStudent(this.student.document, () => {
      this._loading.closeLoading();
      this._mcAlert.showAlertError(MCMessagesEnum.codeBasicCourseExist);
    }, error => {
      this._loading.closeLoading();
      if (error.objectMessage == undefined) {
        this.attributes.codeChecked = true;
      } else {
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      }
    })
  }

  rutValidate() {
    this.student.document = this._mcUtils.removeBlanks(this.student.document);
    this.student.document = this.student.document.toUpperCase();
    if (this.student.document != String()) {
      this.student.document = format(this.student.document);
      validate(this.student.document) ? this.validateExist() : this._mcAlert.showAlertWarning(MCMessagesEnum.globalInvalidRut);
    } else {
      this._mcAlert.showAlertWarning(MCMessagesEnum.globalEmptyRut);
    }
  }

  //valida el correo
  emailKeydownEvent() {
    this.attributes.messageEmail = this._mcUtils.validateEmailKeyMessage(this.student.email);
  }

  capitalLetter(text: any){
    if(text == 'name'){
      this.student.name = this.student.name.toUpperCase();
    } else if(text == 'last_name'){
      this.student.last_name = this.student.last_name.toUpperCase();
    } else if(text == 'mothers_last_name'){
      this.student.mothers_last_name = this.student.mothers_last_name.toUpperCase();
    } else if(text == 'institution'){
      this.student.institution = this.student.institution.toUpperCase();
    } else if(text == 'profession'){
      this.student.profession = this.student.profession.toUpperCase();
    }
  }

}
