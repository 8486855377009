import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MCAlert {
  // Constructor.

  constructor(private _snackBar: MatSnackBar) {}

  // Functions.

  showAlertInformation(message: string) {
    this.showAlert(message, "snackbarInformacion")
  }

  showAlertWarning(message: string) {
    this.showAlert(message, "snackbarAdvertencia")
  }

  showAlertError(message: string) {
    this.showAlert(message, "snackbarError")
  }

  // Private functions.

  private showAlert(message: string, panelClass: string) {
    this._snackBar.open(
      message,
      'X',
      {
        duration: 60000,
        panelClass: [panelClass],
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
      }
    );
  }
}
