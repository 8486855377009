import { Injectable } from "@angular/core";
import { MCError } from "src/app/mc-helpers/mc-error";
import { MCDataSourceAPICoreBuilder } from "src/app/mc-entity/mc-builders/mc-data-source-api-core-builder";
import { MCDataSourceLocal, MCDataSourceLocalInterface } from "../mc-data-source-local";
import { MCDataSourceAPI } from "./mc-data-source-api";
import { MCEndpointsConstants } from "src/app/mc-helpers/mc-constants/mc-endpoints-constants";
import { HttpParams } from "@angular/common/http";
import { MCConstants } from "src/app/mc-helpers/mc-constants/mc-constants";
import { MCHttpMethodsEnum } from "src/app/mc-helpers/mc-enums/mc-http-methods-enum";
import { MCUtils } from "src/app/mc-helpers/mc-class/mc-utils";
import { MCOpenCourseEntityRequests, MCOpenCoursesEntity } from "src/app/mc-entity/mc-entities/mc-open-course-entitys";
import { MCScheduleEntity, MCScheduleEntityRequests } from "src/app/mc-entity/mc-entities/mc-schedules-entitys";
import { MCOpenCourseCustomersEntity } from "src/app/mc-entity/mc-entities/mc-open-courses-customer-entitys";



export interface MCOpenCoursesAPIInterface {

  getOpenCourse(
    idOpenCourse:string,
    success: () => void,
    failure: (error: MCError) => void
  ): any;

  getOpenCourses(
    page: number,
    limit: number,
    scope: string,
    start_date: string,
    start_date_final: string,
    region_name: string,
    mode: string,
    teacher_name: string,
    sence_title: string,
    success: (entity: MCOpenCoursesEntity) => void,
    failure: (error: MCError) => void
  ): any;

  putOpenCourses(
    idOpenCourse: number,
    openCourse: MCOpenCourseEntityRequests,
    success: () => void,
    failure: (error: MCError) => void
  ): any;

  postOpenCourses(
    openCourse: MCOpenCourseEntityRequests,
    success: (entity: any) => void,
    failure: (error: MCError) => void
  ): any;

  postOpenCoursesSchedules(
    idOpenCourse: string,
    openCourseSchedule: MCScheduleEntityRequests,
    success: (entity: any) => void,
    failure: (error: MCError) => void
  ): any;

  deleteOpenCourses(
    idOpenCourse: string,
    success: () => void,
    failure: (error: MCError) => void
  ): any;

  deleteOpenCoursesSchedules(
    idOpenCourse: string,
    idOpenCourseSchedules: string,
    success: () => void,
    failure: (error: MCError) => void
  ): any;

  getOpenCoursesCustomers(
    idOpenCourse: number,
    success: (entity: MCOpenCourseCustomersEntity) => void,
    failure: (error: MCError) => void
  ): any;

  getOpenCoursesCustomerExist(
    idOpenCourse: number,
    documentCustomer: string,
    success: (entity: any) => void,
    failure: (error: MCError) => void
  ): any;

  postCustomersOpenCourses(
    idOpenCourse: number,
    openCourseCustomers: any,
    success: (entity: any) => void,
    failure: (error: MCError) => void
  ): any;

  postCustomerPaidOpenCourses(
    idOpenCourse: number,
    idInscription: number,
    paid: boolean,
    success: (entity: any) => void,
    failure: (error: MCError) => void
  ): any;

  postStudentOpenCourses(
    idOpenCourse: number,
    idInscriptions: string,
    student: any,
    success: () => void,
    failure: (error: MCError) => void
  ): any;

  getOpenCoursesStudentExist(
    idOpenCourse: number,
    documentStudents: string,
    success: (entity: any) => void,
    failure: (error: MCError) => void
  ): any;

  deleteStudentInscription(
    idOpenCourse: number,
    idInscriptions: string,
    idInscriptionsStudent: string,
    success: () => void,
    failure: (error: MCError) => void
  ): any;

  deleteCustomerOpenCourses(
    idOpenCourse: number,
    idInscription: string,
    success: () => void,
    failure: (error: MCError) => void
  ): any;

}

@Injectable({
  providedIn: 'root'
})
export class MCOpenCoursesAPI implements MCOpenCoursesAPIInterface {
  // Private properties.

  private _omDataSourceLocal: MCDataSourceLocalInterface = new MCDataSourceLocal();

  // Contructors.

  constructor(private _apiDataSource: MCDataSourceAPI, private _mcUtils: MCUtils) { }


  postOpenCourses(
    openCourse: MCOpenCourseEntityRequests,
    success: (entity: any) => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    builder.endpoint = MCEndpointsConstants.openCoursesAPI.openCourses;
    builder.httpMethod = MCHttpMethodsEnum.post;
    builder.body = JSON.stringify(openCourse);
    this._apiDataSource.core(builder).subscribe((data: any) => {
      success(data);
    }, error => {
      console.log(error);
      failure(new MCError(error));
    })
  }

  putOpenCourses(
    idOpenCourse: number,
    openCourse: MCOpenCourseEntityRequests,
    success: () => void,
    failure: (error: MCError) => void
  ) {
    console.log(openCourse);
    const builder = new MCDataSourceAPICoreBuilder();
    builder.httpMethod = MCHttpMethodsEnum.put;
    builder.body = JSON.stringify(openCourse);
    builder.endpoint = MCEndpointsConstants.openCoursesAPI.openCourse.replace("[ID]", idOpenCourse.toString());
    this._apiDataSource.core(builder).subscribe(() => {
      success()
    }, error => {
      failure(new MCError(error));
    })
  }

  deleteOpenCourses(
    idOpenCourse: string,
    success: () => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    builder.httpMethod = MCHttpMethodsEnum.delete;
    builder.endpoint = MCEndpointsConstants.openCoursesAPI.openCourse.replace("[ID]", idOpenCourse);
    this._apiDataSource.core(builder).subscribe(() => {
      success()
    }, error => {
      console.log(error);
      failure(new MCError(error));
    })
  }

  getOpenCourse (
    idOpenCourse:string,
    success: () => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    builder.endpoint = MCEndpointsConstants.openCoursesAPI.openCourse.replace("[ID]", idOpenCourse);
    this._apiDataSource.core(builder).subscribe((data: any) => {
      success()
    }, error => {
      failure(new MCError(error));
    })
  }

  getOpenCourses(
    page: number,
    limit: number,
    scope: string,
    start_date: string,
    start_date_final: string,
    region_name: string,
    mode: string,
    teacher_name: string,
    sence_title: string,
    success: (entity: MCOpenCoursesEntity) => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    let params: HttpParams = new HttpParams();
    params = params.append(MCConstants.paramLimit, limit.toString());
    params = params.append(MCConstants.paramPage, page.toString());
    params = params.append(MCConstants.paramScope, scope);
    if(this._mcUtils.validateStringUndefinedNull(start_date) && this._mcUtils.validateStringUndefinedNull(start_date_final)) {
      params = params.append(MCConstants.paramStartDate, this._mcUtils.transformDate(start_date));
      params = params.append(MCConstants.paramStartDateFinal, this._mcUtils.transformDate(start_date_final));
    }
    if(this._mcUtils.validateStringUndefinedNotselected(mode)) {
      params = params.append(MCConstants.paramMode, mode);
    }
    if(this._mcUtils.validateStringUndefinedNotselected(region_name)) {
      params = params.append(MCConstants.paramRegionName, region_name);
    }
    if(this._mcUtils.validateStringUndefined(teacher_name)) {
      params = params.append(MCConstants.paramTeacherName, teacher_name);
    }
    if(this._mcUtils.validateStringUndefined(sence_title)) {
      params = params.append(MCConstants.paramSenceTitle, sence_title);
    }

    builder.httpParams = params;
    builder.endpoint = MCEndpointsConstants.openCoursesAPI.openCourses;
    this._apiDataSource.core(builder).subscribe((data: any) => {
      success(new MCOpenCoursesEntity(data.meta.total,data.data))
    }, error => {
      failure(new MCError(error));
    })
  }

  deleteOpenCoursesSchedules(
    idOpenCourse: string,
    idOpenCourseSchedules: string,
    success: () => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    builder.httpMethod = MCHttpMethodsEnum.delete;
    builder.endpoint = MCEndpointsConstants.openCoursesAPI.deleteOpenCourseSchedule.replace("[ID]", idOpenCourse);
    builder.endpoint = builder.endpoint.replace("[IDS]", idOpenCourseSchedules);
    console.log(builder.endpoint);
    this._apiDataSource.core(builder).subscribe(() => {
      success()
    }, error => {
      console.log(error);
      failure(new MCError(error));
    })
  }

  postOpenCoursesSchedules(
    idOpenCourse: string,
    openCourseSchedule: MCScheduleEntityRequests,
    success: (entity: any) => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    builder.endpoint = MCEndpointsConstants.openCoursesAPI.postOpenCourseSchedule.replace("[ID]", idOpenCourse);
    builder.httpMethod = MCHttpMethodsEnum.post;
    builder.body = JSON.stringify(openCourseSchedule);
    this._apiDataSource.core(builder).subscribe((data: any) => {
      success(new MCScheduleEntity(data.data));
    }, error => {
      console.log(error);
      failure(new MCError(error));
    })
  }


  getOpenCoursesCustomers(
    idOpenCourse: number,
    success: (entity: MCOpenCourseCustomersEntity) => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    builder.endpoint = MCEndpointsConstants.openCoursesAPI.getAllCustomersOpenCourse.replace("[ID]", idOpenCourse.toString());
    this._apiDataSource.core(builder).subscribe((data: any) => {
      success(new MCOpenCourseCustomersEntity(0, data.data))
    }, error => {
      failure(new MCError(error));
    })
  }

  getOpenCoursesCustomerExist(
    idOpenCourse: number,
    documentCustomer: string,
    success: (entity: any) => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    builder.endpoint = MCEndpointsConstants.openCoursesAPI.getCustomersOpenCourseExist.replace("[ID]", idOpenCourse.toString());
    builder.endpoint = builder.endpoint.replace("[IDS]", documentCustomer);
    this._apiDataSource.core(builder).subscribe((data: any) => {
      success(data.data)
    }, error => {
      failure(new MCError(error));
    })
  }

  postCustomersOpenCourses(
    idOpenCourse: number,
    openCourseCustomers: any,
    success: (entity: any) => void,
    failure: (error: MCError) => void
  ) {
    console.log(openCourseCustomers);
    const builder = new MCDataSourceAPICoreBuilder();
    builder.endpoint = MCEndpointsConstants.openCoursesAPI.postCustomersOpenCourse.replace("[ID]", idOpenCourse.toString());
    builder.httpMethod = MCHttpMethodsEnum.post;
    builder.body = JSON.stringify({"customers":openCourseCustomers});
    this._apiDataSource.core(builder).subscribe((data: any) => {
      success(data);
    }, error => {
      console.log(error);
      failure(new MCError(error));
    })
  }

  postCustomerPaidOpenCourses(
    idOpenCourse: number,
    idInscription: number,
    paid: boolean,
    success: (entity: any) => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    builder.endpoint = MCEndpointsConstants.openCoursesAPI.putCustomerPaidOpenCourse.replace("[ID]", idOpenCourse.toString());
    builder.endpoint = builder.endpoint.replace("[IDS]", idInscription.toString());
    builder.httpMethod = MCHttpMethodsEnum.put;
    builder.body = JSON.stringify({"paid": paid});
    this._apiDataSource.core(builder).subscribe((data: any) => {
      success(data);
    }, error => {
      console.log(error);
      failure(new MCError(error));
    })
  }

  postStudentOpenCourses(
    idOpenCourse: number,
    idInscriptions: string,
    student: any,
    success: () => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    builder.endpoint = MCEndpointsConstants.openCoursesAPI.postStudentOpenCourse.replace("[ID]", idOpenCourse.toString());
    builder.endpoint = builder.endpoint.replace("[IDS]", idInscriptions);
    builder.httpMethod = MCHttpMethodsEnum.post;
    builder.body = JSON.stringify({"students":student});
    console.log(builder.body);
    this._apiDataSource.core(builder).subscribe((data: any) => {
      success();
    }, error => {
      console.log(error);
      failure(new MCError(error));
    })
  }

  getOpenCoursesStudentExist(
    idOpenCourse: number,
    documentStudents: string,
    success: (entity: any) => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    builder.endpoint = MCEndpointsConstants.openCoursesAPI.getStudentsOpenCourse.replace("[ID]", idOpenCourse.toString());
    builder.endpoint = builder.endpoint.replace("[IDS]", documentStudents);
    this._apiDataSource.core(builder).subscribe((data: any) => {
      success(data.data)
    }, error => {
      failure(new MCError(error));
    })
  }


  deleteStudentInscription(
    idOpenCourse: number,
    idInscriptions: string,
    idInscriptionsStudent: string,
    success: () => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    builder.httpMethod = MCHttpMethodsEnum.delete;
    builder.endpoint = MCEndpointsConstants.openCoursesAPI.deleteStudentsOpenCourse.replace("[ID]", idOpenCourse.toString());
    builder.endpoint = builder.endpoint.replace("[IDS]", idInscriptions);
    builder.endpoint = builder.endpoint.replace("[IDI]", idInscriptionsStudent);
    console.log(idOpenCourse);
    console.log(idInscriptions);
    console.log(idInscriptionsStudent);
    console.log(builder.endpoint);
    this._apiDataSource.core(builder).subscribe(() => {
      success()
    }, error => {
      console.log(error);
      failure(new MCError(error));
    })
  }

  deleteCustomerOpenCourses(
    idOpenCourse: number,
    idInscription: string,
    success: () => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    builder.endpoint = MCEndpointsConstants.openCoursesAPI.deleteCustomerOpenCourse.replace("[ID]", idOpenCourse.toString());
    builder.endpoint = builder.endpoint.replace("[IDS]", idInscription);
    console.log(builder.endpoint);
    builder.httpMethod = MCHttpMethodsEnum.delete;
    this._apiDataSource.core(builder).subscribe((data: any) => {
      success();
    }, error => {
      console.log(error);
      failure(new MCError(error));
    })
  }

}
