<div *ngIf="attributes.hidden" class="m-0 row justify-content-center form-content-main">
  <div class="col-auto text-center" style="padding: 0.5rem;">
    <div class="box-form mat-elevation-z22">
      <form>
        <div class="row form-box-style">

          <div class="col-12">
            <mat-form-field>
              <mat-label>Código</mat-label>
              <input matInput type="text" maxlength="4" name="id" [disabled]="attributes.codeChecked" [(ngModel)]="course.id" (keyup)="capitalLetter('id')" required>
              <button mat-button matSuffix mat-icon-button aria-label="Find" class="btnBusquedas"
                [disabled]="attributes.codeChecked" [class.disabled-class]="attributes.codeChecked" (click)="codeValidate()">
                <mat-icon>search</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field appearance="legacy">
              <mat-label>Nombre</mat-label>
              <input matInput maxlength="250" name="name" [disabled]="!attributes.codeChecked" [(ngModel)]="course.name" (keyup)="capitalLetter('name')" required>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field appearance="legacy">
              <mat-label>Título para el Diploma</mat-label>
              <textarea matInput [rows]="1" maxlength="249" name="tdi" [disabled]="!attributes.codeChecked"
                        [(ngModel)]="course.certificate_title" (keyup)="capitalLetter('certificate_title')" required>
              </textarea>
            </mat-form-field>
          </div>

        </div>
      </form>
      <mat-divider></mat-divider>
      <div class="d-flex justify-content-between box-form-footer">
        <div>
          <button *ngIf="true" mat-raised-button color="primary" [disabled]="!attributes.codeChecked" (click)="actionButton('')">
            <mat-icon>save</mat-icon> Guardar
          </button>
        </div>
        <div class="separater"></div>
        <div>
          <button mat-raised-button color="accent" (click)="actionButton('Select')">
            <mat-icon>close</mat-icon> Cerrar
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
