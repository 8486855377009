import { MCConstants } from "src/app/mc-helpers/mc-constants/mc-constants";
import { MCBasicCourseEntity } from "./mc-basic-course-entitys";
import { MCCommuneEntity } from "./mc-commune-entitys";
import { MCScheduleEntity } from "./mc-schedules-entitys";
import { MCSenceEntity } from "./mc-sence-entitys";
import { MCTeacherEntity } from "./mc-teacher-entitys";

export class MCOpenCoursesEntity {
  // Properties.

  total: number;
  entities: MCOpenCourseEntity[] = [];

  // Constructors.

  constructor(total: number, object: any) {
    this.total = total ?? 0;
    if (object) {
      for (let entity of object) {
        this.entities.push(new MCOpenCourseEntity(entity));
      }
    }
  }
}


export class MCOpenCourseEntity {
  id: number;
  teacher: MCTeacherEntity;
  course: MCBasicCourseEntity;
  commune: MCCommuneEntity;
  sence?: MCSenceEntity;
  schedules: MCScheduleEntity[];
  name_course: string;
  certificate_title_course: string;
  date: string;
  duration: any;
  mode: string;
  price: any;
  start_date: string;
  final_date: string;
  deadline: string;
  material_date: string;
  discontinued: boolean;
  scope: string;
  place: string;
  address: string;
  course_link: string;
  message_to_user: string;
  image: string;
  observation: string;

  constructor(object: any) {
    this.id = object.id ?? 0;
    this.teacher = object.teacher ?? new MCTeacherEntity(String());
    this.course = object.course ?? new MCBasicCourseEntity(String());
    this.commune = object.commune ?? new MCCommuneEntity;
    this.sence = object.sence ?? new MCSenceEntity(String());
    this.schedules = object.schedules ?? [] ;
    this.name_course = object.name_course ?? String();
    this.certificate_title_course = object.certificate_title_course ?? String();
    this.date = object.date ?? String();
    this.duration = object.duration ?? String();
    this.mode = object.mode ?? MCConstants.faceToFace;
    this.price = object.price ?? String();
    this.start_date = object.start_date ?? String();
    this.final_date = object.final_date ?? String();
    this.deadline = object.deadline ?? String();
    this.material_date = new Date().toISOString().split('T')[0];//this.dateTimetoDate(new Date()) ?? String();
    this.discontinued = object.discontinued ?? false;
    this.scope = object.scope ?? String();
    this.place = object.place ?? String();
    this.address = object.address ?? String();
    this.course_link = object.course_link ?? String();
    this.message_to_user = object.message_to_user ?? String();
    this.image = object.image ?? String();
    this.observation = object.observation ?? String();
  }

  formatAmount(value: any){
    if(value == undefined){
      return String();
    } else {
      var arrayDeCadenas = value.split(".");
      let cadena0 = arrayDeCadenas[0];
      let cadena1 = cadena0.replace(/\$/g,'');
      let cadena =  cadena1.replace(/\./g,'');
      let sinpunto : number = parseInt(cadena);
      let conpunto = sinpunto.toLocaleString('de-DE');
      return (isNaN(sinpunto)) ? '$0' : ('$'+conpunto);
    }

  }

  formatearMontosInciales(monto: any) {
    return '$' + monto.toLocaleString('de-DE');
  }

  dateTimetoDate(value: any): string {
    if(value != undefined && value != String() && value != null){
      return value.split(" ")[0];
    }
    return String();
  }

}

export class MCOpenCourseEntityRequests {
  teacher_id: number;
  course_id: string;
  commune_id?: number;
  sence_id?: number;
  name_course: string;
  certificate_title_course: string;
  date: string;
  duration: number;
  mode: string;
  price: number;
  start_date: string;
  final_date: string;
  deadline: string;
  material_date: string;
  discontinued: boolean;
  scope: string;
  place: string;
  address: string;
  course_link: string;
  message_to_user: string;
  image: string;
  observation: string;
  schedule?: any;

  constructor(
    object: any,
    teacherID: number,
    senceID: any,
    comuneID: any,
    courseBaseID: string,
    listSchedules: any,
    scopeLocal: string,
    endDeadline: string,
    endHours: string
  ) {
    this.teacher_id = teacherID;
    this.course_id = courseBaseID;
    if(!isNaN(comuneID)) { this.commune_id = Number(comuneID);}
    if(!isNaN(senceID)) { if(Number(senceID) != 0){this.sence_id = Number(senceID);}}
    this.name_course = object.name_course;
    this.certificate_title_course = object.certificate_title_course;
    this.date = object.date;
    this.duration = Number(object.duration);
    this.mode = object.mode;
    this.price = this.transformPrice(object.price);
    this.start_date = this.transformDate(object.start_date);
    this.final_date = this.transformDate(object.final_date);
    this.deadline = this.transformDate(endDeadline) +" "+ endHours;
    this.material_date = this.transformDate(object.material_date);
    this.discontinued = object.discontinued;
    this.scope = scopeLocal;
    this.place = object.place;
    this.address = object.address;
    this.course_link = object.course_link;
    this.message_to_user = object.message_to_user;
    this.image = object.image;
    this.observation = object.observation;
    if(listSchedules.length > 0){ this.schedule = listSchedules; }
  }

  transformDate(valor: string) : string {
    return new Date(valor).toISOString().split('T')[0];
  }

  lineBreak(value: string): string {
    return value.replace(/(\r\n|\n|\r)/gm, " ");
  }

  transformPrice(value: any) : number{
        let cadena1 = value.replace(/\$/g,'');
        let cadena = cadena1.replace(/\./g,'');
        return parseInt(cadena);
  }
}

export class MCOpenCourseInscriptionStudenEntity {
  id: number;
  course: MCBasicCourseEntity;
  name_course: string;
  certificate_title_course: string;
  date: string;
  duration: any;
  mode: string;
  price: any;
  start_date: string;
  final_date: string;
  deadline: string;
  material_date: string;
  discontinued: boolean;
  scope: string;
  place: string;
  address: string;
  course_link: string;
  message_to_user: string;
  image: string;
  observation: string;

  constructor(object: any) {
    this.id = object.id ?? 0;
    this.course = object.course ?? new MCBasicCourseEntity(String());
    this.name_course = object.name_course ?? String();
    this.certificate_title_course = object.certificate_title_course ?? String();
    this.date = object.date ?? String();
    this.duration = object.duration ?? String();
    this.mode = object.mode ?? MCConstants.faceToFace;
    this.price = object.price ?? String();
    this.start_date = object.start_date ?? String();
    this.final_date = object.final_date ?? String();
    this.deadline = object.deadline ?? String();
    this.material_date = this.dateTimetoDate(object.material_date) ?? String();
    this.discontinued = object.discontinued ?? false;
    this.scope = object.scope ?? String();
    this.place = object.place ?? String();
    this.address = object.address ?? String();
    this.course_link = object.course_link ?? String();
    this.message_to_user = object.message_to_user ?? String();
    this.image = object.image ?? String();
    this.observation = object.observation ?? String();
  }

  formatAmount(value: any){
    if(value == undefined){
      return String();
    } else {
      console.log(value);
      var arrayDeCadenas = value.split(".");
      let cadena0 = arrayDeCadenas[0];
      console.log(cadena0);
      let cadena1 = cadena0.replace(/\$/g,'');
      let cadena =  cadena1.replace(/\./g,'');
      let sinpunto : number = parseInt(cadena);
      let conpunto = sinpunto.toLocaleString('de-DE');
      return (isNaN(sinpunto)) ? '$0' : ('$'+conpunto);
    }

  }

  formatearMontosInciales(monto: any) {
    return '$' + monto.toLocaleString('de-DE');
  }

  dateTimetoDate(value: any): string {
    if(value != undefined && value != String() && value != null){
      return value.split(" ")[0];
    }
    return String();
  }

}
