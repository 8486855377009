import { Component, OnInit,  ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MCToolbarClass } from 'src/app/mc-helpers/mc-class/mc-toolbar-class';
import { MCConstants } from 'src/app/mc-helpers/mc-constants/mc-constants';
import { MCViewTeacherComponent } from 'src/app/mc-component/mc-view-teacher/mc-view-teacher.component';
import { MCLoadingService } from 'src/app/mc-service/mc-loading.service';
import { MCUtils } from 'src/app/mc-helpers/mc-class/mc-utils';
import { Router } from '@angular/router';
import { MCRouter } from 'src/app/mc-helpers/mc-class/mc-router';
import { MCAlert } from 'src/app/mc-helpers/mc-alert';
import { MCTeacherAPI } from 'src/app/mc-repository/mc-api/mc-teacher-api';
import { MCTeacherEntity } from 'src/app/mc-entity/mc-entities/mc-teacher-entitys';
import { MCMessagesEnum } from 'src/app/mc-helpers/mc-enums/mc-messages-enum';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
declare var window: any;

export interface filter {
  name: string;
  title: string;
}

export interface attributesView {
  idDeleteTeacher : string;
  filterCollapses: boolean;
  view_crud: boolean;
  isMobile: boolean;
  lengthPaginador: number;
  pageIndex : number;
  pageSize : number;
  pageSizeOptions: number[];
  hideFiltersText: string;
  showFilteraText: string;
  tableArrow: any;
  isMobilePhone: boolean;
}

@Component({
  selector: 'app-mc-teacher',
  templateUrl: './mc-teacher.component.html',
  styleUrls: ['./mc-teacher.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' , padding:'3px' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MCTeacherComponent implements OnInit {

  @ViewChild(MCViewTeacherComponent) child: any;
  private _mcRouter: MCRouter;
  actionRequestToolbar: MCToolbarClass = new MCToolbarClass(MCConstants.teachers, MCConstants.select);

  attributesView = {
    idDeleteTeacher:  String(),
    filterCollapses: false,
    view_crud: false,
    isMobile: false,
    lengthPaginador: 10,
    pageIndex: 0,
    pageSize: 10,
    pageSizeOptions: [10, 20, 30, 50],
    hideFiltersText: MCConstants.hideFiltersText,
    showFilteraText: MCConstants.showFilteraText,
    tableArrow: null,
    isMobilePhone: false,
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  columnsToDisplay = ['tcfirst', 'tcrut', 'tcname','tcarrow'];
  listData : MCTeacherEntity[] = [];
  listDataTwo : MCTeacherEntity[] = [];
  listDataCopy : MCTeacherEntity[] = [];
  filter = {name: String(), title: String() };
  dialogRef : any;
  formModal: any = null;

  constructor(
    private _loading:  MCLoadingService,
    private dialog: MatDialog,
    public _route: Router,
    private _mcAlert: MCAlert,
    private _mcTeacherAPI: MCTeacherAPI,
    private _mcUtils: MCUtils

  ) {
      this._mcRouter = new MCRouter(_route);
      this.actionRequestToolbar = new MCToolbarClass(MCConstants.teachers, MCConstants.select);
  }

  ngOnInit(): void {
    this.ChangeMobile(window.innerWidth);
    this.getTeachersBeforePaging();
  }

  onResize(event: any) {
    this.ChangeMobile(event.target.innerWidth);
  }

  ChangeMobile(size: number) {
    this.attributesView.isMobile = size < 800;
    this.attributesView.isMobilePhone = size < 600;
    if (this.attributesView.isMobile == false) {
      this.columnsToDisplay = ['tc_first', 'tc_rut', 'tc_name', 'tc_mobile', 'tc_email','tc_arrow'];
    } else {
      if(this.attributesView.isMobilePhone == false) {
        this.columnsToDisplay = ['tc_first', 'tc_rut', 'tc_name', 'tc_mobile','tc_arrow'];
      } else {
        this.columnsToDisplay = ['tc_first', 'tc_rut', 'tc_name','tc_arrow'];
      }
    }
  }

  getTeachersBeforePaging() {
    this.listData = [];
    this.listDataTwo = [];
    this.listDataCopy = [];
    this._loading.callLoading();
    this._mcTeacherAPI.getTeachers(this.attributesView.pageIndex+1, this.attributesView.pageSize,
                                   this.filter.name, this.filter.title, data => {
      this.attributesView.lengthPaginador = data.total;
      this.listData = data.entities;
      this.listDataTwo = data.entities;
      this.listDataCopy = data.entities;
      this._loading.closeLoading();
    }, error => {
      this._loading.closeLoading();
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }

  getTeachersAfterPaging() {
    this._loading.callLoading();
    this.listDataTwo = [];
    this.listDataCopy = [];
    this._mcTeacherAPI.getTeachers(this.attributesView.pageIndex+1 ,this.attributesView.pageSize,
                                   this.filter.name, this.filter.title, data => {
      this.attributesView.lengthPaginador = data.total;
      this.listDataTwo = data.entities;
      this.listDataCopy = data.entities;
      for (let i: number = 0; i < data.entities.length; i++) {
        this.listData.push(data.entities[i]);
      }
      this._loading.closeLoading();
    }, error => {
      this._loading.closeLoading();
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }

  paginador(event: any) {
    if (this.attributesView.pageSize != event.pageSize) {
      this.attributesView.pageIndex = 0;
      this.attributesView.pageSize = event.pageSize;
      this.getTeachersBeforePaging();
    } else {
      this.attributesView.pageIndex = event.pageIndex;
      let numero: number = (this.attributesView.pageIndex + 1) * this.attributesView.pageSize;
      if ((numero > this.listData.length) && (this.listData.length < event.length)) {
        this.getTeachersAfterPaging();
      } else {
        this.localTableData();
      }
    }
  }

  //recargar datos de la tabla
  localTableData() {
    this.listDataTwo = [];
    this.listDataCopy = [];
    for (let i: number = 0; i < this.listData.length; i++) {
      let mult: number = this.attributesView.pageIndex * this.attributesView.pageSize;
      if ((i >= mult) && (i < (mult + this.attributesView.pageSize))) {
        this.listDataTwo.push(this.listData[i]);
        this.listDataCopy.push(this.listData[i]);
      }
    }
  }

  deleteTeacher() {
    this.closeModal();
    this._loading.callLoading();
    this._mcTeacherAPI.deleteTeacher(this.attributesView.idDeleteTeacher, () => {
      this.resfleshTable();
      this._mcAlert.showAlertInformation(MCMessagesEnum.globalDeleteData);
    }, error => {
      this._loading.closeLoading();
      if (error.objectMessage == undefined) {
        this.resfleshTable();
        this._mcAlert.showAlertInformation(MCMessagesEnum.globalDeleteData);
      } else {
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      }
    })
  }

  modifyTeacher(element: any) {
    this.actionRequestToolbar = new MCToolbarClass(MCConstants.teacher, MCConstants.modify);
    this.child.attributes.hidden = true;
    this.child.passTeacher(element);
    this.attributesView.view_crud = !this.attributesView.view_crud;
  }

  toolRespond($event : string) {
    if($event == MCConstants.select) {
      this.actionRequestToolbar = new MCToolbarClass(MCConstants.teachers, MCConstants.select);
      this.attributesView.view_crud = !this.attributesView.view_crud;
      this.child.attributes.hidden = false;
    } else {
      this.actionRequestToolbar = new MCToolbarClass(MCConstants.teacher, MCConstants.register);
      this.attributesView.view_crud = !this.attributesView.view_crud;
      this.child.attributes.hidden = true;
      this.child.resetTeacher();
    }
  }

  refleshTable(respondTeacher : MCTeacherEntity){
    for (let i: number = 0; i < this.listData.length; i++) {
      if(respondTeacher.id == this.listData[i].id){
        this.listData[i] = respondTeacher;
        break;
      }
    }

    for (let i: number = 0; i < this.listDataTwo.length; i++) {
      if(respondTeacher.id == this.listDataTwo[i].id){
        this.listDataTwo[i] = respondTeacher;
        this.listDataCopy[i] = respondTeacher;
        break;
      }
    }

    this._mcAlert.showAlertInformation(MCMessagesEnum.globalUpdateData);
  }

  teacherRespond($event : any) {
    let respondTeacher : MCTeacherEntity = this.child.teacher;
    this.child.attributes.hidden = false;
    this.child._loading.closeLoading();
    this.attributesView.view_crud = !this.attributesView.view_crud;
    this.actionRequestToolbar = new MCToolbarClass(MCConstants.teachers, MCConstants.select);
    if($event == MCConstants.register){
      this.cleanFilter();
      this._mcAlert.showAlertInformation(MCMessagesEnum.teacherCreated);
    } else if($event == MCConstants.modify) {
      this.refleshTable(respondTeacher);
    }
  }

  resfleshTable() {
    this.attributesView.pageIndex = 0;
    this.getTeachersBeforePaging();
  }

  deleteTeacherModal(templateRef : any, element: any) {
    this.dialogRef = this.dialog.open(templateRef, {
        width: '600px',
        minWidth: '300px',
        disableClose: true,
        backdropClass: 'dialog-bg-trans',
        panelClass: 'custom-dialog-container'
    });
    this.attributesView.idDeleteTeacher = element.id;
  }

  findFilter() {
    this.filter.title = this.filter.title.trim();
    this.filter.name = this.filter.name.trim();
    //if(this._mcUtils.validateFilter([this.filter.title, this.filter.name])){
      this.resfleshTable();
    //}
  }

  cleanFilter(){
    this.filter.title = String();
    this.filter.name = String();
    this.resfleshTable();
  }

  actionCollapses() {
    this.attributesView.filterCollapses = !this.attributesView.filterCollapses;
  }

  indicatorArrow(value : any){
    this.attributesView.tableArrow = value;
  }

  openModal(id: string) {
    this.formModal = new window.bootstrap.Modal(
      document.getElementById('staticBackdrop')
    );
    this.attributesView.idDeleteTeacher = id;
    this.formModal.show();
  }

  closeModal(){
    this.formModal.hide();
  }

}


// scrollTop(){
  //   setTimeout(() => {
  //     window.scrollTo(0, 0);
  //   },1);
  // }

/*   deleteTeacher() {
    this.loading.callLoading();
    this._mcTeacherAPI.deleteTeacher(this.idBorrarRelator, () => {
      this.dialogRef.close();
      this.loading.closeLoading();
      this._mcAlert.showAlertInformation(MCMessagesEnum.globalDeleteData);
    }, error => {
      console.log("error");
      this.dialogRef.close();
      this.loading.closeLoading();
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  } */
