<div *ngIf="attributes.hidden" class="m-0 row justify-content-center form-content-main">
  <div class="col-auto text-center" style="padding: 0.5rem;">
    <div class="box-form mat-elevation-z22">

      <form>
        <div class="row form-box-style">
          <div class="col-12">
            <mat-form-field>
              <mat-label>Código SENCE</mat-label>
              <input matInput type="text" maxlength="15" (paste)="false" name="code" [disabled]="attributes.codeChecked"
                [(ngModel)]="sence.code" (keyup)="capitalLetter('code')" required>
              <button mat-button matSuffix mat-icon-button aria-label="Find" class="btnBusquedas"
                [disabled]="attributes.codeChecked" [class.disabled-class]="attributes.codeChecked" (click)="codeValidate()">
                <mat-icon>search</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field appearance="legacy">
              <mat-label>Nombre</mat-label>
              <input matInput maxlength="230" name="name" [disabled]="!attributes.codeChecked" [(ngModel)]="sence.title" (keyup)="capitalLetter('title')"
                required>
            </mat-form-field>
          </div>

          <div class="col-12 aling-radio-button">
            <mat-radio-group [(ngModel)]="sence.mode">
              <mat-radio-button value="Presencial" [disabled]="!attributes.codeChecked">Presencial</mat-radio-button>
              <mat-radio-button value="Virtual" [disabled]="!attributes.codeChecked">Virtual</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-12">
            <mat-form-field appearance="legacy">
              <mat-label>Duración en Horas</mat-label>
              <input type="text" matInput maxlength="3" name="duration" [disabled]="!attributes.codeChecked"
                (keyup)="validateDuration($event)" [(ngModel)]="sence.duration" required>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field appearance="legacy">
              <mat-label>Fecha de Expiración</mat-label>
              <input matInput [matDatepicker]="picker" (keydown)="$event.preventDefault();"
                (click)="picker.open()" name="exdate" [disabled]="!attributes.codeChecked"
                [(ngModel)]="sence.expired_date" required>
              <!-- <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle> -->
              <span matSuffix (click)="picker.open()">
                <svg viewBox="0 0 24 24" width="24px" height="24px" focusable="false"
                  class="mat-datepicker-toggle-default-icon ng-star-inserted"
                  [style.fill]="attributes.codeChecked == true ? '#4169E1': 'rgba(0,0,0,.38)'">
                  <path
                    d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z">
                  </path>
                </svg>
              </span>
              <mat-datepicker #picker [disabled]="!attributes.codeChecked"></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field appearance="legacy">
              <mat-label>Relator</mat-label>
              <mat-select disableRipple [disabled]="!attributes.codeChecked"
                [(ngModel)]="attributes.selectedValueTeacher" (selectionChange)="changeSelect($event,12)" name="relator"
                required>
                <mat-option value="notselected">-- Relator --</mat-option>
                <mat-option *ngFor="let rep of listTeacher; let iv = index" [value]="rep.id.toString()">{{rep?.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>


        </div>
      </form>
      <mat-divider></mat-divider>
      <div class="d-flex justify-content-between box-form-footer">
        <div>
          <button mat-raised-button color="primary" [disabled]="!attributes.codeChecked" (click)="actionButton('')">
            <mat-icon>save</mat-icon> Guardar
          </button>
        </div>
        <div class="separater"></div>
        <div>
          <button mat-raised-button color="accent" (click)="actionButton('Select')">
            <mat-icon>close</mat-icon> Cerrar
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
