import { Component, OnInit, ViewChild } from '@angular/core';
import { MCToolbarClass } from 'src/app/mc-helpers/mc-class/mc-toolbar-class';
import { MCConstants } from 'src/app/mc-helpers/mc-constants/mc-constants';
import { MCViewStudentComponent } from 'src/app/mc-component/mc-view-student/mc-view-student.component';
import { MCLoadingService } from 'src/app/mc-service/mc-loading.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { MCAlert } from 'src/app/mc-helpers/mc-alert';
import { MCUtils } from 'src/app/mc-helpers/mc-class/mc-utils';
import { MatPaginator } from '@angular/material/paginator';
import { MCInscriptionStudentEntity } from 'src/app/mc-entity/mc-entities/mc-inscription-student-entitys';
import { MCRouter } from 'src/app/mc-helpers/mc-class/mc-router';
import { MCMessagesEnum } from 'src/app/mc-helpers/mc-enums/mc-messages-enum';
import { MCInscriptionStudentAPI } from 'src/app/mc-repository/mc-api/mc-inscription-students-api';
import { MCBasicCourseEntity } from 'src/app/mc-entity/mc-entities/mc-basic-course-entitys';
import { MCBasicCourseAPI } from 'src/app/mc-repository/mc-api/mc-basic-course-api';
import { MCStudentAPI } from 'src/app/mc-repository/mc-api/mc-student-api';
declare var window: any;
declare var require: any;
const { format } = require('rut.js');

export interface filter {
  name: string;
  last_name: string;
  mothers_last_name: string;
  course_base: string;
  document: string;
}

export interface attributesView {
  idDeleteStudent: string;
  filterCollapses: boolean;
  view_crud: boolean;
  isMobile: boolean;
  lengthPaginador: number;
  pageIndex: number;
  pageSize: number;
  pageSizeOptions: number[];
  hideFiltersText: string;
  showFilteraText: string;
  tableArrow: any;
  isMobileTwo: boolean;
}

@Component({
  selector: 'app-mc-student',
  templateUrl: './mc-student.component.html',
  styleUrls: ['./mc-student.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MCStudentComponent implements OnInit {

  @ViewChild(MCViewStudentComponent) child: any;
  statu: boolean = false;
  actionRequestToolbar: MCToolbarClass = new MCToolbarClass(MCConstants.students, MCConstants.select);
  view_crud: boolean = false;
  listData: MCInscriptionStudentEntity[] = [];
  listDataTwo: MCInscriptionStudentEntity[] = [];
  listDataCopy: MCInscriptionStudentEntity[] = [];
  columnsToDisplay = ['sco_first', 'sco_last_name', 'sco_mothers_last_name', 'sco_name', 'sco_arrow'];
  private _mcRouter: MCRouter;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  formModal: any;
  listBasicCourse: MCBasicCourseEntity[] = [];


  filter = {
    name: String(),
    last_name: String(),
    mothers_last_name: String(),
    course_base: String(),
    document: String()
  };

  attributesView = {
    idDeleteStudent: String(),
    filterCollapses: false,
    view_crud: false,
    isMobile: false,
    lengthPaginador: 10,
    pageIndex: 0,
    pageSize: 10,
    pageSizeOptions: [10, 20, 30, 50],
    hideFiltersText: MCConstants.hideFiltersText,
    showFilteraText: MCConstants.showFilteraText,
    tableArrow: null,
    isMobileTwo: false
  }

  constructor(
    private _loading: MCLoadingService,
    public _route: Router,
    private _mcAlert: MCAlert,
    private _mcMCInscriptionStudentAPI: MCInscriptionStudentAPI,
    private _mcUtils: MCUtils,
    private _mcStudentAPI: MCStudentAPI,
    private _mcBasicCourseAPI: MCBasicCourseAPI,

  ) {
    this._mcRouter = new MCRouter(_route);
    this.actionRequestToolbar = new MCToolbarClass(MCConstants.students, MCConstants.select);
  }

  ngOnInit(): void {
    this.ChangeMobile(window.innerWidth);
    this.getInscriptionStudentsPaging();
    this.getBasicCourses();
  }

  getBasicCourses() {
    this.listBasicCourse = [];
    this._mcBasicCourseAPI.getBasicCourses(1, 10000, String(), String(), data => {
      this.listBasicCourse = data.entities;
    }, error => {
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }

  onResize(event: any) {
    this.ChangeMobile(event.target.innerWidth);
  }

  ChangeMobile(size: number) {
    this.attributesView.isMobileTwo = size < 1100;
    this.attributesView.isMobile = size < 800
    if (this.attributesView.isMobileTwo == false) {
      this.columnsToDisplay = ['sco_first', 'sco_last_name', 'sco_mothers_last_name', 'sco_name', 'sco_document',
        'sco_cust_document', 'sco_code_course', 'sco_scope', 'sco_region', 'sco_comune', 'sco_arrow'];
    } else {//movil
      if (this.attributesView.isMobile == false) {
        this.columnsToDisplay = ['sco_first', 'sco_last_name', 'sco_mothers_last_name', 'sco_name', 'sco_document',
          'sco_cust_document', 'sco_code_course', 'sco_scope', 'sco_arrow'];
      } else {
        this.columnsToDisplay = ['sco_first', 'sco_last_name', 'sco_mothers_last_name', 'sco_name', 'sco_arrow'];
      }
    }
  }

  gettInscriptionStudentsAfterPaging() {
    this._loading.callLoading();
    this.listDataTwo = [];
    this.listDataCopy = [];
    this._mcMCInscriptionStudentAPI.getAllStudents(
      this.attributesView.pageIndex + 1, this.attributesView.pageSize, this.filter.last_name,
      this.filter.mothers_last_name, this.filter.name, this.filter.course_base, this.filter.document, data => {
        this.attributesView.lengthPaginador = data.total;
        this.listDataTwo = data.entities;
        this.listDataCopy = data.entities;
        for (let i: number = 0; i < data.entities.length; i++) {
          this.listData.push(data.entities[i]);
        }
        this._loading.closeLoading();
      }, error => {
        this._loading.closeLoading();
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      })
  }

  getInscriptionStudentsPaging() {
    this.listData = [];
    this.listDataTwo = [];
    this.listDataCopy = [];
    this._loading.callLoading();
    this._mcMCInscriptionStudentAPI.getAllStudents(
      this.attributesView.pageIndex + 1, this.attributesView.pageSize, this.filter.last_name,
      this.filter.mothers_last_name, this.filter.name, this.filter.course_base, this.filter.document, data => {
        this.attributesView.lengthPaginador = data.total;
        this.listData = data.entities;
        this.listDataTwo = data.entities;
        this.listDataCopy = data.entities;
        this._loading.closeLoading();
      }, error => {
        this._loading.closeLoading();
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      })
  }

  paginador(event: any) {
    if (this.attributesView.pageSize != event.pageSize) {
      this.attributesView.pageIndex = 0;
      this.attributesView.pageSize = event.pageSize;
      this.getInscriptionStudentsPaging();
    } else {
      this.attributesView.pageIndex = event.pageIndex;
      let numero: number = (this.attributesView.pageIndex + 1) * this.attributesView.pageSize;
      if ((numero > this.listData.length) && (this.listData.length < event.length)) {
        this.gettInscriptionStudentsAfterPaging();
      } else {
        this.localTableData();
      }
    }
  }

  //recargar datos de la tabla
  localTableData() {
    this.listDataTwo = [];
    this.listDataCopy = [];
    for (let i: number = 0; i < this.listData.length; i++) {
      let mult: number = this.attributesView.pageIndex * this.attributesView.pageSize;
      if ((i >= mult) && (i < (mult + this.attributesView.pageSize))) {
        this.listDataTwo.push(this.listData[i]);
        this.listDataCopy.push(this.listData[i]);
      }
    }
  }

  modifyStudent(element: any) {
    this.actionRequestToolbar = new MCToolbarClass(MCConstants.student, MCConstants.modify);
    this.child.attributes.hidden = true;
    this.child.passStudent(element.student, element.inscription.customer.name);
    this.attributesView.view_crud = !this.attributesView.view_crud;
  }

  toolRespond($event: string) {
    if ($event == MCConstants.select) {
      this.actionRequestToolbar = new MCToolbarClass(MCConstants.students, MCConstants.select);
      this.attributesView.view_crud = !this.attributesView.view_crud;
      this.child.attributes.hidden = false;
    }
  }

  studentRespond($event: any) {
    this.child.attributes.hidden = false;
    this.child._loading.closeLoading();
    this.attributesView.view_crud = !this.attributesView.view_crud;
    this.actionRequestToolbar = new MCToolbarClass(MCConstants.students, MCConstants.select);
    if ($event == MCConstants.modify) {
      this.resfleshTable();
      this._mcAlert.showAlertInformation(MCMessagesEnum.globalUpdateData);
    }
  }

  resfleshTable() {
    this.attributesView.pageIndex = 0;
    this.getInscriptionStudentsPaging();
  }

  findFilter() {
    if(this.filter.document == "-"){ this.filter.document = String(); }
    this.filter.last_name = this.filter.last_name.trim();
    this.filter.mothers_last_name = this.filter.mothers_last_name.trim();
    this.filter.name = this.filter.name.trim();
    this.filter.course_base = this.filter.course_base.trim();
    this.resfleshTable();
  }

  cleanFilter() {
    this.filter.last_name = String();
    this.filter.mothers_last_name = String();
    this.filter.name = String();
    this.filter.course_base = String();
    this.filter.document = String();
    this.resfleshTable();
  }

  actionCollapses() {
    this.attributesView.filterCollapses = !this.attributesView.filterCollapses;
  }

  indicatorArrow(value: any) {
    this.attributesView.tableArrow = value;
  }

  openModal(element: any) {
    this.formModal = new window.bootstrap.Modal(
      document.getElementById('staticBasicCourse')
    );
    this.attributesView.idDeleteStudent = element.student.id;
    this.formModal.show();
  }

  closeModal() {
    this.formModal.hide();
  }

  deleteStudent() {
    this.closeModal();
    this._loading.callLoading();
    this._mcStudentAPI.deleteStudent(this.attributesView.idDeleteStudent, () => {
      this.resfleshTable();
      this._mcAlert.showAlertInformation(MCMessagesEnum.globalDeleteData);
    }, error => {
      this._loading.closeLoading();
      if (error.objectMessage == undefined) {
        this.resfleshTable();
        this._mcAlert.showAlertInformation(MCMessagesEnum.globalDeleteData);
      } else {
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      }
    })
  }

  formatRut(event: any) {
    let next : boolean = false;
    (this._mcUtils.validateRut(event) == false) ? (next = false , event.preventDefault()) :  next = true;
    if (next) {
      this.filter.document = format(event.target.value);
    }
  }

}
