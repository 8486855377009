<app-mc-toolbar [actionRequest]="actionRequestToolbar" (toolRespond)="toolRespond($event)" class="fixed-top up-div">
</app-mc-toolbar>
<div class="mobileClassTool">
  <app-mc-view-email (emailRespond)="emailRespond($event)"></app-mc-view-email>

  <div *ngIf="!attributesView.view_crud">
    <div class="mainDivContent">
      <div *ngIf="!attributesView.filterCollapses">
        <div class="d-flex flex-wrap pe-3">
          <div><button class="btn btn-outline-dark btnDarkCollapse"
              (click)="actionCollapses()">{{attributesView.showFilteraText}} <i
                class="bi bi-caret-down-fill"></i></button></div>
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li *ngIf="filter.selectedValueAudience != 'notselected'" class="breadcrumb-item">Dependencia:
                  <span>{{filter.selectedValueAudience}}</span>
                <li *ngIf="filter.selectedValueRegion != 'notselected'" class="breadcrumb-item">Región:
                  <span>{{filter.selectedValueRegion}}</span>
                <li *ngIf="filter.selectedValueCommune != 'notselected'" class="breadcrumb-item">Comuna:
                  <span>{{filter.selectedValueCommune}}</span>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <button *ngIf="attributesView.filterCollapses" class="btn btn-outline-dark btnDarkCollapse"
        (click)="actionCollapses()">
        {{attributesView.hideFiltersText}} <i class="bi bi-caret-up-fill"></i>
      </button>
    </div>
    <div *ngIf="attributesView.filterCollapses" class="mat-elevation-z8 mainFilterStyle">
      <div>
        <div class="row">

          <div class="col-12 col-sm-6 col-lg-4">
            <mat-form-field appearance="legacy">
              <mat-label>Audiencia</mat-label>
              <mat-select disableRipple [(ngModel)]="filter.selectedValueAudience" name="audience"
                (ngModelChange)="findFilter()">
                <mat-option value="notselected">-- Audiencia --</mat-option>
                <mat-option value="Institución">Institución</mat-option>
                <mat-option value="Natural">Natural</mat-option>
                <mat-option value="Participante">Participante</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-lg-4">
            <mat-form-field appearance="legacy">
              <mat-label>Región</mat-label>
              <mat-select disableRipple [(ngModel)]="filter.selectedValueRegion"
                (selectionChange)="changeSelect($event,12)" name="reg" (ngModelChange)="findFilter()">
                <mat-option value="notselected">-- Región --</mat-option>
                <mat-option *ngFor="let reg of listRegion; let iv = index" [value]="reg.id">{{reg?.id}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-lg-4">
            <mat-form-field appearance="legacy">
              <mat-label>Comuna</mat-label>
              <mat-select disableRipple [(ngModel)]="filter.selectedValueCommune"
                (selectionChange)="changeSelect($event,13)" name="com" (ngModelChange)="findFilter()">
                <mat-option value="notselected">-- Comuna --</mat-option>
                <mat-option *ngFor="let com of listCommune" [value]="com.name">{{com?.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12" style="margin-top: 15px;">
            <button mat-raised-button color="primary" (click)="findFilter()">
              <mat-icon>search</mat-icon>Buscar
            </button>
            <button mat-raised-button class="btnFilter" (click)="cleanFilter()">
              <mat-icon>brush</mat-icon>Limpiar
            </button>
          </div>
        </div>
      </div>
    </div>

    <button class="btn btn-outline-dark btnDarkCollapse" (click)="exportAsExcelFile()" style="margin-left: 10px; margin-top: 10px;"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-file-earmark-excel" viewBox="0 0 16 16">
      <path d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z"/>
      <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
    </svg> Excel</button>
    <div class="before-table mat-elevation-z8">
      <div class="before-table-div">

        <table id="excel-table" mat-table (window:resize)="onResize($event)" [dataSource]="listDataTwo"
          multiTemplateDataRows>

          <!-- <ng-container matColumnDef="ma_first">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="modifyBasicCourse(element)">
                  <mat-icon>create</mat-icon>
                  <span>Modificar</span>
                </button>
              </mat-menu>

            </td>
          </ng-container> -->

          <ng-container matColumnDef="ma_rut">
            <th mat-header-cell *matHeaderCellDef> RUT </th>
            <td mat-cell *matCellDef="let element"> {{element?.document}} </td>
          </ng-container>

          <ng-container matColumnDef="ma_razonsocial">
            <th mat-header-cell *matHeaderCellDef> Razón social </th>
            <td mat-cell *matCellDef="let element"> {{element?.businessName}} </td>
          </ng-container>

          <ng-container *ngIf="!attributesView.isMobile" matColumnDef="ma_audience">
            <th mat-header-cell *matHeaderCellDef> Audiencia </th>
            <td mat-cell *matCellDef="let element"> {{element?.audience}} </td>
          </ng-container>

          <ng-container *ngIf="!attributesView.isMobile" matColumnDef="ma_region">
            <th mat-header-cell *matHeaderCellDef> Región </th>
            <td mat-cell *matCellDef="let element"> {{element?.commune?.region?.id}} </td>
          </ng-container>

          <ng-container *ngIf="!attributesView.isMobile" matColumnDef="ma_comune">
            <th mat-header-cell *matHeaderCellDef> Comuna </th>
            <td mat-cell *matCellDef="let element"> {{element?.commune?.name}} </td>
          </ng-container>

          <ng-container matColumnDef="ma_arrow">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <mat-icon *ngIf="attributesView.tableArrow != element">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="attributesView.tableArrow == element">keyboard_arrow_up</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
              <div class="example-element-detail" [@detailExpand]="element == listDataCopy ? 'expanded' : 'collapsed'">
                <div style="width: 100%; padding: 3px !important;">
                  <div class="row g-0 mat-elevation-z8">

                    <div class="col-12 p-2">
                      <div class="row">
                        <div class="col-12" style="padding-top: 20px;">
                          <fieldset class="scheduler-border fieldOld">
                            <legend class="scheduler-border"
                              style="margin-top: -15px !important; width: 100px !important;"> Información
                            </legend>
                            <div>
                              <div class="row">
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa"> RUT: </span> {{element?.document}}
                                  </mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa">	Razón social: </span> {{element?.businessName}}
                                  </mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa"> Correo: </span> {{element?.email}}
                                  </mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa">	Audiencia: </span> {{element?.audience}}
                                  </mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa">	Región: </span> {{element?.commune?.region?.id}}
                                  </mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa">	Comuna: </span> {{element?.commune?.name}}
                                  </mat-list-item>
                                </div>

                              </div>

                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>


              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
            [class.example-expanded-row]="listDataCopy === element"
            (click)="listDataCopy = listDataCopy === element ? null : element; indicatorArrow(listDataCopy != element ? null : element)">
          </tr>

          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

        </table>

        <mat-paginator #paginator [length]="attributesView.lengthPaginador" [pageIndex]="attributesView.pageIndex"
          [pageSize]="attributesView.pageSize" [pageSizeOptions]="attributesView.pageSizeOptions"
          (page)="paginador($event)">
        </mat-paginator>

      </div>
    </div>

  </div>

</div>
