import { HttpHeaders, HttpParams } from "@angular/common/http";
import { MCHttpMethodsEnum } from "src/app/mc-helpers/mc-enums/mc-http-methods-enum"; 

/**
 * enableObserve  true si desea reglesar toda la respuesta incuidas las header.
 */
export class MCDataSourceAPICoreBuilder {
    // Public properties.

    endpoint = String()
    enableObserve = false  
    httpMethod = MCHttpMethodsEnum.get
    httpHeaders?: HttpHeaders
    httpParams?: HttpParams
    body?: string
    formData?: FormData
}