import { MCConstants } from "src/app/mc-helpers/mc-constants/mc-constants";
import { MCAcademicTitleEntity } from "./mc-academic-title-entitys";
import { MCCommuneEntity } from "./mc-commune-entitys";
import { MCSenceEntity } from "./mc-sence-entitys";

export class MCTeachersEntity {
    // Properties.

    total: number;
    entities: MCTeacherEntity[] = [];

    // Constructors.

    constructor(total: number, object: any) {
        this.total = total ?? 0;
        if (object) {
            for (let entity of object) {
                this.entities.push(new MCTeacherEntity(entity));
            }
        }
    }
}

export class MCTeacherEntity {
    id: number;
    document_type: string;
    document: string;
    name: string;
    sex: string;
    curriculum: string;
    mobile: string;
    email: string;
    address: string;
    observation: string;
    commune: MCCommuneEntity;
    academic_titles: MCAcademicTitleEntity[];
    sences: MCSenceEntity[];

    constructor(object: any) {
        this.id = object.id ?? 0;
        this.document_type = object.document_type ?? MCConstants.rut;
        this.document = object.document ?? String();
        this.name = object.name ?? String();
        this.sex = object.sex ?? MCConstants.men;
        this.curriculum = object.curriculum ?? String();
        this.mobile = object.mobile ?? String();
        this.email = object.email ?? String();
        this.address = object.address ?? String();
        this.observation = object.observation ?? String();
        this.commune = object.commune ?? new MCCommuneEntity;
        this.sences = object.sences ?? [];
        this.academic_titles = object.academic_titles ?? [];
    }
}

export class MCTeacherEntityRequests {
    commune_id: number;
    document_type: string;
    document: string;
    name: string;
    sex: string;
    curriculum: string;
    mobile: string;
    email: string;
    address: string;
    observation: string;
    academic_titles:number[];

    constructor(object: any, idCommune: number, listAcademicTitles: any) {
        this.commune_id = idCommune;
        this.document_type = object.document_type;
        this.document = object.document;
        this.name = object.name;
        this.sex = object.sex;
        this.curriculum = object.curriculum;
        this.mobile = object.mobile;
        this.email = object.email;
        this.address = object.address;
        this.observation = object.observation;
        this.academic_titles = listAcademicTitles;
    }
}
