import { MCEmailEntity } from "./mc-emails-entitys";

export class MCJsonToExcelEmailsEntity {

  transforData(emails: MCEmailEntity[]): any {
    let jsonToExcel: MCJsonToExcelEmailEntity[] = [];
    if (emails) {
      for (let entity of emails) {
        jsonToExcel.push(new MCJsonToExcelEmailEntity(entity));
      }
    }
    return jsonToExcel;
  }

}

export class MCJsonToExcelEmailEntity {
  RUT: string;
  Razón_Social: string;
  Correo: string;
  Audiencia: string;
  Región: string;
  Comuna: string;

  constructor(object: any) {
    this.RUT = object.document;
    this.Razón_Social = object.businessName;
    this.Correo = object.email;
    this.Audiencia = object.audience;
    this.Región = object.commune?.region?.id ?? String();
    this.Comuna = object.commune?.name ?? String();
  }
}
