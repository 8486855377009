export enum MCMessagesEnum {

    //global
    globalRequiredFields = "*Campo Obligatorio",
    globalRequiredFieldsAlert = "Los campos con * son obligatorios",
    globalExpiredToken = "Su sesión ha expirado",
    globalCurrentError= "Ha ocurrido un error",
    globalRefreshPage = "Ha ocurrido un error al actualizar los datos, se recomienda actualizar la página, puede pulsar la tecla f5.",
    globalDeleteData = "¡Datos eliminados de forma exitosa!",
    globalUpdateData = "¡Datos modificados de forma exitosa!",
    globalEmptyFilters = "No hay registro para los criterios de filtro aplicado",
    globalInvalidEmailFormat = "Formato de correo inválido",
    globalPlaceholderRut = "Ingresa tu RUT",
    globalPlaceholderPas = "Ingresa tu Pasaporte",
    globalEmptyRut = "Debe escribir el RUT",
    globalEmptyPas = "Debe escribir el pasaporte",
    globalInvalidRut = "RUT inválido, por favor verificar",
    globalPhoneLength = "El número de celular debe contener 9 números",
    globalPhoneInvalid = "El número de celular es incorrecto",
    rutNotExist = "El RUT no se encuentra registrado",
    rutExist = "El RUT ya se encuentra registrado",
    durationZero = "La duración debe ser mayor de 0 horas",
    errorRefleshToken = "Ha ocurrido un error al actualizar la sesión",
    studentRegisterExist = "El participante ya está inscripto en este curso",
    studentRegisterExistNotInscription = "El participante existe pero no esta inscrito en este curso complete los campos e inscriba",
    globalRemoveInscriptionStudent = "¡El participante se desinscribió del curso!",
    globalRemoveInscriptionCustomer = "¡El cliente se desinscribió del curso!",
    incorrectEmailPassword = "Correo y/o contraseña son incorrectos",

    codeSenceFind = "El código sence no puede ser vacío",
    codeSenceExist = "El código sence ya se encuentra registrado",
    teacherCreated = "Relator registrado",
    senceCreated = "Sence registrado",
    customerCreated = "Cliente registrado",
    basicCourseCreated = "Curso registrado",
    openCourseCreated = "Curso registrado",
    codeBasicCourseFind = "El código no puede ser vacío",
    codeBasicCourseExist = "El código del curso base ya se encuentra registrado",
    enrolledCustomer = "El cliente se ha inscripto",
    enrolledStident = "El estudiante se ha inscripto",
    payUpdate = "Información de pago actualizada",
    courseCloseOneCliente = "Un curso cerrado solo permite inscribir un cliente",

    senceCodeFieldEmpty = "El campo código sence es obligatorio",
    senceNameFieldEmpty = "El campo nombre es obligatorio",
    senceDurationFieldEmpty ="El campo Duración en Horas es obligatorio y no puede ser menor de 1",
    senceExpirationDateMinorDate = "El campo Fecha de Expiración no puede ser menor a la fecha actual",
    senceTeacherSeleted = "Debe seleccionar un relator",

    teacherDocumentFieldEmpty = "El campo RUT es obligatorio",
    teacherNameFieldEmpty = "El campo Nombres y Apellidos es obligatorio",
    teacherListTitlesEmpty = "Debe seleccionar un Título Profesional y hacer clic en el botón '+'",
    teacherCurriculumFieldEmpty = "El campo Curriculum para Diploma es obligatorio",
    teacherMobilFieldEmpty = "El campo Celular es obligatorio",
    teacherEmailFieldEmpty = "El campo Correo Electrónico es obligatorio",
    teacherRegionSeleted = "Debe seleccionar una Región",
    teacherComunaSeleted = "Debe seleccionar una Comuna",

    courseBaeCodeFieldEmpty = "El campo Código es obligatorio",
    courseBaseNameFieldEmpty = "El campo Nombre es obligatorio",
    courseBaseTitleFieldEmpty = "El campo Título para el Diploma es obligatorio",

    studentDocumentFieldEmpty = "El campo RUT es obligatorio",
    studentNameFieldEmpty = "El campo Nombres es obligatorio",
    studentLastNameFieldEmpty = "El campo Apellido Paterno es obligatorio",
    studentMothersLastNameFieldEmpty = "El campo Apellido Materno es obligatorio",
    studentProfessionFieldEmpty = "El campo Profesión/Cargo es obligatorio",
    studentEmailFieldEmpty = "El campo Correo Electrónico es obligatorio",
    studentIntitutionNotSeleted = "Debe selecionar una Institución",

    customerDocumentFieldEmpty = "El campo RUT es obligatorio",
    customerNameFieldEmpty = "El campo Razón Social es obligatorio",
    customerBusinessNameFieldEmpty = "El campo Nombre de la Institución es obligatorio",
    customerContactFieldEmpty = "El campo Persona de Contacto es obligatorio",
    customerDependencieSeleted = "Debe seleccionar una Dependencia",
    customerMobilFieldEmpty = "El campo Celular es obligatorio",
    customerEmailFieldEmpty = "El campo Correo Electrónico es obligatorio",
    customerRegionSeleted = "Debe seleccionar una Región",
    customerComunaSeleted = "Debe seleccionar una Comuna",
    customerNameNaturalFieldEmpty = "El campo Nombres es obligatorio",
    customerLastNameFieldEmpty = "El campo Apellido Paterno es obligatorio",
    customerMothersLastNameFieldEmpty = "El campo Apellido Materno es obligatorio",
    customerProfessionFieldEmpty = "El campo Profesión/Cargo es obligatorio",

    coursesTeacherNotSeleted = "Debe seleccionar un Relator",
    coursesCourseBasicNotSeleted= "Debe seleccionar un Curso Base",
    coursesRegionNotSeleted = "Debe seleccionar una Región",
    coursesComunaNotSeleted = "Debe seleccionar una Comuna",
    coursesListScheduleEmpty = "Debe Agregar por lo menos un horario",
    coursesDurationFieldEmpty ="El campo Duración del Curso es obligatorio y no puede ser menor de 1",
    coursesNameFieldEmpty = "El campo Nombre es obligatorio",
    coursesTitleFieldEmpty = "El campo Título para el Diploma es obligatorio",
    coursesPlaceFieldEmpty = "El campo Lugar es obligatorio",
    coursesAddressFieldEmpty = "El campo Dirección es obligatorio",
    coursesStartDateFieldEmpty = "El campo Fecha de Inicio es obligatorio",
    coursesFinalDateFieldEmpty = "El campo Fecha de Término es obligatorio",
    coursesDeadlineFieldEmpty = "El campo Fecha de Cierre de Incripción es obligatorio",
    coursesMaterialDateFieldEmpty = "El campo Fecha Material de Asistencia es obligatorio",
    coursesMaxHours = "Ha Superado el numero de horas del curso, por favor revisa la lista de horarios",

}
