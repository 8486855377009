const MCAPIConstants = {
    configuration: "/configurations",
    authentication: "/auth",
    customer: "/customers",
    teacher: "/teachers",
    student: "/students",
    sence: "/sences",
    basicCourse: "/courses",
    openCourses: "/open_courses",
    inscriptionStudents: "/inscription_students",
    email: "/emails"
}

const MCAPIConfigurationsConstants = {
    regions: MCAPIConstants.configuration+ "/regions",
}

const MCAPIAuthenticationConstants = {
    login: MCAPIConstants.authentication + "/login",
    logout: MCAPIConstants.authentication + "/logout",
    refresh: MCAPIConstants.authentication + "/refresh"
}

const MCAPICustomersConstants = {
    customers: MCAPIConstants.customer,
    customer: MCAPIConstants.customer + "/[ID]",
    customersNatural: MCAPIConstants.customer + "/natural",
    customerNatural: MCAPIConstants.customer + "/natural/[ID]",
    customersLegal: MCAPIConstants.customer + "/legal",
    customerLegal: MCAPIConstants.customer + "/legal/[ID]",
    dependency: MCAPIConstants.customer + "/dependencies"
}

const MCAPITeachersConstants = {
    teachers: MCAPIConstants.teacher,
    teacher: MCAPIConstants.teacher + "/[ID]",
    academicTitles: MCAPIConstants.teacher + "/academic-titles"
}

const MCAPIStudentsConstants = {
  students: MCAPIConstants.student,
  student: MCAPIConstants.student + "/[ID]",
}

const MCAPISencesConstants = {
    sences: MCAPIConstants.sence,
    sence: MCAPIConstants.sence + "/[ID]"
}

const MCAPIBasicCourseConstants = {
    basicCourses: MCAPIConstants.basicCourse,
    basicCourse: MCAPIConstants.basicCourse + "/[ID]"
}

const MCAPIOpenCoursesConstants = {
  openCourses: MCAPIConstants.openCourses,
  openCourse: MCAPIConstants.openCourses + "/[ID]",
  postOpenCourseSchedule: MCAPIConstants.openCourses + "/[ID]/schedules" ,
  deleteOpenCourseSchedule: MCAPIConstants.openCourses + "/[ID]/schedules/[IDS]",
  postCustomersOpenCourse: MCAPIConstants.openCourses + "/[ID]/inscriptions",
  getAllCustomersOpenCourse: MCAPIConstants.openCourses + "/[ID]/inscriptions",
  putCustomerPaidOpenCourse: MCAPIConstants.openCourses + "/[ID]/inscriptions/[IDS]",
  getCustomersOpenCourseExist: MCAPIConstants.openCourses + "/[ID]/customers/[IDS]",
  postStudentOpenCourse: MCAPIConstants.openCourses + "/[ID]/inscriptions/[IDS]/students",
  getStudentsOpenCourse: MCAPIConstants.openCourses + "/[ID]/students/[IDS]",
  deleteStudentsOpenCourse: MCAPIConstants.openCourses + "/[ID]/inscriptions/[IDS]/inscriptions_students/[IDI]",
  deleteCustomerOpenCourse: MCAPIConstants.openCourses + "/[ID]/inscriptions/[IDS]",
}

const MCAPIInscriptionStudentsConstants = {
  inscriptionStudents: MCAPIConstants.inscriptionStudents,
}

const MCAPIEmailConstants = {
  emails: MCAPIConstants.email,
}

export const MCEndpointsConstants = {
    configurationAPI: MCAPIConfigurationsConstants,
    authenticationAPI: MCAPIAuthenticationConstants,
    customerAPI: MCAPICustomersConstants,
    teacherAPI: MCAPITeachersConstants,
    studentAPI: MCAPIStudentsConstants,
    senceAPI: MCAPISencesConstants,
    basicCourseAPI: MCAPIBasicCourseConstants,
    openCoursesAPI: MCAPIOpenCoursesConstants,
    inscriptionStudentsAPI: MCAPIInscriptionStudentsConstants,
    emailAPI: MCAPIEmailConstants
}
