import { Injectable } from '@angular/core'
import { MCScheduleEntity } from 'src/app/mc-entity/mc-entities/mc-schedules-entitys';
import { MCAlert } from '../mc-alert';
import { MCConstants } from '../mc-constants/mc-constants';
import { MCMessagesEnum } from '../mc-enums/mc-messages-enum';
import { MCError } from '../mc-error';
import { MCErrorTypeEnum } from '../mc-enums/mc-error-type-enum';
import { MCRouter, MCRouterData } from './mc-router';

@Injectable({
  providedIn: 'root'
})
export class MCUtils {

  emailPattern: RegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  phonePattern: RegExp = /[0-9]{9}/;

  onlyNumber(value: any): boolean {
    let key = value.keyCode;
    if (key == 8) { return true; }
    if ((key >= 48 && key <= 57) || (key >= 96 && key <= 105)) { return true; }
    return false;
  }

  validateRut(value: any): boolean {
    let key = value.keyCode;
    if (key == 75) { return true; }
    if (key == 8) { return true; }
    if ((key >= 48 && key <= 57) || (key >= 96 && key <= 105)) { return true; }
    return false;
  }

  validatePhone(value: string): boolean {
    return (value == String()) ? false : (this.phonePattern.test(value));
  }

  validatePhoneKeyMessage(value: string): string {
    if (value == String()) {
      return MCMessagesEnum.globalRequiredFields;
    } else {
      return (!this.phonePattern.test(value)) ? MCMessagesEnum.globalPhoneLength : String();
    }
  }

  validateEmail(value: string): boolean {
    return (value == String()) ? false : (this.emailPattern.test(value));
  }

  validateEmailKeyMessage(value: string): string {
    if (value == String()) {
      return String();
    } else {
      return (!this.emailPattern.test(value)) ? MCMessagesEnum.globalInvalidEmailFormat : String();
    }
  }

  validateDate(valor: string): boolean {
    let dateActual = new Date().toISOString().split('T')[0];
    let date = new Date(valor).toISOString().split('T')[0];
    let da = new Date(dateActual);
    let dr = new Date(date);
    if (da == dr) {
      return false;
    } else {
      return (da < dr);
    }
  }

  transformDateNumberString(value: number): string {
    let date = new Date(value * 1000);
    return date.toLocaleDateString('en-GB') + " " + date.toLocaleTimeString('en-GB').substring(0, 5);
  }

  transformDate(valor: string): string {
    return new Date(valor).toISOString().split('T')[0];
  }

  removeBlanks(value: string): string {
    return value = value.replace(/ /g, String());
  }

  lineBreak(value: string): string {
    return value.replace(/(\r\n|\n|\r)/gm, "");
  }

  validateFilter(array: string[]): boolean {
    let response = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i] != String()) {
        response = true;
        break;
      }
    }
    return response;
  }

  validateFormEmpty(array: string[]): boolean {
    let response = true;
    for (var i = 0; i < array.length; i++) {
      if (array[i].trim() == String()) {
        response = false;
        break;
      }
    }
    return response;
  }

  validateFormSelect(array: string[]): boolean {
    let response = true;
    for (var i = 0; i < array.length; i++) {
      if (array[i].trim() == MCConstants.notSelected) {
        response = false;
        break;
      }
    }
    return response;
  }

  validateNullUndefined(value: any): boolean {
    return (value != undefined) && (value != null);
  }

  validateStringUndefined(value: any): boolean {
    return (value != undefined) && (value != String());
  }

  validateStringUndefinedNull(value: any): boolean {
    return (value != null) && (value != String()) && (value != undefined);
  }

  validateStringUndefinedNotselected(value: any): boolean {
    return (value != String()) && (value != undefined) && (value != MCConstants.notSelected);
  }


  upperLowerCaseWord(value: string) {
    let palabras = value.split(" ");
    palabras.map((palabra) => {
      return palabra[0].toUpperCase() + palabra.substring(1);
    }).join(" ");
  }


  listTimeTwentyFourHours(value: MCScheduleEntity[]): MCScheduleEntity[] {
    console.log(value);
    let listSchedule: MCScheduleEntity[] = [];
    for (let schedule of value) {
      if (schedule.id != 0 && schedule.id != null && schedule.id != undefined) {
        listSchedule.push(new MCScheduleEntity(
          {
            "id": Number(schedule.id),
            "day": this.transformDate(schedule.day),
            "start_time": this.timeTwentyFourHours(schedule.start_time),
            "final_time": this.timeTwentyFourHours(schedule.final_time)

          }
        ));
      } else {
        listSchedule.push(new MCScheduleEntity(
          {
            "day": this.transformDate(schedule.day),
            "start_time": this.timeTwentyFourHours(schedule.start_time),
            "final_time": this.timeTwentyFourHours(schedule.final_time)
          }
        ));
      }
    }
    console.log(listSchedule);
    return listSchedule;
  }


  timeTwentyFourHours(value: string) {
    let ampm = value.split(" ");
    let hhmm = ampm[0];
    let hh = hhmm.split(":");
    if (ampm[1] == 'AM') {
      if (hh[0] == '12') {
        return "00:" + hh[1];
      } else {
        return (hh[0] + ":" + hh[1]);
      }
    } else {
      if (hh[0] == '12') {
        return "12:" + hh[1];
      } else {
        return Number(hh[0]) + 12 + ":" + hh[1];
      }
    }
  }

  schedulesTimeTwelveHours(value: any): MCScheduleEntity[] {
    if (value == undefined || value.length == 0) {
      return [];
    } else {
      for (let i: number = 0; i < value.length; i++) {
        value[i].start_time = this.timeTwelveHours(value[i].start_time);
        value[i].final_time = this.timeTwelveHours(value[i].final_time);
      }
      return value;
    }
  }

  timeTwelveHours(value: any): string {
    let ti = value.split(":");
    if (ti[0] > 12) {
      if (ti[0] < 22) {
        return ("0" + (ti[0] - 12) + ":" + ti[1] + " " + "PM");
      } else {
        return ((ti[0] - 12) + ":" + ti[1] + " " + "PM");
      }
    } else if (ti[0] == "12") {
      return ((ti[0]) + ":" + ti[1] + " " + "PM");
    } else if (ti[0] == "00") {
      return ("12:" + ti[1] + " " + "AM");
    } else {
      return ((ti[0]) + ":" + ti[1] + " " + "AM");
    }
  }


  validateUndefinedNull(value: any): boolean {
    return (value != null) && (value != undefined);
  }

  handleError(
    error: MCError,
    router: MCRouter,
    alert: MCAlert
  ) {
    switch (error.errorType) {
      case MCErrorTypeEnum.all:
        alert.showAlertError(error.message)
        router.navigateToURL(new MCRouterData(error.route, error))
        break
      case MCErrorTypeEnum.alert:
        alert.showAlertError(error.message)
        break
      case MCErrorTypeEnum.redirect:
        router.navigateToURL(new MCRouterData(error.route, error))
        break
    }
  }
}
