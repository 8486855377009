<div *ngIf="attributes.hidden">
  <mat-tab-group (selectedTabChange)="clickTab($event)"  [class.invisible-tabs] = "attributes.modifyBasic == 'basic'" >
    <mat-tab>
      <ng-template mat-tab-label>
        <i class="bi bi-megaphone-fill icon-boostrap-tap"></i>&nbsp;{{titleFirst}}
      </ng-template>
      <div>
        <div class="m-0 row justify-content-center form-content-main">
          <div class="col-auto text-center" style="padding: 0.5rem;">
            <div class="box-form mat-elevation-z2">

              <form>
                <div class="row form-box-style">

                  <div class="col-12">
                    <mat-form-field appearance="legacy">
                      <mat-label>Curso Base</mat-label>
                      <mat-select disableRipple [(ngModel)]="attributes.selectedValueCourseBasic"
                        (selectionChange)="changeSelect($event,1)" name="coubas"
                        [disabled]="this.attributes.action == 'Modificar'" required>
                        <mat-option value="notselected">-- Seleccione --</mat-option>
                        <mat-option *ngFor="let cuba of listBasicCourse; let vx = index" [value]="cuba.id">{{cuba?.id}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-12">
                    <mat-form-field appearance="legacy">
                      <mat-label>Nombre:</mat-label>
                      <input matInput maxlength="100" name="cbname" [(ngModel)]="courses.name_course"
                            (keyup)="capitalLetter('name_course')" required>
                    </mat-form-field>
                  </div>

                  <div class="col-12">
                    <mat-form-field appearance="legacy">
                      <mat-label>Título Para el Diploma:</mat-label>
                      <input matInput maxlength="100" name="cbtirle" [(ngModel)]="courses.certificate_title_course"
                            (keyup)="capitalLetter('certificate_title_course')" required>
                    </mat-form-field>
                  </div>


                  <div class="col-12 aling-radio-button">
                    <mat-radio-group [(ngModel)]="courses.mode" name="rgdos">
                      <mat-radio-button [checked]="courses.mode == 'Presencial'" value='Presencial' name="Presencial">
                        Presencial</mat-radio-button>
                      <mat-radio-button [checked]="courses.mode == 'Virtual'" value='Virtual' name="Virtual">Virtual
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>

                  <div class="col-12">
                    <mat-form-field appearance="legacy">
                      <mat-label>Relator</mat-label>
                      <mat-select disableRipple [(ngModel)]="attributes.selectedValueTeacher"
                        (selectionChange)="changeSelect($event,2)" name="relatorcb" required>
                        <mat-option value="notselected">-- Relator --</mat-option>
                        <mat-option *ngFor="let rep of listTeacher; let iv = index" [value]="rep.id.toString()">
                          {{rep?.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-12">
                    <mat-form-field appearance="legacy">
                      <mat-label>Sence</mat-label>
                      <mat-select disableRipple [(ngModel)]="attributes.selectedValueSence" name="cseom">
                        <mat-option value="notselected">-- No Ocupa Sence --</mat-option>
                        <mat-option *ngFor="let sen of listSence; let v = index" [value]="sen.id?.toString()">
                          {{sen?.title}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-12">
                    <mat-form-field appearance="legacy">
                      <mat-label>{{attributes.labelPrice}}</mat-label>
                      <input matInput maxlength="11" name="prices"
                        (keydown)="formatAmount($event, 1,2)"
                        (keyup)="formatAmount($event, 2,2)"
                        [ngModel]="courses.price" required>
                    </mat-form-field>
                  </div>

                  <!-- <ng-container matColumnDef="valor" class="mat-column-costocomuna">
                    <th mat-header-cell *matHeaderCellDef > Costo </th>
                    <td mat-cell *matCellDef="let elemento">
                        <mat-form-field style="width: 100px !important;">
                            <input  matInput type="tel" maxlength="20" (paste)="false" (keydown)="formatAmount($event,elemento, 1,2)"
                                    (keyup)="formatAmount($event,elemento, 2,2)"  [value]="elemento.amount"  name="amount" autocomplete="off" required>
                        </mat-form-field>
                    </td>
                  </ng-container> -->



                  <div class="col-12">
                    <mat-form-field appearance="legacy">
                      <mat-label>Duración del Curso (Horas)</mat-label>
                      <input matInput type="text" maxlength="3" name="ddc" (keyup)="validateDuration($event)"
                        [(ngModel)]="courses.duration" required>
                    </mat-form-field>
                  </div>

                  <div class="col-12" *ngIf="courses.mode == 'Presencial'">
                    <mat-form-field appearance="legacy">
                      <mat-label>Región</mat-label>
                      <mat-select disableRipple [(ngModel)]="attributes.selectedValueRegion"
                        (selectionChange)="changeSelect($event,12)" name="reg" required>
                        <mat-option value="notselected">-- Región --</mat-option>
                        <mat-option *ngFor="let reg of listRegion; let iv = index" [value]="reg.id">{{reg?.id}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-12" *ngIf="courses.mode == 'Presencial'">
                    <mat-form-field appearance="legacy">
                      <mat-label>Comuna</mat-label>
                      <mat-select disableRipple [(ngModel)]="attributes.selectedValueCommune"
                        (selectionChange)="changeSelect($event,13)" name="com" required>
                        <mat-option value="notselected">-- Comuna --</mat-option>
                        <mat-option *ngFor="let com of listCommune" [value]="com.id.toString()">{{com?.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div *ngIf="courses.mode == 'Presencial'" class="col-12">
                    <mat-form-field appearance="legacy">
                      <mat-label>Lugar</mat-label>
                      <input matInput maxlength="100" name="logar" [(ngModel)]="courses.place" (keyup)="capitalLetter('place')" required>
                    </mat-form-field>
                  </div>

                  <div *ngIf="courses.mode == 'Presencial'" class="col-12">
                    <mat-form-field appearance="legacy">
                      <mat-label>Dirección</mat-label>
                      <input matInput maxlength="100" name="direc" [(ngModel)]="courses.address" (keyup)="capitalLetter('address')" required>
                    </mat-form-field>
                  </div>

                  <div *ngIf="courses.mode == 'Virtual'" class="col-12">
                    <mat-form-field appearance="legacy">
                      <mat-label>Link Zoom</mat-label>
                      <input matInput maxlength="100" name="zoomlin" [(ngModel)]="courses.course_link">
                    </mat-form-field>
                  </div>

                  <div class="col-12">
                    <mat-form-field appearance="legacy">
                      <mat-label>Fecha de Inicio</mat-label>
                      <input matInput [matDatepicker]="pickerfi" [disabled]="false" (keydown)="$event.preventDefault();"
                        (click)="pickerfi.open()" name="inidate" [(ngModel)]="courses.start_date" required>
                      <!-- <input matInput [matDatepicker]="picker" [disabled]="false" (keydown)="picker.open()" (click)="picker.open()" >  -->
                      <mat-datepicker-toggle matSuffix [for]="pickerfi" [disabled]="false"></mat-datepicker-toggle>
                      <mat-datepicker #pickerfi [disabled]="false"></mat-datepicker>
                    </mat-form-field>
                  </div>

                  <div class="col-12">
                    <mat-form-field appearance="legacy">
                      <mat-label>Fecha de Término</mat-label>
                      <input matInput [matDatepicker]="pickerft" [disabled]="false" (keydown)="$event.preventDefault();"
                        (click)="pickerft.open()" name="datter" [(ngModel)]="courses.final_date" required>
                      <mat-datepicker-toggle matSuffix [for]="pickerft" [disabled]="false"></mat-datepicker-toggle>
                      <mat-datepicker #pickerft [disabled]="false"></mat-datepicker>
                    </mat-form-field>
                  </div>

                  <div class="col-6" *ngIf="attributes.scopeLocal != 'Cerrado'">
                    <mat-form-field appearance="legacy">
                      <mat-label>Fecha de Cierre de Inscripción</mat-label>
                      <input matInput [matDatepicker]="pickerfhci" [disabled]="false"
                        (keydown)="$event.preventDefault();" (click)="pickerfhci.open()" name="fhci"
                        [(ngModel)]="attributes.deadline" required>
                      <mat-datepicker-toggle matSuffix [for]="pickerfhci" [disabled]="false"></mat-datepicker-toggle>
                      <mat-datepicker #pickerfhci [disabled]="false"></mat-datepicker>
                    </mat-form-field>
                  </div>

                  <div class="col-6" *ngIf="attributes.scopeLocal != 'Cerrado'">
                    <mat-form-field class="txtTime">
                      <mat-label>Hora de Cierre de Inscripción:</mat-label>
                      <input matInput type="time" [(ngModel)]="attributes.endHours">
                    </mat-form-field>
                  </div>

        <!--           <div class="col-12">
                    <mat-form-field appearance="legacy">
                      <mat-label>Fecha Material de Asistencia</mat-label>
                      <input matInput [matDatepicker]="pickerfma" [disabled]="false"
                        (keydown)="$event.preventDefault();" (click)="pickerfma.open()" name="asima"
                        [(ngModel)]="courses.material_date" required>
                      <mat-datepicker-toggle matSuffix [for]="pickerfma" [disabled]="false"></mat-datepicker-toggle>
                      <mat-datepicker #pickerfma [disabled]="false"></mat-datepicker>
                    </mat-form-field>
                  </div> -->

                  <div class="col-12" *ngIf="attributes.scopeLocal != 'Cerrado'">
                    <mat-form-field appearance="legacy">
                      <mat-label>Consejo Después del Cierre</mat-label>
                      <input matInput maxlength="100" name="cdc" [(ngModel)]="courses.message_to_user" (keyup)="capitalLetter('message_to_user')">
                    </mat-form-field>
                  </div>

                  <div class="col-12" style="text-align: left !important;">
                    <mat-checkbox name="mccs" [(ngModel)]="courses.discontinued">Curso Suspendido</mat-checkbox>
                  </div>

                  <div class="col-12">
                    <mat-form-field appearance="legacy">
                      <mat-label>Observación</mat-label>
                      <textarea matInput [rows]="1" maxlength="497" name="obs"
                        [(ngModel)]="courses.observation" (keyup)="capitalLetter('observation')"></textarea>
                    </mat-form-field>
                  </div>

                  <div class="col-12" style="padding-top: 10px;">
                    <div class="fieldOld">
                      <fieldset class="scheduler-border fieldOld">
                        <legend class="scheduler-border" style="margin-top: -15px !important; width: 90px !important;">
                          Horarios *</legend>
                        <div>
                          <div class="row">
                            <div class="col-12 col-sm-4 sin extra">
                              <mat-form-field appearance="legacy">
                                <mat-label>Fecha</mat-label>
                                <input matInput [matDatepicker]="picker" [disabled]="false"
                                  (keydown)="$event.preventDefault();" (click)="picker.open()" name="secesxdate"
                                  [(ngModel)]="schedule.day">
                                <!-- <input matInput [matDatepicker]="picker" [disabled]="false" (keydown)="picker.open()" (click)="picker.open()" >  -->
                                <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="false">
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker [disabled]="false"></mat-datepicker>
                              </mat-form-field>
                            </div>

                            <div class="col-5 col-sm-3 sin">
                              <mat-form-field appearance="legacy" class="txtTime">
                                <mat-label>Hora de Inicio:</mat-label>
                                <input matInput type="time" name="hics" [(ngModel)]="schedule.start_time">
                              </mat-form-field>
                            </div>

                            <div class="col-5 col-sm-3 sin">
                              <mat-form-field appearance="legacy" class="txtTime">
                                <mat-label>Hora de Término:</mat-label>
                                <input matInput type="time" name="sxsx" [(ngModel)]="schedule.final_time">
                              </mat-form-field>
                            </div>

                            <div class="col-2 sin"><button mat-raised-button color="primary"
                                class="mat-raised-button-add" (click)="addSchedule()">+</button>
                            </div>

                            <div class="col-12 sin">
                              <div class="mat-raised-button-add">
                                <p style="text-align: right; font-size: 14px; font-weight: 500;">
                                   {{attributes.sumHours}} / {{courses.duration}} horas
                                </p>
                              </div>
                            </div>


                          </div>
                          <mat-divider></mat-divider>

                          <mat-selection-list [multiple]="false" style="line-height: normal; padding-bottom: 10px;">
                            <mat-list-option *ngFor="let rept of listSchedule;" [value]="rept.id">
                              {{rept.day | date:'dd/MM/yyyy'}} de {{rept.start_time | slice:0:5}} a {{rept.final_time | slice:0:5}}
                              <mat-icon mat-list-icon (click)="deleteListSchedule(rept)">delete</mat-icon>
                            </mat-list-option>
                          </mat-selection-list>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </form>
              <mat-divider></mat-divider>
              <div class="d-flex justify-content-between box-form-footer">
                <div>
                  <button *ngIf="true" mat-raised-button color="primary" (click)="actionButton('')">
                    <mat-icon>save</mat-icon> Guardar
                  </button>
                </div>
                <div class="separater"></div>
                <div>
                  <button mat-raised-button color="accent" (click)="actionButton('Select')">
                    <mat-icon>close</mat-icon> Cerrar
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab *ngIf="attributes.modifyBasic != 'basic'">
      <ng-template mat-tab-label>
        <i class="bi bi-briefcase-fill icon-boostrap-tap"></i>&nbsp; {{attributes.tabCustomer}}
      </ng-template>
      <div>
        <app-mc-course-customers (modalRespond)="modalRespond($event)"></app-mc-course-customers>
      </div>
    </mat-tab>

    <mat-tab *ngIf="attributes.modifyBasic != 'basic'">
      <ng-template mat-tab-label>
        <i class="bi bi-people-fill icon-boostrap-tap"></i>&nbsp; Listado de participantes
      </ng-template>

      <div>
        <app-mc-course-students (modalRespond)="modalRespond($event)"></app-mc-course-students>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="modal fade" id="modalExamen" data-bs-backdrop="static" data-bs-keyboard="false"
  aria-labelledby="staticBackdropLabel" aria-hidden="false" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h2 id="staticBackdropLabel">{{attributes.modalTitle}}</h2>
        <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        <div>
          <div>
            <form>
              <div class="row form-box-style">
                <h4>{{attributes.modalMessaje}}</h4>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row" style="width: 100%;">
          <div class="col-6">
            <button mat-raised-button color="warn" style="width: 100%;" (click)="respuestaModal('si')">Sí</button>
          </div>
          <div class="col-6">
            <button mat-raised-button style="width: 100%;" (click)="respuestaModal('no')">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- <div class="col-12">
                    <input hidden (change)="onFileSelected($event)" #fileInput type="file">
                    <mat-form-field (click)="fileInput.click()">
                      <mat-label>Imagen del curso</mat-label>
                      <span matPrefix>
                        <mat-icon>image</mat-icon>
                      </span>
                      <input matInput type="text" (paste)="false" name="file" [ngModel]="selectedFile?.name" required>
                    </mat-form-field>
                  </div> -->

<!-- <div class="col-12" style="padding-bottom: 20px;">
                    <div class="fieldOld">
                      <div class="carga">
                        <input hidden (change)="onFileSelected($event)" #fileInput name="as" type="file">
                        <div>
                          <button mat-raised-button color="primary" style="width: 100%;" (click)="fileInput.click()">
                            <mat-icon>image</mat-icon> Seleccione una imagen
                          </button>
                          <img *ngIf="url != ''" [src]="url" class="img-fluid imagenClass" alt="" width="100">
                        </div>
                      </div>
                    </div>
                  </div> -->
