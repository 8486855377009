import { Injectable } from "@angular/core";
import { MCError } from "src/app/mc-helpers/mc-error";
import { MCDataSourceAPICoreBuilder } from "src/app/mc-entity/mc-builders/mc-data-source-api-core-builder";
import { MCDataSourceLocal, MCDataSourceLocalInterface } from "../mc-data-source-local";    
import { MCDataSourceAPI } from "./mc-data-source-api";
import { MCEndpointsConstants } from "src/app/mc-helpers/mc-constants/mc-endpoints-constants"; 
import { HttpParams } from "@angular/common/http";
import { MCConstants } from "src/app/mc-helpers/mc-constants/mc-constants"; 
import { MCHttpMethodsEnum } from "src/app/mc-helpers/mc-enums/mc-http-methods-enum"; 
import { MCUtils } from "src/app/mc-helpers/mc-class/mc-utils";
import { MCCustomerEntityRequests, MCCustomerLegalEntityRequests, MCCustomersEntity } from "src/app/mc-entity/mc-entities/mc-customer-entitys";
import { MCDependencysEntity } from "src/app/mc-entity/mc-entities/mc-dependency-entitys";
 

export interface MCCustomerAPIInterface {
  
  getCustomer( 
    document:string,
    success: () => void, 
    failure: (error: MCError) => void
  ): any;

  getCustomers( 
    page: number, 
    limit: number, 
    type: string,
    region_name: string, 
    commune_name: string, 
    name: string, 
    last_name: string,
    mother_last_name: string,
    dependency_name: string,  
    document: string, 
    institution_name: string, 
    success: (entity: MCCustomersEntity) => void, 
    failure: (error: MCError) => void
  ): any;

  putCustomer(  
    idCustomer: string,
    customer: MCCustomerEntityRequests,
    success: () => void, 
    failure: (error: MCError) => void
  ): any;

  postCustomer(  
    customer: MCCustomerEntityRequests,
    success: (entity: any) => void, 
    failure: (error: MCError) => void
  ): any;

  putCustomerLegal(  
    idCustomer: string,
    customer: MCCustomerLegalEntityRequests,
    success: () => void, 
    failure: (error: MCError) => void
  ): any;

  postCustomerLegal(  
    customer: MCCustomerLegalEntityRequests,
    success: (entity: any) => void, 
    failure: (error: MCError) => void
  ): any;

  deleteCustomer(  
    idCustomer: string,
    success: () => void, 
    failure: (error: MCError) => void
  ): any;

  getDependency(  
    success: (entity: MCDependencysEntity) => void, 
    failure: (error: MCError) => void
  ): any;  
} 

@Injectable({
  providedIn: 'root'
})
export class MCCustomerAPI implements MCCustomerAPIInterface {
  // Private properties.

  private _omDataSourceLocal: MCDataSourceLocalInterface = new MCDataSourceLocal();

  // Contructors.

  constructor(private _apiDataSource: MCDataSourceAPI, private _mcUtils: MCUtils) { }

  postCustomer( 
    customer: MCCustomerEntityRequests,
    success: (entity: any) => void, 
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();  
    builder.endpoint = MCEndpointsConstants.customerAPI.customersNatural; 
    builder.httpMethod = MCHttpMethodsEnum.post;  
    builder.body = JSON.stringify(customer);     
    this._apiDataSource.core(builder).subscribe((data: any) => {   
      success(data);
    }, error => {   
      console.log(error);
      failure(new MCError(error));       
    })
  }

  putCustomer(
    idCustomer: string,
    customer: MCCustomerEntityRequests,
    success: () => void, 
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();  
    builder.httpMethod = MCHttpMethodsEnum.put;       
    builder.body = JSON.stringify(customer);   
    builder.endpoint = MCEndpointsConstants.customerAPI.customerNatural.replace("[ID]", idCustomer);
    this._apiDataSource.core(builder).subscribe(() => {  
      success()
    }, error => { 
      failure(new MCError(error));
    })
  }

  postCustomerLegal( 
    customer: MCCustomerLegalEntityRequests,
    success: (entity: any) => void, 
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();  
    builder.endpoint = MCEndpointsConstants.customerAPI.customersLegal; 
    builder.httpMethod = MCHttpMethodsEnum.post;  
    builder.body = JSON.stringify(customer);     
    this._apiDataSource.core(builder).subscribe((data: any) => {   
      success(data);
    }, error => {   
      console.log(error);
      failure(new MCError(error));       
    })
  }

  putCustomerLegal(
    idCustomer: string,
    customer: MCCustomerLegalEntityRequests,
    success: () => void, 
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();  
    builder.httpMethod = MCHttpMethodsEnum.put;       
    builder.body = JSON.stringify(customer);   
    builder.endpoint = MCEndpointsConstants.customerAPI.customerLegal.replace("[ID]", idCustomer);
    this._apiDataSource.core(builder).subscribe(() => {  
      success()
    }, error => { 
      failure(new MCError(error));
    })
  }

  deleteCustomer(
    idCustomer: string,  
    success: () => void, 
    failure: (error: MCError) => void
  ) { 
    const builder = new MCDataSourceAPICoreBuilder();    
    builder.httpMethod = MCHttpMethodsEnum.delete; 
    builder.endpoint = MCEndpointsConstants.customerAPI.customer.replace("[ID]", idCustomer);
    this._apiDataSource.core(builder).subscribe(() => {   
      success()
    }, error => { 
      console.log(error);
      failure(new MCError(error));
    })
  }

  getCustomer ( 
    document: string,
    success: () => void, 
    failure: (error: MCError) => void
  ) { 
    const builder = new MCDataSourceAPICoreBuilder();        
    builder.endpoint = MCEndpointsConstants.customerAPI.customer.replace("[ID]", document);
    this._apiDataSource.core(builder).subscribe((data: any) => {  
      success()
    }, error => { 
      failure(new MCError(error));
    })
  }
 
  getCustomers (
    page: number, 
    limit: number, 
    type: string,
    region_name: string, 
    commune_name: string, 
    name: string, 
    last_name: string,
    mother_last_name: string,
    dependency_name: string,  
    document: string, 
    institution_name: string, 
    success: (entity: MCCustomersEntity) => void, 
    failure: (error: MCError) => void
  ) { 
    const builder = new MCDataSourceAPICoreBuilder();  
    let params: HttpParams = new HttpParams(); 
    params = params.append(MCConstants.paramLimit, limit.toString()); 
    params = params.append(MCConstants.paramPage, page.toString()); 

    if(this._mcUtils.validateStringUndefinedNotselected(type)) {  
      params = params.append(MCConstants.paramType, type)
    }
    if(this._mcUtils.validateStringUndefined(last_name)) {  
      params = params.append(MCConstants.paramLastName, last_name)
    }
    if(this._mcUtils.validateStringUndefined(mother_last_name)) {  
      params = params.append(MCConstants.paramMotherLastName, mother_last_name)
    }
    if(this._mcUtils.validateStringUndefined(name)) {  
      params = params.append(MCConstants.paramName, name)
    }
    if(this._mcUtils.validateStringUndefined(document)) {   
      params = params.append(MCConstants.paramDocumentType, MCConstants.rut)
      params = params.append(MCConstants.paramDocument, document)
    }
    if(this._mcUtils.validateStringUndefined(institution_name)) {  
      params = params.append(MCConstants.paramInstitutionName, institution_name)
    }
    if(this._mcUtils.validateStringUndefinedNotselected(region_name)) {  
      params = params.append(MCConstants.paramRegionName, region_name)
    }
    if(this._mcUtils.validateStringUndefinedNotselected(commune_name)) {  
      params = params.append(MCConstants.paramCommuneName, commune_name)
    }    
    if(this._mcUtils.validateStringUndefinedNotselected(dependency_name)) {  
      params = params.append(MCConstants.paramDependencyName, dependency_name)
    }
    
   
    builder.httpParams = params; 
    builder.endpoint = MCEndpointsConstants.customerAPI.customers;
    this._apiDataSource.core(builder).subscribe((data: any) => {  
      success(new MCCustomersEntity(data.meta.total,data.data))
    }, error => { 
      failure(new MCError(error));
    })
  }

  getDependency ( 
    success: (entity: MCDependencysEntity) => void, 
    failure: (error: MCError) => void
  ) { 
    const builder = new MCDataSourceAPICoreBuilder();       
    builder.endpoint = MCEndpointsConstants.customerAPI.dependency;
    this._apiDataSource.core(builder).subscribe((data: any) => {  
      success(new MCDependencysEntity(data.data))
    }, error => { 
      failure(new MCError(error));
    })
  }

}
 