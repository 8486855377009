import { Injectable } from "@angular/core";
import { MCError } from "src/app/mc-helpers/mc-error";
import { MCDataSourceAPICoreBuilder } from "src/app/mc-entity/mc-builders/mc-data-source-api-core-builder";
import { MCDataSourceLocal, MCDataSourceLocalInterface } from "../mc-data-source-local";
import { MCDataSourceAPI } from "./mc-data-source-api";
import { MCEndpointsConstants } from "src/app/mc-helpers/mc-constants/mc-endpoints-constants";
import { MCHttpMethodsEnum } from "src/app/mc-helpers/mc-enums/mc-http-methods-enum";
import { MCUtils } from "src/app/mc-helpers/mc-class/mc-utils";
import { MCStudentEntityRequests } from "src/app/mc-entity/mc-entities/mc-students-entitys";


export interface MCStudentAPIInterface {

  getStudent(
    document:string,
    success: () => void,
    failure: (error: MCError) => void
  ): any;

  putStudent(
    idStudent: string,
    student: MCStudentEntityRequests,
    success: () => void,
    failure: (error: MCError) => void
  ): any;

  deleteStudent(
    idStudent: string,
    success: () => void,
    failure: (error: MCError) => void
  ): any;


}

@Injectable({
  providedIn: 'root'
})
export class MCStudentAPI implements MCStudentAPIInterface {
  // Private properties.

  private _omDataSourceLocal: MCDataSourceLocalInterface = new MCDataSourceLocal();

  // Contructors.

  constructor(private _apiDataSource: MCDataSourceAPI, private _mcUtils: MCUtils) { }



  putStudent(
    idStudent: string,
    student: MCStudentEntityRequests,
    success: () => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    builder.httpMethod = MCHttpMethodsEnum.put;
    builder.body = JSON.stringify(student);
    builder.endpoint = MCEndpointsConstants.studentAPI.student.replace("[ID]", idStudent);
    this._apiDataSource.core(builder).subscribe(() => {
      success()
    }, error => {
      failure(new MCError(error));
    })
  }

  deleteStudent(
    idStudent: string,
    success: () => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    builder.httpMethod = MCHttpMethodsEnum.delete;
    builder.endpoint = MCEndpointsConstants.studentAPI.student.replace("[ID]", idStudent);
    this._apiDataSource.core(builder).subscribe(() => {
      success()
    }, error => {
      console.log(error);
      failure(new MCError(error));
    })
  }

  getStudent(
    document: string,
    success: () => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    builder.endpoint = MCEndpointsConstants.studentAPI.student.replace("[ID]", document);
    this._apiDataSource.core(builder).subscribe((data: any) => {
      success()
    }, error => {
      failure(new MCError(error));
    })
  }

}
