<div class="m-0 row justify-content-center form-content-main">
    <div class="col-auto text-center" style="padding: 0.5rem;">
        <div class="box-form mat-elevation-z22">
            <form>
                <div class="row form-box-style">
                    <div class="col-12">
                        <div class="color">
                            <div class="container">
                                <form id="contact" action="" method="post">
                                    <div class="row">
                                        <div class="col-12">
                                            <h3 style="padding-top:8px;">{{titulo}}</h3>
                                        </div>
                                        <div class="col-12 align-center">
                                            <div class="row justify-content-center">
                                                <img src="./assets/logonuevo.png" class="img-fluid imagenClass"
                                                    alt="Responsive image" width="50px">
                                            </div>
                                        </div>
                                    </div>
                                    <h4 style="padding-top: 20px;">{{mesaje}}</h4>
                                    <div class="row">
                                        <div class="col-12"> 
                                            <button mat-flat-button color="primary"
                                                (click)="cancelar()" style="margin-bottom: 20px;">Reintentar</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>