import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MCAlert } from 'src/app/mc-helpers/mc-alert';
import { MCRouter } from 'src/app/mc-helpers/mc-class/mc-router';
import { MCUtils } from 'src/app/mc-helpers/mc-class/mc-utils';
import { MCMessagesEnum } from 'src/app/mc-helpers/mc-enums/mc-messages-enum';
import { MCRoutesEnum } from 'src/app/mc-helpers/mc-enums/mc-routes-enum';
import { MCAuthenticationAPI } from 'src/app/mc-repository/mc-api/mc-authentication-api';
import { MCDataSourceLocal, MCDataSourceLocalInterface } from 'src/app/mc-repository/mc-data-source-local';
import { MCLoadingService } from 'src/app/mc-service/mc-loading.service';
declare var window: any;

export interface attributes {
  hide: boolean;
  email: string;
  password: string;
  messageEmail: string;
  isMobile: boolean;
};

@Component({
  selector: 'app-mc-login',
  templateUrl: './mc-login.component.html'
})
export class MCLoginComponent implements OnInit {

  private _mcDataSourceLocal: MCDataSourceLocalInterface = new MCDataSourceLocal();

  attributes = {
    hide: true,
    email: String(),
    password: String(),
    messageEmail: String(),
    isMobile: false
  };

  private _mcRouter: MCRouter;

  constructor(
    private _loading: MCLoadingService,
    public _route: Router,
    private _mcAlert: MCAlert,
    private _mcUtils: MCUtils,
    private _mcAuthenticationAPI: MCAuthenticationAPI,
  ) {
    this._mcRouter = new MCRouter(_route);
  }

  ngOnInit(): void {
    this.ChangeMobile(window.innerWidth);
  }

  validateForm(): boolean {
    return !(!this._mcUtils.validateFormEmpty([this.attributes.email, this.attributes.password]))
  }

  stripe(value: string) {
     return (value == String()) ? "- " : "\n- ";
  }

  post() {
    this._loading.callLoading();
    if (this.validateForm()) {
      this._mcAuthenticationAPI.postLogin(this.attributes.email, this.attributes.password, () => {
        this._mcDataSourceLocal.setTimeStart();
        this._mcRouter.navigateTo(MCRoutesEnum.admin)
      }, error => {
        this._loading.closeLoading();
        if(error.code == 401) {
          error.message = MCMessagesEnum.incorrectEmailPassword
        }
        this._mcAlert.showAlertError(error.message);
      })
    } else {
      this._loading.closeLoading();
      this._mcAlert.showAlertWarning(MCMessagesEnum.globalRequiredFieldsAlert);
    }
  }
  //"mahuida.projects@gmail.com"   "mahuida_2022."
  emailKeydownEvent() {
    this.attributes.messageEmail = this._mcUtils.validateEmailKeyMessage(this.attributes.email);
  }

  onResize(event: any) {
    this.ChangeMobile(event.target.innerWidth);
  }

  ChangeMobile(size: number) {
    this.attributes.isMobile = size > 600;
  }

}
