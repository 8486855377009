import { Injectable } from "@angular/core";
import { MCError } from "src/app/mc-helpers/mc-error";
import { MCDataSourceAPICoreBuilder } from "src/app/mc-entity/mc-builders/mc-data-source-api-core-builder";
import { MCDataSourceLocal, MCDataSourceLocalInterface } from "../mc-data-source-local";
import { MCDataSourceAPI } from "./mc-data-source-api";
import { MCEndpointsConstants } from "src/app/mc-helpers/mc-constants/mc-endpoints-constants";
import { HttpParams } from "@angular/common/http";
import { MCConstants } from "src/app/mc-helpers/mc-constants/mc-constants";
 import { MCUtils } from "src/app/mc-helpers/mc-class/mc-utils";
import { MCInscriptionStudentsEntity } from "src/app/mc-entity/mc-entities/mc-inscription-student-entitys";


export interface MCInscriptionStudentsAPIInterface {

  getAllStudents(
    page: number,
    limit: number,
    last_name: string,
    mothers_last_name: string,
    name: string,
    course_base: string,
    document: string,
    success: (entity: MCInscriptionStudentsEntity) => void,
    failure: (error: MCError) => void
  ): any;


}

@Injectable({
  providedIn: 'root'
})
export class MCInscriptionStudentAPI implements MCInscriptionStudentsAPIInterface {
  // Private properties.

  private _omDataSourceLocal: MCDataSourceLocalInterface = new MCDataSourceLocal();

  // Contructors.

  constructor(private _apiDataSource: MCDataSourceAPI, private _mcUtils: MCUtils) { }


  getAllStudents(
    page: number,
    limit: number,
    last_name: string,
    mothers_last_name: string,
    name: string,
    course_base: string,
    document: string,
    success: (entity: MCInscriptionStudentsEntity) => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    let params: HttpParams = new HttpParams();
    params = params.append(MCConstants.paramLimit, limit.toString());
    params = params.append(MCConstants.paramPage, page.toString());
    if (this._mcUtils.validateStringUndefined(last_name)) {
      params = params.append(MCConstants.paramLastName, last_name)
    }
    if (this._mcUtils.validateStringUndefined(mothers_last_name)) {
      params = params.append(MCConstants.paramMotherLastName, mothers_last_name)
    }
    if (this._mcUtils.validateStringUndefined(name)) {
      params = params.append(MCConstants.paramName, name)
    }
    if (this._mcUtils.validateStringUndefined(document)) {
      params = params.append(MCConstants.paramDocument, document)
    }
    if (this._mcUtils.validateStringUndefined(course_base) && course_base != MCConstants.notSelected) {
      params = params.append(MCConstants.paramCourseBaseName, course_base)
    }
    builder.httpParams = params;
    builder.endpoint = MCEndpointsConstants.inscriptionStudentsAPI.inscriptionStudents;
    this._apiDataSource.core(builder).subscribe((data: any) => {
      success(new MCInscriptionStudentsEntity(data.meta.total, data.data))
    }, error => {
      failure(new MCError(error));
    })
  }

}
