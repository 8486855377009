import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mc-main',
  template: `<div class="m-0 row justify-content-center form-content-main">
    <div class="col-auto text-center" style="padding: 0.5rem;">
      <div class="form-content-main-img"><img src="../assets/logonuevo.jpeg" alt="" width="60%"></div>
    </div>
  </div>`
})
export class MCMainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
