import { Injectable } from "@angular/core";
import { MCError } from "src/app/mc-helpers/mc-error";
import { MCDataSourceAPICoreBuilder } from "src/app/mc-entity/mc-builders/mc-data-source-api-core-builder";
import { MCDataSourceLocal, MCDataSourceLocalInterface } from "../mc-data-source-local";    
import { MCDataSourceAPI } from "./mc-data-source-api";
import { MCEndpointsConstants } from "src/app/mc-helpers/mc-constants/mc-endpoints-constants"; 
import { HttpParams } from "@angular/common/http";
import { MCConstants } from "src/app/mc-helpers/mc-constants/mc-constants";
import { MCHttpMethodsEnum } from "src/app/mc-helpers/mc-enums/mc-http-methods-enum"; 
import { MCBasicCourseEntityRequests, MCBasicCoursesEntity } from "src/app/mc-entity/mc-entities/mc-basic-course-entitys";
import { MCUtils } from "src/app/mc-helpers/mc-class/mc-utils";
 

export interface MCBasicCourseAPIInterface {
  
  getBasicCourse( 
    id:string,
    success: () => void, 
    failure: (error: MCError) => void
  ): any;

  getBasicCourses( 
    page: number, 
    limit: number, 
    name: string,
    certificate_title:string,
    success: (entity: MCBasicCoursesEntity) => void, 
    failure: (error: MCError) => void
  ): any;

  putBasicCourse(  
    idBasicCourse: string,
    basicCourse: MCBasicCourseEntityRequests,
    success: () => void, 
    failure: (error: MCError) => void
  ): any;

  postBasicCourse(  
    basicCourse: MCBasicCourseEntityRequests,
    success: (entity: any) => void, 
    failure: (error: MCError) => void
  ): any;

  deleteBasicCourse(  
    idBasicCourse: string,
    success: () => void, 
    failure: (error: MCError) => void
  ): any;

}

@Injectable({
  providedIn: 'root'
})
export class MCBasicCourseAPI implements MCBasicCourseAPIInterface {
  // Private properties.

  private _omDataSourceLocal: MCDataSourceLocalInterface = new MCDataSourceLocal();

  // Contructors.

  constructor(private _apiDataSource: MCDataSourceAPI, private _mcUtils: MCUtils) { }


  postBasicCourse( 
    basicCourse: MCBasicCourseEntityRequests,
    success: (entity: any) => void, 
    failure: (error: MCError) => void
  ) {
    console.log(basicCourse);
    const builder = new MCDataSourceAPICoreBuilder();  
    builder.endpoint = MCEndpointsConstants.basicCourseAPI.basicCourses; 
    builder.httpMethod = MCHttpMethodsEnum.post;  
    builder.body = JSON.stringify(basicCourse);     
    this._apiDataSource.core(builder).subscribe((data: any) => {   
      success(data);
    }, error => {   
      console.log(error);
      failure(new MCError(error));       
    })
  }

  putBasicCourse(
    idBasicCourse: string,
    basicCourse: MCBasicCourseEntityRequests,
    success: () => void, 
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();  
    builder.httpMethod = MCHttpMethodsEnum.put;       
    builder.body = JSON.stringify(basicCourse);   
    builder.endpoint = MCEndpointsConstants.basicCourseAPI.basicCourse.replace("[ID]", idBasicCourse); 
    this._apiDataSource.core(builder).subscribe(() => {  
      success()
    }, error => { 
      failure(new MCError(error));
    })
  }

  deleteBasicCourse(
    idBasicCourse: string,  
    success: () => void, 
    failure: (error: MCError) => void
  ) { 
    const builder = new MCDataSourceAPICoreBuilder();    
    builder.httpMethod = MCHttpMethodsEnum.delete; 
    builder.endpoint = MCEndpointsConstants.basicCourseAPI.basicCourse.replace("[ID]", idBasicCourse); 
    this._apiDataSource.core(builder).subscribe(() => {   
      success()
    }, error => { 
      console.log(error);
      failure(new MCError(error));
    })
  } 

  getBasicCourse ( 
    id:string,
    success: () => void, 
    failure: (error: MCError) => void
  ) { 
    const builder = new MCDataSourceAPICoreBuilder();        
    builder.endpoint = MCEndpointsConstants.basicCourseAPI.basicCourse.replace("[ID]", id);
    this._apiDataSource.core(builder).subscribe((data: any) => {  
      success()
    }, error => { 
      failure(new MCError(error));
    })
  }
 
  getBasicCourses(
    page: number, 
    limit: number, 
    name: string,
    certificate_title:string,
    success: (entity: MCBasicCoursesEntity) => void, 
    failure: (error: MCError) => void
  ) { 
    const builder = new MCDataSourceAPICoreBuilder();  
    let params: HttpParams = new HttpParams(); 
    params = params.append(MCConstants.paramLimit, limit.toString()); 
    params = params.append(MCConstants.paramPage, page.toString());
    if(this._mcUtils.validateStringUndefined(name)) {  
      params = params.append(MCConstants.paramName, name)
    }
    if(this._mcUtils.validateStringUndefined(certificate_title)) {  
      params = params.append(MCConstants.paramCertificateTitle, certificate_title)
    }
    builder.httpParams = params; 
    builder.endpoint = MCEndpointsConstants.basicCourseAPI.basicCourses;
    this._apiDataSource.core(builder).subscribe((data: any) => {   
      success(new MCBasicCoursesEntity(data.meta.total,data.data))
    }, error => { 
      failure(new MCError(error));
    })
  }

}