import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MCAcademicTitleEntity } from 'src/app/mc-entity/mc-entities/mc-academic-title-entitys';
import { MCCommuneEntity } from 'src/app/mc-entity/mc-entities/mc-commune-entitys';
import { MCRegionEntity } from 'src/app/mc-entity/mc-entities/mc-region-entitys';
import { MCTeacherEntity, MCTeacherEntityRequests } from 'src/app/mc-entity/mc-entities/mc-teacher-entitys';
import { MCAlert } from 'src/app/mc-helpers/mc-alert';
import { MCRouter } from 'src/app/mc-helpers/mc-class/mc-router';
import { MCUtils } from 'src/app/mc-helpers/mc-class/mc-utils';
import { MCConstants } from 'src/app/mc-helpers/mc-constants/mc-constants';
import { MCMessagesEnum } from 'src/app/mc-helpers/mc-enums/mc-messages-enum';
import { MCTeacherAPI } from 'src/app/mc-repository/mc-api/mc-teacher-api';
import { MCDataSourceLocal, MCDataSourceLocalInterface } from 'src/app/mc-repository/mc-data-source-local';
import { MCLoadingService } from 'src/app/mc-service/mc-loading.service';
declare var require: any;
const { validate, format } = require('rut.js');

export interface attributes {
  messageEmail: string;
  messageMobile: string;
  hidden: boolean;
  selectedValueRegion: string;
  selectedValueCommune: string;
  action: string;
  rutChecked: boolean;
  selectedValueAcademicTitle: string;
}

@Component({
  selector: 'app-mc-view-teacher',
  templateUrl: './mc-view-teacher.component.html',
  styleUrls: ['./mc-view-teacher.component.css']
})
export class MCViewTeacherComponent implements OnInit {

  private _mcRouter: MCRouter;
  @Output() teacherRespond = new EventEmitter<string>();
  private _mcDataSourceLocal: MCDataSourceLocalInterface = new MCDataSourceLocal();
  teacher: MCTeacherEntity = new MCTeacherEntity(String());

  listRegion: MCRegionEntity[] = [];
  listCommune: MCCommuneEntity[] = [];
  listAcademicTitle: MCAcademicTitleEntity[] = [];
  listAcademicTitleTeacher: MCAcademicTitleEntity[] = [];

  attributes = {
    messageEmail: String(),
    messageMobile: String(),
    hidden: false,
    selectedValueRegion: MCConstants.notSelected,
    selectedValueCommune: MCConstants.notSelected,
    action: MCConstants.register,
    rutChecked: false,
    selectedValueAcademicTitle: MCConstants.notSelected
  };

  constructor(
    private _loading: MCLoadingService,
    public _route: Router,
    private _mcUtils: MCUtils,
    private _mcAlert: MCAlert,
    private _mcTeacherAPI: MCTeacherAPI,
  ) {
    this._mcRouter = new MCRouter(_route);
    this.listRegion = this._mcDataSourceLocal.getRegions();
    this.getAcademicTitles();
  }

  ngOnInit(): void {
    this.fillCommunes(this.attributes.selectedValueRegion);
  }

  addAcademicTitle() {
    let selected: string = this.attributes.selectedValueAcademicTitle;
    if (selected != MCConstants.notSelected) {
      for (let rep of this.listAcademicTitle) {
        if (rep.id.toString() == selected) {
          let repeat: boolean = false;
          for (let tac of this.listAcademicTitleTeacher) {
            if (tac.id == rep.id) {
              repeat = true;
              break;
            }
          }
          if (repeat == false) {
            this.listAcademicTitleTeacher.push(rep);
          }
          break;
        }
      }
      this.attributes.selectedValueAcademicTitle = MCConstants.notSelected;
    }
  }

  getAcademicTitles() {
    this.listAcademicTitle = [];
    this._mcTeacherAPI.getAcademicTitles(data => {
      this.listAcademicTitle = data.entities;
    }, error => {
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }

  rutValidate() {
    this.teacher.document = this._mcUtils.removeBlanks(this.teacher.document);
    this.teacher.document = this.teacher.document.toUpperCase();
    if (this.teacher.document != String()) {
      this.teacher.document = format(this.teacher.document);
      validate(this.teacher.document) ? this.validateExist() : this._mcAlert.showAlertWarning(MCMessagesEnum.globalInvalidRut);
    } else {
      this._mcAlert.showAlertWarning(MCMessagesEnum.globalEmptyRut);
    }
  }

  validateExist() {
    this._loading.callLoading();
    this._mcTeacherAPI.getTeacher(this.teacher.document, () => {
      this._loading.closeLoading();
      this._mcAlert.showAlertError(MCMessagesEnum.rutExist);
    }, error => {
      this._loading.closeLoading();
      if (error.objectMessage == undefined) {
        this.attributes.rutChecked = true;
      } else {
        this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
      }
    })
  }

  changeSelect(event: any, numero: number) {
    if (numero == 12) {
      this.attributes.selectedValueCommune = MCConstants.notSelected;
      this.fillCommunes(this.attributes.selectedValueRegion);
    }
  }

  //llena el combo de comunas dependiendo del la region seleccionada
  fillCommunes(valor: any) {
    if (valor != MCConstants.notSelected) {
      for (let rep of this.listRegion) {
        if (valor == rep.id) {
          this.listCommune = rep.communes!;
          break;
        }
      }
      if (this.teacher.commune.id != 0) {
        this.attributes.selectedValueCommune = this.teacher.commune.id.toString();
      }
    } else {
      this.attributes.selectedValueCommune = MCConstants.notSelected;
      this.listCommune = [];
    }
  }

  resetTeacher() {
    this.listAcademicTitleTeacher = [];
    this.teacher = new MCTeacherEntity(String());
    this.attributes.rutChecked = false;
    this.attributes.action = MCConstants.register;
    this.attributes.selectedValueRegion = MCConstants.notSelected;
    this.attributes.selectedValueCommune = MCConstants.notSelected;
  }

  passTeacher(passTeacher: MCTeacherEntity) {
    this.listAcademicTitleTeacher = [];
    this.teacher = new MCTeacherEntity(passTeacher);
    this.attributes.rutChecked = true;
    this.attributes.selectedValueRegion = this.teacher.commune.region!.id.toString();
    this.fillCommunes(this.attributes.selectedValueRegion);
    this.attributes.action = MCConstants.modify;
    for (let rep of this.teacher.academic_titles) {
      this.listAcademicTitleTeacher.push(rep);
    }
  }

  deletelistAcademicTitleTeacher(value: any) {
    for (let i: number = 0; i < this.listAcademicTitleTeacher.length; i++) {
      if (this.listAcademicTitleTeacher[i].id == value.id) {
        this.listAcademicTitleTeacher.splice(i, 1)
        break;
      }
    }
  }

  onEdit(event: any, item: any) {
    event.stopPropagation();
  }

  actionButton(action: string) {
    if (action == MCConstants.select) {
      this.teacherRespond.emit(MCConstants.teachers);
    } else {
      if (this.validateForm()) {
        if (this.attributes.action == MCConstants.register) {
          this.post();
        } else if (this.attributes.action == MCConstants.modify) {
          this.put()
        }
      }
    }
  }
  //valida formulario antes de realzar una accion
  validateForm(): boolean {
    if (!this._mcUtils.validateStringUndefined(this.teacher.document)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.teacherDocumentFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateStringUndefined(this.teacher.name)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.teacherNameFieldEmpty);
      return false;
    } else if (this.listAcademicTitleTeacher.length == 0) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.teacherListTitlesEmpty);
      return false;
    } else if (!this._mcUtils.validateStringUndefined(this.teacher.curriculum)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.teacherCurriculumFieldEmpty);
      return false;
    } else if (!this._mcUtils.validatePhone(this.teacher.mobile)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.teacherMobilFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateEmail(this.teacher.email)) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.teacherEmailFieldEmpty);
      return false;
    } else if (!this._mcUtils.validateFormSelect([this.attributes.selectedValueRegion])) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.teacherRegionSeleted);
      return false;
    } else if (!this._mcUtils.validateFormSelect([this.attributes.selectedValueCommune])) {
      this._mcAlert.showAlertWarning(MCMessagesEnum.teacherComunaSeleted);
      return false;
    } else {
      return true;
    }
  }

  returnArray(): number[] {
    let arrayAcademicTitles: number[] = [];
    for (let rep of this.listAcademicTitleTeacher) {
      arrayAcademicTitles.push(rep.id);
    }
    return arrayAcademicTitles;
  }

  post() {
    this._loading.callLoading();
    let teacherInsert: MCTeacherEntityRequests = new MCTeacherEntityRequests(
      this.teacher,
      Number(this.attributes.selectedValueCommune),
      this.returnArray()
    );

    this._mcTeacherAPI.postTeacher(teacherInsert, () => {
      this.teacherRespond.emit(MCConstants.register);
    }, error => {
      this._loading.closeLoading();
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }

  put() {
    this._loading.callLoading();
    let teacherInsert: MCTeacherEntityRequests = new MCTeacherEntityRequests(
      this.teacher,
      Number(this.attributes.selectedValueCommune),
      this.returnArray()
    );

    this._mcTeacherAPI.putTeacher(this.teacher.id.toString(), teacherInsert, () => {
      this.replaceData();
    }, error => {
      this._loading.closeLoading();
      this._mcUtils.handleError(error, this._mcRouter, this._mcAlert)
    })
  }

  replaceData() {
    this.teacher.academic_titles = [];
    this.teacher.academic_titles = this.listAcademicTitleTeacher;

    for (let rep of this.listRegion) {
      if (rep.id == this.attributes.selectedValueRegion) {
        this.teacher.commune.region.id = rep.id;
        this.teacher.commune.region.sequence = rep.sequence;
      }
    }

    for (let rep of this.listCommune) {
      if (rep.id == Number(this.attributes.selectedValueCommune)) {
        this.teacher.commune.id = rep.id;
        this.teacher.commune.name = rep.name;
      }
    }
    this.teacherRespond.emit(MCConstants.modify);
  }

  //valida el correo
  emailKeydownEvent() {
    this.attributes.messageEmail = this._mcUtils.validateEmailKeyMessage(this.teacher.email);
  }


  capitalLetter(text: any){
    if(text == 'name'){
      this.teacher.name = this.teacher.name.toUpperCase();
    } else if(text == 'curriculum'){
      this.teacher.curriculum = this.teacher.curriculum.toUpperCase();
    } else if(text == 'address'){
      this.teacher.address = this.teacher.address.toUpperCase();
    } else if(text == 'observation'){
      this.teacher.observation = this.teacher.observation.toUpperCase();
    }
  }

  //valida el numero en el campo telefono
  phoneKeydownEvent(event: any) {
    if (this._mcUtils.onlyNumber(event)) {
      this.attributes.messageMobile = this._mcUtils.validatePhoneKeyMessage(this.teacher.mobile);
    } else {
      this.teacher.mobile = this.teacher.mobile.replace(event.key, String());
    }
  }

}
