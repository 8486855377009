import { Injectable } from '@angular/core';
import { Router } from '@angular/router';  
import { MCRoutesEnum } from '../mc-enums/mc-routes-enum';

export class MCRouterData {
    // Properties.

    path: MCRoutesEnum
    data?: any

    // Constructor.

    constructor(path: MCRoutesEnum, data?: any) {
        this.path = path
        this.data = data
    }
}

@Injectable({
    providedIn: 'root'
})
export class MCRouter {
    // Properties.

    routerData: MCRouterData | undefined;    

    // Constructor.

    constructor(private route: Router) {
        if (this.route.getCurrentNavigation() && this.route.getCurrentNavigation()!.extras && this.route.getCurrentNavigation()!.extras.state) {
            const state = this.route.getCurrentNavigation()!.extras.state
            this.routerData = new MCRouterData(MCRoutesEnum.admin, null)
            if (state) {
                this.routerData.path = state['previousPath']
                this.routerData.data = state['data']
            }
        }
    }

    // Functions.

    navigateTo(route: string, data?: any) {
        this.route.navigate(
            [route],
            { state: { previousPath: this.getPathEnum(this.route.url), data: data } }
        )
    }

     navigateToURL(routerData: MCRouterData) {
         this.route.navigateByUrl(
             this.getPath(routerData.path),
             { state: { previousPath: this.getPathEnum(this.route.url), data: routerData.data } }
         )
    }

    getPath(route: MCRoutesEnum): string {
        switch (route) { 
            case MCRoutesEnum.login:
                return this.getPaths(0, undefined)
            case MCRoutesEnum.admin:
                return this.getPaths(1, undefined)
            case MCRoutesEnum.main:
                return this.getPaths(1, 0)
            case MCRoutesEnum.customer:
                return this.getPaths(1, 1)
            case MCRoutesEnum.student:
                return this.getPaths(1, 2)
            case MCRoutesEnum.sence:
                return this.getPaths(1, 3)
            case MCRoutesEnum.teacher:
                return this.getPaths(1, 4)
            case MCRoutesEnum.course:
                return this.getPaths(1, 5)
            case MCRoutesEnum.courseOpen:
                return this.getPaths(1, 6)
            case MCRoutesEnum.courseClose:
                return this.getPaths(1, 7)
            default:
                return this.getPaths(0, undefined)
        }
    } 

    navigateToWithWindowLocation(route: MCRoutesEnum) {
        window.location.href = this.getPath(route)
    }
 
    getPathEnum(route: string): MCRoutesEnum {
        switch (route) { 
            case "/login":
                return MCRoutesEnum.login  
            case "/admin":
                return MCRoutesEnum.admin  
            case "/admin/main":
                return MCRoutesEnum.main
            case "/admin/cliente":
                return MCRoutesEnum.customer
            case "/admin/participante":
                return MCRoutesEnum.student
            case "/admin/sence":
                return MCRoutesEnum.sence
            case "/admin/relator":
                return MCRoutesEnum.teacher
            case "/admin/curso":
                return MCRoutesEnum.course
            case "/admin/curso-abierto":
                return MCRoutesEnum.courseOpen
            case "/admin/curso-cerrado":
                return MCRoutesEnum.courseClose
            default:
                return MCRoutesEnum.error
        }
    }
 
    private getPaths(root: number, children?: number): string {
        const backSlash = "/"
        let path = backSlash + this.route.config[root].path
        if (children) {
            path = path + backSlash + this.route.config[root].children![children].path
        }
        return path
    }
}