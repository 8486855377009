import { Component, Input, OnInit, Output , EventEmitter} from '@angular/core';
import { MCToolbarClass } from 'src/app/mc-helpers/mc-class/mc-toolbar-class';
import { MCConstants } from 'src/app/mc-helpers/mc-constants/mc-constants';

@Component({
  selector: 'app-mc-toolbar',
  templateUrl: './mc-toolbar.component.html',
  styleUrls: ['./mc-toolbar.component.css']
})
export class MCToolbarComponent implements OnInit {

  @Input() set actionRequest(value: MCToolbarClass) {
    this.testAction(value);
  }
  @Output() toolRespond = new EventEmitter<string>();

  btnDisable : boolean = true;
  title: string = String();
  titleLocal: string = String();
  titleToolTip: string = String();

  constructor() {
  }

  ngOnInit(): void {
  }

  testAction(value: MCToolbarClass) {
    this.title = value.whereView;
    this.titleToolTip = this.titleMethod(this.title);
    if(value.actionRequest == MCConstants.select) {
      this.titleLocal = value.whereView;
      this.btnDisable = true;
    } else if(value.actionRequest == MCConstants.register) {
      this.titleLocal = value.actionRequest +" "+value.whereView;
      this.btnDisable = false;
    } else if(value.actionRequest == MCConstants.modify) {
      this.titleLocal = value.actionRequest +" "+value.whereView;
      this.btnDisable = false;
    } else {
      this.titleLocal = value.whereView +" - ["+ value.actionRequest+"]";
      this.btnDisable = false;
    }
  }

  titleMethod(value: string) : any {

    if(value == MCConstants.teachers) {
      return MCConstants.register+" "+MCConstants.teacher;
    } else if(value == MCConstants.sences) {
      return MCConstants.register+" "+MCConstants.sence;
    } else if(value == MCConstants.students) {
      return MCConstants.register+" "+MCConstants.student;
    } else if(value == MCConstants.customers) {
      return MCConstants.register+" "+MCConstants.customer;
    } else if(value == MCConstants.coursesBasic) {
      return MCConstants.register+" "+MCConstants.courseBasic;
    } else if(value == MCConstants.courses) {
      return MCConstants.register+" "+MCConstants.course;
    } else if(value == MCConstants.open_courses) {
      return MCConstants.register+" "+MCConstants.open_course;
    } else if(value == MCConstants.close_courses) {
      return MCConstants.register+" "+MCConstants.close_course;
    } else if(value == MCConstants.emails) {
      return MCConstants.register+" "+MCConstants.email;
    } else {
      return value;
    }
  }

  addEvent(){
    this.toolRespond.emit(MCConstants.register);
  }

  arrowEvent(){
    this.toolRespond.emit(MCConstants.select);
  }

}
