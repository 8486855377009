import { Injectable } from "@angular/core";
import { MCRegionEntity } from "../mc-entity/mc-entities/mc-region-entitys";


export interface MCDataSourceLocalInterface {
    setRegions(entity: MCRegionEntity[]) : void;
    getRegions(): MCRegionEntity[] ;
    setToken(token: string): void;
    getToken(): string;
    removeToken(): void;
    setTimeStart(): void;
    getTimeStart(): any;
}

@Injectable({
    providedIn: 'root'
})
export class MCDataSourceLocal implements MCDataSourceLocalInterface {

    private _regionsKey = "regions"
    private _tokenKey = "xxx"
    private _timeStart = "start"

    setRegions(entity: MCRegionEntity[]) {
        localStorage.setItem(this._regionsKey, JSON.stringify(entity));
    }

    getRegions(): MCRegionEntity[] {
        let object = JSON.parse(localStorage.getItem(this._regionsKey)!)
        return object ? object as MCRegionEntity[] : object
    }

    setToken(token: string) {
        sessionStorage.setItem(this._tokenKey, token);
    }

    getToken(): string {
        return sessionStorage.getItem(this._tokenKey)!;
    }

    removeToken() {
        sessionStorage.removeItem(this._tokenKey)
    }

    setTimeStart() {
        sessionStorage.removeItem(this._timeStart)
        sessionStorage.setItem(this._timeStart, new Date().toISOString());
    }

    getTimeStart(): any {
        return sessionStorage.getItem(this._timeStart);
    }


}
