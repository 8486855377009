<app-mc-toolbar [actionRequest]="actionRequestToolbar" (toolRespond)="toolRespond($event)" class="fixed-top up-div">
</app-mc-toolbar>
<div class="mobileClassTool">
  <app-mc-view-sence (senceRespond)="senceRespond($event)"></app-mc-view-sence>

  <div *ngIf="!attributesView.view_crud">
    <div class="mainDivContent">
      <div *ngIf="!attributesView.filterCollapses">
        <div class="d-flex flex-wrap pe-3">
          <div><button class="btn btn-outline-dark btnDarkCollapse"
              (click)="actionCollapses()">{{attributesView.showFilteraText}} <i
                class="bi bi-caret-down-fill"></i></button></div>
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li *ngIf="filter.code != ''" class="breadcrumb-item">Código: <span>{{filter.code}}</span></li>
                <li *ngIf="filter.title != ''" class="breadcrumb-item">Título: <span>{{filter.title}}</span></li>
                <li *ngIf="filter.selectedValueTeacher != 'notselected'" class="breadcrumb-item">Relator:
                  <span>{{filter.selectedValueTeacher}}</span></li>
                <li *ngIf="filter.modalidad != 'notselected'" class="breadcrumb-item">Modalidad: <span>{{filter.modalidad}}</span>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <button *ngIf="attributesView.filterCollapses" class="btn btn-outline-dark btnDarkCollapse"
        (click)="actionCollapses()">
        {{attributesView.hideFiltersText}} <i class="bi bi-caret-up-fill"></i>
      </button>
    </div>
    <div *ngIf="attributesView.filterCollapses" class="mat-elevation-z8 mainFilterStyle">
      <div>
        <div class="row">

          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <mat-form-field appearance="legacy">
              <mat-label>Código</mat-label>
              <input matInput maxlength="50" [(ngModel)]="filter.code" (keyup.enter)="findFilter()">
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <mat-form-field appearance="legacy">
              <mat-label>Título</mat-label>
              <input matInput maxlength="100" (paste)="false" name="tp" [(ngModel)]="filter.title" (keyup.enter)="findFilter()">
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <mat-form-field appearance="legacy">
              <mat-label>Modalidad</mat-label>
              <mat-select disableRipple [(ngModel)]="filter.modalidad" name="fmodalidad" (ngModelChange)="findFilter()">
                <mat-option value="notselected">-- Todas --</mat-option>
                <mat-option value="Presencial">Presencial</mat-option>
                <mat-option value="Virtual">Virtual</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <mat-form-field appearance="legacy">
              <mat-label>Relator</mat-label>
              <mat-select disableRipple [(ngModel)]="filter.selectedValueTeacher" name="relator" (ngModelChange)="findFilter()">
                <mat-option value="notselected">-- Relator --</mat-option>
                <mat-option *ngFor="let rep of listTeacher; let iv = index" [value]="rep.name">{{rep?.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12" style="margin-top: 15px;">
            <button mat-raised-button color="primary" (click)="findFilter()">
              <mat-icon>search</mat-icon>Buscar
            </button>
            <button mat-raised-button class="btnFilter" (click)="cleanFilter()">
              <mat-icon>brush</mat-icon>Limpiar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="before-table mat-elevation-z8">
      <div class="before-table-div">

        <table mat-table (window:resize)="onResize($event)" [dataSource]="listDataTwo" multiTemplateDataRows>

          <ng-container matColumnDef="se_first">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="modifySence(element)">
                  <mat-icon>create</mat-icon>
                  <span>Modificar</span>
                </button>
                <button mat-menu-item (click)="openModal(element.id)">
                  <mat-icon>delete</mat-icon>
                  <span>Eliminar</span>
                </button>
              </mat-menu>

            </td>
          </ng-container>

          <ng-container matColumnDef="se_code">
            <th mat-header-cell *matHeaderCellDef> Código SENCE</th>
            <td mat-cell *matCellDef="let element"> {{element?.code}} </td>
          </ng-container>

          <ng-container matColumnDef="se_title">
            <th mat-header-cell *matHeaderCellDef> Título </th>
            <td mat-cell *matCellDef="let element"> {{element?.title}} </td>
          </ng-container>

          <ng-container *ngIf="!attributesView.isMobile" matColumnDef="se_mode">
            <th mat-header-cell *matHeaderCellDef> Modalidad </th>
            <td mat-cell *matCellDef="let element"> {{element?.mode}} </td>
          </ng-container>

          <!-- <ng-container *ngIf="!attributesView.isMobile" matColumnDef="duration">
            <th mat-header-cell *matHeaderCellDef> Duración </th>
            <td mat-cell *matCellDef="let element"> {{element?.duration}} Horas</td>
          </ng-container>

          <ng-container *ngIf="!attributesView.isMobile" matColumnDef="expired_date">
            <th mat-header-cell *matHeaderCellDef> Fecha expiración </th>
            <td mat-cell *matCellDef="let element"> {{element?.expired_date | date:'dd/MM/yyyy'}} </td>
          </ng-container> -->

          <ng-container *ngIf="!attributesView.isMobile" matColumnDef="se_teacher">
            <th mat-header-cell *matHeaderCellDef> Relator </th>
            <td mat-cell *matCellDef="let element"> {{element?.teacher?.name}} </td>
          </ng-container>

          <ng-container matColumnDef="se_arrow">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <mat-icon *ngIf="attributesView.tableArrow != element">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="attributesView.tableArrow == element">keyboard_arrow_up</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
              <div class="example-element-detail" [@detailExpand]="element == listDataCopy ? 'expanded' : 'collapsed'">
                <div style="width: 100%; padding: 3px !important;">
                  <div class="row g-0 mat-elevation-z8">
                    <div class="col-12 p-2">
                      <div class="row">
                        <div class="col-12" style="padding-top: 20px;">
                          <fieldset class="scheduler-border fieldOld">
                            <legend class="scheduler-border"
                              style="margin-top: -15px !important; width: 100px !important;"> Información
                            </legend>
                            <div>
                              <div class="row">

                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa">Modalidad: </span> {{element?.mode}} </mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa">Duración: </span> {{element?.duration}} Horas
                                  </mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa">Fecha expiración: </span> {{element?.expired_date |
                                    date:'dd/MM/yyyy'}}
                                  </mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa">Relator: </span> {{element?.teacher?.name}}
                                  </mat-list-item>
                                </div>

                                <div class="col-mat-list">
                                  <mat-list-item>&nbsp;</mat-list-item>
                                </div>
                              </div>

                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>


                  </div>

                </div>


              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
            [class.example-expanded-row]="listDataCopy === element"
            (click)="listDataCopy = listDataCopy === element ? null : element; indicatorArrow(listDataCopy != element ? null : element)">
          </tr>

          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

        </table>

        <mat-paginator #paginator [length]="attributesView.lengthPaginador" [pageIndex]="attributesView.pageIndex"
          [pageSize]="attributesView.pageSize" [pageSizeOptions]="attributesView.pageSizeOptions"
          (page)="paginador($event)">
        </mat-paginator>

      </div>
    </div>

  </div>

</div>

<div class="modal fade" id="modalSence" data-bs-backdrop="static" data-bs-keyboard="false"
  aria-labelledby="staticBackdropLabel" aria-hidden="false" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h2 id="staticBackdropLabel">Eliminar Sence</h2>
        <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        <div>
          <div>
            <form>
              <div class="row form-box-style">
                <h4>¿Si elimina este registro no podrá recuperarlo, desea eliminar el registro?</h4>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row" style="width: 100%;">
          <div class="col-6">
            <button mat-raised-button color="warn" (click)="deleteSence()" style="width: 100%;">Sí</button>
          </div>
          <div class="col-6">
            <button mat-raised-button (click)="closeModal()" style="width: 100%;">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
