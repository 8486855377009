import { MCError } from "src/app/mc-helpers/mc-error";
import { MCDataSourceAPICoreBuilder } from "src/app/mc-entity/mc-builders/mc-data-source-api-core-builder";
import { MCDataSourceLocal, MCDataSourceLocalInterface } from "../mc-data-source-local";
import { MCDataSourceAPI } from "./mc-data-source-api";
import { MCEndpointsConstants } from "src/app/mc-helpers/mc-constants/mc-endpoints-constants";
import { HttpParams } from "@angular/common/http";
import { MCConstants } from "src/app/mc-helpers/mc-constants/mc-constants";
 import { MCHttpMethodsEnum } from "src/app/mc-helpers/mc-enums/mc-http-methods-enum";
import { MCSenceEntityRequests, MCSencesEntity } from "src/app/mc-entity/mc-entities/mc-sence-entitys";
import { Injectable } from "@angular/core";
import { MCUtils } from "src/app/mc-helpers/mc-class/mc-utils";


export interface MCSenceAPIInterface {

  getSence(
    code_sence:string,
    success: () => void,
    failure: (error: MCError) => void
  ): any;

  getSences(
    page: number,
    limit: number,
    code_sence: string,
    title:string,
    teacher_name: string,
    mode: string,
    success: (entity: MCSencesEntity) => void,
    failure: (error: MCError) => void
  ): any;

  putSence(
    idSence: string,
    sence: MCSenceEntityRequests,
    success: () => void,
    failure: (error: MCError) => void
  ): any;

  postSence(
    sence: MCSenceEntityRequests,
    success: (entity: any) => void,
    failure: (error: MCError) => void
  ): any;

   deleteSence(
     idSence: string,
     success: () => void,
     failure: (error: MCError) => void
   ): any;

}
@Injectable({
  providedIn: 'root'
})
export class MCSenceAPI implements MCSenceAPIInterface {
  // Private properties.

  private _omDataSourceLocal: MCDataSourceLocalInterface = new MCDataSourceLocal();

  // Contructors.

  constructor(private _apiDataSource: MCDataSourceAPI, private _mcUtils: MCUtils) { }


  postSence(
    sence: MCSenceEntityRequests,
    success: (entity: any) => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    builder.endpoint = MCEndpointsConstants.senceAPI.sences;
    builder.httpMethod = MCHttpMethodsEnum.post;
    builder.body = JSON.stringify(sence);
    this._apiDataSource.core(builder).subscribe((data: any) => {
      success(data);
    }, error => {
      console.log(error);
      failure(new MCError(error));
    })
  }

  putSence(
    idSence: string,
    sence: MCSenceEntityRequests,
    success: () => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    builder.httpMethod = MCHttpMethodsEnum.put;
    builder.body = JSON.stringify(sence);
    builder.endpoint = MCEndpointsConstants.senceAPI.sence.replace("[ID]", idSence);
    this._apiDataSource.core(builder).subscribe(() => {
      success()
    }, error => {
      failure(new MCError(error));
    })
  }

  deleteSence(
    idSence: string,
    success: () => void,
    failure: (error: MCError) => void
  ) {
    console.log(idSence);
    const builder = new MCDataSourceAPICoreBuilder();
    builder.httpMethod = MCHttpMethodsEnum.delete;
    builder.endpoint = MCEndpointsConstants.senceAPI.sence.replace("[ID]", idSence);
    this._apiDataSource.core(builder).subscribe(() => {
      success()
    }, error => {
      console.log(error);
      failure(new MCError(error));
    })
  }

  getSence (
    code_sence: string,
    success: () => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    builder.endpoint = MCEndpointsConstants.senceAPI.sence.replace("[ID]", code_sence);
    this._apiDataSource.core(builder).subscribe((data: any) => {
      success()
    }, error => {
      failure(new MCError(error));
    })
  }

  getSences (
    page: number,
    limit: number,
    code_sence: string,
    title:string,
    teacher_name: string,
    mode: string,
    success: (entity: MCSencesEntity) => void,
    failure: (error: MCError) => void
  ) {
    const builder = new MCDataSourceAPICoreBuilder();
    let params: HttpParams = new HttpParams();
    params = params.append(MCConstants.paramLimit, limit.toString());
    params = params.append(MCConstants.paramPage, page.toString());

    if(this._mcUtils.validateStringUndefined(code_sence)) {
      params = params.append(MCConstants.paramCodeSence, code_sence)
    }
    if(this._mcUtils.validateStringUndefined(title)) {
      params = params.append(MCConstants.paramTitle, title)
    }
    if(teacher_name != MCConstants.notSelected) {
      params = params.append(MCConstants.paramTeacherName, teacher_name)
    }
    if(mode != MCConstants.notSelected) {
      params = params.append(MCConstants.paramTeacherMode, mode)
    }
    builder.httpParams = params;
    builder.endpoint = MCEndpointsConstants.senceAPI.sences;
    this._apiDataSource.core(builder).subscribe((data: any) => {
      success(new MCSencesEntity(data.meta.total,data.data))
    }, error => {
      failure(new MCError(error));
    })
  }

}
