<div>
  <app-mc-course-student (inscriptionRespond)="inscriptionRespond($event)"></app-mc-course-student>
  <div *ngIf="!attributesView.view_crud">

    <div class="mainFilterStyled">
      <div class="d-flex bd-highlight">
        <div class="w-100 bd-highlight">
          <mat-form-field>
            <mat-label> Buscar Por Apellidos y Nombre </mat-label>
            <input matInput type="text" maxlength="100" name="find" placeholder="Ej. López Álvarez Erwis José"
              [(ngModel)]="attributesView.search" (keyup.enter)="search('1')">
            <button mat-button matSuffix mat-icon-button aria-label="Find" class="btnBusquedas" (click)="search('1')">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="flex-shrink-1 bd-highlight">
          <button mat-raised-button color="primary" (click)="registerStudent()"
            style="margin-top: 8px; margin-left: 10px;">
            <mat-icon>add</mat-icon> {{attributesView.btnTitle}}
          </button>
        </div>
      </div>
    </div>

    <div class="before-table mat-elevation-z8">
      <div class="before-table-div">

        <table mat-table (window:resize)="onResize($event)" [dataSource]="listDataTwo" multiTemplateDataRows>

          <ng-container matColumnDef="scu_first">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
              <!-- <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="openModal(element)">
                  <mat-icon>delete</mat-icon>
                  <span>Desinscribir</span>
                </button>
              </mat-menu> -->

              <button mat-icon-button (click)="openModal(element)"><i class="bi bi-clipboard2-x-fill"
                  style="font-size: 18px;"></i></button>

            </td>
          </ng-container>

          <ng-container matColumnDef="scu_last_name">
            <th mat-header-cell *matHeaderCellDef> Apellido Paterno </th>
            <td mat-cell *matCellDef="let element"> {{element?.last_name}} </td>
          </ng-container>

          <ng-container matColumnDef="scu_mothers_last_name">
            <th mat-header-cell *matHeaderCellDef> Apellido Materno </th>
            <td mat-cell *matCellDef="let element"> {{element?.mothers_last_name}} </td>
          </ng-container>

          <ng-container matColumnDef="scu_name">
            <th mat-header-cell *matHeaderCellDef> Nombres </th>
            <td mat-cell *matCellDef="let element"> {{element?.name}} </td>
          </ng-container>

          <ng-container *ngIf="!attributesView.isMobileTwo" matColumnDef="scu_document">
            <th mat-header-cell *matHeaderCellDef> RUT </th>
            <td mat-cell *matCellDef="let element"> {{element?.document}} </td>
          </ng-container>

          <ng-container *ngIf="!attributesView.isMobileTwo" matColumnDef="scu_cust_document">
            <th mat-header-cell *matHeaderCellDef> RUT Institución </th>
            <td mat-cell *matCellDef="let element"> {{element?.document_customer}} </td>
          </ng-container>


          <ng-container matColumnDef="scu_arrow">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <mat-icon *ngIf="attributesView.tableArrow != element">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="attributesView.tableArrow == element">keyboard_arrow_up</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
              <div class="example-element-detail" [@detailExpand]="element == listDataCopy ? 'expanded' : 'collapsed'">
                <div style="width: 100%; padding: 3px !important;">
                  <div class="row g-0 mat-elevation-z8">
                    <div class="col-12" style="padding: 10px !important; padding-top: 20px !important;">
                      <fieldset class="scheduler-border">
                        <legend class="scheduler-border" style="margin-top: -15px !important; width: 100px !important;">
                          Información
                        </legend>

                        <div class="row">

                          <div class="col-12 col-md-6 p-2">

                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">RUT: </span> {{element?.document}}
                              </mat-list-item>
                            </div>

                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Institución: </span>
                                {{element?.customer?.institution}}</mat-list-item>
                            </div>

                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Profesión/Cargo: </span> {{element?.profession}}
                              </mat-list-item>
                            </div>

                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Correo: </span> {{element?.email}} </mat-list-item>
                            </div>

                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Observación: </span> {{element?.observation}}
                              </mat-list-item>
                            </div>
                          </div>

                          <div class="col-12 col-md-6 p-2">
                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">RUT: </span> {{element?.document_customer}}
                              </mat-list-item>
                            </div>


                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Razón Social: </span> {{element?.businessName_customer}}
                              </mat-list-item>
                            </div>

                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Celular: </span> +56 {{element?.celular_customer}}
                              </mat-list-item>
                            </div>

                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Región: </span> {{element?.region_customer}}
                              </mat-list-item>
                            </div>

                            <div class="col-mat-list">
                              <mat-list-item><span class="spa">Comuna: </span> {{element?.comuna_customer}}
                              </mat-list-item>
                            </div>
                          </div>


                        </div>

                      </fieldset>


                    </div>

                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
            [class.example-expanded-row]="listDataCopy === element"
            (click)="listDataCopy = listDataCopy === element ? null : element; indicatorArrow(listDataCopy != element ? null : element)">
          </tr>

          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

        </table>

        <mat-paginator #paginator [length]="attributesView.lengthPaginador" [pageIndex]="attributesView.pageIndex"
          [pageSize]="attributesView.pageSize" [pageSizeOptions]="attributesView.pageSizeOptions"
          (page)="paginador($event)">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
