import { MCConstants } from "src/app/mc-helpers/mc-constants/mc-constants";

export class MCSchedulesEntity {
    total: number;
    entities: MCScheduleEntity[] = [];

    constructor(total: number, object: any) {
        this.total = total ?? 0;
        if (object) {
            for (let entity of object) {
                this.entities.push(new MCScheduleEntity(entity));
            }
        }
    }
}

export class MCScheduleEntity {
    id?: number;
    day: string;
    start_time: string;
    final_time: string;

    constructor(object: any) {
        this.id = object.id ?? 0;
        this.day = object.day ?? this.transformDate();
        this.start_time = object.start_time ?? String();
        this.final_time = object.final_time ?? String();
    }

    transformDate() : string{
        let date = new Date();
        return date.toLocaleDateString('sv-SE');
    }
}


export class MCScheduleEntityRequests {
  id?: number;
  day: string;
  start_time: string;
  final_time: string;

    constructor(object: any) {
        this.id = object.id ?? 0;
        this.day = this.transformDate(object.day);
        this.start_time = object.start_time;
        this.final_time = object.final_time;
    }


    transformDate(valor: string) : string {
        return new Date(valor).toISOString().split('T')[0];
    }

}
