export class MCToolbarClass {

    whereView: string;
    actionRequest: string;
    
    constructor(whereView: string , actionRequest: string){
        this.whereView = whereView;
        this.actionRequest = actionRequest;
    }
}
