<app-mc-toolbar [actionRequest]="actionRequestToolbar" (toolRespond)="toolRespond($event)" class="fixed-top up-div">
</app-mc-toolbar>
<div class="mobileClassTool">
  <app-mc-view-student (studentRespond)="studentRespond($event)"></app-mc-view-student>

  <div *ngIf="!attributesView.view_crud">
    <div class="mainDivContent">
      <div *ngIf="!attributesView.filterCollapses">
        <div class="d-flex flex-wrap pe-3">
          <div><button class="btn btn-outline-dark btnDarkCollapse"
              (click)="actionCollapses()">{{attributesView.showFilteraText}} <i
                class="bi bi-caret-down-fill"></i></button></div>
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li *ngIf="filter.last_name!= ''" class="breadcrumb-item">Apellido Paterno:
                  <span>{{filter.last_name}}</span></li>
                <li *ngIf="filter.mothers_last_name!= ''" class="breadcrumb-item">Apellido Materno:
                  <span>{{filter.mothers_last_name}}</span></li>
                <li *ngIf="filter.name != ''" class="breadcrumb-item">Nombre: <span>{{filter.name}}</span></li>
                <li *ngIf="filter.mothers_last_name!= ''" class="breadcrumb-item">Curso Base:
                  <span>{{filter.course_base}}</span></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <button *ngIf="attributesView.filterCollapses" class="btn btn-outline-dark btnDarkCollapse"
        (click)="actionCollapses()">
        {{attributesView.hideFiltersText}} <i class="bi bi-caret-up-fill"></i>
      </button>
    </div>
    <div *ngIf="attributesView.filterCollapses" class="mat-elevation-z8 mainFilterStyle">
      <div>
        <div class="row">

          <div class="col-12 col-sm-4">
            <mat-form-field appearance="legacy">
              <mat-label>Apellido Paterno</mat-label>
              <input matInput maxlength="30" name="fap" [(ngModel)]="filter.last_name" (keyup.enter)="findFilter()">
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-4">
            <mat-form-field appearance="legacy">
              <mat-label>Apellido Materno</mat-label>
              <input matInput maxlength="30" name="fam" [(ngModel)]="filter.mothers_last_name" (keyup.enter)="findFilter()">
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-4">
            <mat-form-field appearance="legacy">
              <mat-label>Nombre</mat-label>
              <input matInput maxlength="30" name="fname" [(ngModel)]="filter.name" (keyup.enter)="findFilter()">
            </mat-form-field>
          </div>


          <div class="col-12 col-sm-6">
            <mat-form-field appearance="legacy">
              <mat-label>RUT</mat-label>
              <input matInput maxlength="20" (paste)="false" name="rut" placeholder="Ejemplo: 19.324.511-k"
                (keydown)="formatRut($event)" (keyup)="formatRut($event)" [ngModel]="filter.document" (keyup.enter)="findFilter()">
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6">
            <mat-form-field appearance="legacy">
              <mat-label>Curso Base</mat-label>
              <mat-select disableRipple [(ngModel)]="filter.course_base" name="coubas" (ngModelChange)="findFilter()">
                <mat-option value="notselected">-- Seleccione --</mat-option>
                <mat-option *ngFor="let cuba of listBasicCourse; let vx = index" [value]="cuba.id">{{cuba?.id}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12" style="margin-top: 15px;">
            <button mat-raised-button color="primary" (click)="findFilter()">
              <mat-icon>search</mat-icon>Buscar
            </button>
            <button mat-raised-button class="btnFilter" (click)="cleanFilter()">
              <mat-icon>brush</mat-icon>Limpiar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="before-table mat-elevation-z8">
      <div class="before-table-div">

        <table mat-table (window:resize)="onResize($event)" [dataSource]="listDataTwo" multiTemplateDataRows>

          <ng-container matColumnDef="sco_first">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="modifyStudent(element)">
                  <mat-icon>create</mat-icon>
                  <span>Modificar</span>
                </button>
                <button mat-menu-item (click)="openModal(element)">
                  <mat-icon>delete</mat-icon>
                  <span>Eliminar</span>
                </button>
              </mat-menu>

            </td>
          </ng-container>

          <ng-container matColumnDef="sco_last_name">
            <th mat-header-cell *matHeaderCellDef> Apellido Paterno </th>
            <td mat-cell *matCellDef="let element"> {{element?.student?.last_name}} </td>
          </ng-container>

          <ng-container matColumnDef="sco_mothers_last_name">
            <th mat-header-cell *matHeaderCellDef> Apellido Materno </th>
            <td mat-cell *matCellDef="let element"> {{element?.student?.mothers_last_name}} </td>
          </ng-container>

          <ng-container matColumnDef="sco_name">
            <th mat-header-cell *matHeaderCellDef> Nombre </th>
            <td mat-cell *matCellDef="let element"> {{element?.student?.name}} </td>
          </ng-container>

          <ng-container *ngIf="!attributesView.isMobile" matColumnDef="sco_document">
            <th mat-header-cell *matHeaderCellDef> RUT </th>
            <td mat-cell *matCellDef="let element"> {{element?.student?.document}} </td>
          </ng-container>

          <ng-container *ngIf="!attributesView.isMobile" matColumnDef="sco_cust_document">
            <th mat-header-cell *matHeaderCellDef> RUT Institución </th>
            <td mat-cell *matCellDef="let element"> {{element?.inscription?.customer?.type != 'Natural' ?
              element?.inscription?.customer?.document: ""}} </td>
          </ng-container>

          <ng-container *ngIf="!attributesView.isMobile" matColumnDef="sco_code_course">
            <th mat-header-cell *matHeaderCellDef> Curso Base </th>
            <td mat-cell *matCellDef="let element">
              {{element?.inscription?.course?.course?.id}}-{{element?.inscription?.course?.id}} </td>
          </ng-container>

          <ng-container *ngIf="!attributesView.isMobile" matColumnDef="sco_scope">
            <th mat-header-cell *matHeaderCellDef> Tipo </th>
            <td mat-cell *matCellDef="let element"> {{element?.inscription?.course?.scope}} </td>
          </ng-container>

          <ng-container *ngIf="!attributesView.isMobileTwo" matColumnDef="sco_region">
            <th mat-header-cell *matHeaderCellDef> Región </th>
            <td mat-cell *matCellDef="let element"> {{element?.inscription?.customer?.commune?.region?.id}} </td>
          </ng-container>

          <ng-container *ngIf="!attributesView.isMobileTwo" matColumnDef="sco_comune">
            <th mat-header-cell *matHeaderCellDef> Comuna </th>
            <td mat-cell *matCellDef="let element"> {{element?.inscription?.customer?.commune?.name}}</td>
          </ng-container>

          <ng-container matColumnDef="sco_arrow">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <mat-icon *ngIf="attributesView.tableArrow != element">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="attributesView.tableArrow == element">keyboard_arrow_up</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
              <div class="example-element-detail" [@detailExpand]="element == listDataCopy ? 'expanded' : 'collapsed'">
                <div style="width: 100%; padding: 3px !important;">
                  <!-- <div class="row g-0 borderExpandDetail mat-elevation-z8"> -->
                  <div class="row g-0 mat-elevation-z8">
                    <div class="col-12 col-md-6 col-lg-6 p-2">
                      <div class="row">
                        <div class="col-12" style="padding-top: 20px;">
                          <fieldset class="scheduler-border fieldOld">
                            <legend class="scheduler-border"
                              style="margin-top: -15px !important; width: 100px !important;"> Información
                            </legend>
                            <div>
                              <div class="row">
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa">RUT: </span> {{element?.student?.document}} </mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa">Profesión/Cargo: </span> {{element?.student?.profession}}
                                  </mat-list-item>
                                </div>
                                <div class="col-mat-list" *ngIf="element?.inscription?.customer?.type == 'Natural'">
                                  <mat-list-item><span class="spa">Institución: </span> {{element?.student?.institution}}
                                  </mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa">Correo: </span> {{element?.student?.email}}</mat-list-item>
                                </div>
                                <div class="col-mat-list" *ngIf="element?.student?.institution != null">
                                  <mat-list-item><span class="spa">Región: </span>
                                    {{element?.inscription?.customer?.commune?.region?.id}}
                                  </mat-list-item>
                                </div>
                                <div class="col-mat-list" *ngIf="element?.student?.institution != null">
                                  <mat-list-item><span class="spa">Comuna: </span>
                                    {{element?.inscription?.customer?.commune?.name}}
                                  </mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa">Observación: </span> {{element?.student?.observation}}
                                  </mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item>&nbsp;</mat-list-item>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-6 p-2">
                      <div class="row">
                        <div class="col-12 col-md-12 col-lg-12" style="padding-top: 20px;">
                          <fieldset class="scheduler-border fieldOld">
                            <legend class="scheduler-border"
                              style="margin-top: -15px !important; width: 170px !important;"> Datos del Curso </legend>
                            <div>
                              <div class="row">
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa"> Código:</span>
                                    {{element?.inscription?.course?.course?.id}}-{{element?.inscription?.course?.id}}
                                  </mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa"> Nombre:</span> {{element?.inscription?.course?.name_course}}
                                  </mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa"> Titulo:</span>
                                    {{element?.inscription?.course?.certificate_title_course}}</mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa"> Fecha de Inicio:</span>
                                    {{element?.inscription?.course?.start_date | date:'dd/MM/yyyy'}}</mat-list-item>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>

                        <div class="col-12 col-md-12 col-lg-12" style="padding-top: 35px;" *ngIf="element?.inscription?.customer?.type != 'Natural'">
                          <fieldset class="scheduler-border fieldOld">
                            <legend class="scheduler-border" style="margin-top: -15px !important; width: 200px !important;">Institución</legend>
                            <div>
                              <div class="row">
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa"> RUT: </span> {{element?.inscription?.customer?.document}}
                                  </mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa"> Razón Social: </span>
                                    {{element?.inscription?.customer?.name}}</mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa"> Región: </span>
                                    {{element?.inscription?.customer?.commune?.region?.id}}
                                  </mat-list-item>
                                </div>
                                <div class="col-mat-list">
                                  <mat-list-item><span class="spa"> Comuna: </span>
                                    {{element?.inscription?.customer?.commune?.name}}
                                  </mat-list-item>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>

                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </td>
          </ng-container>

    <!--       <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
              <div class="example-element-detail" [@detailExpand]="element == listDataCopy ? 'expanded' : 'collapsed'">

                <div style="width: 100%; padding: 3px !important;">
                  <div class="row g-0 mat-elevation-z8">
                    <div class="col-12 col-md-6 p-2">
                      <div class="row">
                        <div class="col-12" style="padding-top: 20px;">
                          <fieldset class="scheduler-border fieldOld">
                            <legend class="scheduler-border"
                              style="margin-top: -15px !important; width: 100px !important;"> Información
                            </legend>
                            <div>
                              <div class="row">
                      <div class="col-mat-list">
                        <mat-list-item><span class="spa">RUTs: </span> {{element?.student?.document}} </mat-list-item>
                      </div>
                      <div class="col-mat-list">
                        <mat-list-item><span class="spa">Profesión/Cargo: </span> {{element?.student?.profession}}
                        </mat-list-item>
                      </div>
                      <div class="col-mat-list" *ngIf="element?.inscription?.customer?.type == 'Natural'">
                        <mat-list-item><span class="spa">Institución: </span> {{element?.student?.institution}}
                        </mat-list-item>
                      </div>
                      <div class="col-mat-list">
                        <mat-list-item><span class="spa">Correo: </span> {{element?.student?.email}}</mat-list-item>
                      </div>
                      <div class="col-mat-list" *ngIf="element?.student?.institution != null">
                        <mat-list-item><span class="spa">Región: </span>
                          {{element?.inscription?.customer?.commune?.region?.id}}
                        </mat-list-item>
                      </div>
                      <div class="col-mat-list" *ngIf="element?.student?.institution != null">
                        <mat-list-item><span class="spa">Comuna: </span>
                          {{element?.inscription?.customer?.commune?.name}}
                        </mat-list-item>
                      </div>
                      <div class="col-mat-list">
                        <mat-list-item><span class="spa">Observación: </span> {{element?.student?.observation}}
                        </mat-list-item>
                      </div>
                    </div>
                  </div>
                </fieldset>
                    </div>
                  </div>

                    </div>

                    <div class="col-12 col-md-6 p-2">
                      <fieldset class="scheduler-border fieldOld">
                        <legend class="scheduler-border"
                          style="margin-top: -15px !important; width: 170px !important;"> Títulos Profesionales
                        </legend>
                      <div class="col-mat-list">
                        <mat-list-item><span class="spa"><u>Datos del Curso</u></span></mat-list-item>
                      </div>
                      <div class="col-mat-list">
                        <mat-list-item><span class="spa"> Código:</span>
                          {{element?.inscription?.course?.course?.id}}-{{element?.inscription?.course?.id}}
                        </mat-list-item>
                      </div>
                      <div class="col-mat-list">
                        <mat-list-item><span class="spa"> Nombre:</span> {{element?.inscription?.course?.name_course}}
                        </mat-list-item>
                      </div>
                      <div class="col-mat-list">
                        <mat-list-item><span class="spa"> Titulo:</span>
                          {{element?.inscription?.course?.certificate_title_course}}</mat-list-item>
                      </div>
                      <div class="col-mat-list">
                        <mat-list-item><span class="spa"> Fecha de Inicio:</span>
                          {{element?.inscription?.course?.start_date | date:'dd/MM/yyyy'}}</mat-list-item>
                      </div>
                    </fieldset>

                      <ng-container *ngIf="element?.inscription?.customer?.type != 'Natural'">
                        <fieldset class="scheduler-border fieldOld">
                          <legend class="scheduler-border"
                            style="margin-top: -15px !important; width: 170px !important;"> Títulos Profesionales
                          </legend>
                        <div class="col-mat-list">
                          <mat-list-item><span class="spa"><u>Institución</u></span></mat-list-item>
                        </div>

                        <div class="col-mat-list">
                          <mat-list-item><span class="spa"> RUT: </span> {{element?.inscription?.customer?.document}}
                          </mat-list-item>
                        </div>
                        <div class="col-mat-list">
                          <mat-list-item><span class="spa"> Razón Social: </span>
                            {{element?.inscription?.customer?.name}}</mat-list-item>
                        </div>
                        <div class="col-mat-list">
                          <mat-list-item><span class="spa"> Región: </span>
                            {{element?.inscription?.customer?.commune?.region?.id}}
                          </mat-list-item>
                        </div>
                        <div class="col-mat-list">
                          <mat-list-item><span class="spa"> Comuna: </span>
                            {{element?.inscription?.customer?.commune?.name}}
                          </mat-list-item>
                        </div>
                      </fieldset>
                      </ng-container>
                    </div>
                  </div>
                </div>

              </div>
            </td>
          </ng-container> -->

          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
            [class.example-expanded-row]="listDataCopy === element"
            (click)="listDataCopy = listDataCopy === element ? null : element; indicatorArrow(listDataCopy != element ? null : element)">
          </tr>

          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

        </table>

        <mat-paginator #paginator [length]="attributesView.lengthPaginador" [pageIndex]="attributesView.pageIndex"
          [pageSize]="attributesView.pageSize" [pageSizeOptions]="attributesView.pageSizeOptions"
          (page)="paginador($event)">
        </mat-paginator>

      </div>
    </div>

  </div>

</div>

<div class="modal fade" id="staticBasicCourse" data-bs-backdrop="static" data-bs-keyboard="false"
  aria-labelledby="staticBackdropLabel" aria-hidden="false" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h2 id="staticBackdropLabel">Eliminar Participante</h2>
        <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        <div>
          <div>
            <form>
              <div class="row form-box-style">
                <h4>¿Si elimina este registro no podrá recuperarlo, desea eliminar el registro?</h4>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row" style="width: 100%;">
          <div class="col-6">
            <button mat-raised-button color="warn" style="width: 100%;" (click)="deleteStudent()">Sí</button>
          </div>
          <div class="col-6">
            <button mat-raised-button (click)="closeModal()" style="width: 100%;">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
